<template>
    <div class="flex">
        <div class="message-avatar-container message-avatar-container__admin">
            <div v-if="message.author.avatar.square_25">
                <div
                    :style="{ backgroundImage : 'url(' + message.author.avatar.square_25 + ')'}"
                    class="conversation-profile-circle bg-img-cover"
                >
                </div>
            </div>
            <div v-else class="conversation-profile-circle-patient-container">
                <img src="@/assets/icons/profile.svg" alt="profile image" />
            </div>
        </div>
        <div class="message-bubble">
            <div class="message-bubble__message-info">
                <span class="message-bubble__message-info">
                    <span class="message-bubble__admin-name">{{ admin_name }}</span>
                    <span class="message-bubble__message-timestamp">{{ created_at }}</span>
                </span>
            </div>
            <div class="message-bubble__container message-bubble__admin">
                <div v-for="(block, index) in message.blocks" :key="index">
                    <TextBlockComment
                        v-if="block.type === 'paragraph'"
                        :block="block"
                        :block_obj="block"
                        :index_num="index"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import TextBlockComment from '@/components/ConversationComponents/BlockComponents/TextBlockComment.vue';

export default {
    name: 'AdminBubble',
    components: {
        TextBlockComment,
    },
    props: ['message', 'conversation_details', 'last_patient_sent'],
    data() {
        return {};
    },
    computed: {
        admin_name() {
            return this.message.author.display_name ? this.message.author.display_name : this.message.author.first_name;
        },
        created_at() {
            return moment(this.message.created_at).format('MMM Do, h:mm A');
        },
    },
};

</script>
