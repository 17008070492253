import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import Vue from 'vue';

import App from './App.vue';
import router from './router';
import { initializeSentry } from './sentryConfig';
import store from './store/index.js';

Vue.config.ignoredElements = [/^ion-/];

//check if there is a token and attach to Authorization on header if available
Vue.prototype.$http = axios;

const token = localStorage.getItem('hs_token') || null;

if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

import VueSnip from 'vue-snip';
Vue.use(VueSnip);

//plugins
import VueMask from 'v-mask';

Vue.use(VueMask);

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import VueGtagPlugin from 'vue-gtag';

Vue.use(VueGtagPlugin, {
    config: {
        id: 'G-KG8PLYYJ3K',
        allow_enhanced_conversions: true,
    },
});

window.gtag('config', 'G-KG8PLYYJ3K', {
    allow_enhanced_conversions: true,
});
window.gtag('config', 'AW-375008541', {
    allow_enhanced_conversions: true,
});

import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);

import VueCalendly from 'vue-calendly';

Vue.use(VueCalendly);

import Clipboard from 'v-clipboard';

Vue.use(Clipboard);

import VueChatScroll from 'vue-chat-scroll';

Vue.use(VueChatScroll);

import InfiniteLoading from 'vue-infinite-loading';

Vue.use(InfiniteLoading);

import moment from 'moment';
import linkify from 'vue-linkify';

Vue.directive('linkified', linkify);

//universal filter
Vue.filter('nodecimal', function (value) {
    if (!value) {
        value = 0;
    }

    return Math.round(parseInt(value));
});

Vue.filter('space', function (value) {
    if (!value) {
        return '';
    } else {
        return value.replace(/[-_]/g, ' ');
    }
});

Vue.filter('moment_date', function (value) {
    if (!value) {
        return '';
    } else {
        return moment(value).format('MMMM Do YYYY');
    }
});

Vue.filter('unix_date', (date, format) => {
    return moment.unix(date).format(format);
});

Vue.config.productionTip = false;

Vue.prototype.$http.interceptors.response.use((response) => {
    return response;
}, (err) => {
    if (err.isAxiosError) {
        if (err.response && err.response.data) {
            const error = err.response.data;

            if ('error' in error) {
                if (error.message === 'TokenExpired') {
                    if (router.currentRoute.path !== '/login') {
                        return router.push('/login?expired=true');
                    }
                } else {
                    throw err;
                }
            } else {
                throw { ...error, status: err.response.status };
            }
        } else {
            throw err.response;
        }
    } else {
        throw err;
    }

});

if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
    initializeSentry(Vue, router);
}

new Vue({
    router,
    store,
    beforeCreate() {
        this.$store.commit('authenticate/initializeUserStore');
        this.$store.commit('user/initializeUserStore');
        this.$store.commit('intake/initializeIntakeId');
        this.$store.commit('initializeOrg');
    },

    render: h => h(App),
}).$mount('#app');
