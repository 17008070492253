<template>
<div>
    <div class="zp-block-image-container">
        <img :src="block_obj.url" class="block-image"/>

    </div>
</div>
</template>

<script>
export default {
    props: ['block_obj', 'index_num']
}

</script>

