import Vue from "vue";

export const nav = {
    namespaced: true,
    state: {
        title: 'Checkout'
    },
    mutations: {
        setNavTitle(state, title) {
            Vue.set(state, 'title', title);
        }
    }
}