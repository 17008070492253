var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:{
			wide: _vm.wide,
			'full-width': _vm.full_width,
			large: _vm.large,
			primary: _vm.variant === 'primary',
			secondary: _vm.variant === 'secondary',
			tertiary: _vm.variant === 'tertiary',
			'secondary-cta': _vm.variant === 'secondary-cta',
			// @deprecated, use one of the above variants and cta
			ctaVariant: _vm.variant === 'cta',
			cta: !!_vm.cta,
			loading: _vm.loading,
			'loading loading__primary': _vm.loading && (_vm.variant === 'primary' || _vm.variant === 'cta'),
			disabled: _vm.disabled,
            small: _vm.small,
		},attrs:{"type":_vm.type && _vm.type,"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){_vm.handleClick ? _vm.handleClick() : null}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))])])
}
var staticRenderFns = []

export { render, staticRenderFns }