<template>
    <div class="settings-section">
        <div class="settings-alert">
            <transition name="alert-fade" mode="out-in">
                <HAlert v-if="alert.status" :message="alert.message" :status="alert.status" @dismiss="dismissAlert" />
            </transition>
        </div>
        <div class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div class="settings-section__header">
            <h2>Profile Image</h2>
            <p>An image of your face</p>
        </div>
        <div class="settings-section__content settings-section__image">
            <div v-if="profileImage.profile_url && !uploadOpen" class="settings-image">
                <img :src="profileImageSrc" alt="profile photo"> 
                <button class="settings-image__btn settings-image__btn-change" @click="showUploadOptions">
                    Change Picture
                </button>
            </div>
            <div v-if="!profileImage.profile_url || uploadOpen" class="settings-upload">
                <div v-if="!canvasOpen" class="settings-upload__default">
                    <button v-if="profileImage.profile_url" class="settings-upload__btn-back" @click="showUploadOptions">
                        <img src="@/assets/icons/arrow-back-circle.svg" alt="back arrow">
                    </button>
                    <div class="settings-upload__default-img">
                        <img src="@/assets/images/verification/selfie_icon.svg">
                    </div>
                </div>
                <div v-if="canvasOpen" class="settings-upload__canvas">
                    <video v-if="!image" id="video" ref="video" autoplay />
                    <div v-if="image" class="settings-image">
                        <img :src="image" alt="profile photo">
                    </div>
                </div>
                <div v-if="!image" class="settings-upload__actions">
                    <div v-if="!canvasOpen" class="settings-upload__take">
                        <button class="settings-upload__btn settings-upload__btn--take" @click="openCanvas">
                            Take Photo
                        </button>
                        <div class="settings-upload__file">
                            <label class="settings-upload__btn settings-upload__btn--upload" for="file">
                                Upload Photo
                            </label>
                            <input
                                id="file"
                                ref="file"
                                class="hidden-radio"
                                type="file"
                                accept="image/*"
                                @change="uploadPhoto()"
                            >
                        </div>
                    </div>
                    <div v-if="canvasOpen" class="settings-upload__capture">
                        <div v-if="captureInProgress" class="settings-upload__capture-progress">
                            say cheese! wait while we take photo
                        </div> 
                        <div v-else class="settings-upload__btn-container">
                            <button class="settings-upload__btn" @click="capture">
                                Capture
                            </button>
                            <button class="settings-upload__btn settings-upload__btn--cancel" @click="closeCanvas">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="image" class="settings-upload__retake">
                    <button class="settings-upload__retake-btn" @click="reset">
                        Retake
                    </button>
                </div>
            </div>
            <div v-if="image && !file_size_error && !general_error" class="settings-upload__btn-submit">
                <h-button
                    variant="secondary"
                    :loading="submitting"
                    class="settings-section__btn"
                    @click="submitFile"
                >
                    Submit
                </h-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import HAlert from '@/components/Shared/HAlert.vue';
import HButton from '@/components/Shared/HButtonV2.vue';
import Alert from '@/helpers/alert';

export default {
    name: 'ProfileImageSection',
    components: {
        HButton,
        HAlert,
    },
    data() {
        return {
            profileImage: null,
            uploadOpen: false,
            mediaStream: null,
            upload_type: null,
            camera_photo: false,
            canvasOpen: false,
            captureInProgress: false,
            file: '',
            image: '',
            alert: new Alert(),
            file_size_error: null,
            general_error: null,
            submitting: false,
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        profileImageSrc() {
            return `${this.profileImage.profile_url}?t=${this.profileImage.date_uploaded}`;
        },
    },
    created() {
        this.profileImage = this.user.image?.profile;    
    },
    methods: {
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        dismissAlert() {
            this.alert.dismiss();
        },
        showUploadOptions() {
            this.uploadOpen = !this.uploadOpen;
        },
        reset() {
            this.file = '';
            this.image = '';
            this.canvasOpen = false;
            this.uploadOpen = true;

            if (this.file_size_error || this.general_error){
                this.dismissAlert();
            }
            this.file_size_error = null;
            this.general_error = null;
        },
        openCanvas() {
            let vm = this;

            this.canvasOpen = true;

            this.upload_type = 'camera';
            //set a flag that will display photo booth UI
            this.camera_photo = true;

            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

                //stream video
                navigator.mediaDevices.getUserMedia({ video: true }).then(mediaStream => {

                    //set media stream
                    vm.mediaStream = mediaStream;

                    //set source of the video to the media stream
                    vm.$refs.video.srcObject = mediaStream;

                    //start the video streaming to the video element
                    vm.$refs.video.play();
                });
            }
        },
        capture() {
            let vm = this;

            this.captureInProgress = true;

            if (!('ImageCapture' in window)) {
                this.safariImageCapture();
                
                return;
            }
            //take a track of video now i.e. {kind:video, label : "FaceTime HD Camera"}
            const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
            const imageCapture = new window.ImageCapture(mediaStreamTrack);

            //snap the image via takePhoto
            return imageCapture.takePhoto().then(image => {
                var reader = new FileReader();

                //set the read image to the img video
                reader.onload = (e) => {
                    vm.image = e.target.result;
                };
                //convert the image into base64
                reader.readAsDataURL(image);

                //set the base 64 data on completion
                reader.onloadend = function () {
                    var base64data = reader.result;

                    vm.base64data = base64data;
                    //create a new file from the base64 data
                    let newFile = vm.dataURLtoFile(vm.base64data, 'hello.png');

                    vm.file = newFile;
                };

                this.stopCamera();
            });
        },
        safariImageCapture() {
            let vm = this;
            const video = document.createElement('video');
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            video.srcObject = this.mediaStream;

            return new Promise((resolve, reject) => {
                video.addEventListener('loadeddata', async () => {
                    const { videoWidth, videoHeight } = video;

                    canvas.width = videoWidth;
                    canvas.height = videoHeight;

                    try {
                        await video.play();
                        context.drawImage(video, 0, 0, videoWidth, videoHeight);
                        vm.image = canvas.toDataURL(resolve, 'image/png');
                        let newFile = vm.dataURLtoFile(vm.image, 'hello.png');

                        vm.file = newFile;

                        this.stopCamera();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
        },
        dataURLtoFile(dataurl, filename) {
            //divide the url
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                //decode the base64 string
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            //complete the creation of the file
            return new File([u8arr], filename, { type: mime });
        },
        closeCanvas() {
            this.canvasOpen = false;
            this.stopCamera();
        },
        stopCamera() {
            if (this.mediaStream) {
                this.mediaStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            this.captureInProgress = false;
        },
        uploadPhoto() {
            this.file = this.$refs.file.files[0];
            this.createImage(this.file);
        },
        createImage(file) {
            this.canvasOpen = true;

            //FileReader object lets the app asynchronously read content of file
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        async submitFile() {
            this.file_size_error = false;
            this.general_error = false;
            this.submitting = true;
            let formData = new FormData();

            //append the file to the formData
            formData.append('file', this.file);

            //append clientId to the file
            formData.append('client_id', this.user._id);

            //choose section type
            formData.append('image_type', 'profile_images');

            //this is not a reupload section
            formData.append('reupload_image', false);

            try {
                const response = await this.uploadProfileImage(formData);
                
                this.submitting = false;
                this.file = '';
                this.image = '';
                this.alert.showWithTimeout('Photo updated successfully', 'success');
                this.uploadOpen = false;

                this.profileImage = response.image.profile;

                setTimeout(() => {
                    this.updateUserImage(true);
                }, 2000);

            } catch (error) {
                if ('message' in error && error.text === 'FileSizeLimitError') {
                    this.file_size_error = error.message;
                } else {
                    this.general_error = error.message;
                }
                this.submitting = false;
                this.alert.show(error.message, 'critical');
            }
        },
        ...mapActions({
            uploadProfileImage: 'intake/uploadProfileImage',
        }),
        ...mapMutations({
            updateUserImage: 'user/updateUserImage',
        }),
    },
};
</script>
