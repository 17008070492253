<template>
    <div class="notification-item" @click="handleClick">
        <div class="notification-item-icon">
            <div class="notification-item-new__container">
                <div v-if="!notification.read" class="notification-item-new__dot" />
            </div>
            <component :is="iconComponent" />
        </div>
        <div class="notification-item-details">
            <p class="notification-item-details__description">
                {{ notification.message }}
            </p>
            <div class="notification-item-date">
                {{ formattedDate }}
            </div>
        </div>
    </div>
</template>

<script>
import { formatNotificationDate } from '@/helpers/date';
import { NOTIFICATION_ICON_MAP } from '../utils/NotificationMap';

export default {
    name: 'NotificationItem',
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        formattedDate() {
            return formatNotificationDate(this.notification.created_at);
        },
        iconComponent() {
            return NOTIFICATION_ICON_MAP[this.notification.category];
        },
    },
    methods: {
        handleClick() {
            this.$emit('click', this.notification);
        },
    },
};
</script>
