<template>
    <div class="h-bar">
        <div class="h-bar__base">
            <div
                v-for="(range, index) in ranges"
                :key="`${range.type}${range.min}`"
                :style="getBarStyle(index)"
                class="h-bar__base--bar"
                :class="range.type"
            >
                <span v-if="index !== 0" class="h-bar__label" :style="{ left: '-7px' }">{{ roundDownToTenth(range.min) }}</span>
                <div class="h-bar__value">
                    <div v-if="myRange === range.type" class="h-bar__marker h-bar__marker-box" :style="getMarkerStyle(range, 'box')">
                        <span>{{ myValue }}</span>
                    </div>
                    <div v-if="myRange === range.type" class="h-bar__marker h-bar__marker-bar" :style="getMarkerStyle(range, 'bar')" />
                </div>
            </div>
            <div v-if="myRange === 'no_range'" class="h-bar__value">
                <div class="h-bar__marker h-bar__marker-box" :style="getMarkerStyle('no_range', 'box')">
                    <span>{{ myValue }}</span>
                </div>
                <div class="h-bar__marker h-bar__marker-bar" :style="getMarkerStyle('no_range', 'bar')" />
            </div>
        </div>
    </div>
</template>

<script>
import { roundDownToTenth } from '@/helpers/math.js';

const DEFAULT_MARKER_POSITION = 50;

export default {
    name: 'LabBarChart',
    props: {
        ranges: {
            type: Array,
            default: () => [],
        },
        myRange: {
            type: String,
            default: 'no_range',
        },
        myValue: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            low: '255, 88, 80',
            high: '255, 88, 80',
            normal: '15, 192, 142',
            optimal: '15, 192, 142',
            borderline: '255, 161, 20',
        };
    },
    methods: {
        getBarStyle(index) {
            const length = 100 / this.ranges.length;

            const baseStyle = {
                'left': `${length * index}%`,
                'width': `${length}%`,
                'border-top-left-radius': index === 0  ? '10px' : '0',
                'border-bottom-left-radius': index === 0 ? '10px' : '0',
                'border-top-right-radius': index === this.ranges.length - 1 ? '10px' : '0',
                'border-bottom-right-radius': index === this.ranges.length - 1 ? '10px' : '0',
                'border-left': index !== 0 && '2px solid #fff',
                'backgroundColor': this.myRange === 'no_range' && '#212931',
            };

            return baseStyle;
        },
        roundDownToTenth(value) {
            return roundDownToTenth(value);
        },
        getMarkerStyle(range, type) {
            const rangeType = range !== 'no_range' && range.type.split('-')[0];

            const baseStyle = {
                left: range === 'no_range' ? '50%' : this.markerPosition(range) + '%',
                border: type === 'box' ? (range === 'no_range' ? '2px solid #222' : `2px solid rgb(${this[rangeType]})`) : undefined,
                backgroundColor: type === 'box' ? (range === 'no_range' ? 'rgba(34, 34, 34, .05)' : `rgba(${this[rangeType]}, .05)`) : undefined,
            };

            return baseStyle;
        },
        markerPosition(range) {
            if (range.type === 'low' || range.type === 'high' || range.max === null || range.min === null || !range.max) {
                return DEFAULT_MARKER_POSITION;
            } else {
                return ((this.myValue - range.min) / (range.max - range.min)) * 100;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
.h-bar {
	padding: 2.125rem 0 1.875rem;

	&__base {
		position: relative;
		display: flex;
		width: 100%;
		height: 8px;
		border-radius: 10px;
		background-color: $lab-base;

		&--bar {
			position: absolute;
			top: 0;
			bottom: 0;
			background-color: $charcoal;
		}
	}

	&__label {
		position: absolute;
		top: 25px;
		font-size: 0.75rem;
	}

	&__value {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
		align-items: center;
	}

	&__marker {
		position: absolute;
		height: 26px;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.25rem;
		z-index: 1;
	}

	&__marker-box {
		top: calc(-26px + 0px);
		font-size: 0.875rem;
		font-weight: 500;
		border-radius: 0.25rem;
		color: $charcoal;
		padding: 0.25rem 0.75rem;
	}

	&__marker-bar {
		top: 4px;
		width: 4px;
		background-color: $charcoal;
		box-shadow: 0px 2px 3px rgb(0 0 0 / 30%);
	}
}

.low, 
.high {
	background-color: $lab-base;
}

.borderline-low, 
.borderline-high {
	background-color: $lab-borderline;
}

.normal,
.optimal {
	background-color: $lab-optimal;
}
</style>
