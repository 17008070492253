export default {
    data() {
        return {
            lastScrollTop: 0,
            scrollUp: true,
            isDesktop: window.innerWidth >= 1024,
        };
    },
    methods: {
        handleResize() {
            this.isDesktop = window.innerWidth >= 1024;
            
            if (window.innerWidth < 1024) {
                this.lastScrollTop = window.scrollY || document.documentElement.scrollTop;
                window.addEventListener('scroll', this.handleScroll);
            } else {
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
  
            if (currentScrollTop > this.lastScrollTop) {
                this.scrollUp = false;
            } else {
                this.scrollUp = true;
            }
            this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
        },
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    },
};
