<template>
    <div v-if="shopLoading">
        <shop-skeleton />
    </div>
    <div v-else class="shop">
        <shop-nav />
        <shop-hero v-if="shop" :banners="shop.banners" />
        <div class="shop-main">
            <div class="shop-filter">
                <div v-if="isMobile" class="shop-filter__btn-container">
                    <h-icon-button label="Filters" @click="handleFilterVisibility">
                        <FilterIcon />
                    </h-icon-button>
                </div>
                <transition name="shop-filter">
                    <div v-if="(!isMobile) || (isMobile && filterOpen)" class="shop-filter__content">
                        <shop-filter
                            v-if="shop"
                            :options="shop.filters"
                            @close="handleFilterVisibility"
                            @reset="handleResetFilters"
                            @type="(checked) => handleSelectedFilter('type', checked)"
                            @protocols="(checked) => handleSelectedFilter('protocols', checked)"
                            @benefits="(checked) => handleSelectedFilter('benefits', checked)"
                        />
                    </div>
                </transition>
            </div>
            <div class="shop-results">
                <div v-if="productLoading">
                    <product-grid-skeleton />
                </div>
                <div v-else class="shop-products">
                    <div v-for="product in products" :key="product._id" class="shop-grid">
                        <ShopCard :product="product" @openCheckoutPanel="handleCheckoutPanel" />
                    </div>
                </div>
                <div>
                    <h-pagination
                        :total-pages="totalPages"
                        :current-page="currentPage"
                        :has-prev-page="hasPrevPage"
                        :has-next-page="hasNextPage"
                        :page-number-limit="5"
                        :paging-counter="1"
                        @handlePageChange="handlePageChange"
                    />
                </div>
            </div>
        </div>
        <transition name="copanel">
            <LabCheckout
                v-if="isCheckoutPanelOpen"
                :open="isCheckoutPanelOpen"
                :product-id="checkoutProductId"
                @handleClose="closeCheckoutPanel"
            />
        </transition>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HPagination from '@/components/Shared/HPagination.vue';
import ProductGridSkeleton from '@/components/Shared/Skeletons/Shop/ProductGridSkeleton.vue';
import ShopSkeleton from '@/components/Shared/Skeletons/Shop/ShopSkeleton.vue';
import { handleScreenSize } from '@/helpers/responsive.js';

import FilterIcon from '../../../assets/icons/FilterIcon.vue';
import LabCheckout from '../../Checkout/LabCheckout.vue';
import HIconButton from '../../Shared/HIconButton.vue';
import { filterSelectedValues, updateFilterByQuery } from './OrganizationProducts.controller';
import ShopCard from './ShopCard.vue';
import ShopFilter from './ShopFilter.vue';
import ShopHero from './ShopHero.vue';
import ShopNav from './ShopNav.vue';

export default {
    name: 'OrganizationProducts',
    components: {
        ShopSkeleton,
        ProductGridSkeleton,
        ShopNav,
        ShopHero,
        ShopFilter,
        ShopCard,
        HIconButton,
        FilterIcon,
        HPagination,
        LabCheckout,
    },
    data() {
        return {
            shopLoading: false,
            productLoading: false,
            isMobile: false,
            shop: null,
            products: [],
            filterOpen: false,
            type: {},
            protocols: {},
            benefits: {},
            currentPage: 1,
            totalPages: 1,
            hasNextPage: false,
            hasPrevPage: false,
            isCheckoutPanelOpen: false,
            checkoutProductId: null,
        };
    },
    computed: {
        ...mapState({
            v2products: (state) => state.products.v2products,
        }),
    },
    async created() {
        this.initializeShop();

        const screenSize = handleScreenSize();

        this.isMobile = screenSize.isMobile;

        this.handleResize = () => {
            const screenSize = handleScreenSize();

            this.isMobile = screenSize.isMobile;
        };

        window.addEventListener('resize', this.handleResize);

        this.initFilters();
        await this.fetchProducts();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        async initializeShop() {
            this.shopLoading = true;

            try {
                const organization = await this.getOrganization();

                this.shop = organization.settings.shop;
                this.shopLoading = false;
            } catch (error) {
                this.shopLoading = false;
                throw error;
            }
        },
        async fetchProducts() {
            this.productLoading = true;

            const productType = filterSelectedValues(this.type);
            const protocolsTags = filterSelectedValues(this.protocols);
            const benefitsTags = filterSelectedValues(this.benefits);
            const tags = [...protocolsTags, ...benefitsTags];

            const query = { page: this.currentPage, product_type: productType, tags: tags.join(',') };

            try {
                await this.getV2Products(query);

                this.products = this.v2products.data.docs;
                this.currentPage = this.v2products.data.page;
                this.totalPages = this.v2products.data.totalPages;
                this.hasNextPage = this.v2products.data.hasNextPage;
                this.hasPrevPage = this.v2products.data.hasPrevPage;
                this.productLoading = false;

            } catch (error) {
                this.productLoading = false;
                throw error;
            }
        },
        handleFilterVisibility() {
            this.filterOpen = !this.filterOpen;
        },
        async handleSelectedFilter(type, checked) {
            this[type] = checked;

            await this.fetchProducts();
        },
        async handleResetFilters() {
            this.initFilters();
            await this.fetchProducts();
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.fetchProducts();
        },
        handleCheckoutPanel(productId) {
            this.isCheckoutPanelOpen = true;
            this.checkoutProductId = productId;
        },
        closeCheckoutPanel() {
            this.isCheckoutPanelOpen = false;
        },
        initFilters() {
            this.type = updateFilterByQuery(this.$route.query.type);
            this.protocols = updateFilterByQuery(this.$route.query.protocols);
            this.benefits = updateFilterByQuery(this.$route.query.benefits);
        },
        ...mapActions({
            getV2Products: 'products/getV2Products',
            getOrganization: 'getOrganization',
        }),
    },
};
</script>
