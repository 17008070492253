<template>
    <div v-if="loading" class="analysis-loader">
        <h-spinner />
    </div>
    <div v-else class="analysis">
        <div ref="hiddenTopElement" style="width: 100px; height: 77px; position: absolute; top: -77px; pointer-events: none;" />
        <div class="analysis-top">
            <div class="analysis-top__header">
                <h1>{{ report_data.title }}</h1>
                <div v-if="report_data.reviewer" class="analysis-top__header--signature">
                    <div v-if="report_data.reviewer.profile_img.url" class="analysis-top__header--avatar">
                        <img :src="report_data.reviewer.profile_img.url" :alt="report_data.reviewer.last_name">
                    </div>
                    <div>
                        Reviewed by <span>{{ report_data.reviewer.first_name }} {{ report_data.reviewer.last_name }}</span>
                        <span v-if="report_data.updated_at"> on {{ formattedDate(report_data.updated_at) }}</span>
                    </div>
                </div>
                <div class="analysis-top__header--actions">
                    <button @click="downloadPDF">
                        <ion-icon name="arrow-down-outline" />
                        <div v-if="pdf_loading" class="analysis-top__header--loader-container">
                            <span class="analysis-top__header--loader" />
                        </div>
                        <span v-else>Download</span>
                    </button>
                    <button @click="handleMessage">
                        <ion-icon name="chatbubble-outline" />
                        <span>Message</span>
                    </button>
                </div>
            </div>
            <div class="analysis-top__message">
                <div class="analysis-top__message--body" :class="{ 'analysis-top__message--open': collapse_summary }">
                    <div class="analysis-top__message--content">
                        <h2 class="mb25">
                            Message from your clinical team
                        </h2>
                        <HMarkdownContainer :content="report_data.text.intro" />
                        <HMarkdownContainer :content="report_data.text.main" />
                        <HMarkdownContainer :content="report_data.text.conclusion" />
                    </div>
                </div>

                <div class="analysis-top__message--footer" :class="{'analysis-top__message--footeropen': collapse_summary}" @click="handleReadMore">
                    <div class="analysis-top__message--button">
                        <span>{{ collapse_summary ? 'Read Less' : 'Read More' }}</span>
                        <ion-icon v-if="!collapse_summary" name="chevron-down-outline" />
                        <ion-icon v-if="collapse_summary" name="chevron-up-outline" />
                    </div>
                </div>
            </div>
        </div>

        <div class="analysis-summary">
            <div class="analysis-summary__container">
                <h2>Your Optimized Biomarkers</h2>
                <div class="analysis-summary__wrapper">
                    <lab-optimization :markers="report_data.markers" :summary="report_data.optimized" />
                </div>
            </div>
            <div v-if="report_data.bioAge" class="analysis-summary__container">
                <h2>Your Biological Age</h2>
                <div class="analysis-summary__wrapper">
                    <lab-biological-age :age="report_data.bioAge" />
                </div>
            </div>
        </div>

        <section class="analysis-biomarkers">
            <div class="analysis-biomarkers__header">
                <h2>Biomarkers</h2>
            </div>
            <div ref="filterContainer" class="analysis-filter">
                <div class="analysis-filter__desktop">
                    <filter-category
                        type="categoryName"
                        :selected="categoryName || 'all'"
                        :options="categoryOptions"
                        @category-selected="handleSelectedOption"
                    />
                </div>
                <div class="analysis-filter__mobile">
                    <filter-options
                        type="categoryName"
                        :default-label="categoryName || 'All Categories'"
                        :options="categoryOptions"
                        @option-selected="handleSelectedOption" 
                    />
                </div>
                <filter-options
                    type="rangeType"
                    default-label="All Ranges"
                    show-indicator
                    :options="rangeOptions"
                    @option-selected="handleSelectedOption" 
                />
            </div>
            <div v-if="section_loading" class="analysis__section-loader">
                <h-spinner />
            </div>
            <div v-else class="analysis-biomarkers__container">
                <lab-biomarker v-for="marker in report_data.markers" :key="marker.id" ref="markers" :item="marker" />
            </div>
        </section>

        <div class="analysis-scroll" :class="{ 'analysis-scroll__down': !scrollUp, 'analysis-scroll__up': scrollUp || isDesktop }" @click="scrollToTop">
            <ion-icon name="chevron-up-outline" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HMarkdownContainer from '@/components/Shared/HMarkdownContainer.vue';
import { createFormatDate, downloadPdfToDevice } from '@/helpers/labs';
import scrollResizeMixin from '@/helpers/scrollResizeMixin';

import HSpinner from '../../Shared/HSpinner.vue';
import LabBiologicalAge from './LabBiologicalAge.vue';
import LabBiomarker from './LabBiomarkerItem.vue';
import LabOptimization from './LabOptimization.vue';
import FilterCategory from './Subcomponents/FilterCategory.vue';
import FilterOptions from './Subcomponents/FilterOptions.vue';

export default {
    name: 'LabAnalysis',
    components: {
        LabOptimization,
        LabBiologicalAge,
        LabBiomarker,
        HMarkdownContainer,
        HSpinner,
        FilterCategory,
        FilterOptions,
    },
    mixins: [scrollResizeMixin],
    data() {
        return {
            collapse_summary: false,
            categoryOptions: [],
            rangeOptions: [
                {
                    name: 'Optimal',
                    value: 'optimal',
                },
                {
                    name: 'Needs Improvement',
                    value: 'borderline',
                },
                {
                    name: 'Needs Attention',
                    value: 'high-low',
                },
            ],
            categoryName: null,
            report_data: {},
            loading: false,
            section_loading: false,
            pdf_loading: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            lab_analysis: (state) => state.labResults.lab_analysis,
            lab_pdf: (state) => state.labResults.lab_pdf,
        }),
    },
    async created() {
        this.getReportData('all');
    },
    methods: {
        ...mapActions({
            getLabAnalysis: 'labResults/getLabAnalysis',
            getLabPDF: 'labResults/getLabPDF',
        }),
        async getReportData() {
            const reportId = this.$route.params.id;

            this.loading = true;

            await this.getLabAnalysis({ reportId });

            this.report_data = {
                ...this.lab_analysis.data,
                title: this.lab_analysis.data.title || 'Lab Panel Results',
            };

            this.categoryOptions = this.report_data.panelCategoryNames.map(cateogory => ({ 
                name: cateogory,
                value: cateogory,
            }));

            this.loading = false;
        },
        formattedDate: createFormatDate('MMMM Do YYYY'),
        handleMessage() {
            this.$router.push(`/dashboard/${this.user._id}/messages`);
        },
        async handleSelectedOption({ type, value }) {
            this[type] = value === 'all' ? null : value;

            this.$nextTick(() => {
                if (type === 'categoryName') {
                    this.filterScrollToStart();
                } else if (type === 'rangeType') {
                    this.filterScrollToEnd();
                }
            });

            this.section_loading = true;
            const reportId = this.$route.params.id;

            await this.getLabAnalysis({ reportId, rangeType: this.rangeType, categoryName: this.categoryName });
            this.report_data = {
                ...this.lab_analysis.data,
                title: this.lab_analysis.data.title || 'Lab Panel Results',
            };
           
            this.section_loading = false;
        },
        filterScrollToStart() {
            const filterContainer = this.$refs.filterContainer;

            filterContainer.scrollTo({
                left: 0,
                behavior: 'smooth',
            });
        },
        filterScrollToEnd() {
            const filterContainer = this.$refs.filterContainer;

            filterContainer.scrollTo({
                left: filterContainer.scrollWidth - filterContainer.clientWidth,
                behavior: 'smooth',
            });
        },
        handleReadMore() {
            this.collapse_summary = !this.collapse_summary;
            window.scrollTo(0, 0);
        },
        scrollToTop() {
            const hiddenTopElement = this.$refs.hiddenTopElement;
            const dashboardContent = document.querySelector('.dashboard-content');

            if (window.innerWidth >= 1024) {
                dashboardContent.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                hiddenTopElement.scrollIntoView({ behavior: 'smooth' });
            }
        },
        async downloadPDF() {
            this.pdf_loading = true;
            const reportId = this.$route.params.id;
            const response = await this.getLabPDF({ reportId });
            const file = new Blob([response.data], { type: 'application/pdf' });

            downloadPdfToDevice(file, `${this.report_data.title}.pdf`);
            this.pdf_loading = false;
        },
    },
};
</script>
