<template>
    <div>
        <div class="verify-account">
            <div class="identity__header">
                <h1>Verify Your Account</h1>
                <p class="identity__header--desc">Digital Safety Starts here</p>
                <slot name="why" />
            </div>
            <div class="verify-account__options">
                <VerifyCard
                    type="verify-id"
                    title="Identity Verification"
                    :badge="{ label: 'Quick & Easy', color: '#55D291' }"
                    :withAction="true"
                    button_label="Select"
                    @click.native="handleNext('existing_identity')"
                    class="pointer"
                    data-cypress="id_verification"
                >
                    <div>
                        <p class="h-caption text-dim-80">Verify by either uploading any of the following</p>
                        <ul class="h-caption">
                            <li>Passport</li>
                            <li>Drivers License</li>
                            <li>Valid Government ID</li>
                        </ul>
                        <p class="h-caption text-dim-60">*all documents are destroyed after account verification</p>
                    </div>
                </VerifyCard>
            </div>
        </div>
    </div>
</template>

<script>
import VerifyCard from './SubComponents/VerifyCard.vue';

export default {
    name: 'VerifyAccount',
    components: {
        VerifyCard,
    },
    props : {
        handleNext: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.verify-account {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0 1.5rem;
    margin: 0 auto;

    &__options {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    @media (min-width: 1280px) {
        padding: 0;
    }
}

</style>
