var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth"},[_c('auth-info',{attrs:{"type":"new_password","organization_settings":_vm.organizationSettings}},[_c('form',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.checkForm()}}},[_c('div',{staticClass:"login-form__input"},[_c('Input',{attrs:{"id":"new_password","name":"new_password","type":"password","label":"New Password","vuelidate":_vm.$v.password.new_password,"errors":[
                        !_vm.$v.password.new_password.required && _vm.$v.password.new_password.$dirty && 'Please enter your password.',
                        !_vm.$v.password.new_password.minlength && _vm.$v.password.new_password.$dirty && 'Password must be at least 6 characters long.',
                    ]},model:{value:(_vm.password.new_password),callback:function ($$v) {_vm.$set(_vm.password, "new_password", $$v)},expression:"password.new_password"}}),_c('Input',{attrs:{"id":"confirm_password","name":"confirm_password","type":"password","label":"Confirm Password","vuelidate":_vm.$v.password.confirm_password,"errors":[
                        !_vm.$v.password.confirm_password.required && _vm.$v.password.confirm_password.$dirty && 'Please enter your password.',
                        !_vm.$v.password.confirm_password.minlength && _vm.$v.password.confirm_password.$dirty && 'Password must be at least 6 characters long.',
                        !_vm.$v.password.confirm_password.sameAsPassword && _vm.$v.password.confirm_password.$dirty && 'Please make sure your passwords match.',
                    ]},model:{value:(_vm.password.confirm_password),callback:function ($$v) {_vm.$set(_vm.password, "confirm_password", $$v)},expression:"password.confirm_password"}})],1),(_vm.error.is_error && _vm.submitted)?_c('span',{staticClass:"reset-success-msg"},[_vm._v(" "+_vm._s(_vm.error.err_msg)+". "),_c('a',{staticClass:"login-link",attrs:{"href":"/#/request-password-reset"}},[_vm._v("Resubmit your password reset request.")])]):_vm._e(),_c('h-button',{attrs:{"label":"Continue","variant":"primary","full_width":"","large":"","handle-click":_vm.checkForm,"loading":_vm.submitted,"disabled":_vm.$v.$invalid}})],1)]),_c('div',{staticClass:"auth__hero"},[_c('div',{staticClass:"auth__image auth__hero__backdrop auth__hero__right",style:({'background-image': `url(${_vm.get_image()})`})})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }