<template>
	<div class="biological-age">
		<div class="biological-age__container">
			<div class="biological-age__number">{{ end }}</div>
			<div class="biological-age__sub">years old</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BiologicalAge',
	props: {
		age: {
			type: Number,
			default: 100,
		},
	},
	data() {
		return {
			start: 0,
			end: 0,
			interval: 2000,
		};
	},
	methods: {
		updateCount() {
			let startValue = 0;
			let endValue = this.age;
			let duration = Math.floor(this.interval / endValue);
			let counter = setInterval(() => {
				startValue += 1;
				this.end = startValue;
				if (startValue === endValue) {
					clearInterval(counter);
				}
			}, duration);
		},
	},
	mounted() {
		this.updateCount();
	},
};
</script>

<style lang="scss" scoped>
.biological-age {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 1.5rem 1.25rem;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 130px;
		height: 130px;
		border-radius: 50%;
		border: 4px solid #fff;
		box-shadow: 0 0 0 8px rgba(15, 192, 142, 1);
		padding: 2rem 0;
	}

	&__number {
		font-size: 2rem;
		font-weight: 500;
		line-height: 2rem;
		color: rgba(15, 192, 142, 1);;
	}

	&__sub {
		font-size: 0.875rem;
		color: #666;
	}
}
</style>
