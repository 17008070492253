<template>
    <div>
        <div class="status-bar">
            <div class="status-fill" :style="{ width: animatedFillPercentage }" />
        </div>
        <div class="status-labels">
            <div
                v-for="(item, index) in statusItems"
                :key="item.key"
                class="status-label"
                :class="{ 'active': status === item.key }"
                @mouseenter="showTooltip(item.key, $event)"
                @mouseleave="hideTooltip"
            >
                <span>{{ item.label }}</span>
                <InfoIcon />
                <div
                    v-if="hoveredStatus === item.key"
                    class="status-description"
                    :class="{ 'above': tooltipAbove, 'below': !tooltipAbove, 'visible': tooltipVisible, 'first-item': index === 0, 'last-item': index === statusItems.length - 1 }"
                >
                    {{ status === item.key ? item.activeDescription : item.inactiveDescription }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InfoIcon from '@/assets/icons/InfoIcon.vue';

export default {
    name: 'StatusBar',
    components: {
        InfoIcon,
    },
    props: {
        status: {
            type: String,
            required: true,
        },
        reviewedDate: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            hoveredStatus: null,
            tooltipAbove: false,
            tooltipVisible: false,
            initialFillPercentage: '0%',
        };
    },
    computed: {
        fillPercentage() {
            const percentages = {
                pending: '20%',
                prepared: '40%',
                awaiting: '75%',
                reviewed: '100%',
            };

            return percentages[this.status] || '0%';
        },
        animatedFillPercentage() {
            return this.initialFillPercentage;
        },
        statusItems() {
            return [
                {
                    key: 'pending',
                    label: 'Visit Form Pending',
                    activeDescription: 'Your visit form is currently pending. Please allow up to 2 hours for it to be completed. If it is not ready within this timeframe, please contact us for assistance.',
                    inactiveDescription: 'Your visit form is currently pending. Please allow up to 2 hours for it to be completed. If it is not ready within this timeframe, please contact us for assistance.',
                },
                {
                    key: 'prepared',
                    label: 'Prepared',
                    activeDescription: 'Your visit form is now ready for download. Please download your form and bring it to your upcoming lab visit.',
                    inactiveDescription: 'The ‘Prepared’ status indicates that your lab form is ready for you to take to your lab appointment.',
                },
                {
                    key: 'awaiting',
                    label: 'Awaiting Results',
                    activeDescription: 'We have successfully received your results. Please allow some time for our team to review them and provide you with an in-depth analysis.',
                    inactiveDescription: 'The status ‘Awaiting Results’ means we have successfully received your results, and our team is currently reviewing them to provide you with a detailed analysis.',
                },
                {
                    key: 'reviewed',
                    label: 'Results Ready',
                    activeDescription: `Your results are now ready and have been reviewed on ${this.reviewedDate}.`,
                    inactiveDescription: 'The status will change to ‘Results Ready’ once our team has completed their analysis, allowing you to view your results.',
                },
            ];
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.initialFillPercentage = this.fillPercentage;
            }, 100);
        });
    },
    methods: {
        showTooltip(statusKey, event) {
            this.hoveredStatus = statusKey;
            this.updateTooltipPosition(event);
        },
        updateTooltipPosition(event) {
            const tooltipOffset = event.target.getBoundingClientRect().top;
            const screenMiddle = window.innerHeight / 2;

            this.tooltipAbove = tooltipOffset > screenMiddle;
            this.tooltipVisible = true;
        },
        hideTooltip() {
            this.hoveredStatus = null;
            this.tooltipVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.status-bar {
    width: 100%;
    height: 5px;
    border-radius: 0.75rem;
    background-color: $gray-50;
}

.status-fill {
    height: 100%;
    border-radius: 0.75rem;
    background-color: $calm-green-200;
    transition: width 0.5s ease;
}

.status-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.75rem;

    .active {
        font-weight: 700;
        color: $charcoal;
        cursor: pointer;
    }
}

.status-label {
    position: relative;
    flex: 1;
    @extend %caption;
    font-size: 0.75rem;
    white-space: normal;
    text-align: center;
    color: $gray-600;

    &:first-child {
        text-align: left;
    }

    &:last-child {
        text-align: right;
    }

    svg {
        margin-left: 0.25rem;
    }

    @media (min-width: 1024px) {
        flex: 0 1 auto;
        white-space: nowrap;

        svg {
            display: none;
        }
    }

    @media (min-width: 1280px) {
        font-size: 0.875rem;
    }
}

.status-description {
    position: absolute;
    left: 50%;
    border-radius: 0.75rem;
    min-width: 220px;
    max-width: 280px;
    @extend %caption;
    font-size: 0.75rem;
    text-align: left;
    white-space: normal;
    background-color: $charcoal;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    padding: 0.875rem 1rem;
    z-index: $z-tooltip;

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: -13px;
        transform: translateX(-10%);
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 5px solid transparent;
        border-bottom: 8px solid $charcoal;
    }

    &.first-item {
        left: 0;
        transform: translateX(0);

        &:before {
            left: 15%;
            top: -13px;
        }
    }

    &.last-item {
        left: auto;
        right: 0;
        transform: translateX(0);

        &:before {
            left: auto;
            right: 10%;
            top: -13px;
        }
    }

    &.above {
        bottom: calc(100% + 0.5rem);

        &:before {
            top: auto;
            bottom: -12px;
            border-top: 8px solid $charcoal;
            border-bottom: 5px solid transparent;
        }
    }

    &.below {
        top: calc(100% + 0.5rem);
    }

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    @media (min-width: 760px) {
        left: 20%;
        transform: translateX(-50%);

        &.first-item {
            left: 50%;
            transform: translateX(-50%);

            &:before {
                left: 40%;
                top: -12px;
            }
        }

        &.above {
            &:before {
                top: auto;
            }
        }
    }

    @media (min-width: 1024px) {
        left: 50%;
        transform: translateX(-50%);

        &.first-item {
            left: 50%;
            transform: translateX(-50%);

            &:before {
                left: 50%;
                top: -12px;
            }
        }

        &.last-item {
            left: 50%;
            transform: translateX(-50%);

            &:before {
                left: 50%;;
                right: auto;
                top: -12px;
            }
        }

        &.above {
            &:before {
                top: auto;
            }
        }
    }
}

</style>
