import axios from 'axios'
import { unavailableLabStates } from '@/constants/labUnavailableStates';

const baseUrl = process.env.VUE_APP_BASE_END;

export const states = {
    namespaced: true,
    state: {
        states: []
    },
    getters: {},
    mutations: {
        setStates(state, payload) {
            state.states = payload.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
    },
    actions: {
        retrieveStates({commit, getters, state}) {
            return new Promise((resolve, reject) => {
                axios({url: baseUrl + '/states', method: 'GET'}).then(response => {
                    commit('setStates', response.data || []);
                    resolve(response.data);
                }).catch(err => {
                    reject(err);
                })
            })
        },
        checkStateAvailability({commit, getters, state, rootState}, payload) {

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/states/' + rootState.orgId.data + '/state/' + payload.state_id + '/intake/' + payload.intake_template_id + '/validate',
                    method: 'GET'
                }).then((response) => {
                    resolve(response.data);
                }).catch((err) => {
                    reject(err);
                })
            })

        },
        checkLabStateAvailability({commit}, payload) {
            let abbreviation = payload;
            if ('abbreviation' in payload) {
                abbreviation = payload.abbreviation;
            }

            return !!unavailableLabStates.find(state => state.abbreviation === abbreviation);
        }
    }
}
