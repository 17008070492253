<template>
    <div class="hstatus" :class="[status, variant]" data-test="badge">
        <div class="hstatus-dot" />
        <p class="hstatus-label">
            {{ label }}
        </p>
        <div class="hstatus-info">
            <InfoIcon />
            <div class="hstatus-content">
                {{ content }}
            </div>
        </div>
        <div v-if="variant === 'action'" class="hstatus-action">
            <ChevronRightSimple :width="14" :height="14" />
        </div>
    </div>
</template>

<script>
import ChevronRightSimple from '@/assets/icons/ChevronRightSimple';
import InfoIcon from '@/assets/icons/InfoIcon';

export default {
    name: 'HStatusBadge',
    components: {
        InfoIcon,
        ChevronRightSimple,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            default: 'info',
        },
        variant: {
            type: String,
            default: 'info',
        },
        content: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.hstatus {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    height: 30px;
    border-radius: 3rem;
    background-color: #f9f9f9;
    color: #212931;
    padding: 0.5rem 0.75rem;
}

.hstatus-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;

    &__dark {
        background-color: #212931;
    }
}

.hstatus-label {
    order: 1;
    @include caption;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}

.hstatus-info {
    order: 2;
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
        .hstatus-content {
            visibility: visible;
        }
    }
}

.hstatus-content  {
    @include caption;
    position: absolute;
    bottom: 30px;
    right: -20px;
    visibility: hidden;
    min-width: 200px;
    background-color: $charcoal;
    color: $white;
    border-radius: 0.5rem;
    padding: 1rem;
    transition: all 0.2s ease-in-out;

    &:before {
        content: "";
        position: absolute;
        right: 22px;
        bottom: -13px;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-top: 8px solid #212931;
    }
}

.hstatus-action {
    order: 3;
}

.success {
    background-color: $calm-green-100;

    p {
        color: $calm-green-300;
    }

    .hstatus-dot {
        background-color: $calm-green-200;
    }
}

.warning {
    background-color: $heal-yellow-100;

    p {
        color: $heal-yellow-300;
    }

    .hstatus-dot {
        background-color: $heal-yellow-200;
    }
}

.action {
    cursor: pointer;

    .hstatus-dot {
        display: none;
    }

    .hstatus-label {
        order: 2;
    }

    .hstatus-info {
        order: 1;
    }

    .hstatus-content  {
        bottom: 30px;
        left: -20px;

        &:before {
            left: 22px;
            bottom: -13px;
        }
    }
}

</style>
