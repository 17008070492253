<template>
    <div class="hinfoicon" @mouseenter="updateTooltipPosition" @mouseleave="hideTooltip">
        <InfoIcon />
        <div
            class="hinfoicon-content"
            :class="{ 'above': tooltipAbove, 'below': !tooltipAbove, 'visible': tooltipVisible, 'wide': isLong }"
        >
            {{ content }}
        </div>
    </div>
</template>

<script>
import InfoIcon from '@/assets/icons/InfoIcon';

const CONTENT_LENGTH_THRESHOLD = 50;

export default {
    name: 'HInfoIcon',
    components: {
        InfoIcon,
    },
    props: {
        content: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tooltipAbove: false,
            tooltipVisible: false,
        };
    },
    computed: {
        isLong() {
            return this.content.length > CONTENT_LENGTH_THRESHOLD;
        },
    },
    methods: {
        updateTooltipPosition(event) {
            const tooltipOffset = event.target.getBoundingClientRect().top;
            const screenMiddle = window.innerHeight / 2;

            this.tooltipAbove = tooltipOffset > screenMiddle;
            this.tooltipVisible = true;
        },
        hideTooltip() {
            this.tooltipVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.hinfoicon {
    position: relative;
    display: flex;
    align-items: center;
}

.hinfoicon-content {
    @extend %caption;
    position: absolute;
    right: -20px;
    opacity: 0;
    visibility: hidden;
    background-color: $charcoal;
    color: $white;
    border-radius: 0.5rem;
    z-index: $z-tooltip;
    padding: 1rem;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    white-space: nowrap;

    &:before {
        content: "";
        position: absolute;
        right: 22px;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }

    &.above {
        bottom: calc(100% + 1rem);

        &:before {
            bottom: -8px;
            border-top: 8px solid $charcoal;
        }
    }

    &.below {
        top: calc(100% + 1rem);

        &:before {
            top: -8px;
            border-bottom: 8px solid $charcoal;
        }
    }

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    &.wide {
        min-width: 0;
        width: 300px;
        white-space: normal;
    }

    @media (min-width: 1024px) {
        left: 50%;
        right: auto;
        transform: translateX(-50%);

        &:before {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }
    }
}

</style>
