exports.getHeight = (inches) => {

    return {
        ft: Math.round(inches / 12),
        in: inches % 12,
    };
};

exports.convertToInches = (feet, inches) => {
    feet = parseInt(feet);
    inches = parseInt(inches);

    return (feet * 12) + inches;
};

exports.weightToDosage = (weight, productTemplate) => {
    const maxQuantity = productTemplate?.settings.max_quantity_recommendation;
    const weeklyQuantity = (maxQuantity / 4);

    const dosage = (weight / 2.2) * .075;
    const rounded = Math.max(1, Math.floor(dosage));

    if (productTemplate.intake_template === process.env.VUE_APP_COMPOUNDED_RAPAMYCIN_TEMPLATE_ID) {
        const compoundedDose = Math.floor(dosage * 2.5);
        const compoundedDoseThreeMgQuantity = compoundedDose - (compoundedDose % 3);

        return Math.min(compoundedDoseThreeMgQuantity, weeklyQuantity);
    } else if (maxQuantity) {
        return Math.min(rounded, weeklyQuantity);
    } else {
        return rounded;
    }
};
