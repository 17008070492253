<template>
    <div class="protocol-skeleton skeleton-container">
        <div class="protocol-skeleton__side">
            <skeleton-base height="400px"/>
            <skeleton-text-block />
        </div>
        <div class="protocol-skeleton__preferences">
            <div class="protocol-skeleton__duration">
                <skeleton-base height="140px"/>
                <skeleton-base height="140px"/>
            </div>
            <div class="protocol-skeleton__header">
                <skeleton-base width="80%" height="25px"/>
                <skeleton-text-block color="#e7e7e7"/>
            </div>
            <skeleton-base width="280px" height="30px"/>
            <div class="protocol-skeleton__feature">
                <skeleton-base width="65%" height="20px"/>
                <skeleton-base width="80%" height="20px"/>
                <skeleton-base width="50%" height="20px"/>
                <skeleton-base width="75%" height="20px"/>
            </div>
        </div>
    </div>
</template>

<script>
import SkeletonBase from './Base/SkeletonBase.vue';
import SkeletonTextBlock from './Base/SkeletonTextBlock.vue';

    export default {
        name: "ProtocolSkeleton",
        components: {
    SkeletonBase,
    SkeletonTextBlock,
    SkeletonBase
}
    }
</script>

<style lang="scss" scoped>
.protocol-skeleton {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 1rem;

    &__side {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__preferences {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__duration {
        display: flex;
        gap: 1rem;
    } 

    &__header {
        display: flex;
        flex-direction: column;
        gap: 0.875rem;
    }

    &__feature {
        display: none;
    }

    @media (min-width: 1024px) {
        flex-direction: row;
        gap: 5rem;
        padding: 116px 2.5rem 2.5rem;

        &__side {
            flex: 1;
        }

        &__preferences {
            flex: 1.25;
            gap: 4rem;
        }

        &__feature {
            display: block;
            display: flex;
            flex-direction: column;
            gap: 2rem;
    }
    }
}

</style>