<template>
<div>
        <div v-if="pricing" class="checkout-total__container">
            <ul class="checkout-total__breakdown">
                <li class="checkout-total__item">
                    <span class="checkout-total__title">Lab Work</span>
                    <span class="checkout-total__description charcoal">Free</span>
                </li>
                <li class="checkout-total__item">
                    <span class="checkout-total__title">Sub-Total</span>
                    <span v-if="pricing.costs !== pricing.total" class="checkout-total__description">
                        ${{ pricing.costs }}
                    </span>
                </li>
                <li v-if="pricing.savings" class="checkout-total__item">
                    <span class="checkout-total__title system-green-200">Discount</span>
                    <span class="checkout-total__description system-green-200">${{ pricing.savings }}</span>
                </li>
            </ul>
            <div class="checkout-total__total-item">
                <span class="checkout-total__total">Total</span>
                <span class="checkout-total__total">
                    ${{ pricing.total }}
                </span>
            </div>
        </div>
</div>
</template>
<script>

export default {
    name: 'SummaryTotal',
    props: ['products', 'intake_template'],
    components: {},
    data() {
        return {
            pricing: null,
            one_time_pricing: null
        }
    },
    watch: {
        products: 'updatePricing',
    },
    async created() {
        this.updatePricing();
    },
    computed: {
    },
    methods: {
        updatePricing() {
            let generatedPricing;

            if (!this.products) {
                const products = JSON.parse(window.localStorage.getItem('selected_products'));

                generatedPricing = this.generate_pricing(products);
            } else {
                generatedPricing = this.generate_pricing(this.products);
            }
            this.$emit('onPricingGenerated', generatedPricing.pricing);
            this.pricing = generatedPricing.pricing;
        },
        generate_pricing(selected_products) {

            let subscription_products = selected_products.filter(product => {
                return !product.selected_configuration.shipping.non_recurring
            })
            let pricing = {
                total: subscription_products.map(product => {
                    return product.selected_configuration.pricing.total_discount
                }).reduce((a, b) => {
                    return a + b
                }, 0),
                savings: this.generate_savings(subscription_products),
                costs: subscription_products.map(product => {
                    return product.selected_configuration.pricing.total
                }).reduce((a, b) => {
                    return a + b;
                })
            }

            return { pricing };
        },
        generate_savings(selected_products) {
            let product_savings = selected_products.map(product => {
                return product.selected_configuration.pricing.product_savings
            }).reduce((a, b) => {
                return a + b;
            });

            let membership_savings = selected_products.map(product => {
                return product.selected_configuration.pricing.membership_savings
            }).reduce((a, b) => {
                return a + b;
            })

            return product_savings + membership_savings;
        }
    }
}
</script>
<style lang="scss">
.summary-total {

    &__container {
        margin-top: 1rem;
        background-color: #fff;
        border-radius: 12px;
        -webkit-box-shadow: rgba(0,0,0,.08) 0 0.25rem 0.75rem;
        box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.08);
        padding: 2rem 1.5rem;
    }

    &__breakdown {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 1rem;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 500;
    }

    &__alert  {

        p {
            color: #757575;
            font-family: 'Sofia Regular', sans-serif;
            font-size: 14px;
            margin-bottom: 0.5rem;
            -webkit-font-smoothing: antialiased;
        }

    }



    &__savings {
        color: $nature500;
        font-weight: 500;
    }

    &__strikethrough {
        text-decoration: line-through;
        color: #a8a8a8;
    }
}
</style>
