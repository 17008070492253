<template>
    <div class="confirm-block confirm-prep">
        <img src="@/assets/images/labs/lab-blood-drops.png" alt="lab-blood-drops" class="confirm-prep__image">
        <h2>2. Preparation Guidelines</h2>
        <ul>
            <li>
                <span class="strong">Fasting: </span>
                <span>Do not eat or drink anything except water for 12 hours before your test.</span>
            </li>
            <li>
                <span class="strong">Exercise: </span>
                <span>Avoid any strenuous physical activities for 12 hours prior to the test.</span>
            </li>
            <li>
                <span class="strong">Hydration: </span>
                <span>Drink plenty of water to stay hydrated, but avoid other beverages.</span>
            </li>
        </ul>
        <div class="confirm-prep__callout">
            Some individuals may feel light-headed or dizzy after a blood draw. It's advisable to bring a small snack or drink to consume afterwards to help you feel more comfortable and stable.
        </div>
        <div class="confirm-prep__callout">
            If you are undergoing the Longevity Pro test, keep in mind that while the blood volume drawn is slightly more than usual, it is still a safe and routine amount.
        </div>
    </div>
</template>

<script>
export default {
    name: 'LabPreparation',
};
</script>
