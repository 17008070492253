<template>
    <transition name="sidebar" mode="out-in">
        <HSidebar
            v-if="isOpened"
            :open="isOpened"
            badge_label="Incomplete visits"
            data-test="incomplete-panel"
            @handleClose="onClose"
        >
            <div class="typography-header">
                <h2>Complete your visits</h2>
                <p>We make it simple for you to pick up right where you left off.</p>
            </div>
            <div class="panel__list" data-test="incomplete-panel-list">
                <PanelCardContent
                    v-for="incompleteIntake in intakes"
                    :key="incompleteIntake.intake._id"
                    class="panel__card"
                    :incomplete-intake="incompleteIntake"
                    :product-templates-map="productTemplatesMap"
                    :on-cancel="onCancel"
                    :on-continue="onContinue"
                />
            </div>
        </HSidebar>
    </transition>
</template>

<script>
import { mapState } from 'vuex';

import PanelCardContent from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/PanelCardContent';
import HSidebar from '@/components/Shared/HSidebar';
import { getProductTemplatesMap } from '@/store/selectors/productTemplates';

export default {
    name: 'IncompletePanel',
    components: {
        HSidebar,
        PanelCardContent,
    },
    props: {
        intakes: {
            type: Array,
            required: true,
            default: () => [],
        },
        isOpened: {
            type: Boolean,
            default: false,
            required: true,
        },
        onClose: {
            type: Function,
            required: true,
        },
        onContinue: {
            type: Function,
            required: true,
        },
        onCancel: {
            type: Function,
            required: true,
        },
    },
    computed: {
        ...mapState({
            productTemplatesMap: getProductTemplatesMap,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.panel {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__card:not(:last-child) {
        border-bottom: 1px solid $border-default;
    }
}
</style>
