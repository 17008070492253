<template>
    <LabSuccess />
</template>

<script>
import LabSuccess from '@/components/Confirmation/LabSuccess/LabSuccess.vue';

export default {
    name: 'ConfirmationView',
    components: {
        LabSuccess,
    },
};
</script>
