<template>
    <div class="confirm-ins">
        <div class="confirm-ins__header">
            <h2>How to book your appointment</h2>
            <p>Labcorp streamlines the appointment booking process, we will guide you every step of the way to ensure it's as effortless as possible.</p>
        </div>
        <div class="confirm-ins__block">
            <div class="confirm-ins__block-header">
                <h3>1. Search Location</h3>
                <p>Enter your address or zipcode, and select Routine Labwork from the dropdown.</p>
                <div class="confirm-ins__image">
                    <img src="./assets/location.png">
                </div>
            </div>
        </div>
        <div class="confirm-ins__block">
            <div class="confirm-ins__block-header">
                <h3>2. Select Location</h3>
                <p>Once you search for a LabCorp location near you, you'll be presented with a list of options. Simply select your preferred location and click the 'Make Appointment' button to proceed.</p>
            </div>
            <div class="confirm-ins__image">
                <img src="./assets/select-location.png">
            </div>
        </div>
        <div class="confirm-ins__block">
            <div class="confirm-ins__block-header">
                <h3>3. Scheduling your Appointment</h3>
                <p>Please fill out the form to schedule your appointment. Please select the options.</p>
                <ol>
                    <li>Service: Labwork</li>
                    <li>Will you be fasting? Yes</li>
                    <li>Select a time and date to schedule.</li>
                </ol>
            </div>
            <div class="confirm-ins__image">
                <img src="./assets/schedule.png">
            </div>
        </div>
        <div class="confirm-ins__block">
            <div class="confirm-ins__block-header">
                <h3>4. Fill out your Information</h3>
                <p>You will be asked to fill out your Personal information. Please fill out the following.</p>
                <ol>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Sex</li>
                    <li>Date of Birth</li>
                    <li>Address</li>
                </ol>
            </div>
            <div class="confirm-ins__image">
                <img src="./assets/information.png">
            </div>
        </div>
        <div class="confirm-ins__block">
            <div class="confirm-ins__block-header">
                <h3>5. Financial Details</h3>
                <p>LabCorp will ask you to provide financial details. Please ensure to select the option I have already paid or someone else is responsible.</p>
            </div>
            <div class="confirm-ins__image">
                <img src="./assets/financial-details.png">
            </div>
        </div>
        <div class="confirm-ins__block">
            <div class="confirm-ins__block-header">
                <h3>6. Contact Information</h3>
                <p>LabCorp might need your contact details. Just provide your email address and mobile number when asked.</p>
            </div>
            <div class="confirm-ins__image">
                <img src="./assets/contact.png">
            </div>
        </div>
        <div class="confirm-ins__block">
            <div class="confirm-ins__block-header">
                <h3>7. Complete</h3>
                <p>Review your details on the last step and click <span class="strong">Create Appointment</span></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleInstructions',
};
</script>
