<template>
	<div class="providers__container">
        <div class="page__title">Providers</div>
        <div class="providers__physicians">
            To ensure you can see available providers in your area, kindly include your state information in the profile section.
            <providers-list v-if="!physicians.loading && physicians.data"
                               :physicians="physicians"
            ></providers-list>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import AppointmentSelectPhysicians from "@/components/Dashboard/Appointments/AppointmentSelectPhysicians";
import ProviderList from "@/components/Dashboard/Providers/ProviderList";

export default {
	name: 'Providers',
	components: {
        selectPhysicians: AppointmentSelectPhysicians,
        providersList: ProviderList
    },
	data() {
		return {

		}
	},
	async created() {

        try {
            if (this.user.state && !this.patient_state.data && '_id' in this.patient_state.data) {
                await this.getPatientState({
                   patient: this.user._id
               })
            }
            if (this.user.state && this.patient_state.data) {
                await this.getPhysicians({
                    state_id: this.patient_state.data._id,
                    is_provider: true
                });
            }
        } catch (e) {
            console.log('is there an alert', e);
        }
	},
	computed: {
        ...mapState({
            physicians: state => state.physician.state_physicians,
            patient_state: state => state.user.patient_state,
            user: state => state.user.user
        })
    },
	methods: {
        ...mapActions({
            getPhysicians: 'physician/getPhysicians',
            getPatientState: 'user/getPatientState'
        })
    }
}
</script>
<style lang="scss">
    .providers {
        @media (min-width: 1440px) {
            &__container {
                width: 100%;
                max-width: 1440px;
                margin: 0 auto;
            }
        }
    }
</style>
