<template>
    <div class="confirm-block confirm-results">
        <h2>4. Lab Results Delivered to your Patient Portal</h2>
        <p>After your sample has been collected at the lab, please stay tuned for updates on your results through your patient portal. You will also receive text notifications to ensure you never miss an update.</p>
        <HButtonV2 v-if="organizationData && organizationData.verification && organizationData.verification.has_mobile_verification && !user.mobile_verified" variant="secondary" @click="handleSidebar">
            Enable Text Notifications
        </HButtonV2>
        <div v-if="user.mobile_verified">
            <div class="confirm-verify__desktop">
                <HBadge label="You are now verified to receive text notifications" preset="green" variant="secondary" />
            </div>
            <div class="confirm-verify__mobile">
                <HBadge label="Notification enabled" preset="green" variant="secondary" />
            </div>
        </div>
        <transition name="sidebar" mode="out-in">
            <h-sidebar v-if="open_sidebar" :open="open_sidebar" badge_label="Mobile Verification" @handleClose="handleSidebar">
                <MobileVerification @submit-success="handleSidebar" />
            </h-sidebar>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HBadge from '@/components/Shared/HBadge';
import HButtonV2 from '@/components/Shared/HButtonV2';
import HSidebar from '@/components/Shared/HSidebar';

import MobileVerification from './components/MobileVerification';

export default {
    name: 'LabResults',
    components: {
        HButtonV2,
        HSidebar,
        HBadge,
        MobileVerification,
    },
    data() {
        return {
            open_sidebar: false,
            organizationData: null,
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
    },
    async created() {
        this.organizationData = await this.getOrganization();
    },
    methods: {
        ...mapActions({
            getOrganization: 'getOrganization',
        }),
        handleSidebar() {
            this.open_sidebar = !this.open_sidebar;
        },
        onSuccess() {
            this.open_sidebar = !this.open_sidebar;
            window.location.reload();
        },
    },
};
</script>
