<template>
    <div class="hdropdown">
        <label v-if="label">{{ label }}</label>
        <select
            class="hdropdown__select"
            :class="{ 'hdropdown__error-border': error }"
            @input="onInput($event)"
        >
            <option value="" disabled selected>
                Select an option
            </option>
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :selected="option.value === selectedValue"
            >
                <span>{{ option.name }}</span> <span v-if="suffix">{{ suffix }}</span>
            </option>
        </select>
        
        <span v-if="error" class="hdropdown__error">{{ error }}</span>
    </div>
</template>

<script>
export default {
    name: 'HDropDown',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: [String, Number],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        suffix: {
            type: String,
            default: null,
        },
        vuelidate: {
            type: Object,
            required: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        selectedValue() {
            return String(this.selected);
        },
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
            this.vuelidate.$touch();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.hdropdown {
	width: 100%;

	label {
        @extend %caption;
        font-weight: 700;
    }

	&__select {
		@extend %caption;
		display: block;
		width: 100%;
		border: 1px solid $border-medium;
		border-radius: 0.25rem;
		padding: 0.625rem 1rem;
		background-image : url('../../assets/icons/chevron-down.svg');
		background-repeat: no-repeat;
		background-position: right 8px center;
		background-size: 16px;
		-webkit-font-smoothing: antialiased;
		-webkit-appearance: none;
		transition: all 200ms cubic-bezier(0.33, 0, 0, 1) 0s;
        margin-top: 3px;
	}

    &__error-border {
        border-color: $errors;
    }

    &__error {
        @extend %caption-sm;
        color: $errors;
    }
}

</style>
