<template>
	<div class="modal-footer__container">
        <button v-if="has_cancel" type="button" class="pill-button link strong" @click="on_cancel()">
            Cancel
        </button>
        <button :disabled="is_submitting" type="button" class="pill-button black-btn medium" @click="on_submit()">
            {{ submit_button_text || 'Submit' }}
        </button>
    </div>
</template>
<script>
export default {
	name: 'ModalFooter',
	components: {},
    props: ['has_cancel', 'submit_button_text', 'is_submitting'],
	data() {
		return {

		}
	},
	created() {
	},
	computed: {},
	methods: {
        on_cancel() {
            this.$emit('onCancel', true);
        },
        on_submit() {
            this.$emit('onSubmit', true);
        }
    }
}
</script>
<style lang="scss">
.modal-footer {
    &__container {
        display: flex;
        width: 100%;
        justify-content: end;
        padding: 2rem;

    }
}
</style>
