<template>
    <div class="headernav">
        <verification-badge />
        <nav-dropdown />
    </div>
</template>

<script>
import NavDropdown from '@/components/Dashboard/Subcomponents/NavDropdown.vue';
import VerificationBadge from '@/components/Dashboard/VerificationBadge.vue';

export default {
    name: 'HeaderNav',
    components: {
        NavDropdown,
        VerificationBadge,
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/utility.scss';

.headernav {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 68px;
    background-color: #fff;
    padding: 0 $mobile-mx;
    z-index: $z-nav;

    .verification-container {
        padding: 0;
    }

    .header-nav-button-wrapper {
        margin: 0;
    }

    @media (min-width: 640px) {
        padding: 0 $tablet-mx;
    }

    @media (min-width: 1024px) {
        display: none;
    }
}
</style>
