<template>
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1_8040)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79995 1.225C9.79995 0.935051 10.035 0.7 10.325 0.7H12.775C13.0649 0.7 13.3 0.935051 13.3 1.225V3.675C13.3 3.96495 13.0649 4.2 12.775 4.2C12.485 4.2 12.25 3.96495 12.25 3.675V2.49246L8.07118 6.67123C7.86616 6.87626 7.53375 6.87626 7.32872 6.67123C7.1237 6.46621 7.1237 6.13379 7.32872 5.92877L11.5075 1.75H10.325C10.035 1.75 9.79995 1.51495 9.79995 1.225ZM0.699951 3.325C0.699951 2.26185 1.5618 1.4 2.62495 1.4H6.29995C6.5899 1.4 6.82495 1.63505 6.82495 1.925C6.82495 2.21495 6.5899 2.45 6.29995 2.45H2.62495C2.1417 2.45 1.74995 2.84175 1.74995 3.325V11.375C1.74995 11.8582 2.1417 12.25 2.62495 12.25H10.675C11.1582 12.25 11.55 11.8582 11.55 11.375V7.7C11.55 7.41005 11.785 7.175 12.075 7.175C12.3649 7.175 12.6 7.41005 12.6 7.7V11.375C12.6 12.4381 11.7381 13.3 10.675 13.3H2.62495C1.5618 13.3 0.699951 12.4381 0.699951 11.375V3.325Z" fill="#B1B1B1" />
        </g>
        <defs>
            <clipPath id="clip0_1_8040">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'ExternalIcon',
    props: {
        color: {
            type: String,
            default: '#212931',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
