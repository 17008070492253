<template>
    <div class="skeleton-text-container">
        <div class="skeleton-text" :style="{backgroundColor: color}"></div>
        <div class="skeleton-text" :style="{backgroundColor: color}"></div>
        <div class="skeleton-text" :style="{backgroundColor: color, width: '70%'}"></div>
    </div>
</template>

<script>
    export default {
        name: "SkeletonTextBase",
        props: {
            color: {
                type: String,
                default: "#dcdcdc"
            },
        }
    }
</script>

<style lang="scss" scoped>

.skeleton-text-container {
    display: flex;
    flex-direction: column;
    gap: .65rem;
}

.skeleton-text {
    width: 100%;
    height: 12px;
    border-radius: 0.25rem;
}
</style>