<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="4"
            y="4"
            width="6"
            height="6"
            rx="1.5"
            :fill="color"
            :stroke="color"
            stroke-width="2"
        />
        <rect
            x="14"
            y="4"
            width="6"
            height="6"
            rx="1.5"
            stroke="#FF82B8"
            stroke-width="2"
        />
        <rect
            x="4"
            y="14"
            width="6"
            height="6"
            rx="1.5"
            stroke="#FF82B8"
            stroke-width="2"
        />
        <rect
            x="14"
            y="14"
            width="6"
            height="6"
            rx="1.5"
            :stroke="color"
            stroke-width="2"
        />
    </svg>
</template>

<script>
export default {
    name: 'HomeIcon',
    props: {
        color: {
            type: String,
            default: '#212931',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
