<template>
    <div :class="'zp-alert--' + type + ' zp-alert--' + (layout ? layout : 'default') + (message || $slots.message ? '' : ' flex') " class="zp-alert">
        <p v-if="title" class="ppe-small-title" :class="{'mb-0': !message && !$slots.message}">
            <span class="ppe-description-icon"><ion-icon :name="icon"></ion-icon></span>{{ title }}
        </p>
        <p v-if="message" class="ppe-description" :class="{'mb-0': !actionText}">
            {{ message }}
        </p>
        <p v-if="$slots.message" class="ppe-description" :class="{'mb-0': !actionText}">
            <slot name="message" />
        </p>
        <button v-if="actionText" class="pill-button black-btn" @click="onAction">
            {{ actionText }}
        </button>
    </div>
</template>
<script>
export default {
    name: 'AlertComponent',
    props: {
        layout: String,
        actionText: String,
        message: String,
        title: String,
        icon: String,
        type: String,
    },
    methods: {
        onAction() {
            this.$emit('onAction', true);
        },
    },
};
</script>
<style lang="scss">
.zp-alert {
    width: 100%;
    color: #000000;
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid #EBEBEB;
    background-color: #f7f7f7;

    &.flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--single-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin-bottom: 0;
        }
    }

    &--error {
        .ppe-description-icon {
            color: #FF385C;
        }
    }

    &--success {
        .ppe-description-icon {
            color: $statusSuccess;
        }
    }
}

</style>
