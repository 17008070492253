<template>
<div v-if="physician">
    <div class="appointment__selected-view">
        <div class="appointment__header">
            <h4 class="headline-3">Schedule Appointment</h4>
            <p class="appointment__desc">Select a time and day to schedule your appointment with Dr
                {{ physician.first_name }} {{ physician.last_name }}.</p>
            <p class="appointment__sub-text">Please make sure to use your account email when booking appointment <span>{{ user.email }}</span>
            </p>
        </div>
        <div class="appointment__book-item">
            <div :style="{'background-image': `url(${physician.profile_img.url})`}"
                 class="appointment__book-avatar"></div>
            <div class="appointment__book-bio">
                <span class="appointment__book-title">Dr. {{ physician.first_name }} {{ physician.last_name }}</span>
                <span class="appointment__book-desc">NPI #{{ physician.npi_num }} </span>
            </div>
            <div v-if="physicians.data && physicians.data.length > 1" class="appointment__book-icon"
                 @click="onClose(physician)">
                <ion-icon name="close"></ion-icon>
            </div>
        </div>
        <div class="appointment__calendly-container">
            <div id="calendly"></div>
        </div>
    </div>

</div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'AppointmentSelectedPhysician',
    components: {},
    props: ['physician'],
    data() {
        return {
            time_selected: false
        }
    },
    created() {
        console.log('what is the physician', this.physician);

        const user = this.user;
        const physician = this.physician;
        const vue = this;
        setTimeout(() => {

            window.addEventListener('message', function (e) {
                if (vue.isCalendlyEvent(e)) {
                    vue.$emit('onCalendlyEvent', e);
                }
            })

            Calendly.initInlineWidget({
                url: physician.calendly.scheduling_url,
                parentElement: document.getElementById('calendly'),
                prefill: {
                    email: user.email,
                    name: `${user.first_name} ${user.last_name}`,
                    customAnswers: {
                        a1: `Telehealth consultation with Dr. ${physician.first_name} ${physician.last_name}`
                    }
                },
                utm: {}
            });
        })


    },
    computed: {
        ...mapState({
            physicians: state => state.physician.state_physicians,
            user: state => state.user.user
        })
    },
    methods: {
        onClose(physician) {
            this.$emit('onCloseSelectedPhysician', physician);
        },
        isCalendlyEvent(e) {
            return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
        },

    }
}
</script>
<style lang="scss">

</style>
