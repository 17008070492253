<template>
    <div class="skeleton-base" :style="{ height, width, backgroundColor: color, borderRadius: radius }" />
</template>

<script>
export default {
    name: 'SkeletonBase',
    props: {
        height: {
            type: String,
            default: '100%',
        },
        width: {
            type: String,
            default: '100%',
        },
        color: {
            type: String,
            default: '#dcdcdc',
        },
        radius: {
            type: String,
            default: '0.35rem',
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/utility.scss";

.skeleton-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: $z-overlay;
}

.skeleton-base {
    background: linear-gradient(90deg, rgb(220, 220, 220), rgb(241, 241, 241), rgb(220, 220, 220));
    background-size: 200%;
    animation: loader 1s infinite reverse;

    @keyframes loader {
        from {
            background-position: -100% 0;
        }
        to {
            background-position: 100% 0;
        }
    }
}
</style>
