import axios from 'axios'
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const physician = {
    namespaced: true,
    state: {
        state_physicians: {},
        selected_physician: null,
        calendly_event: null,
        physician_states: {}
    },
    getters: {},
    mutations: {
        setPhysicians(state, payload) {
            Vue.set(state.state_physicians, payload.id, payload.value);
        },
        setPhysician(state, payload) {
            Vue.set(state, 'selected_physician', payload);
        },
        setPhysicianStates(state, payload) {
            Vue.set(state.physician_states, payload.id, payload.value);
        },
    },
    actions: {
        async getPhysicians({commit, rootState}, payload) {
            const organization = rootState.orgId.data;
            const {state_id, is_provider} = payload;
            commit('setPhysicians', {id: 'loading', value: true});
            commit('setPhysicians', {id: 'error', value: false});

            try {
                const response = await axios.get(`${baseUrl}/physician/${organization}/state/${state_id}`, {
                    params: {
                        is_provider: is_provider
                    }
                });
                console.log('what is the repsonse', response);
                if ('data' in response) {
                    commit('setPhysicians', {id: 'loading', value: false});
                    commit('setPhysicians', {id: 'data', value: response.data});
                    return response.data;
                }
            } catch (error) {
                commit('setPhysicians', {id: 'loading', value: false});
                commit('setPhysicians', {id: 'error', value: true});
                commit('setPhysicians', {id: 'data', value: null});

                if (axios.isAxiosError(error)) {
                    commit('setPhysicians', { id: 'error', value: error.response.data });
                    throw error.response.data;
                } else {
                    throw error;
                }

            }

        },
        async getPhysicianStates({commit, rootState}, payload) {
            const organization = rootState.orgId.data;
            const {physician} = payload;
            commit('setPhysicianStates', {id: 'loading', value: true});
            commit('setPhysicianStates', {id: 'error', value: false});

            try {
                let response = await axios.get(`${baseUrl}/physician/${organization}/physician/${physician}/states`);
                if ('data' in response) {
                    commit('setPhysicianStates', {id: 'loading', value: false});
                    commit('setPhysicianStates', {id: 'data', value: response.data});
                    return response.data;
                }
            } catch (error) {
                console.error('Error in getPhysicianStates:', error);

                commit('setPhysicianStates', {id: 'loading', value: false});
                commit('setPhysicianStates', {id: 'error', value: true});
                commit('setPhysicianStates', {id: 'data', value: null});

                if (axios.isAxiosError(error)) {
                    commit('setPhysicianStates', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        }
    }
}