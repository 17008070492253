import { render, staticRenderFns } from "./LabBarChart.vue?vue&type=template&id=9494994c&scoped=true"
import script from "./LabBarChart.vue?vue&type=script&lang=js"
export * from "./LabBarChart.vue?vue&type=script&lang=js"
import style0 from "./LabBarChart.vue?vue&type=style&index=0&id=9494994c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9494994c",
  null
  
)

export default component.exports