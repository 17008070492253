<template>
    <div class="button-wrapper">
        <button
            :disabled="is_invalid_state || submitting"
            class="green-consult-button"
            :class="{'disabled-button' : is_invalid_state }"
        >
            <img v-if="submitting" class="gcb-loader" src="@/assets/images/loader/button-loader.svg" alt="loader" />

            {{ text }}
        </button>
    </div>
</template>

<script>

export default {
    name: 'IntakeButton',
    props: ['text', 'is_invalid_state', 'submitting'],
};

</script>
