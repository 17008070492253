<template>
	<div class="h-empty">
		<div class="h-empty__container">
			<div class="h-empty__container__graphic" :class="graphic_type"></div>
			<div class="headline-3-slim">{{ title }}</div>
			<p v-if="descriptions" class="h-empty__descriptions">{{ descriptions }}</p>
			<div v-if="button_label"><h-button :label="button_label" variant="primary" :handleClick="handleButtonClick" /></div>
		</div>
	</div>
</template>

<script>
import HButton from '@/components/Shared/HButton.vue';

export default {
	name: 'HEmptyPage',
	components: {
		HButton,
	},
	props: {
		graphic_type: {
			type: String,
			default: 'subscriptions',
		},
		title: {
			type: String,
		},
		descriptions: {
			type: String,
		},
		page: {
			type: String,
		},
		button_label: {
			type: String,
		},
		handleButtonClick: {
			type: Function,
		},
	},
};
</script>

<style lang="scss" scoped>
.h-empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2rem;
		width: 100%;
		height: calc(100vh - 177px);

		@media (min-width: 760px) {
			width: 550px;
		}

		&__graphic {
			width: 100%;
			height: 150px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('../../assets/images/empty-state/subscription-empty-state.svg');

			&.messages {
				background-image: url('../../assets//images/messages/conversation-empty-state.svg');
			}
		}
	}

	&__descriptions {
		font-family: 'ZP-UI', sans-serif;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: center;
	}
}
</style>
