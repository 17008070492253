<template>
    <WidgetContainer class="widget__container">
        <div class="widget__content">
            <img :src="icon" width="24" height="24" />
            <div class="widget__header">
                {{ header }}
            </div>
            <div class="widget__description">
                <slot></slot>
            </div>
        </div>
        <h-button
            v-if="onClick"
            variant="tertiary"
            class="widget__button"
            @click="onClick"
        >
            {{ buttonText }}
        </h-button>
    </WidgetContainer>
</template>

<script>
import HButton from '@/components/Shared/HButtonV2';
import WidgetContainer from '@/components/Dashboard/Widgets/WidgetContainer';

export default {
    name: 'InfoWidget',
    components: {
        HButton,
        WidgetContainer,
    },
    props: {
        icon: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        header: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
        onClick: {
            type: Function,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.widget {
    &__badge {
        width: fit-content;
        z-index: 1;
        margin-bottom: 1.5rem;
    }

    &__container {
        background-color: $gray-75;
        justify-content: space-between;
    }

    &__content {
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

    &__header {
        @include heading-sm;
        color: $charcoal;
        z-index: 1;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }

    &__description {
        @include body;
        color: $charcoal;
        z-index: 1;
    }

    &__button {
        z-index: 2;
        justify-self: end;
        margin-top: 1.5rem;
        max-width: fit-content;
    }

    @media (max-width: 768px) {
        &__header {
            @include heading-sm;
        }
    }

    @media (max-width: 430px) {
        &__header {
            @include body;
            font-weight: 700;
        }

        &__description {
            @include caption
        }

        &__button {
            max-width: 100%;
            flex: 1;
        }
    }
}
</style>
