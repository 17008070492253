import axios from "axios";
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const memberships = {
    namespaced: true,
    state: {
        membership: {
            data: null,
            loading: false,
            error: false,
        },
        price: {
            data: null,
            loading: false,
            error: false,
        },
        subscription: {
            data: null,
            loading: false,
            error: false,
        },
        //this is the array of membership subscriptions shown in the ddashboard
        user_subscriptions: {
            data: null,
            loading: false,
            error: false,
        },
        prices: {},
    },
    mutations: {
        setMembershipLoading(state, loading) {
            state.membership.loading = loading;
        },
        setMembershipData(state, data) {
            state.membership.data = data;
        },
        setMembershipError(state, error) {
            state.membership.error = error;
        },
        setMembershipPriceLoading(state, loading) {
            state.price.loading = loading;
        },
        setMembershipPriceData(state, data) {
            state.price.data = data;
        },
        setMembershipPriceError(state, error) {
            state.price.error = error;
        },
        setMembershipSubscriptionData(state, data) {
            state.subscription.data = data;
        },
        setMembershipSubscriptionLoading(state, loading) {
            state.subscription.loading = loading;
        },
        setMembershipSubscriptionError(state, error) {
            state.subscription.error = error;
        },
        setPatientSubscriptionsData(state, data) {
            state.user_subscriptions['data'] = data;
        },
        setPatientSubscriptionsError(state, error) {
            state.user_subscriptions['error'] = error;
        },
        setPatientSubscriptionsLoading(state, loading) {
            state.user_subscriptions['loading'] = loading;
        },
        setPrices(state, payload) {
            Vue.set(state.prices, payload.id, payload.value);
        },
    },
    getters: {},
    actions: {
        getMembership({ commit, rootState }, payload) {
            commit('setMembershipError', false);
            commit('setMembershipLoading', true);

            return new Promise((resolve, reject) => {
                axios({
                    url:
            baseUrl +
            '/memberships/' +
            rootState.orgId.data +
            '/membership/' +
            payload.membership_id,
                    method: 'GET',
                })
                    .then((response) => {
                        commit('setMembershipData', response.data);
                        commit('setMembershipLoading', false);
                        commit('setMembershipError', false);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        commit('setMembershipLoading', false);
                        commit('setMembershipError', true);
                        reject(err.response.data);
                    });
            });
        },
        getMembershipPrice({ commit, rootState }, payload) {
            commit('setMembershipPriceError', false);
            commit('setMembershipPriceLoading', true);

            return new Promise((resolve, reject) => {
                axios({
                    url:
            baseUrl +
            '/memberships/' +
            rootState.orgId.data +
            '/price/' +
            payload.price_id,
                    method: 'GET',
                })
                    .then((response) => {
                        commit('setMembershipPriceError', false);
                        commit('setMembershipPriceLoading', false);
                        commit('setMembershipPriceData', response.data);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        commit('setMembershipPriceError', true);
                        commit('setMembershipPriceLoading', false);
                        reject(err.response.data);
                    });
            });
        },
        async getMembershipPrices({ commit, rootState }, payload) {
            commit('setPrices', { id: 'loading', value: true });
            commit('setPrices', { id: 'error', value: false });
            const { membership } = payload;

            try {
                const prices = await axios.get(
                    `${baseUrl}/memberships/${rootState.orgId.data}/membership/${membership}/prices`,
                );

                if ('data' in prices) {
                    commit('setPrices', { id: 'loading', value: false });
                    commit('setPrices', { id: 'data', value: prices.data });

                    return prices.data;
                }
            } catch (error) {
                commit('setPrices', { id: 'loading', value: false });
                commit('setPrices', { id: 'error', value: true });
                commit('setPrices', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setPrices', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }
                throw error;
            }
        },
        startMembershipSubscription(
            { commit, rootState },
            payload,
        ) {
            commit('setMembershipSubscriptionError', false);
            commit('setMembershipSubscriptionLoading', true);

            return new Promise((resolve, reject) => {
                axios({
                    url:
            baseUrl +
            '/memberships/' +
            rootState.orgId.data +
            '/membership/' +
            payload.membership_id +
            '/price/' +
            payload.price_id +
            '/start',
                    method: 'POST',
                    data: payload.data,
                })
                    .then((response) => {
                        commit('setMembershipSubscriptionLoading', false);
                        commit('setMembershipSubscriptionError', true);
                        commit('setMembershipSubscriptionData', response.data);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        commit('setMembershipSubscriptionError', true);
                        commit('setMembershipSubscriptionLoading', false);
                        reject(err.response.data);
                    });
            });
        },
    },
};
