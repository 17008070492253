<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="7"
            y="21"
            width="3"
            height="3"
            rx="1.5"
            :fill="color" 
        />
        <rect
            x="18"
            y="21" 
            width="3"
            height="3"
            rx="1.5"
            :fill="color"
        />
        <path d="M0 1H1.8421C3.49896 1 4.84211 2.34315 4.84211 4V14C4.84211 15.6569 6.18525 17 7.84211 17H20C21.6569 17 23 15.6569 23 14V7.69231C23 6.03546 21.6569 4.69231 20 4.69231H8.47368" :stroke="color" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'ShopIcon',
    props: {
        color: {
            type: String,
            default: '#b1b1b1',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
