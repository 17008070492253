module.exports.tags = {
    status: {
        intake: {
            incomplete: 'status:intake:incomplete',
            complete: 'status:intake:complete',
        },
        verification: {
            incomplete: 'status:verification:incomplete',
            complete: 'status:verification:complete',
        },
        checkout: {
            incomplete: 'status:checkout:incomplete',
            complete: 'status:checkout:complete',
        },
    },
};

module.exports.generateTreatmentTag = (productName) => {
    return `treatment_preferences:product:${productName}`;
};
