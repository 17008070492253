<template>
    <WidgetContainer>
        <div class="widget__content">
            <div class="widget__header">
                <HBadge
                    label="Incomplete VIsits"
                    dark
                    small
                    class="widget__badge"
                />
                <div
                    v-if="!productTemplatesLoading && !incompleteIntakes.loading"
                    class="widget__view-all"
                    role="button"
                    tabIndex="0"
                    data-test="incomplete-widget-view-all"
                    @click="handleClickViewAll"
                >
                    View All
                </div>
            </div>
            <div v-if="productTemplatesLoading || incompleteIntakes.loading">
                <div class="widget__card">
                    <div class="widget__card-content">
                        <div class="widget__card-image">
                            <SkeletonBase width="100%" height="100%" />
                        </div>
                        <div class="widget__card-info">
                            <SkeletonBase width="80%" height="20px" />
                            <SkeletonBase width="60%" height="20px" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="widget__cards">
                <CardContent
                    v-for="incompleteIntake in incompleteIntakesData.slice(0, 2)"
                    :key="incompleteIntake.intake._id"
                    :incomplete-intake="incompleteIntake"
                    :on-cancel="handleCancelIntake"
                    :on-continue="handleClickIntake"
                />
            </div>
        </div>
        <IncompletePanel
            :is-opened="isOpenedSidebar"
            :intakes="incompleteIntakesData"
            :on-close="handleCloseSidebar"
            :on-continue="handleClickIntake"
            :on-cancel="handleCancelIntake"
        />
    </WidgetContainer>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import CardContent from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/CardContent';
import { getIncompleteProductIds, getSortedIncompleteIntakes } from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/IncompleteIntakesWidget.controller';
import IncompletePanel from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/IncompletePanel';
import WidgetContainer from '@/components/Dashboard/Widgets/WidgetContainer';
import HBadge from '@/components/Shared/HBadge';
import SkeletonBase from '@/components/Shared/Skeletons/Base/SkeletonBase';
import { getStateUser } from '@/domains/user/lib/getStateUser';
import IntakeService from '@/services/IntakeService';
import { getIncompleteIntakes } from '@/store/selectors/intake';
import { getProductTemplatesLoading } from '@/store/selectors/productTemplates';

export default {
    name: 'IncompleteIntakesWidget',
    components: {
        HBadge,
        WidgetContainer,
        IncompletePanel,
        SkeletonBase,
        CardContent,
    },
    data() {
        return {
            rapamycinTemplateId: process.env.VUE_APP_GENERIC_RAPAMYCIN_TEMPLATE_ID,
            isOpenedSidebar: false,
        };
    },
    computed: {
        ...mapState({
            user: getStateUser,
            incompleteIntakes: getIncompleteIntakes,
            productTemplatesLoading: getProductTemplatesLoading,
        }),
        ...mapGetters({
            isCompletedUserProfile: 'user/isCompletedUserProfile',
        }),
        incompleteIntakesData() {
            return getSortedIncompleteIntakes(this.incompleteIntakes.data, this.rapamycinTemplateId);
        },
        incompleteProductIds() {
            return getIncompleteProductIds(this.incompleteIntakesData);
        },
    },
    watch: {
        incompleteProductIds: {
            handler(productIds) {
                try {
                    if (productIds.length) {
                        this.getProductTemplatesDataByIds({ ids: productIds });
                    }
                } catch (error) {
                    console.error('Error get product templates', error);
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions({
            retrieveIntake: 'intake/retrieveIntake',
            closeIntake: 'intake/patientClosesIntake',
            getIntakes: 'intake/retrievePatientIntakes',
            retrieveIncompleteIntakes: 'intake/retrieveIncompleteIntakes',
            getProductTemplatesDataByIds: 'productTemplates/getProductTemplatesDataByIds',
        }),
        ...mapMutations({
            setIntakeId: 'intake/setIntakeId',
            initializeIntakeId: 'intake/initializeIntakeId',
            setDashboardIncompleteIntakes: 'intake/setDashboardIncompleteIntakes',
        }),
        async handleClickIntake(incompleteIntake) {
            await IntakeService.continueCompletingIntake(
                {
                    retrieveIntake: this.retrieveIntake,
                    setIntakeId: this.setIntakeId,
                    initializeIntakeId: this.initializeIntakeId,
                    routerPush: (props) => this.$router.push(props),
                },
                {
                    userId: this.user._id,
                    source: 'dashboard',
                    intakeId: incompleteIntake.intake._id,
                    intakeTemplate: incompleteIntake.template,
                    status: incompleteIntake.status,
                    productId: incompleteIntake.template?.product_defaults[0],
                    medicalProfile: this.user.medical_profile,
                    isCompletedUserProfile: this.isCompletedUserProfile,
                },
            );
        },
        async handleCancelIntake(incompleteIntake) {
            try {
                await this.closeIntake({ user: this.user._id, intake: incompleteIntake.intake._id });

                this.setDashboardIncompleteIntakes({
                    id: 'data', value: this.incompleteIntakesData.filter((intake) => intake.intake._id !== incompleteIntake.intake._id),
                });

                await this.getIntakes({ user: this.user._id });
            } catch (error) {
                console.error('Error closing intake', error);
                this.handleClickViewAll();
            }
        },
        handleClickViewAll() {
            this.isOpenedSidebar = true;
        },
        handleCloseSidebar() {
            this.isOpenedSidebar = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.widget {
    &__view-all {
        @include caption;
        font-weight: 700;
        color: $link-color;
        cursor: pointer;
    }

    &__content,
    &__cards {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__badge {
        width: fit-content;
    }

    &__card {
        padding: 1rem;
        gap: 1rem;
        border-radius: 1rem;
        color: $powder-blue-400;
        background-color: $white-100;
    }

    &__card-content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 1rem;
    }

    &__card-info {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__card-image {
        width: 36px;
        min-width: 36px;
        height: 36px;
    }

    @media ($min-810) {
        &__view-all {
            @include body;
            font-weight: 700;
        }

        &__content {
            gap: 1.5rem;
        }

        &__card-image {
            width: 74px;
            min-width: 74px;
            height: 74px;
        }
    }
}
</style>
