module.exports.unavailableLabStates = [
    {
        abbreviation: 'NY',
        name: 'New York',
        syncRequired: false,
    },
    {
        abbreviation: 'NJ',
        name: 'New Jersey',
        syncRequired: true,
    },
    {
        abbreviation: 'RI',
        name: 'Rhode Island',
        syncRequired: true,
    },
];
