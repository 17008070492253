export const MEDICAL_HISTORY_QUESTIONS = [
    {
        id: 'pregnancyStatus',
        label: 'Do any of the following apply to you?',
        key: 'pregnancy_status',
        options: [
            {
                label: 'I am pregnant',
                value: 'pregnant',
            },
            {
                label: 'I am planning to be pregnant in the next 6 months',
                value: 'planning_pregnancy',
            },
            {
                label: 'I am currently breastfeeding',
                value: 'breastfeeding',
            },
        ],
        type: 'checkbox',
    },
    {
        id: 'hasAllergies',
        label: 'Do you currently have any allergies to food, dyes, medications, or anything else?',
        subLabel: 'Please list what you are allergic to and the reaction that each one causes',
        key: 'has_allergies',
        textKey: 'allergy_text',
        type: 'radio',
    },
    {
        id: 'hasCurrentMedications',
        label: 'Are you currently taking any medications or supplements?',
        subLabel: 'Please list all medications and supplements, dose and how frequently you are taking them',
        key: 'has_current_medications',
        textKey: 'current_medications',
        type: 'radio',
    },
    {
        id: 'hasMedicalConditions',
        label: 'Do you currently have any medical conditions or a history of prior surgeries?',
        subLabel: 'Please list your medical condition(s) and any prior surgeries',
        key: 'has_medical_conditions',
        textKey: 'medical_condition_text',
        type: 'radio',
    },
];
