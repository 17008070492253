export const hasPendingIntakes = (pendingIntakes = [], rapamycinTemplateId) => {
    return !!pendingIntakes?.some((intake) => intake.intake_template?._id === rapamycinTemplateId && intake.patient_complete);
};

export const hasApprovedIntake = (pendingIntakes = [], rapamycinTemplateId) => {
    return !!pendingIntakes?.some((intake) => intake.intake_template?._id === rapamycinTemplateId && intake.physician_review);
};

export const getIncompleteIntake = (incompleteIntakes = [], rapamycinTemplateId) => {
    return incompleteIntakes?.find((intake) => intake.template?._id === rapamycinTemplateId);
};

export const getGreetingsText = (firstName) => {
    return `Hello, ${firstName}`;
};

export const hasOrderedLabs = (labsResults = []) => {
    return !!labsResults?.some((lab) => lab.status === 'ordered' || lab.order?.status?.fulfilled);
};

export const getCreatedOrderId = (labsResults = []) => {
    return labsResults?.find((lab) => lab.status === 'created' && !lab.order?.status?.fulfilled)?.order._id;
};

export const getAlerts = (isMobileVerified, verificationStatus) => {
    const result = [];

    if (!isMobileVerified) result.push('mobile-verification');

    if (['not_started', 'outdated', 'rejected'].some(status => status === verificationStatus)) {
        result.push('id-verification');
    }

    return result;
};

export const getShowAppointment = (appointmentsLength, isLoadingProductWidgets, hasActiveLabs, hasPendingIntakes) => {
    if (isLoadingProductWidgets || appointmentsLength) return false;

    return hasActiveLabs || hasPendingIntakes;
};

export const getShowIncompleteIntakes = (incompleteIntakes) => {
    return incompleteIntakes.isInit && !!incompleteIntakes.data?.length;
};

export const getIsOddWidgetList = (visibleWidgets, isLoading) => {
    if (isLoading) return false;

    return Object.keys(visibleWidgets).filter(widgetKey => visibleWidgets[widgetKey]).length % 2 !== 0;
};
