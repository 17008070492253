<template>
    <div v-if="status" class="verification-container" :class="{'verification-container--sidebar': sidebar, 'verification-container--sidebar-collapse': collapse}">
        <div class="verification-badge strong" :class="status.class" @click="redirectToVerification()">
            <div class="verification-circle"></div>
            <span :class="{'collapse': collapse}">{{ status.text }}</span>
            <div class="popover-trigger">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none"><path fill="#0D301E" d="M5.4 6.6a.6.6 0 0 1 1.2 0v1.8a.6.6 0 0 1-1.2 0V6.6ZM6 3.3a.9.9 0 1 0 0 1.8.9.9 0 0 0 0-1.8ZM0 6a6 6 0 1 1 12 0A6 6 0 0 1 0 6Zm6-4.8a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6Z"/></svg>
                <div class="popover-content">{{ status.info }}</div>
            </div>
        </div>
        <a v-if="shouldRedirect && !collapse" class="verification-link" :href="`/#/verification/${user._id}/verify_account`">Verify Now</a>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'VerificationBadge',
    props: {
        sidebar: {
            type: Boolean,
            default: false
        },
        collapse: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            test: true
        };
    },
    methods: {
        redirectToVerification() {
            if (this.shouldRedirect && window.innerWidth >= 760) {
                this.$router.push({path: this.verificationLink});
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            verificationStatus: state => state.user.user.verificationStatus,
            organizationSettings: state => state.organizationSettings.data
        }),
        shouldRedirect: {
            get() {
                return this.verificationStatus === 'not_started' || this.verificationStatus === 'outdated';
            }
        },
        verificationLink: {
            get() {
                return `/verification/${this.user._id}/verify_account`;
            }
        },
        status: {
            get() {
                switch (this.verificationStatus) {
                case 'not_started':
                case 'outdated':
                    return {
                        class: 'verification-badge--not-verified',
                        text: 'Not Verified',
                        info: "Ensuring the best care and accurate treatment prescriptions require the verification of your account. Take the next step by clicking on 'Verify Now'.",
                    };
                case 'rejected':
                    return {
                        class: 'verification-badge--not-verified',
                        text: 'Verification Rejected',
                        info: "There was an issue with your previous verification attempt. Click on 'Verify Now' to upload new images or schedule a call with our team.",
                    };
                case 'pending':
                    return {
                        class: 'verification-badge--pending',
                        text: 'Verification Pending',
                        info: 'Our care team is currently in the process of verifying your account.',
                    };
                case 'completed':
                    return {
                        class: 'verification-badge--verified',
                        text: 'Verified Account',
                        info: 'Congratulations! You are now officially Healthspan Verified, granting you access to our dedicated care team and a range of treatments.',
                    };
                default:
                    return null;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_colors.scss";
    @import '@/assets/scss/_type.scss';
    @import '@/assets/scss/utility.scss';

    .verification-container {
        flex-direction: column;
        row-gap: 10px;
        z-index: 50;
        padding-bottom: 26px;
        align-items: flex-start;
        display: none;

        @media screen and (max-width: 1024px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 0.5rem;
        }

        &--sidebar {
            width: calc(250px - 12px - 12px);
            display: flex;
            padding-bottom: 0;
        }

        &--sidebar-collapse {
            width: 0;
        }

        .collapse {
            width: 0;
        }

        @media (min-width: 360px) {
            column-gap: 1rem;
        }
    }

    .verification-link {
        font-weight: 700;
        @extend %helveticaNowDisplay;
        font-size: 12px;
        letter-spacing: 0.03rem;
        color: #599BEA;
        text-decoration: none;
        text-align: center;
    }

    .verification-badge {
        width: fit-content;
        cursor: default;
        @extend %helveticaNowDisplay;
        font-size: 12px;
        display: flex;
        padding: 6px 11px;
        border-radius: 30px;
        align-items: center;
        column-gap: 8px;
        letter-spacing: 0.03rem;

        &--verified {
            background-color: #BEECC8;
            color: #0D301E;

            & svg path {
                fill: #0D301E;
            }
            & .verification-circle {
                background-color: $success-200;
            }
        }
        &--not-verified {
            background-color: #FFCABE;
            color: #4E3500;

            & svg path {
                fill: #4E3500;
            }
            & .verification-circle {
                background-color: $critical-200;
            }
        }
        &--pending {
            background-color: #FFF8C8;
            color: #530400;

            & svg path {
                fill: #530400;
            }
            & .verification-circle {
                background-color: #F5C64F;
            }
        }

        img {
            margin-bottom: -2px;
        }
    }

    .verification-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    .popover-trigger {
        display: inline-block;
        top: -1px;
        position: relative;
    }

    .popover-content {
        z-index: $z-tooltip;
        animation: fadeOut 0.3s forwards;
        text-align: left;
        position: absolute;
        border-radius: 0.75rem;
        bottom: -25px;
        left: 30px;
        width: 250px;
        padding: 0.75rem 0.95rem;
        background-color: #212931;
        color: #FFF;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
        font-weight: normal;
        font-size: 14px;
        visibility: hidden;
        transition: all 0.3s ease;

        @media screen and (max-width: 1024px){
            top: 35px;
            left: -100px;
            bottom: auto;
        }
    }

    .popover-content::before {
        content: "";
        position: absolute;
        bottom: 27px;
        left: -9px;
        width: 0;
        height: 0;
        border-right: 10px solid #212931;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;

        @media screen and (max-width: 1024px){
            left: 42%;
            top: -14px;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-bottom: 10px solid #212931;
        }
    }

    .popover-trigger:hover .popover-content {
        animation: fadeIn 0.3s forwards;
        visibility: visible;
    }

</style>
