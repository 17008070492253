<template>
    <div>
        <ul class="treatment-checkout__recommended-options">
            <li :class="{'active': active_item === product._id}"
                v-if="alternative_products && alternative_products.length"
                v-for="product in alternative_products"
                class="treatment-checkout__recommended-cell default"
                @click="select_active(product)">
                <div class="treatment-checkout__recommended-option">
                    <div class="hs-radio">
                        <input :id="`radio-${product._id}`"
                               name="medicationType"
                               type="radio"
                               v-model="active_item"
                               :value="product._id">
                        <label class="hs-radio-label" :for="`radio-${product._id}`"></label>
                    </div>
                    <div class="treatment-checkout__recommended-helper treatment-checkout__recommended-helper-preview">
                        <div class="treatment-checkout__recommended-image" :style="{'background-image': `url(${product.settings.alternate_image})`}"></div>
                        <div class="treatment-checkout__recommended-description">
                            <p class="treatment-checkout__recommended-title">{{ product.settings.product_type }}</p>
                            <blockquote class="treatment-checkout__recommended-desc">{{ product.settings.short_description }}</blockquote>
                        </div>

                    </div>
                </div>
                <div v-if="is_best_value(product._id)"
                     class="treatment-checkout__recommended-tip" >
                    {{ product.settings.promotional_text }}
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
    name: 'TreatmentMedicationType',
    components: {},
    props: ['products', 'intake_template'],
    data() {
        return {
            alternative_products: [],
            active_item: null
        }
    },
    async created() {
        try {
            this.alternative_products = await this.getProducts({ids: this.products})
            this.configure_active_item();
        } catch (e) {
            console.log('Error created() TreatmentMedicationType', e);
        }
    },
    computed: {
        ...mapState({})
    },
    methods: {
        ...mapActions({
            getProducts: 'productTemplates/getManyProductTemplatesByIds'
        }),
        is_best_value(product_id) {
            if (this.intake_template.product_best_value) {
                return product_id === this.intake_template.product_best_value
            } else {
                return false;
            }
        },
        configure_active_item() {
            this.active_item = this.products.find(p => p === this.$route.query.product);
        },
        select_active(product) {
            this.active_item = product._id;
            this.$router.replace({
                query: {
                    product: this.active_item
                },
                params: {
                    savePosition: true
                }
            });
            this.$emit('onSelectedType', product);
        }
    },

}
</script>
<style lang="scss"></style>
