<template>
    <div class="confirm-hero">
        <div class="confirm-hero__content">
            <HBadge label="Welcome" dark small />
            <h2>Getting Started with Labs</h2>
            <p v-if="pageType === 'success'">
                You’ve successfully ordered your lab test with Healthspan. Please review the important details below to ensure a smooth testing process.
            </p>
            <p v-else>
                Please review the important details below to ensure a smooth testing process.
            </p>
        </div>
        <div class="confirm-hero__image">
            <img src="./assets/hero.png" alt="shop hero image">
            <div class="confirm-hero__overlay" />
        </div>
    </div>
</template>

<script>
import HBadge from '@/components/Shared/HBadge';

export default {
    name: 'LabHero',
    components: {
        HBadge,
    },
    props: {
        pageType: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.confirm-hero {
    position: relative;
    width: 100%;
    height: 405px;
    background-color: #c5b99e;
    background-image: radial-gradient(
            circle farthest-corner at top left,
            rgba(255, 158, 158, 1) 0%,
            rgba(255, 158, 158, 0) 50%
        ),
        radial-gradient(circle farthest-side at top right, rgba(181, 176, 177, 1) 0%, rgba(181, 176, 177, 0) 10%),
        radial-gradient(circle farthest-corner at bottom right, rgba(255, 65, 97, 1) 0%, rgba(255, 65, 97, 0) 33%),
        radial-gradient(circle farthest-corner at top right, rgba(155, 221, 240, 1) 0%, rgba(155, 221, 240, 0) 50%),
        radial-gradient(ellipse at bottom center, rgba(52, 49, 151, 1) 0%, rgba(52, 49, 151, 0) 80%);
    overflow: hidden;
    margin: 0 auto;

    &__content {
        position: absolute;
        bottom: 0;
        color: #fff;
        padding: 1.5rem;
        z-index: 1;

        h2 {
            @include heading-sm;
            margin: 1rem 0 0.5rem;
        }

        p {
            @include caption;
            margin: 0;
        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        width: auto;
        height: 95%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__overlay {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgb(33, 41, 49) 10%, rgba(33, 41, 49, 0) 100%);
    }

    @media (min-width: 430px) {
        &__image {
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: 640px) {
        height: 480px;
    }

    @media (min-width: 830px) {
        height: 500px;

        &__image {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center; 

            img {
                width: 80%;
                height: auto;
            }
        }
    }

    @media (min-width: 1024px) {
        max-width: 1440px;
        height: 515px;
        border: 1px solid $border-faint;
        border-radius: 1.5rem;
        margin-top: 1rem;

        &__content {
            position: static;
            color: #fff;
            max-width: 356px;
            padding: 3.25rem 0 0 2.75rem;
    
            h2 {
                @include heading-lg;
            }

            p {
                @include body;
            }
        }

        &__image {
            position: absolute;
            top: 0.875rem;
            right: 0.875rem;
            width: auto;
            height: 100%;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__overlay {
            display: none;
        }
    }
}

</style>
