<template>
    <ul class="benefits h-caption">
        <li v-for="(feature, index) in features" :key="index">
            <span class="benefits__icon" :class="feature.icon"></span>
            <span>{{ feature.description }}</span>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Benefits',
    props: {
        features: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.benefits {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 500px;
    list-style: none;
    padding: 2rem 0;
    margin: 0 auto;

    li {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
            display: inline-block;
        }
    }

    &__icon {
        display: inline-block;
        width: 12px;
        height: 13px;
        background-size: contain;
    }
}

.health-care {
    background-image: url("@/assets/icons/health-care.svg");
    background-repeat: no-repeat;
}

.heart {
    background-image: url("@/assets/icons/heart.svg");
    background-repeat: no-repeat;
}

.shipping {
    background-image: url("@/assets/icons/shipping.svg");
    background-repeat: no-repeat;
    margin: 0;
}

.chat {
    background-image: url("@/assets/icons/chat.svg");
    background-repeat: no-repeat;
}

.smile {
    background-image: url("@/assets/icons/smile.svg");
    background-repeat: no-repeat;
}
</style>
