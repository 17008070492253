<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12 7L12 11.5C12 12.3284 12.6716 13 13.5 13L17 13" :stroke="color" stroke-width="2" />
        <rect
            x="4"
            y="4"
            width="16"
            height="16"
            rx="3"
            :stroke="color"
            stroke-width="2"
        />
    </svg>
</template>

<script>
export default {
    name: 'SubscriptionsIcon',
    props: {
        color: {
            type: String,
            default: '#212931',
        },
        size: {
            type: Number,
            default: 24,
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
