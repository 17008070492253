<template>
	<nav class="h-select">
		<button v-for="option in options" :key="option.id" :class="{ active: status === option.status }" @click="handleselectedOption(option)">{{ option.label }}</button>
	</nav>
</template>

<script>
export default {
	name: 'HCategorySelect',
	props: {
		options: {
			type: Array,
			default: () => [],
		},
		status: {
			type: String,
		},
	},
	methods: {
		handleselectedOption(option) {
			this.$emit('handleselectedOption', option);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.h-select {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
	overflow: auto;
	padding: 1rem 0;
	margin-bottom: 1rem;

	button {
		display: block;
		white-space: nowrap;
		border: 1px solid $border-medium;
		border-radius: 20px;
		background: $white-100;
		color: $gray-900;
		font-size: 0.875rem;
		padding: 0.3rem 1rem;
	}
}
.active {
	border-width: 1px !important;
	border-color: $black-85 !important;
	background: $bg-light !important;
	color: $black-100 !important;
}
</style>
