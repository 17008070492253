<template>
    <div :class="isCommonSection ? 'settings-section' : undefined">
        <div>
            <transition name="alert-fade" mode="out-in">
                <HAlert
                    v-if="alert.status"
                    class="settings-alert"
                    :message="alert.message"
                    :status="alert.status"
                    @dismiss="closeAlert"
                />
            </transition>
        </div>
        <div v-if="isCommonSection" class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div v-if="isCommonSection" class="settings-section__header">
            <h2>Email Address</h2>
            <p>Update your email address</p>
        </div>
        <div class="settings-email">
            <NotificationToggle v-if="withNotificationToggle" label="Email Notifications" notification-key="email" />
            <h-text-input-secondary
                id="email"
                v-model="userData.email"
                name="email"
                label="Email"
                :vuelidate="$v.userData.email"
                :errors="[$v.userData.email.$invalid && $v.userData.email.$dirty && errorMessages.EMAIL]"
            />
            <h-button
                variant="secondary"
                :loading="submitting"
                class="settings-section__btn"
                @click="updateEmail"
            >
                Submit
            </h-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HAlert from '@/components/Shared/HAlert.vue';
import HButton from '@/components/Shared/HButtonV2.vue';
import HTextInputSecondary from '@/components/Shared/HTextInputSecondary.vue';
import Alert from '@/helpers/alert';
import { emailValidation, errorMessages  } from '@/helpers/validations';

export default {
    name: 'EmailSection',
    components: {
        HAlert,
        HTextInputSecondary,
        HButton,
        NotificationToggle: () => import('./Subcomponents/NotificationToggle'),
    },
    props: {
        isCommonSection: {
            type: Boolean,
            default: true,
        },
        withNotificationToggle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            submitting: false,
            userData: {
                email: '',
            },
            errorMessages,
            alert: new Alert(),
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
    },
    validations: {
        userData: {
            email: emailValidation,
        },
    },
    created() {
        this.userData.email = this.user.email;
    },
    methods: {
        ...mapActions({
            updateUser: 'user/updateUser',
        }),
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        closeAlert() {
            this.alert.dismiss();
            this.userData.email = this.user.email;
        },
        async updateEmail() {
            const payload = {
                _id: this.user._id,
                email: this.userData.email,
            };

            this.submitting = true;

            try {
                await this.updateUser(payload);
                this.alert.showWithTimeout('Email address updated successfully', 'success');
            } catch (error) {
                this.alert.show(error.message, 'critical');
            } finally {
                this.submitting = false;
            }
        },
    },
};
</script>
