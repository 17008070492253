class Alert {
    constructor() {
        this.message = '';
        this.status = '';
    }

    dismiss() {
        this.message = '';
        this.status = '';
    }

    show(message, status) {
        this.message = message;
        this.status = status;
    }

    showWithTimeout(message, status, timeout = 3000) {
        this.show(message, status);
        setTimeout(() => {
            this.dismiss();
        }, timeout);
    }
}

export default Alert;
