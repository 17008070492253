<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.75 1.75V3.75M6.75 5.25V3.75M13.25 1.75V3.75M13.25 5.25V3.75M6.75 3.75H3.75C2.64543 3.75 1.75 4.64543 1.75 5.75V16.25C1.75 17.3546 2.64543 18.25 3.75 18.25H16.25C17.3546 18.25 18.25 17.3546 18.25 16.25V5.75C18.25 4.64543 17.3546 3.75 16.25 3.75H13.25M6.75 3.75H13.25M1.75 8.75H18.25"
            stroke="#212931"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'AppointmentsIcon',
};
</script>

<style lang="scss" scoped>

</style>
