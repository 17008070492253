<template>
    <div class="widget__container" :style="{ height: '100%', width: '100%' }">
        <HBadge
            v-if="label"
            :label="label"
            dark
            small
            class="widget__badge"
        />
        <div class="widget__header">
            {{ header }}
        </div>
        <div class="widget__content">
            <slot></slot>
        </div>
        <video
            autoplay
            loop
            muted
            playsinline
            :poster="poster"
            class="widget__video"
        >
            <source :src="src" type="video/webm" />
        </video>
        <div class="widget__overlay"></div>
        <a
            v-if="externalLink.link"
            :href="externalLink.link"
            class="widget__external-link"
            target="_blank"
            @click="openModal"
        >
            <img :src="externalLink.icon" /> {{ externalLink.description }}
        </a>
        <WelcomeVideoModal :is-open="isOpened" :on-close="closeModal" />
    </div>
</template>

<script>
import WelcomeVideoModal from '@/components/Dashboard/Modals/Components/WelcomeVideoModal';
import HBadge from '@/components/Shared/HBadge';

export default {
    name: 'VideoWidget',
    components: {
        HBadge,
        WelcomeVideoModal,
    },
    data() {
        return {
            isOpened: false,
        };
    },
    props: {
        poster: {
            type: String,
            default: '',
        },
        src: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
        header: {
            type: String,
            default: '100%',
        },
        externalLink: {
            type: Object,
            default: () => ({
                link: null,
                description: null,
            }),
        },
    },
    methods: {
        openModal(event) {
            event.preventDefault();

            this.isOpened = true;
        },
        closeModal() {
            this.isOpened = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.widget {
    &__badge {
        width: fit-content;
        z-index: 2;
    }

    &__container {
        position: relative;
        border: 1px solid $gray-150;
        display: flex;
        flex-direction: column;
        padding: 3rem;
        gap: 1.5rem;
        overflow: hidden;
        border-radius: 1.5rem;
        width: 100%;
        height: 100%;
    }

    &__header {
        @extend %display;

        width: 50%;
        color: $white;
        z-index: 2;
        text-transform: none;
    }

    &__content {
        color: $white;
        z-index: 2;
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    &__external-link {
        @extend .strong;

        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: $white;
        z-index: 1;
        width: fit-content;
    }

    @media (max-width: 768px) {
        &__container {
            padding: 1.5rem;
        }

        &__header {
            @include heading-lg;
            width: 100%;
            z-index: 2;
            text-transform: none;
        }
    }

    @media (max-width: 430px) {
        &__container {
            padding: 1.5rem;
        }

        &__header {
            @include body;
            font-weight: 700;
            width: 100%;
            z-index: 2;
            text-transform: none;
        }
    }
}
</style>
