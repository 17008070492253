<template>
    <infinite-loading :identifier="identifier" @infinite="$emit('infinite', $event)">
        <div slot="spinner" class="infinite-loader">
            <div class="infinite-spinner"></div>
        </div>
        <div slot="no-more" class="infinite-no-more">
            You're all caught up.
        </div>
        <div slot="no-results" class="infinite-no-more">
            <span v-if="endIndicator">End of notifications</span>
        </div>
    </infinite-loading>
</template>

<script>
export default {
    name: 'HInfiniteLoader',
    props: {
        identifier: {
            type: [String, Number],
            required: true,
        },
        endIndicator: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.infinite-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.infinite-spinner {
    border: 2px solid $gray-100;
    border-top: 2px solid $charcoal;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
}

.infinite-no-more {
    @include caption;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>
