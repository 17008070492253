<template>
    <div>
        <div class="notifications-page">
            <div class="notifications-header typography-header">
                <h1>Notifications</h1>
                <div v-if="unreadCount" class="notifications-header__body">
                    <div class="notifications-header__unread">
                        You have <span class="notifications-header__count" @click="filterNotifications('Unread')">{{ unreadCount }} unread</span> notification{{ unreadCount === 1 ? '' : 's' }}
                    </div>
                    <div class="notifications-header__read" @click="handleMarkAllAsRead">
                        Mark all as read
                    </div>
                </div>
            </div>
            <div class="notifications-filter">
                <NotificationFilter :options="types" :selected-option="selectedFilter" @filter-selected="filterNotifications" />
            </div>
            <section v-if="todayNotifications.length" class="notifications-section">
                <h2>Today</h2>
                <div>
                    <NotificationItem
                        v-for="notification in todayNotifications"
                        :key="notification._id"
                        :notification="notification"
                        @click="handleNotificationClick"
                    />
                </div>
            </section>
            <section v-if="weekNotifications.length" class="notifications-section">
                <h2>Last 7 Days</h2>
                <div>
                    <NotificationItem
                        v-for="notification in weekNotifications"
                        :key="notification._id"
                        :notification="notification"
                        @click="handleNotificationClick"
                    />
                </div>
            </section>
            <section v-if="monthNotifications.length" class="notifications-section">
                <h2>Last 30 Days</h2>
                <div>
                    <NotificationItem
                        v-for="notification in monthNotifications"
                        :key="notification._id"
                        :notification="notification"
                        @click="handleNotificationClick"
                    />
                </div>
            </section>
            <section v-if="earlierNotifications.length" class="notifications-section">
                <h2>Earlier</h2>
                <div>
                    <NotificationItem
                        v-for="notification in earlierNotifications"
                        :key="notification._id"
                        :notification="notification"
                        @click="handleNotificationClick"
                    />
                </div>
            </section>
            <h-infinite-loader identifier="page" @infinite="infiniteHandler" />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';
import { navigateToNotificationLink } from './utils/navigateToNotificationLink';

import NotificationFilter from '@/components/Dashboard/Notifications/Subcomponents/NotificationFilter.vue';
import NotificationItem from '@/components/Dashboard/Notifications/Subcomponents/NotificationItem.vue';
import HInfiniteLoader from '@/components/Shared/HInfiniteLoader.vue';

export default {
    name: 'NotificationsPage',
    components: {
        NotificationFilter,
        NotificationItem,
        HInfiniteLoader,
    },
    data() {
        return {
            selectedFilter: 'All',
            types: ['Unread'],
            localNotifications: [],
            totalPages: 0,
            page: 1,
            allRead: false,
        };
    },
    computed: {
        ...mapState({
            notifications: state => state.alerts.notifications,
            unreadCount: state => state.alerts.unreadCount,
            user: (state) => state.user.user,
        }),
        filteredNotifications() {
            if (this.selectedFilter === 'Unread') {
                return this.localNotifications.filter(notification => !notification.read);
            }

            return this.localNotifications;
        },
        todayNotifications() {
            return this.filterByDateRange(moment().startOf('day'));
        },
        weekNotifications() {
            return this.filterByDateRange(moment().subtract(7, 'days').startOf('day'), moment().startOf('day'));
        },
        monthNotifications() {
            return this.filterByDateRange(moment().subtract(30, 'days').startOf('day'), moment().subtract(7, 'days').startOf('day'));
        },
        earlierNotifications() { 
            return this.filterByDateRange(null, moment().subtract(30, 'days').startOf('day'));
        },
    },
    watch: {
        notifications: {
            handler(newNotifications) {
                this.updateLocalNotifications(newNotifications);
            },
            deep: true,
        },
    },
    mounted() {
        this.fetchNotifications();
        this.updateUnreadCount();
    },
    methods: {
        ...mapActions({
            getNotifications: 'alerts/getNotifications',
            markNotificationAsRead: 'alerts/markNotificationAsRead',
            markAllNotificationsAsRead: 'alerts/markAllNotificationsAsRead',
        }),
        ...mapMutations({
            updateUnreadCount: 'alerts/updateUnreadCount',
        }),
        async fetchNotifications(page = this.page) {
            await this.getNotifications({ page });

            this.updateLocalNotifications(this.notifications);
        },
        updateLocalNotifications(notifications) {
            if (notifications?.docs) {
                const existingIds = new Set(this.localNotifications.map(notification => notification._id));

                const updatedNotifications = this.localNotifications.map(localNotification => {
                    const updatedNotification = notifications.docs.find(notification => notification._id === localNotification._id);

                    return updatedNotification || localNotification;
                });
                const newNotifications = notifications.docs.filter(notification => !existingIds.has(notification._id));
                
                this.localNotifications = [...updatedNotifications, ...newNotifications];
                this.page = notifications.page;
                this.totalPages = notifications.totalPages;
                this.updateUnreadCount();
            }
        },
        filterByDateRange(startDate, endDate = moment()) {
            return this.filteredNotifications
                .filter(notification => {
                    const createdAt = moment(notification.created_at);

                    return (!startDate || createdAt.isSameOrAfter(startDate)) && (!endDate || createdAt.isBefore(endDate));
                })
                .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
        },
        async handleMarkAllAsRead() {
            await this.markAllNotificationsAsRead();
            this.updateUnreadCount();
        },
        filterNotifications(option) {
            this.selectedFilter = option;
        },
        async handleNotificationClick(notification) {
            if (!notification.read) {
                await this.markNotificationAsRead(notification._id);
            }

            navigateToNotificationLink(notification, this.user._id, this.$router);
        },
        infiniteHandler($state) {
            if (this.page < this.totalPages) {
                const nextPage = this.page + 1;

                setTimeout(() => {
                    this.fetchNotifications(nextPage).then(() => {
                        $state.loaded();
                    }).catch(() => {
                        $state.complete();
                    });
                }, 1000);
            } else {
                $state.complete();
            }
        },
    },
};
</script>
