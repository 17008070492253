<template>
    <div v-click-outside="closeNavDropdown" class="rel header-nav-button-wrapper" @click="openNavDropdown">
        <button class="header-nav-button">
            <div class="main-menu-icon">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    style="display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 3; overflow: visible;"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fill-rule="nonzero">
                        <path d="m2 16h28" />
                        <path d="m2 24h28" />
                        <path d="m2 8h28" />
                    </g>
                </svg>
            </div>
            <div
                v-if="!userProfileUrl"
                class="nav-profile-img"
            >
                <img class="nav-profile-img-container" src="@/assets/icons/profile.svg">
            </div>
            <div
                v-if="userProfileUrl"
                :style="{'background-image': 'url(' + userProfileUrl + ')'}"
                class="nav-profile-img"
            />
        </button>
        <div v-if="is_nav_dropdown" class="nav-dropdown-container">
            <router-link
                :to="'/dashboard/' + user._id + '/profile'"
                class="nav-dropdown-list-item"
                @click.native="closeNavDropdown"
            >
                Profile
            </router-link>
            <router-link
                :to="'/dashboard/' + user._id + '/labs'"
                class="nav-dropdown-list-item"
                @click.native="closeNavDropdown"
            >
                Labs
            </router-link>
            <router-link :to="'/dashboard/' + user._id + '/orders'" class="nav-dropdown-list-item" @click.native="closeNavDropdown">
                Orders
            </router-link>
            <router-link
                :to="'/dashboard/' + user._id + '/subscriptions'"
                class="nav-dropdown-list-item"
                @click.native="closeNavDropdown"
            >
                Subscriptions
            </router-link>
            <router-link
                :to="'/messages/' + user._id + '/new'"
                class="nav-dropdown-list-item"
                @click.native="closeNavDropdown"
            >
                Messages
            </router-link>
            <router-link
                :to="'/dashboard/' + user._id + '/appointments'"
                class="nav-dropdown-list-item"
                @click.native="closeNavDropdown"
            >
                Appointments
            </router-link>
            <router-link
                :to="'/dashboard/' + user._id + '/providers'"
                class="nav-dropdown-list-item"
                @click.native="closeNavDropdown"
            >
                Providers
            </router-link>
            <router-link
                :to="'/dashboard/' + user._id + '/settings'"
                class="nav-dropdown-list-item"
                @click.native="closeNavDropdown"
            >
                Settings
            </router-link>
            <div class="dropdown-break" />
            <div v-if="organization_settings && organization_settings.dropdown_links && organization_settings.dropdown_links.length">
                <a
                    v-for="item in organization_settings.dropdown_links"
                    :key="item._id"
                    class="nav-dropdown-list-item"
                    :href="item.url"
                    target="_blank"
                >
                    {{ item.name }}
                </a>
            </div>
            <div v-if="organization_settings && organization_settings.dropdown_links && organization_settings.dropdown_links.length" class="dropdown-break" />
            <a class="nav-dropdown-list-item light-option" @click="onLogout()">
                Logout
            </a>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapActions, mapState } from 'vuex';

export default {
    components: {},
    // do not forget this section

    data() {
        return {
            modal: false,
            success: false,
            email: '',
            isScrolled: false,
            selected_nav_item: null,
            nav_item_selected: false,
            is_nav_dropdown: false
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    },
    async created() {
        await this.retrieve_settings();
    },
    methods: {
        ...mapActions({
            retrieve_settings: 'getOrganizationSettings'
        }),
        onLogout() {
            this.logout().then(() => {
                this.$router.push({path: '/login'});
            })
        },
        selectNavItem(selection) {
            this.isScrolled = true;
            this.nav_item_selected = true;
            this.selected_nav_item = selection;
        },
        closeSubnav() {
            this.handleScroll();
            this.nav_item_selected = false;
            this.selected_nav_item = null;
        },
        handleScroll() {
            if (window.scrollY > 50) {
                this.isScrolled = true;
            }
            if (window.scrollY < 50) {
                this.isScrolled = false;
            }
        },
        openNavDropdown() {
            this.is_nav_dropdown = true;
        },
        closeNavDropdown() {
            this.is_nav_dropdown = false;
        },
        ...mapActions({
            logout: 'authenticate/logout'
        })
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            organization_settings: state => state.organizationSettings.data
        }),
        userProfileUrl() {
            return this.user?.image?.profile?.profile_url;
        },
    },
    directives: {ClickOutside}

};


</script>
