<template>
    <ul class="co-breakdown">
        <li class="co-breakdown__item">
            <span>Shipping</span>
            <span class="co-breakdown__charcoal strong">
                Free
            </span>
        </li>
        <li v-if="price?.subTotal" class="co-breakdown__item">
            <span>Sub-Total</span>
            <span class="strong">
                {{ formattedSubTotal }}
            </span>
        </li>
        <li v-if="price?.savings > 0" class="co-breakdown__item co-breakdown__saving">
            <span>Discount</span>
            <span class="strong">
                {{ formattedSavings }}
            </span>
        </li>
        <li class="co-breakdown__total">
            <span>Total Due Now</span>
            <span>
                {{ formattedTotal }}
            </span>
        </li>
    </ul>
</template>

<script>

export default {
    name: 'PriceBreakdown',
    props: {
        price: {
            type: Object,
            required: true,
            default: () => ({
                subTotal: 0,
                savings: 0,
                total: 0,
            }),
        },
    },
    computed: {
        formattedSubTotal() {
            return `$${this.price?.subTotal.toFixed(2)}`;
        },
        formattedSavings() {
            return `$${this.price?.savings.toFixed(2)}`;
        },
        formattedTotal() {
            return `$${this.price?.total.toFixed(2)}` || null;
        },
    },
};
</script>
