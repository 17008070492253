<template>
    <div class="widget__container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'WidgetContainer',
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/utility.scss';
@import '@/assets/scss/_colors.scss';

.widget {
    &__container {
        position: relative;
        background-color: $gray-75;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        overflow: hidden;
        border-radius: 1.5rem;
        width: 100%;
        height: auto;
    }

    @media ($min-810) {
        &__container {
            padding: 2rem;
        }
    }
}
</style>
