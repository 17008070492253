import axios from 'axios'
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const prescriptions = {
    namespaced: true,
    state: {
        prescriptions: {
            loading: null,
            data: null
        },
        approvals: {
            loading: null,
            data: null
        },
        active_prescription: {}
    },
    getters: {},
    mutations: {
        setApprovalsLoading(state, loading) {
            state.approvals.loading = loading
        },
        setActivePrescription(state, payload) {
            Vue.set(state.active_prescription, payload.id, payload.value);
        }
    },
    actions: {
        getApprovedPrescriptions({commit, getters, state, rootState}, payload) {
            commit('setApprovalsLoading', true);
            let orgId = rootState.orgId.data;
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/prescriptions/' + orgId + '/patient/' + payload.patient_id + '/product/' + payload.product_id,
                    method: 'GET'
                }).then(response => {
                    state.approvals.data = response.data;
                    commit('setApprovalsLoading', false);
                    resolve(response.data);
                }).catch(err => {
                    commit('setApprovalsLoading', false);
                    reject(err);
                });
            });
        },
        async getActivePrescription({rootState, commit}, payload) {
            const organization = rootState.orgId.data;
            const { patient, subscription_id, prescription_id } = payload;
            commit('setActivePrescription', { id: 'error', value: false });
            commit('setActivePrescription', { id: 'loading', value: true });

            try {
                let response = await axios.get(`${baseUrl}/users/${organization}/user/${patient}/subscription/${subscription_id}/medication`, {
                    params: {
                        prescription_id: prescription_id
                    }
                });
                if ('data' in response) {
                    commit('setActivePrescription', { id: 'loading', value: false });
                    commit('setActivePrescription', { id: 'data', value: response.data });
                    return response.data;
                }
            } catch (error) {
                commit('setActivePrescription', { id: 'error', value: true });
                commit('setActivePrescription', { id: 'data', value: null });
                commit('setActivePrescription', { id: 'loading', value: false });

                if (axios.isAxiosError(error)) {
                    commit('setActivePrescription', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        }
    }
};
