import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

import { memberships } from '@/store/modules/memberships';
import { nav } from '@/store/modules/nav';
import { organization } from '@/store/modules/organization';
import { physician } from '@/store/modules/physician';
import { pusherChannels } from '@/store/modules/pusherChannels';
import { subscriptions } from '@/store/modules/subscription';
import { treatment } from '@/store/modules/treatment';
import { visits } from '@/store/modules/visit';

import { alerts } from './modules/alerts';
import { appointments } from './modules/appointments.js';
import { authenticate } from './modules/authenticate.js';
import { conversations } from './modules/conversations.js';
import { intake } from './modules/intake';
import { integrations } from './modules/integrations';
import { labResults } from './modules/labResults.js';
import { orders } from './modules/orders.js';
import { payment } from './modules/payment.js';
import { prescriptions } from './modules/prescriptions.js';
import { products } from './modules/products.js';
import { productTemplates } from './modules/producttemplates';
import { states } from './modules/states';
import { user } from './modules/user.js';

Vue.use(Vuex);
const baseUrl = process.env.VUE_APP_BASE_END;

export default new Vuex.Store({
    modules: {
        authenticate,
        user,
        payment,
        conversations,
        products,
        orders,
        appointments,
        prescriptions,
        intake,
        alerts,
        states,
        productTemplates,
        integrations,
        memberships,
        visits,
        subscriptions,
        organization,
        physician,
        pusherChannels,
        treatment,
        labResults,
        navigation: nav,
    },
    state: {
        orgId: {
            data: null,
            loading: false,
            error: false,
        },
        organizationSettings: {
            data: null,
            loading: false,
            error: false,
        },
        organization: {
            data: null,
            loading: false,
            error: false,
        },
        external_appointment_url: {
            data: null,
            loading: false,
            error: false,

        },
        consent_policy: null,
        privacy_policy: null,
        tos_policy: null,
        policy_side_panel: false,
        side_panel_data: null,
    },
    getters: {
        orgId: state => state.orgId.data,
    },
    mutations: {
        setPolicySidePanel(state, payload) {
            state.side_panel_data = payload.side_panel_data;
            state.policy_side_panel = payload.is_open;
        },
        updateOrgIdData(state, payload) {

            state.orgId.data = payload;
            localStorage.setItem('zp_org_id', JSON.stringify(payload));
        },
        updateOrgIdLoading(state, payload) {
            state.orgId.loading = payload;
        },
        updateOrgIdError(state, payload) {
            state.orgId.error = payload;
        },
        setOrgIdRequestPromise(state, payload) {
            state.orgId.promise = payload;
        },
        setOrganizationSettingsData(state, data) {
            localStorage.setItem('zp_org_settings', JSON.stringify(data));
            state.organizationSettings.data = data;
        },
        setOrganizationSettingsLoading(state, loading) {
            state.organizationSettings.loading = loading;
        },
        setOrganizationSettingsError(state, error) {
            state.organizationSettings.error = error;
        },
        setExternalUrlData(state, data) {
            state.external_appointment_url.data = data;
        },
        setExternalUrlError(state, error) {
            state.external_appointment_url.error = error;
        },
        setExternalUrlLoading(state, loading) {
            state.external_appointment_url.loading = loading;
        },
        setOrganizationData(state, data) {
            state.organization.data = data;
        },
        setOrganizationLoading(state, loading) {
            state.organization.loading = loading;
        },
        setOrganizationError(state, error) {
            state.organization.error = error;
        },
        initializeOrg(state) {
            if (localStorage.getItem('zp_org_id')) {
                state.orgId.data = JSON.parse(localStorage.getItem('zp_org_id'));

                if (localStorage.getItem('zp_org_settings')) {
                    state.organizationSettings.data = JSON.parse(localStorage.getItem('zp_org_settings'));
                }
            }
        },
    },
    actions: {
        initializeOrganizationId({ commit }, domainName) {
            const orgIdPromise = new Promise((resolve) => {
                commit('updateOrgIdLoading', true);

                axios({
                    url: baseUrl + '/organization/search',
                    method: 'GET',
                    params: {
                        domain: domainName,
                    },
                }).then(response => {
                    commit('updateOrgIdData', response.data.orgId);
                    resolve(response.data);
                    commit('updateOrgIdLoading', false);
                }).catch(err => {
                    const defaultOrgId = process.env.VUE_APP_ORG_ID;

                    console.error('what is the err', err);
                    // set error?
                    commit('updateOrgIdData', defaultOrgId);
                    console.error(`Could not fetch organization id, continue with the default ${defaultOrgId}`);

                    commit('updateOrgIdLoading', false);
                    commit('updateOrgIdError', true);

                    resolve({ orgId: defaultOrgId });
                });
            });

            commit('setOrgIdRequestPromise', orgIdPromise);

            return orgIdPromise;
        },
        async getOrganization({ commit, rootState }) {
            commit('setOrganizationLoading', true);
            commit('setOrganizationError', false);

            try {
                const organization = await axios({
                    url: `${baseUrl}/organization/${rootState.orgId.data}`,
                    method: 'GET',
                });

                if ('data' in organization) {
                    commit('setOrganizationLoading', false);
                    commit('setOrganizationData', organization.data);

                    return organization.data;
                }
            } catch (err) {
                commit('setOrganizationLoading', false);
                commit('setOrganizationError', true);
                throw new Error(err);
            }
        },
        getOrganizationSettings({ commit, rootState }) {
            return new Promise((resolve, reject) => {
                commit('setOrganizationSettingsLoading', true);
                axios({
                    url: baseUrl + '/organization/' + rootState.orgId.data + '/settings/default',
                    method: 'GET',
                }).then(response => {
                    commit('setOrganizationSettingsData', response.data);
                    resolve(response.data);
                    commit('setOrganizationSettingsLoading', false);
                }).catch(err => {
                    commit('setOrganizationSettingsLoading', false);
                    commit('setOrganizationSettingsError', true);
                    reject(err);
                });
            });
        },
        retrieveOrganizationConsentPolicy({ state }, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/organization/' + payload.organization + '/policy/' + payload.section,
                    method: 'GET',
                }).then((response) => {
                    if (payload.section === 'consent') {
                        state.consent_policy = response.data;
                        resolve(state.consent_policy);

                        return;
                    }

                    if (payload.section === 'privacy') {
                        state.privacy_policy = response.data;
                        resolve(state.privacy_policy);

                        return;
                    }

                    if (payload.section === 'tos') {
                        state.tos_policy = response.data;
                        resolve(state.tos_policy);
                    }

                }).catch((err) => {
                    reject(err);
                });
            });
        },

    },
});
