<template>
    <div class="notifications-widget-container">
        <div class="notifications-widget-header">
            <h-badge label="Notifications" small color="#55D291" dark />
            <div
                class="notifications-widget-read"
                :class="{'disabled': !unreadNotifications.length}"
                @click="handleMarkAllAsRead"
            >
                Mark all as read
            </div>
        </div>
        <div class="notifications-widget-body">
            <div v-if="unreadNotifications.length === 0" class="notifications-widget-empty-state">
                <div class="notifications-widget-empty-message">
                    You're all caught up!
                </div>
            </div>
            <div v-else>
                <div v-for="notification in unreadNotifications" :key="notification._id" class="notifications-widget-item" @click="handleNotificationClick(notification)">
                    <component :is="NOTIFICATION_ICON_MAP[notification.category]" />
                    <div class="notifications-widget-item__details">
                        <div class="notifications-widget-item__message">
                            {{ notification.message }}
                        </div>
                        <div class="notifications-widget-item__time">
                            <div class="notifications-widget-item__new-container">
                                <div v-if="!notification.read" class="notifications-widget-item__new"></div>
                            </div>
                            <div class="notifications-widget-item__timestamp">
                                {{ formatNotificationDate(notification.created_at) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h-infinite-loader identifier="quick" :end-indicator="false" @infinite="infiniteHandler" />
                </div>
            </div>
        </div>
        <div class="notifications-widget-footer">
            <div
                class="notifications-widget-manage"
                @click="goToNotificationsSettings"
            >
                Manage Notifications
            </div>
            <h-button variant="tertiary" @click="goToNotifications">
                View All
            </h-button>
        </div>
    </div>
</template>

<script>
import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButtonV2.vue';
import HInfiniteLoader from '@/components/Shared/HInfiniteLoader.vue';

import { formatNotificationDate } from '@/helpers/date';
import { NOTIFICATION_ICON_MAP } from '../utils/NotificationMap';

export default {
    name: 'QuickNotifications',
    components: {
        HBadge,
        HButton,
        HInfiniteLoader,
    },
    props: {
        unreadNotifications: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            NOTIFICATION_ICON_MAP,
        };
    },
    methods: {
        handleMarkAllAsRead() {
            this.$emit('mark-read');
        },
        formatNotificationDate(date) {
            return formatNotificationDate(date);
        },
        goToNotifications() {
            this.$emit('view-all-notifications');
        },
        goToNotificationsSettings() {
            this.$emit('manage-notifications');
        },
        handleNotificationClick(notification) {
            this.$emit('notification-click', notification);
        },
        infiniteHandler($state) {
            setTimeout(() => {
                if (this.unreadNotifications.length < this.$parent.allUnreadNotifications.length) {
                    this.$emit('load-more-notifications');
                    $state.loaded();
                } else {
                    $state.complete();
                }
            }, 1000);
        },
    },
};
</script>
