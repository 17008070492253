<template>
    <label class="h-checkbox__label h-caption" :class="{'h-checkbox__reverse': reverse}" data-test="checkbox">
        <span v-if="reverse" class="h-checkbox__text">
            <slot></slot>
        </span>
        <input type="checkbox" :checked="isChecked" @change="toggleCheckbox" class="h-checkbox__input" />
        <div class="h-checkbox__container" v-bind:class="{ 'h-checkbox__container__filled': isChecked, 'h-orange': isChecked && orange }">
            <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.14286 5.70004L0.97549 3.65771C0.752333 3.44743 0.390524 3.44743 0.167368 3.65771C-0.0557892 3.86799 -0.0557892 4.20893 0.167368 4.41921L2.7388 6.84229C2.96195 7.05257 3.32376 7.05257 3.54692 6.84229L9.83263 0.919211C10.0558 0.708929 10.0558 0.367994 9.83263 0.157712C9.60948 -0.0525706 9.24767 -0.0525706 9.02451 0.157712L3.14286 5.70004Z"
                    fill="white"
                />
            </svg>
        </div>
        <span v-if="!reverse" class="h-checkbox__label__text">
            <slot></slot>
        </span>
    </label>
</template>

<script>
export default {
    name: 'HCheckbox',
    props: {
        checked: {
            type: Boolean,
        },
        orange: {
            type: Boolean,
        },
        reverse: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            isChecked: this.checked,
        };
    },
    watch: {
        checked(newCheckedValue) {
            // Update isChecked when the checked prop changes from the parent
            this.isChecked = newCheckedValue;
        },
    },
    methods: {
        toggleCheckbox() {
            this.isChecked = !this.isChecked;
            this.$emit('input', this.isChecked);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.h-checkbox {
    &__label {
        display: flex;
        align-items: start;
        user-select: none;
        color: $charcoal;
        margin: 0;
        cursor: pointer;
    }

    &__reverse {
        justify-content: space-between;
    }

    &__text {
        @include body-sm;
    }

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 0.375rem;
        border: 1px solid $border-medium;
        margin-right: 0.75rem;
        flex-shrink: 0;

        &__filled {
            border: none;
            background: $charcoal;
        }
    }
}

.h-orange {
    background: $health-orange;
}
</style>
