<template> 
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M21 14.4211C21 15.5415 20.7697 16.6521 20.3209 17.6899C19.8722 18.7277 19.2131 19.6735 18.3793 20.4722C17.5454 21.271 16.5531 21.9068 15.458 22.3413C14.3629 22.7759 13.1877 23 12 23C10.8123 23 9.63711 22.7759 8.54197 22.3413C7.44688 21.9068 6.45458 21.271 5.62067 20.4722C4.78686 19.6735 4.12784 18.7277 3.67907 17.6899C3.23034 16.6521 3 15.5415 3 14.4211C3 12.8548 3.49805 11.3291 4.42217 10.0645L10.3852 1.90457C11.1842 0.811282 12.8158 0.811282 13.6148 1.90457L19.5778 10.0645C20.5019 11.3291 21 12.8548 21 14.4211Z" :stroke="color" stroke-width="2" />
        <path d="M14.7956 16.7164C14.2289 17.3397 13.519 17.7517 12.7617 17.9174C12.0055 18.0829 11.2205 17.9991 10.5012 17.6713C9.7803 17.3429 9.14852 16.7793 8.69733 16.0365C8.24573 15.293 8 14.4101 8 13.5" :stroke="color" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'LabIcon',
    props: {
        color: {
            type: String,
            default: '#b1b1b1',
        },
    },
};
</script>
