<template>
    <div v-if="membership_info" class="protocol-membership">
        <div><h-badge label="What Your Plan Includes" color="#599bea" :dark="true" small></h-badge></div>
        <ul v-if="membership_info.features && membership_info.features.length">
            <li v-for="feature in membership_info.features">
                <span class="protocol-body810 strong">{{ feature.title }}</span>
                <span class="protocol-body810">{{ feature.description }}</span>
            </li>
        </ul>
    </div>
</template>
<script>
import {mapActions} from "vuex";
import HBadge from "@/components/Shared/HBadge.vue";

export default {
    name: 'TreatmentMembership',
    props: ['membership'],
    components: {HBadge},
    data() {
        return {
            membership_info: null
        }
    },
    async created() {
        try {
            this.membership_info = await this.get_membership({
                membership_id: this.membership._id
            })
        } catch (e) {
            console.log('Error created treatment_membership ', e);
        }
    },
    computed: {},
    methods: {
        ...mapActions({
            'get_membership': 'memberships/getMembership'
        })
    }
}
</script>
<style lang="scss">

</style>
