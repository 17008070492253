<template>
    <div class="cbmg-iteration-container">
        <div class="cbmg-message-container">
            <div class="cbmg-message-container-position-left">
                <div class="comment">
                    <div class="comment-block-paragraph">
                        <div class="conversation-is-typing-animation">
                            <div class="conversation-is-typing-dot-1"></div>
                            <div class="conversation-is-typing-dot-2"></div>
                            <div class="conversation-is-typing-dot-3"></div>
                        </div>
                    </div>
                </div>
                <div class="chat-profile-image-left">
                    <div class="chat-profile-img-container">
                        <span>{{ firstName[0] }}{{ lastName[0] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminTyping',
    props: {
        firstName: {
            type: String,
            default: '',
        },
        lastName: {
            type: String,
            default: '',
        },
    },
};
</script>
