<template>
    <h-modal
        dark
        :open="open"
        :on-close="onClose"
        remove-header
        full-height
        remove-padding
        name="visit-complete-modal"
    >
        <div class="visit-complete-modal__container">
            <img class="visit-complete-modal__image" src="@/assets/images/dashboard/visit-modal-background.png" />
            <div class="visit-complete-modal__content">
                <div class="visit-complete-modal__content__badge">
                    <HBadge label="Success" dark small />
                </div>
                <div v-if="isProtocol" class="visit-complete-modal__content__body">
                    <div class="visit-complete-modal__content__body__header">
                        <div class="visit-complete-modal__content__body__header__item">
                            Your visit
                        </div>
                        <div class="visit-complete-modal__content__body__header__item">
                            is complete
                        </div>
                    </div>
                    <div class="visit-complete-modal__content__body__info">
                        We have received your submission and a member of our medical team will review it shortly. Once reviewed, your case will be assigned to one of our physicians. They will examine your medical history and intake form before issuing your personalized protocol.
                    </div>
                    <div class="visit-complete-modal__content__body__info--mobile">
                        <div>
                            We have received your submission and a member of our medical team will review it shortly.
                        </div>
                        <div>
                            Once reviewed, your case will be assigned to one of our physicians.
                        </div>
                        <div>
                            They will examine your medical history and intake form before issuing your personalized protocol.
                        </div>
                    </div>
                </div>
                <div v-if="!isProtocol" class="visit-complete-modal__content__body">
                    <div class="visit-complete-modal__content__body__header">
                        <div class="visit-complete-modal__content__body__header__item">
                            Thank you!
                        </div>
                    </div>
                    <div class="visit-complete-modal__content__body__info">
                        Welcome to Healthspan, where your journey to optimal longevity begins. At Healthspan, we’re dedicated to monitoring your health, delivering cutting-edge insights, and crafting personalized protocols tailored just for you. Stay informed, stay empowered, and take control of your longevity like never before.
                    </div>
                    <div class="visit-complete-modal__content__body__info--mobile">
                        <div>
                            Welcome to Healthspan, where your journey to optimal longevity begins.
                        </div>
                        <div>
                            At Healthspan, we’re dedicated to monitoring your health, delivering cutting-edge insights, and crafting personalized protocols tailored just for you.
                        </div>
                        <div>
                            Stay informed, stay empowered, and take control of your longevity like never before.
                        </div>
                    </div>
                </div>
                <div class="visit-complete-modal__content__body__button">
                    <h-button
                        label="Continue"
                        variant="cta"
                        full_width
                        large
                        :handle-click="onContinue"
                    />
                </div>
            </div>
        </div>
    </h-modal>
</template>

<script>

import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButton.vue';
import HModal from '@/components/Shared/HModal.vue';

export default {
    name: 'VisitCompleteModal',
    components: {
        HModal,
        HBadge,
        HButton,
    },
    props: {
        onContinue: {
            type: Function,
            required: true,
        },
        open: {
            type: Boolean,
            required: true,
        },
        type: {
            type: String,
            default: 'protocol',
        },
    },
    data() {
        return {
            isLabsCase: true,
        };
    },
    computed: {
        isProtocol() {
            return this.type === 'protocol';
        },
    },
    methods: {
        onClose() {
            this.$modal.hide('visit-complete-modal');
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.visit-complete-modal {
  &__container {
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url('@/assets/images/dashboard/visit-modal-background.png');
    background-size: cover;
    background-color: white;
    padding: 45px;
  }

  &__image {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__badge {
      display: flex;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &__button {
        max-width: 365px;
      }

      &__header {
        @include heading-lg;

        font-size: 4rem;
        line-height: 4.5rem;

        &__item {
          word-break: keep-all;
          white-space: nowrap;
        }
      }

      &__info {
        @include body;

        max-width: 500px;
        width: 100%;

        &--mobile {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    max-height: 70vh;
    border-radius: 1rem;
    padding: 2.5rem;
  }

  @media screen and (max-width: 600px) {
    &__container {
      position: fixed;
      background-image: none;
      height: 100vh;
      max-height: 100vh;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      border-radius: 0 !important;
      padding: 0;
      color: $charcoal;
      overflow: auto;
    }

    &__image {
      display: flex;
      height: 50%;
      width: 100%;
      object-fit: cover;
      object-position: right;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding: 3rem 3rem 4rem 3rem;

      &__badge {
        display: flex;
      }

      &__body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        &__button {
          max-width: 100%;
          width: 100%;
        }

        &__header {
          @include heading-sm;
          display: flex;
          gap: 8px;
          line-height: 40px;
          flex-wrap: wrap;
        }

        &__info {
          display: none;

          &--mobile {
            @include caption;
            display: flex;
            align-items: flex-start;
            gap: 1.5rem;
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
