<template>
    <div class="settings-section">
        <div class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div class="settings-section__header">
            <h2>Manage</h2>
            <p>Configure where you would like to receive notifications.</p>
        </div>
        <div class="settings-notifications">
            <MobileNumber
                :is-common-section="false"
                class="settings-notifications__item"
                :with-notification-toggle="withNotificationToggle"
            />
            <EmailAddress
                :is-common-section="false"
                class="settings-notifications__item"
                :with-notification-toggle="withNotificationToggle"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Notifications',
    components: {
        MobileNumber: () => import('./MobileNumber'),
        EmailAddress: () => import('./EmailAddress'),
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
        }),
        withNotificationToggle() {
            return this.user.mobile_verified;
        },
    },
    methods: {
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
    },
};
</script>
