module.exports.buttonText = {
    subscription: {
        cancel: {
            submit_button_text: 'Submit',
        },
        pause: {
            confirm_button_text: 'Pause my subscription',
        },
        resume_now: {
            confirm_button_text: 'Resume Now & Ship',
        },
        resume: {
            confirm_button_text: 'Resume',
        },
        reschedule: {
            confirm_button_text: 'Reschedule',
        },
    },
};
