export const parseDose = (dosageStrength) => {
    return {
        dose: parseFloat(dosageStrength.strength),
        suffix: dosageStrength.strength.replace(/[0-9]/g, '').replace(/[^a-zA-Z ]/g, ''),
    };
};

export const parseFrequencyInstructions = (frequency, product) => {
    const instructions = frequency.instructions;

    if (instructions && instructions.includes('${dosageWeekly}')) {
        const selectedConfig = product.selected_configuration;
        const doseQuantity = product.dose_quantity ?? 1;
        const calculateDose = (selectedConfig.quantity * doseQuantity * parseDose(selectedConfig.dosage).dose) / 4;

        return instructions.replace('${dosageWeekly}', calculateDose);
    } else if (instructions?.includes('${compoundedDosage}')) {
        const selectedConfig = product.selected_configuration;
        const doseQuantity = product.dose_quantity ?? 1;
        const calculateDose = (selectedConfig.quantity * doseQuantity * parseDose(selectedConfig.dosage).dose) / 4;

        return instructions.replace('${compoundedDosage}', calculateDose);

    } else {
        return instructions;
    }
};
