<template>
    <div class="confirm-block confirm-visit">
        <h2>3. Visit your Lab Location</h2>
        <p>After scheduling your lab visit, remember to bring your lab requisition form when you arrive at the lab location. Please ensure that you do not make any payments; inform the staff that your visit has already been prepaid.</p>
        <HButtonV2 v-if="pageType === 'success'" variant="secondary" :loading="requisitionLoading" @click="handleRequisition">
            Download lab requisition
        </HButtonV2>
        <transition name="alert-fade" mode="out-in">
            <div v-if="requisitionError" class="confirm-alert">
                <HAlert message="Your lab requisition form is currently not ready, please come to your labs page later to download form. If your lab form is not available in the next 2 hours, please contact us." status="critical" @dismiss="dismissAlert" />
            </div> 
        </transition>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import HAlert from '@/components/Shared/HAlert';
import HButtonV2 from '@/components/Shared/HButtonV2';
import { downloadPdfToDevice } from '@/helpers/labs';

export default {
    name: 'ScheduleComponent',
    components: {
        HButtonV2,
        HAlert,
    },
    props: {
        pageType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            labId: this.$route.params.labId,
            requisitionLoading: false,
            requisitionError: false,
        };
    },
    methods: {
        ...mapActions({
            getRequisitionPDF: 'labResults/getRequisitionPDF',
        }),
        async handleRequisition() {
            this.requisitionLoading = true;

            try {
                const response = await this.getRequisitionPDF({ reportId: this.labId });
                const file = new Blob([response.data], { type: 'application/pdf' });

                downloadPdfToDevice(file, 'requisition_form.pdf');
            } catch (error) {
                this.requisitionError = true;
                console.error('Error downloading PDF:', error);
            } finally {
                this.requisitionLoading = false;
            }
        },
        dismissAlert() {
            this.requisitionError = false;
        },
    },
};
</script>
