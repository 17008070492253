<template>
    <div class="messages-input-container">
        <div :class="['messages-textarea-container', { 'expanded': isTextareaExpanded }]">
            <textarea
                ref="textarea"
                v-model="localMessage"
                rows="1"
                placeholder="Type your message..."
                data-test="messages-input"
                @input="resizeTextarea"
                @keydown="typingThrottle"
                @keydown.enter.exact.prevent="submitMessage($event)"
            />
            <h-icon-button
                size="sm"
                :disabled="isSubmitting"
                class="messages-input-mobile"
                @click="submitMessage"
            >
                <arrowUp :stroke="isSubmitting ? '#A6A9AD' : '#212931'" :width="20" :height="20" />
            </h-icon-button>
            <button
                class="messages-input-button"
                :disabled="isSubmitting"
                data-test="messages-send"
                @click="submitMessage"
            >
                Send
            </button>
        </div>
    </div>
</template>

<script>
import arrowUp from '@/assets/icons/arrowUp.vue';
import HIconButton from '@/components/Shared/HIconButtonV2.vue';

export default {
    name: 'MessageInput',
    components: {
        HIconButton,
        arrowUp,
    },
    props: {
        isSubmitting: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            localMessage: this.value,
            isTextareaExpanded: false,
        };
    },
    watch: {
        value(newVal) {
            this.localMessage = newVal;
        },
        localMessage(newVal) {
            this.$emit('input', newVal);
        },
    },
    methods: {
        submitMessage(event) {
            this.$emit('submit-message', event);
        },
        typingThrottle() {
            this.$emit('typing-throttle');
        },
        resizeTextarea() {
            const textarea = this.$refs.textarea;
            const lineHeight = 24;
            const baseHeight = 40;

            textarea.style.height = 'auto';
            const newHeight = Math.max(textarea.scrollHeight, baseHeight);
    
            const maxHeight = baseHeight + (lineHeight * 7);

            textarea.style.height = `${Math.min(newHeight, maxHeight)}px`;

            this.isTextareaExpanded = newHeight > baseHeight;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.messages-input-container {
    border-top: 1px solid $border-default;
    padding: 0.25rem;
    margin: 0;

    @media (min-width: 1024px) {
        padding: 0.5 $tablet-mx;
    }
}

.messages-textarea-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 0.25rem 0.65rem 0.25rem;

    &.expanded {
        align-items: flex-end;
        padding-bottom: 0.75rem;
    }

    textarea {
        @include body-sm;
        width: 100%;
        max-height: calc(40px + 24px * 7);
        outline: none;
        resize: none;
        box-sizing: border-box;
        border: none;
        overflow-y: auto; 
        background-color: transparent;
        padding: 0.5rem 0.75rem;

        &:focus {
            outline: none;
        }
    }
}

.messages-input-button {
    @include tag;
    display: none;

    @media (min-width: 1024px) {
        display : flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 3.5rem;
        background-color: transparent;
        color: $charcoal;
        padding: 0.25rem 0.5rem;
        cursor: pointer;

        &:hover {
            color: $charcoal-hover;
        }
    }
}

.messages-input-mobile {
    @media (min-width: 1024px) {
        display: none;
    }
}
</style>
