<template>
    <div class="patient-portal-empty__container">
        <div class="patient-portal-empty__content">
            <div class="patient-portal-empty__graphic conversations"></div>
            <div class="patient-portal-empty__description">
                Whether you have a question, concern, or just want to share your feedback, our team is here to listen and help.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmptyMessage',
    methods: {
        onStartConversation() {
            this.$emit('start-conversation');
        },
    },
};
</script>
