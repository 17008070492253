<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M2 3H3C4.65685 3 6 4.34315 6 6V13C6 14.6569 7.34315 16 9 16H18C19.6569 16 21 14.6569 21 13V9C21 7.34315 19.6569 6 18 6H9" stroke="#212931" stroke-width="2" />
        <rect
            x="6"
            y="18"
            width="3"
            height="3"
            rx="1.5"
            fill="#242E49"
        />
        <rect
            x="18"
            y="18"
            width="3"
            height="3"
            rx="1.5"
            fill="#242E49"
        />
    </svg>
</template>

<script>
export default {
    name: 'ShopIcon',
};
</script>

<style lang="scss" scoped>

</style>
