<template>
<div class="appointment__item">
    <div class="appointment__item-image"></div>
    <div class="appointment__item-details">
        <h4 class="headline-3">{{appointment.event.name || 'Your Appointment'}}</h4>
        <div v-if="!appointment.event.canceled" class="appointment__item-info">

            <div class="appointment__item-row">
                <div class="appointment__item-icon">
                    <ion-icon name="calendar"></ion-icon>
                    <p class="appointment__item-description">{{ appointment.event.start_time | date }}</p>
                </div>
            </div>
            <div class="appointment__item-row">
                <div class="appointment__item-icon">
                    <ion-icon name="time"></ion-icon>
                    <p class="appointment__item-description">{{ appointment.event.start_time | time }} -
                        {{ appointment.event.end_time | time }}</p>
                </div>

            </div>
        </div>
        <div v-if="appointment.event.canceled" class="appointment__item-info">

            <div class="appointment__item-row">
                <div class="appointment__item-icon">
                    <ion-icon name="close-circle"></ion-icon>
                    <p class="appointment__item-description">Canceled</p>
                </div>
            </div>
        </div>
        <div class="appointment__item-desc">
            <span v-if="appointment.event_type.kind === 'user-verification'">
                Onboarding & Identity Verification
            </span>
            <span v-else>
                {{user.first_name}} <> Care Team Appointment
            </span>
        </div>
        <div class="appointment__item-row" v-if="canModifyEvent(appointment)">
            <a :href="appointment.event.external_source" class="appointment__item-location"
               target="_blank">Join Waitroom</a>
            <a :href="`https://app.cal.com/booking/${appointment.event.uuid}?cancel=true&allRemainingBookings=false`" class="appointment__item-link"
               target="_blank">Cancel</a>
            <a :href="`https://app.cal.com/reschedule/${appointment.event.uuid}`" class="appointment__item-link"
               target="_blank">Reschedule</a>
        </div>
    </div>


</div>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import app from "@/App";

export default {
    name: 'AppointmentItem',
    components: {},
    props: ['appointment'],
    filters: {
        date: function (date) {
            return moment(date).format('MMM Do, YYYY');
        },
        time: function (date) {
            return moment(date).format('hh:mm a')
        }
    },
    data() {
        return {}
    },
    created() {

    },
    computed: {
        ...mapState({
            user: state => state.user.user
        })
    },
    methods: {
        canModifyEvent(appointment) {
            const startTime = appointment.event.start_time;
            const pastDate = moment(startTime).isBefore(new Date());
            return !appointment.event.canceled && !pastDate;
        }
    }
}
</script>
<style lang="scss">
.appointment {
    &__item {
        width: 100%;
        max-width: 960px;
        background-color: #FFFFFF;
        border-radius: 12px;
        -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 0.25rem 0.75rem;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0.25rem 0.75rem;
        padding: 2rem 1.5rem;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 980px) {
            flex-direction: column;
        }

        &-image {
            width: 100%;
            height: 260px;
            background-image: url('../../../assets/images/verification/schedule.svg');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &-avatar {
            width: 24px;
            height: 24px;
            background-size: cover;
            border-radius: 24px;
            background-position: center;
        }

        &-details {
            display: flex;
            width: 100%;
            padding: 42px 24px 24px;
            flex-direction: column;
        }

        &-info {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            max-width: 450px;
            margin-bottom: 1.5rem;
            margin-top: 0.5rem;

            .appointment__item-row {
                margin-right: 1.5rem;
            }

        }

        &-row {
            align-items: baseline;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

        }

        &-icon {
            font-size: 1.5rem;
            display: flex;
            align-self: center;
            flex-direction: row;
            height: 24px;;
            margin-top: .5rem;

            ion-icon {
                color: $cool400;
            }
        }

        &-desc {
            font-family: 'Sofia Pro', sans-serif;
            margin-bottom: 1.5rem;
        }


        &-description {
            font-family: 'Sofia Medium', sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 1rem;
            max-width: 460px;
            margin: 0 0 0 .5rem;
            line-height: 1.5;
            color: $neutral500;
        }

        &-copy {
            cursor: pointer !important;
            display: inline-block !important;
            margin: 0px !important;
            position: relative !important;
            text-align: center !important;
            text-decoration: none !important;
            width: auto !important;
            touch-action: manipulation !important;
            font-family: 'Sofia Regular', sans-serif;
            font-size: 14px !important;
            line-height: 18px !important;
            font-weight: 600 !important;
            border-radius: 8px !important;
            border-width: 1px !important;
            border-style: solid !important;
            outline: none !important;
            padding: 7px 15px !important;
            transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
            border-color: rgb(34, 34, 34) !important;
            background: rgb(255, 255, 255) !important;
            color: rgb(34, 34, 34) !important;
        }

        &-location {
            padding: 11px 45px;
            background: #222;
            border-radius: 33px;
            font-size: 14px;
            color: #fff !important;
            font-family: 'Sofia Semi Bold', sans-serif;
            letter-spacing: 1px;
            text-decoration: none !important;
        }

        &-link {
            margin-left: 1rem;
            font-family: 'Sofia Semi Bold', sans-serif;
        }

        &-clip {
            justify-content: flex-end;
            flex-grow: 1;
            display: flex;
        }

    }

}

ion-icon {
    --ionicon-stroke-width: 2rem;
}
</style>
