<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.7632 8.30478C18.5376 9.13682 19 10.1891 19 11.3333C19 12.2083 18.6102 13.124 18.0228 13.8833C16.8268 15.4295 16.2286 16.2027 16.127 16.4179C15.9577 16.7763 16.0029 16.6282 15.943 17.0201C15.9071 17.2553 15.9304 17.6089 15.9769 18.316L16.0218 19C16.0218 19 13.5846 16.2121 13.2727 16.2121C12.1499 16.2121 11.1026 15.9369 10.2187 15.4613M14.6364 6.72727C14.6364 9.89036 11.5838 12.4545 7.81818 12.4545C7.44687 12.4545 4.54545 15.7273 4.54545 15.7273L4.63058 14.4504C4.68444 13.6424 4.71137 13.2385 4.66466 12.9763C4.59487 12.5847 4.62197 12.6617 4.43117 12.3126C4.30345 12.0789 3.69894 11.421 2.48998 10.1053C1.61223 9.15001 1 7.90707 1 6.72727C1 3.56419 4.0526 1 7.81818 1C11.5838 1 14.6364 3.56419 14.6364 6.72727Z"
            stroke="#212931"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'ChatIcon',
};
</script>

<style lang="scss" scoped>

</style>
