<template>
    <div
        class="halert__container" 
        :class="{
            [`halert__${type}`]: true,
        }"
    >
        <div class="halert__message">
            <img src="@/assets/images/verification/info.svg" alt="" />
            <span>{{ message }}</span>
        </div>
        <div v-if="buttonLabel" class="halert__button">
            <h-button :label="buttonLabel" variant="primary" :handle-click="handleAlertClick" />
        </div>
    </div>
</template>

<script>
import HButton from './HButton.vue';

export default {
    name: 'HAlertBar',
    components: {
        HButton,
    },
    props: {
        type: {
            type: String,
            default: 'warning',
        },
        message: {
            type: String,
            default: '',
        },
        buttonLabel: {
            type: String,
            default: '',
        },
        handleAlertClick: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.halert {
    &__container {
        display: none;

        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.75rem;
            width: 100%;
            padding: 0.75rem 1.25rem;
            z-index: $z-banner;
        }
    }

    &__message {
        display: flex;
        align-items: center;
        gap: .5rem;

        span {
            font-family: 'Helvetica Now Text', serif !important;
            font-size: 0.875rem !important;
            line-height: 1.25rem !important;
            font-weight: 700;
            color: $white-100;
        }

        @media (min-width: 1280px) {
            span {
                font-size: 1.125rem !important;
                line-height: 1.5rem !important;
            }
        }
    }

    &__warning {
        background-color: $critical-200;
    }

    &__button {
        button {
            font-family: 'Helvetica Now Text', serif !important;
            font-size: 0.75rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.075rem;
        }
    }
}
</style>
