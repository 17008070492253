<template>
    <div class="hcarousel">
        <slot :current-slide="currentSlide" />
        <div v-if="showNavigation" class="hcarousel-nav">
            <div class="hcarousel-nav__left" @click="goToPrevSlide">
                <ChevronLeft :fill="(currentSlide === 0) ? '#999' : (showLightIndicator ? '#D2E4EE' : '#242E49')" />
            </div>
            <div class="hcarousel-nav__right" @click="goToNextSlide">
                <ChevronRight :fill="showLightIndicator ? '#D2E4EE' : '#242E49'" />
            </div>
        </div>
        <div v-if="!showNavigation" class="hcarousel-page" :class="{'hcarousel-light': showLightIndicator }">
            <div
                v-for="(slide, index) in slideCount"
                :key="index"
                class="hcarousel-page__container"
                @click="goToSlide(index)"
            >
                <div
                    class="hcarouse-pagination"
                    :class="{ 'active': index === currentSlide }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ChevronLeft from '@/assets/icons/ChevronLeft.vue';
import ChevronRight from '@/assets/icons/ChevronRight.vue';

export default {
    name: 'HCarousel',
    components: {
        ChevronLeft,
        ChevronRight,
    },
    props: {
        length: {
            type: Number,
            required: true,
        },
        indicators: {
            type: Array,
            default: () => [],
        },
        autoPlay: {
            type: Boolean,
            default: true,
        },
        duration: {
            type: Number,
            default: 5000,
        },
    },
    data() {
        return {
            currentSlide: 0,
            slideCount: this.length,
        };
    },
    computed: {
        showNavigation() {
            return this.length > 5;
        },
        showLightIndicator() {
            return this.indicators[this.currentSlide] === 'light';
        },
    },
    mounted() {
        if (this.autoPlay) {
            this.handleAutoPlay();
        }
    },
    beforeDestroy() {
        clearInterval(this.autoPlayInterval);
    },
    methods: {
        goToSlide(index) {
            this.currentSlide = index;
            this.restartAutoPlay();
        },
        goToNextSlide() {
            if (this.currentSlide === this.slideCount - 1) {
                this.currentSlide = 0;

                return;
            } 
            this.currentSlide++;
            this.restartAutoPlay();
        },
        goToPrevSlide() {
            if (this.currentSlide > 0) {
                this.currentSlide--;
                this.restartAutoPlay();
            }
        },
        handleAutoPlay() {
            this.autoPlayInterval = setInterval(() => {
                this.goToNextSlide();
            }, this.duration);
        },
        restartAutoPlay() {
            clearInterval(this.autoPlayInterval);

            if (this.autoPlay) {
                this.handleAutoPlay();
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.hcarousel {
    position: relative;
    display: flex;
    height: 100%;

    @media ($min-1024) {
        gap: 2rem;
        padding: 0 2rem;
    }

    @media ($min-1440) {
        max-width: 1440px;
        margin: 0 auto;
    }
}   

.hcarousel-nav {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 2.5rem;

    &__left,
    &__right {
        display: flex;
        flex: 1;
        z-index: 1;

        svg {
            cursor: pointer;
        }
    }

    &__right {
        justify-content: flex-end;
    }
}

.hcarousel-page {
    position: absolute;
    left: 1.5rem;
    bottom: 1.25rem;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .25rem;
    z-index: 100;

    &__container {
        padding: 1rem 0;
        cursor: pointer;
    }

    .hcarouse-pagination {
        width: 46px;
        height: 2px;
        background-color: rgba($charcoal, 0.2);
    }

    .active {
        background-color: $charcoal;
    }

    @media ($min-810) {
        left: 2.5rem;
    }

    @media ($min-1024) {
        left: 4.5rem;
    }
}

.hcarousel-light {
    .hcarouse-pagination {
        background-color: rgba($powder-blue-100, 0.2);
        z-index: 100;
    }

    .active {
        background-color: $powder-blue-100;
    }
}

</style>
