<template>
    <div>
        <div class="scheduled">
            <div>
                <div
                    v-if="organizationSettings.data.logo"
                    :style="{
                        'background-image': 'url(' + organizationSettings.data.logo + ')',
                    }"
                    class="zp-company-logo"
                />
                <div v-if="!organizationSettings.data.logo" class="zp-company-no-logo">
                    {{ organizationSettings.data.name }}
                </div>
            </div>
            <div class="scheduled__img__container">
                <img src="@/assets/images/verification/scheduled.svg" />
            </div>
            <div class="scheduled__header">
                <h1>
                    Successfully {{ type === "new" ? "Scheduled" : "Rescheduled" }}!
                </h1>
                <p class="identity__body strong">
                    Your appointment was successfully
                    {{ type === "new" ? "scheduled" : "rescheduled" }}!
                </p>
            </div>
            <div>
                <h-badge label="Appointment Details" color="#55D291" dark small />
            </div>
            <div :class="{'scheduled__details--wide': type === 'reschedule', 'scheduled__details': true}">
                <div class="scheduled__details__item">
                    <div>
                        <img src="@/assets/images/verification/calendar.svg" />
                    </div>
                    <div v-if="type === 'new'">
                        {{ time.actualDate }}
                    </div>
                    <div v-else class="scheduled__details__rescheduled">
                        <s class="former__text">{{ time.formerDate }}</s>
                        <svg
                            width="18"
                            height="16"
                            viewBox="0 0 18 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.7045 0.283953C10.3111 -0.103668 9.67798 -0.0989795 9.29036 0.294426C8.90274 0.687832 8.90743 1.32098 9.30083 1.7086L14.6712 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447716 9 1 9H14.6646L9.30083 14.2849C8.90743 14.6725 8.90274 15.3057 9.29036 15.6991C9.67798 16.0925 10.3111 16.0972 10.7045 15.7095L17.6287 8.88715C18.1256 8.39754 18.1256 7.59595 17.6287 7.10634L10.7045 0.283953Z"
                                fill="#212121"
                            />
                        </svg>
                        {{ time.actualDate }}
                    </div>
                </div>
                <div class="scheduled__details__item">
                    <div>
                        <img src="@/assets/images/verification/clock.svg" />
                    </div>
                    <div v-if="type === 'new'">
                        {{ time.actualTime }}
                    </div>
                    <div v-else class="scheduled__details__rescheduled">
                        <s class="former__text">{{ time.formerTime }}</s>
                        <svg
                            width="18"
                            height="16"
                            viewBox="0 0 18 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.7045 0.283953C10.3111 -0.103668 9.67798 -0.0989795 9.29036 0.294426C8.90274 0.687832 8.90743 1.32098 9.30083 1.7086L14.6712 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447716 9 1 9H14.6646L9.30083 14.2849C8.90743 14.6725 8.90274 15.3057 9.29036 15.6991C9.67798 16.0925 10.3111 16.0972 10.7045 15.7095L17.6287 8.88715C18.1256 8.39754 18.1256 7.59595 17.6287 7.10634L10.7045 0.283953Z"
                                fill="#212121"
                            />
                        </svg>
                        {{ time.actualTime }}
                    </div>
                </div>
            </div>
            <div class="scheduled__button-container">
                <h-button
                    label="Return to Appointments"
                    variant="cta"
                    full_width
                    large
                    :handle-click="handleContinue"
                />
            </div>
        </div>
        <div class="profile-certification">
            <HCertification />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';

export default {
    name: 'AppointmentCallback',
    components: {
        HBadge,
        HButton,
        HCertification,
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            organizationSettings: (state) => state.organizationSettings,
        }),
        ...mapActions({
            getOrganizationSettings: 'getOrganizationSettings',
        }),
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const rescheduleUid = urlParams.get('rescheduleUid');

        // for new bookings
        const startTime = moment(urlParams.get('startTime'));
        // for rescheduled bookings
        const slot = moment(urlParams.get('slot'));
        const formerTime = moment(urlParams.get('formerTime'));

        this.$nextTick(() => {
            if (!this.organizationSettings.data) {
                this.getOrganizationSettings();
            }
        });

        this.type = rescheduleUid ? 'reschedule' : 'new';
        this.time = {
            actualDate: this.formatDate(startTime.isValid ? startTime : slot),
            actualTime: this.formatTime(startTime.isValid ? startTime : slot),
            formerDate: this.formatDate(formerTime),
            formerTime: this.formatTime(formerTime),
        };
    },
    methods: {
        formatDate(date) {
            return moment(date).local().format('MMMM Do, YYYY');
        },
        formatTime(time) {
            return moment(time).local().format('LT z');
        },
        handleContinue() {
            this.$router.push({ path: `/dashboard/${this.user._id}/appointments` });
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/_type.scss";

.zp-company-logo {
  margin-top: 1rem;
}

.schedule__header {
  h1 {
    @extend %helveticaNowDisplayImportant;
    text-transform: none;
  }
}

.former__text {
  color: #a7a7a7;
}

.schedule-call {
  &__cal {
    padding: 2rem 0 0;
  }

  &__hidden {
    display: none;
  }
}

.scheduled {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 0 1.5rem;

  &__img {
    &__container {
      position: relative;
      width: 100%;
      max-width: 249px;
      height: 253px;
      padding: 0 1rem;
      margin: 0 auto;
      margin-top: 3rem;

      img {
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      @extend %helveticaNowDisplayImportant;
      font-size: 1.75rem !important;
      line-height: 2.5rem !important;
      font-weight: 400 !important;
      text-transform: capitalize;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.75rem;
    background-color: #f9f9f9;
    width: 100%;
    max-width: 490px;
    padding: 1rem 3rem;

    &--wide {
        max-width: 610px;
    }

    &__rescheduled {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      padding: 1rem 0;
      @include body;

      &:first-child {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }

  &__button-container {
    width: 100%;
    max-width: 490px;
    margin-top: 2rem;

    @extend %helveticaNowDisplayImportant;
    font-size: 0.875rem;
    letter-spacing: 0.2rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  @media (min-width: 1280px) {
    &__header {
      @include helveticaNowDisplayImportantMixin();

      h1 {
        @include helveticaNowDisplayImportantMixin();
        font-size: 3rem !important;
        line-height: 3.5rem !important;
        font-weight: 300 !important;
      }
    }
  }
}
</style>
