<template>
    <svg
        width="6"
        height="12"
        viewBox="0 0 6 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5 11L2.12132 8.12132C0.949745 6.94975 0.949747 5.05025 2.12132 3.87868L5 1" stroke="#242E49" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'ChevronBackIcon',
};
</script>
