<template>
<form :id="intake_instance_response.data._id" @submit.prevent="checkForm()">
    <div class="intake-question__header">
        <h2>{{ intake_instance_response.data.question.label }}</h2>
            `<p v-if="intake_instance_response.data.question.description_text" class="intake-question__description strong">
            {{ intake_instance_response.data.question.description_text }}</p>
    </div>
    <!--if patient says yes to the medical conditions-->
    <div class="question-container">
        <div class="intake-question__textarea">
            <input v-model="free_response"/>
        </div>
    </div>
    <div class="intake-button">
            <h-button
                label="Continue"
                :disabled="!checkTrue"
                :loading="button_submitting"
                variant="primary"
                full_width
                large
                :handle-click="() => {}"
                data-test="continue-button"
            />
    </div>
    <div class="intake-certification">
        <h-certification/>
    </div>
</form>

</template>


<script>
import IntakeButton from '@/components/Consultation/SubmitButton.vue';
import moment from 'moment';
import {required, minLength, between} from 'vuelidate/lib/validators';
import {mapGetters, mapMutations, mapActions, mapState} from 'vuex'
import async from 'async';
import {removeItem} from '@/services/parser.js';
import HButton from '../../Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';


export default {
    name: 'ShortText',
    data() {
        return {
            submitted: false,
            tapped: false,
            submitting: false,
            button_text: 'Next',
            button_submitting: false,
            free_response: '',
            none: false,
        }
    },
    props: ['step', 'retrieving'],
    components: {
        IntakeButton: IntakeButton,
        HButton,
        HCertification
    },
    methods: {
        clicked(not_none) {
            this.tapped = true;
        },

        checkForm(e) {

            const vm = this;
            vm.submitted = true;
            if (vm.free_response.length === 0) {
                return;
            }
            vm.button_text = 'Submitting';
            vm.button_submitting = true;
            const patient_response = {free_format_response: vm.free_response}
            async.waterfall([
                function updateParentIntakeResult(callback) {

                    let payload = {
                        response_id: vm.intake_instance_response.data._id,
                        user_id: vm.user._id,
                        intake_id: vm.$route.params.intakeId,
                        response: patient_response
                    }

                    vm.updateIntakeResponse(payload).then((saved_response) => {
                        callback(null, {})
                    }).catch((err) => {
                        vm.button_text = 'Error. Try Again';
                        vm.button_submitting = false;
                    })
                },

                function nextStep(last, callback) {
                    vm.button_submitting = false;
                    vm.button_text = 'Updated';
                    setTimeout(() => {
                        vm.routerPush(vm.step);
                        vm.button_text = 'Next';
                        this.free_response = '';
                    }, 150)
                }
            ])
        },
        ...mapActions({
            updateIntakeResponse: 'intake/updateIntakeResponse',
        }),
        ...mapMutations({
            routerPush: 'intake/routerPush'
        })
    },
    computed: {
        ...mapGetters({
            totalSteps: 'intake/totalSteps'
        }),
        ...mapState({
            intake_id: state => state.intake.intake_id,
            states: state => state.states.states,
            user: state => state.user.user,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            intake_instance_response: state => state.intake.intake_instance_response
        }),
        checkTrue() {
            const vm = this;
            return (vm.free_response.length > 0); //user response
        },
    },
    created() {
        this.free_response = this.intake_instance_response.data.free_format_response || '';
    }
}
</script>
