import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_END;

export const payment = {
    //namespaced true makes your vuex module accessible by eg: 'payment/nameoffield
    namespaced: true,
    state: {
        error: {
            error: false,
            error_msg: ''
        },
        patientCard: {
            data: null,
            error: false,
            loading: false
        }
    },
    mutations: {
        setError(state, msg) {
            state.error.error = true;
            state.error.error_msg = msg;
        },
        removePaymentError(state) {
            state.error.error = false;
            state.error.error_msg = ''
        },
        setPatientCardData(state, data) {
            state.patientCard.data = data;
        },
        setPatientCardLoading(state, loading) {
            state.patientCard.loading = loading;
        },
        setPatientCardError(state, error) {
            state.patientCard.error = error;
        }
    },
    actions: {
        async updateCreditCard({state, getters, commit, context, rootState}, payload) {
            let user = payload.user;
            let id = user._id;
            let cardObject = {
                token: payload.token, //this is the stripe token for the card captured in stripe.js
                charge: payload.charge, //flag of whether to immediately cahrge the card // (unsuported on the backend)
                charge_amount: payload.charge_amount, // (unsuported on the backend)
                new_user: payload.new_user, // (unsuported on the backend)
            }
            let orgId = rootState.orgId.data;

            try {
                let update_card = await axios.put(`${baseUrl}/users/${orgId}/${id}/add-card`, cardObject);

                if ('data' in update_card) {
                    let new_user = update_card.data;
                    commit('user/setUser', new_user, {root: true});
                    return update_card.data;
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response.status === 400) {
                        let error_msg = 'Invalid Credit Card'
                        commit('setError', error_msg)
                    }

                    if (error.response.status === 402) {
                        commit('setError', error.response.data.message);
                    }

                    throw error.response.data;
                }
                throw error;
            }
        },
        getPatientCard({state, getters, commit, rootState}, payload) {
            const orgId = rootState.orgId.data;
            commit('setPatientCardError', false);
            commit('setPatientCardLoading', true);

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/billing/' + orgId + '/patient/' + payload.user_id + '/card',
                    method: 'GET'
                }).then(response => {
                    commit('setPatientCardData', response.data);
                    commit('setPatientCardError', false);
                    commit('setPatientCardLoading', false);
                    resolve(response.data);
                }).catch((error) => {
                    commit('setPatientCardError', true);
                    commit('setPatientCardLoading', false);
                    console.error('what is the err', error);

                    if (axios.isAxiosError(error)) {
                        reject(error.response.data);
                    } else {
                        reject(error);
                    }
                });
            });
        }
    },

}
