<template>
    <div class="category">
        <ul>
            <li 
                :class="{ 'category__selected': selectedCategory === 'all'}"
                @click="handleCategorySelect()"
            >
                All
            </li>
            <li
                v-for="option in options"
                :key="option.value"
                :class="{ 'category__selected': selectedCategory === option.value }"
                @click="handleCategorySelect(option.value)"
            >
                {{ option.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FilterCategory',
    props: {
        type: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        selected: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedCategory: 'all',
        };
    },
    watch: {
        selected(newValue) {
            this.selectedCategory = newValue;
        },
    },
    methods: {
        handleCategorySelect(category) {
            this.selectedCategory = category;
            this.$emit('category-selected', { type: this.type, value: category });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.category {
    &__label {
        @include caption;
        font-weight: 700;
        margin: 0 0 1rem 0;
    }

    ul {
        display: flex;
        gap: 0.5rem;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        @include caption;
        border: 1px solid $border-light;
        border-radius: $radius-xl;
        padding: 0.45rem 1rem;
        cursor: pointer;
    }

    &__selected {
        background-color: $charcoal;
        color: $white;
    }

    &__desktop {
        display: none;
    }

}

</style>
