<template>
    <div class="more-menu-container">
        <div class="more-menu" :class="{'more-menu--open': open}">
            <ul>
                <li>
                    <router-link :to="`/dashboard/${userId}/providers`" class="more-menu__item">
                        <span>Providers</span>
                    </router-link>
                </li>
                <li v-for="link in links" :key="link._id">
                    <a class="more-menu__item" :href="link.url" target="_blank">
                        <span>{{ link.name }}</span>
                        <ExternalIcon />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ExternalIcon from '@/assets/icons/External.vue';

export default {
    name: 'MoreMenu',
    components: {
        ExternalIcon,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
        links: {
            type: Array,
            default: () => [],
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            moreNavData: [
                {
                    name: 'Providers',
                    isExternal: false,
                    route: 'providers',
                },
                {
                    name: 'Community',
                    isExternal: true,
                    route: '/',
                },
                {
                    name: 'Pharmacy',
                    isExternal: true,
                    route: '/',
                },
                {
                    name: 'Twitter',
                    isExternal: true,
                    route: '/',
                },
                {
                    name: 'Instagram',
                    isExternal: true,
                    route: '/',
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.more-menu-container {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100px;
    height: 48px;
    z-index: 3;
    background-color: transparent;
}

.more-menu {
    position: absolute;
    top: -12px;
    left: 65px;
    border-radius: 12px;
    background: $white-100;
    box-shadow: rgba($gray-800, 0.1) 0px 2px 8px 0px;
    padding: 1rem 0.5rem;
    z-index: 2;
    opacity: 0;
    transform: scale(0);
    transform-origin: 0 36px;
    transition: all 0.2s ease-out;
    
    ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        list-style: none;
        padding: 0;
    }

    &__item {
        @include caption;
        font-weight: 700;
        color: $charcoal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5rem;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: $bg-light;
            color: $charcoal;
            border-radius: 0.75rem;

            svg {
                path {
                    fill: $charcoal;
                }
            }
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        box-shadow: rgba($gray-800, 0.1) 0px 2px 8px 0px;
        top: 28px;
        left: -6.4px;
        background: $white-100;
        border-radius: 4px;
        transform: rotate(45deg);
        z-index: 1;
    }

    &:before {
        content: "";
        width: 14px;
        height: 28px;
        position: absolute;
        background: $white-100;
        left: 0;
        top: 22px;
        z-index: 2;
    }

    &--open {
        opacity: 1;
        transform: scale(1);
    }
}

</style>
