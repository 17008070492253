<template>
    <div class="profile__container">
        <div class="profile__header">
            Dashboard
            <div v-if="unreadCount" class="profile__unread-messages">
                You have <div class="profile__unread-messages__link" @click="goToNotifications">
                    {{ unreadCount }} unread
                </div> notification{{ unreadCount === 1 ? '' : 's' }}
            </div>
        </div>
        <VisitCompleteModal
            :open="!!welcomeNotification"
            :on-continue="onVisitComplete"
            :type="welcomeNotification?.data?.type"
        />
        <PendingVisits />
        <DashboardWidgets class="profile__dashboard" />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import VisitCompleteModal from '@/components/Dashboard/Modals/VisitCompleteModal.vue';
import { NOTIFICATION_CATEGORY } from '@/components/Dashboard/Notifications/utils/NotificationMap';
import DashboardWidgets from '@/components/Dashboard/Widgets/DashboardWidgets.vue';
import PendingVisits from '@/components/Visits/PendingVisits';

export default {
    components: {
        VisitCompleteModal,
        PendingVisits,
        DashboardWidgets,
    },
    computed: {
        ...mapState({
            notifications: state => state.alerts.notifications,
            unreadCount: state => state.alerts.unreadCount,
            user: state => state.user.user,
        }),
        welcomeNotification() {
            return this.notifications?.docs?.find(notification =>
                notification.category === NOTIFICATION_CATEGORY.welcome && !notification.read,
            );
        },
    },
    async created() {
        try {
            await this.getOrganizationSettings();
        } catch (error) {
            console.error('Created error', error);
        }

        try {
            await Promise.all([
                this.getIncompleteIntakes({ user: this.user._id }),
                this.getIntakes({ user: this.user._id }),
            ]);
        } catch (error) {
            console.error('Incomplete Intakes: error', error);
        }

    },
    methods: {
        ...mapActions({
            getOrganizationSettings: 'getOrganizationSettings',
            markNotificationAsRead: 'alerts/markNotificationAsRead',
            getIncompleteIntakes: 'intake/retrieveIncompleteIntakes',
            getIntakes: 'intake/retrievePatientIntakes',
        }),
        goToNotifications() {
            const targetRoute = '/notifications';

            if (this.$route.path !== targetRoute) {
                this.$router.push({ path: targetRoute });
            }
        },
        async onVisitComplete() {
            if (!this.welcomeNotification.read) {
                await this.markNotificationAsRead(this.welcomeNotification._id);
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.profile {
    &__alert {
        margin-bottom: 2rem;
    }

    &__header {
        margin-bottom: 3rem;
        @include heading-lg;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    &__unread-messages {
        @include body;
        display: flex;
        gap: 0.25rem;

        &__link {
            color: $health-orange;
            cursor: pointer;

            &:hover {
                text-decoration: none;
                color: $health-orange;
            }
        }
    }

    &__dashboard {
        margin-bottom: 2rem;
    }

    @media (min-width: 1440px) {
        &__container {
            max-width: 1440px;
            margin: 0 auto;
        }
    }
}

</style>
