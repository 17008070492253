<template>
<div class="zp-messenger-block-paragraph-container">
    <ul v-if="block_obj.items_type === 'unordered'">
        <li v-for="(item, index) in block_obj.list_items" v-bind:key="index">
            <span v-if="item.bold_text"> <strong>{{item.bold_text}}:</strong></span>
            {{item.text}}
        </li>
    </ul>
    <ol v-else-if="block_obj.items_type === 'ordered'">
        <li v-for="(item, index) in block_obj.list_items" v-bind:key="index">
            <span v-if="item.bold_text"> <strong>{{item.bold_text}}:</strong></span>
            {{item.text}}
        </li>
    </ol>
    {{ block_obj.text }}
</div>
</template>

<script>
export default {
    props: ['block_obj', 'index_num']
}
</script>

