<template>
    <div class="hspinner">
    </div>
</template>

<script>
    export default {
        name: 'HSpinner',
        props: {
            size: {
                type: String,
                default: 'medium',
            },
            color: {
                type: String,
                default: 'primary',
            },
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

.hspinner {
   width: 58px;
   height: 58px;
   border-radius: 50%;
   border: 7px solid;
   border-color: $gray-300;
   border-right-color: $charcoal;
   animation: spinner 1s infinite linear;
}

@keyframes spinner {
   to {
      transform: rotate(1turn);
   }
}

</style>