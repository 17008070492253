import moment from 'moment';

export function formatNotificationDate(date) {
    const now = moment();
    const notificationDate = moment(date);
    const minutesDiff = now.diff(notificationDate, 'minutes');
    const hoursDiff = now.diff(notificationDate, 'hours');
    const daysDiff = now.diff(notificationDate, 'days');
    const weeksDiff = now.diff(notificationDate, 'weeks');
    const monthsDiff = now.diff(notificationDate, 'months');

    if (minutesDiff < 60) {
        return 'Just now';
    } else if (hoursDiff < 24) {
        return `${hoursDiff} ${hoursDiff === 1 ? 'hour' : 'hours'} ago`;
    } else if (daysDiff <= 7) {
        return `${daysDiff}d`;
    } else if (daysDiff <= 30) {
        return `${daysDiff}d`;
    } else if (monthsDiff < 3) {
        return `${weeksDiff}w`;
    } else {
        return `${monthsDiff}m`;
    }
}
