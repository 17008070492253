import Vue from "vue";


export const treatment = {
    namespaced: true,
    state: {
        active_treatment_duration: {},
        treatment_product_properties: {},
        treatment_durations: {},
        treatment_products: {},
        treatment_items: {},
        treatment_preferences: {},
        treatment_configuration: {
            units: null,
            dosage_frequency: null,
            duration: null,
            dosage_strength: null,
            preference_option: null
        }
    },
    getters: {},
    mutations: {
        resetTreatment(state, payload) {
            state.treatment_items = []
        },
        setTreatmentProductSetting(state, payload) {
            Vue.set(state.treatment_preferences[payload.product], payload.preference, payload.value);
        },
        saveTreatmentSettings(state, payload) {
            state.treatment_preferences = {};
            Vue.set(state.treatment_preferences, payload.product, payload.value);

        },
        saveConfigurationSettings(state, payload) {
            Vue.set(state, 'treatment_configuration', payload);
        },
        setActiveDuration(state, payload) {
            Vue.set(state, 'active_treatment_duration', payload);
        },
        setTreatmentProducts(state, payload) {
            Vue.set(state, 'treatment_products', payload);
        },
        setTreatmentDurations(state, payload) {
            Vue.set(state, 'treatment_durations', payload);
        },
        updateTreatmentDuration(state, payload) {
            Vue.set(state.treatment_durations, payload.product_id, payload.data);
        },
        setTreatmentProductProperties(state, payload) {
            Vue.set(state.treatment_product_properties, payload.product_id, payload.product_properties);
        },
        setTreatmentItem(state, payload) {
            Vue.set(state, 'treatment_items', payload);
        }
    },
    actions: {
        reset_treatment({commit}) {
            commit('resetTreatment')
        },
    }
}