export const getUpdatedQuery = (currentQuery, item, type, checkedItems) => {
    const query = JSON.parse(JSON.stringify(currentQuery));
    const typeKey = type.toLowerCase();

    if (item === 'All') {
        delete query[typeKey];
    } else if (checkedItems[type].checks[item]) {
        if (!query[typeKey]) {
            query[typeKey] = [];
        }

        if (!Array.isArray(query[typeKey])) {
            query[typeKey] = [query[typeKey]];
        }

        query[typeKey].push(item);
    } else {
        if (Array.isArray(query[typeKey])) {
            query[typeKey] = query[typeKey].filter(value => value !== item);

            if (query[typeKey].length === 0) {
                delete query[typeKey];
            }
        } else if (query[typeKey] === item) {
            delete query[typeKey];
        }
    }

    return query;
};

export const makeDefaultCheckedItems = (query, options) => {
    return options.reduce((acc, option) => {
        const filterByType = query[option.type.toLowerCase()];

        acc[option.type] = {
            all: false,
            checks: option.valid_values.reduce((checksAcc, value) => {
                if (filterByType) {
                    checksAcc[value] = Array.isArray(filterByType) ? filterByType.includes(value) : filterByType === value;
                } else {
                    checksAcc[value] = false;
                }

                return checksAcc;
            }, {}),
            keys: option.valid_values,
        };

        return acc;
    }, {});
};

export const resetCheckedItems = (checkedItems) => {
    return Object.keys(checkedItems).reduce((acc, type) => {
        acc[type].all = false;

        Object.keys(checkedItems[type].checks).forEach(key => {
            acc[type].checks[key] = false;
        });

        return acc;
    }, checkedItems);
};
