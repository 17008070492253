import orderBy from 'lodash/orderBy';

export const getIncompleteProductIds = (incompleteIntakesData) => {
    return incompleteIntakesData.reduce((acc, incompleteIntake) => {
        const id = incompleteIntake.template?.product_defaults?.[0];

        if (id) acc.push(id);

        return acc;
    }, []);
};

export const getSortedIncompleteIntakes = (incompleteIntakesData, rapamycinTemplateId) => {
    return orderBy(
        incompleteIntakesData || [],
        [(intake) => intake.status?.questions?.total - intake.status?.complete?.total, (intake) => intake.template?._id === rapamycinTemplateId],
        ['asc', 'desc'],
    );
};
