<template>
    <div class="consultation-wrapper">
        <div class="consultation-container">
            <form name="membership-summary">
                <h2 class="headline-consultation">
                    Your Program
                </h2>
                <div v-if="membership" class="white-container flex-col flex  mt-4">
                    <div class="product-information-view-wrapper wc-flex flex-row">
                        <div class="product-information-view override-pi-padding">
                            <img :src="membership.image" alt="membership" />
                            <div class="product-information-content">
                                <h3 class="headline-3">
                                    {{ membership.name }}
                                </h3>
                                <p class="body-small-font">
                                    {{ membership.description }}
                                </p>
                            </div>
                        </div>
                        <div v-if="!hasMembership(membership) && price" class="price-info-wrapper-col">
                            <span class="description-text">
                                ${{ (price.unit_amount_decimal / 100) | nodecimal }}/mo
                            </span>
                        </div>
                        <div v-if="hasMembership(membership)" class="price-info-wrapper-col">
                            <span class="description-text">
                                Included
                            </span>
                        </div>
                    </div>
                    <div class="product-container-bottom plan-customization-height" style="padding-top:.5rem!important">
                        <ul class="plan-ul">
                            <li v-for="(feature, index) in membership.features" :key="index">
                                <span class="checkmark-container">
                                    <span class="arbor-list__checkmark"></span>
                                </span>
                                <span class="col-feature-description flex flex-col">
                                    <p class="col-feature-title">{{ feature.title }}</p>
                                    <p>{{ feature.description }}</p>
                                </span>
                            </li>
                        </ul>
                        <button class="product-select-button" @click="continueMembership()">
                            Continue
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapActions, mapState } from 'vuex';

import router from '@/router';

export default {
    name: 'MembershipSignup',
    data() {
        return {
            membershipPayload: {
                price_id: null,
                membership_id: null,
                stripe_price_id: null,
            },
        };
    },
    computed: {
        ...mapState({
            membership: state => state.memberships.membership.data,
            price: state => state.memberships.price.data,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate.data,
            user: state => state.user.user,
        }),
    },
    methods: {
        ...mapActions({
            getMembership: 'memberships/getMembership',
            getPrice: 'memberships/getMembershipPrice',
        }),
        hasMembership(membership) {
            if (this.user && this.user.membership) {
                return membership._id === this.user.membership.id;
            } else {
                return false;
            }
        },
        hasImage() {
            if (this.user && this.user.image) {
                return this.user && !!this.user && !!this.user.image && !!this.user.image.id.id_url && !!this.user.image.profile.profile_url;
            } else {
                return false;
            }
        },
        continueMembership() {
            window.localStorage.setItem('membership', JSON.stringify(this.membershipPayload));
            const signupConfig = JSON.parse(window.localStorage.getItem(('signup-config')));

            signupConfig.membership['data'] = this.membershipPayload;
            window.localStorage.setItem('signup-config', JSON.stringify(signupConfig));
            const photoType = this.hasImage() ? 'existing_identity' : 'id_image';

            if (!signupConfig.product.main_products.length) {
                window.localStorage.setItem('no-product', 'true');

                const identityParams = {
                    section: 'identification-step',
                    intakeId: this.$route.params.intakeId,
                    photoType,
                };

                this.$router.push({
                    name: 'identification-step-alt',
                    params: identityParams,
                }).catch(error => console.error(error));
            } else if (signupConfig.product.main_products.length) {
                const checkoutParams = {
                    section: 'product-configure',
                    intakeId: this.$route.params.intakeId,
                    productTemplateId: signupConfig.product.main_products[0],
                };

                this.$router.push({ name: 'treatment-checkout', params: checkoutParams }).catch(error => console.error(error));
            } else if (signupConfig.lab.product) {
                const labCheckoutParams = {
                    section: 'lab-checkout',
                    intakeId: this.$route.params.intakeId,
                    labProductId: signupConfig.lab.product,
                };

                router.push({ name: 'lab-checkout', params: labCheckoutParams }).catch(error => console.error(error));
            } else {
                const treatmentParams = {
                    section: 'treatment',
                    intakeId: this.$route.params.intakeId,
                };

                this.$router.push({
                    name: 'treatment-selection',
                    params: treatmentParams,
                }).catch(error => console.error(error));
            }

        },
    },
    created() {
        this.$nextTick(() => {
            this.getMembership({ membership_id: this.$route.params.membershipId }).then(() => {
                const selectPlan = this.membership.plans[0];

                selectPlan.active = true;
                this.getPrice({ price_id: selectPlan.price._id }).then(() => {
                    this.membershipPayload.membership_id = this.$route.params.membershipId;
                    this.membershipPayload.price_id = this.price._id;
                    this.membershipPayload.stripe_price_id = this.price.stripe_id;
                });

                this.$forceUpdate();
            });
        });
    },
};
</script>

<style lang="scss">
$membershipHeaderBackground: $stone200;
$membershipPlanButton: $stone200;

.override-pi-padding {
    @media (max-width: 760px) {
        padding: 1rem;
    }
}

.memberships {
    &__container {
        padding: 10rem;
    }

    &__header {
        display: flex;
        min-height: 250px;
        border-bottom: 1px solid $membershipHeaderBackground;
    }

    &__description {
        padding-top: 4rem;
        width: 100%;
        text-align: center;
    }

    &__image {
        width: 40%;
        border-radius: 12px;
    }

    &__plans {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;
    }

    &__plans-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;

        &--item {
            font-weight: 500;
            padding: .5rem 1.5rem;
            margin-right: 1rem;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: $membershipPlanButton;
            }

            &.active {
                background-color: $membershipPlanButton;
            }

        }
    }

    &__price {
        text-align: center;
        padding-top: 2rem;
    }

    &__features {
        list-style: none;
        padding: 0;
        margin-top: 2rem;

        &--item {
            display: flex;
            flex-direction: column;
            padding-bottom: 1.5rem;
            text-align: center;

            &__title {
                font-weight: 500;
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
</style>
