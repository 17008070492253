<template>
<div>
    <Loading color="#222"/>
</div>
</template>
<script type="text/javascript">
import Loading from "@/components/Loading";
import {mapActions, mapState} from "vuex";

export default {
    name: 'productInstance',
    components: {
        Loading: Loading
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        })
    },
    methods: {
        ...mapActions({
            createIntakeInstance: 'intake/createIntakeInstance',
            saveProductTemplateId: 'productTemplates/saveSignupProductTemplateId',
            getIntakeTemplate: 'intake/getIntakeTemplate'
        }),
        init() {
            const params = this.$route.params;
            this.getIntakeTemplate({templateId: params.intakeTemplateId}).then(response => {
                this.saveProductTemplateId(params.productTemplateId);
                window.localStorage.setItem('intake_template', JSON.stringify(response));
                this.createIntakeInstance({
                    templateId: params.intakeTemplateId,
                    userId: this.user._id,
                    pharmacy_verification: response.includes_pharmacy_verification
                }).then(intake => {
                    this.$router.push({path: '/consultation/hiw/' + intake.user + '/' + intake._id + '/question/none'});
                });
            });
        }
    },
    created() {
        this.init();
    }
}
</script>
