<template>
    <div class="subscription-modal__container">
        <modal-header-icon icon="cube" :title="modal_title" @onClose="on_close"></modal-header-icon>
        <div class="subscription-modal__body">
            <div class="subscription-modal__options" v-if="view === 'options'">
                <info-well
                    title="We're here to help!"
                    description="If you're having any trouble, please get in touch."
                    action_title="Message Us"
                    :has_action="true"
                    @onAction="message"
                >
                </info-well>
                <div class="subscription-modal__form">
                    <transition name="slide-fade">
                        <p class="ppe-small-title">What would you prefer to do?</p>
                    </transition>
                    <transition name="slide-fade">
                        <action-card
                            v-if="subscription.status !== 'paused'"
                            title="Put my subscription on hold"
                            description="You will not be charged when on hold and you can reactivate your subscription at anytime"
                            :has_icon="true"
                            icon="arrow-forward-outline"
                            @onAction="on_pause"
                        >
                        </action-card>
                    </transition>
                    <transition name="slide-fade">
                        <action-card
                            title="Cancel my subscription"
                            description="Your subscription will be permanently canceled, you can start a new subscription at anytime."
                            :has_icon="true"
                            icon="arrow-forward-outline"
                            @onAction="on_cancel_option"
                        ></action-card>
                    </transition>
                </div>
            </div>
            <div class="subscription-modal__survey" v-if="view === 'survey'">
                <p class="ppe-small-title">Before you go, would you mind letting us know why?</p>
                <p class="ppe-description">Your feedback will help us make {{organization_data.name}} better for other people.</p>
                <ul class="subscription-modal__survey-list">
                   <li v-for="item in survey_items" @click="on_survey_item(item)" :class="{'active': selected_survey && selected_survey.id === item.id}">
                       <div class="hs-radio">
                           <input :id="item.id"
                                  name="radio"
                                  type="radio"
                                  v-model="selected_survey"
                                  :value="item">
                           <label class="hs-radio-label" :for="item.id">{{item.name}}</label>
                       </div>
                   </li>
                </ul>
                <textarea v-if="other_selected" class="ppe-textarea" placeholder="Please describe" v-model="other_description"></textarea>
                <modal-footer
                    :has_cancel="true"
                    :submit_button_text="button_text.cancel"
                    :is_submitting="cancel_subscription.loading"
                    @onCancel="change_view('options')"
                    @onSubmit="on_subscription_cancel()">

                </modal-footer>
            </div>
            <div class="subscription-modal__pause" v-if="view === 'pause'">
                <modal-confirm title="Are you sure you want to pause your subscription?"
                               :confirm_button_text="button_text.pause"
                               cancel_button_text="Cancel"
                               :is_submitting="pause_subscription.loading"
                               @onCancel="on_pause_return()"
                               @onConfirm="on_pause_confirm()"
                >
                </modal-confirm>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import ModalHeaderIcon from "@/components/Dashboard/Modals/Components/ModalHeaderIcon";
import InfoWell from "@/components/Shared/InfoWell";
import ActionCard from "@/components/Shared/ActionCard.vue";
import ModalFooter from "@/components/Dashboard/Modals/Components/ModalFooter";
import ModalConfirm from "@/components/Dashboard/Modals/Components/ModalConfirm.vue";

export default {
    name: 'SubscriptionCancelModal',
    components: {
        ModalHeaderIcon: ModalHeaderIcon,
        InfoWell: InfoWell,
        ActionCard: ActionCard,
        ModalFooter: ModalFooter,
        ModalConfirm: ModalConfirm
    },
    props: ['subscription', 'vendor'],
    data() {
        return {
            organization_data: null,
            selected_survey: null,
            other_description: null,
            other_selected: false,
            modal_title: null,
            view: 'options',
            survey_items: [
                {
                    name: 'Too expensive',
                    id: 'expensive'
                },
                {
                    name: 'Not taking medications anymore',
                    id: 'not_taking'
                },
                {
                    name: 'Medications not working for me',
                    id: 'not_working'
                },
                {
                    name: `I don't need it anymore.`,
                    id: 'not_needed'
                },
                {
                    name: 'Other',
                    id: 'other'
                }
            ]
        }
    },
    async created() {
        this.modal_title = `We'll miss you ${this.user.first_name}!`;
        this.organization_data = this.organization;
        if (!this.organization_data) {
            this.organization_data = JSON.parse(window.localStorage.getItem('zp_org_settings'));
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            organization: state => state.organization.organization,
            cancel_subscription: state => state.subscriptions.cancel_subscription,
            pause_subscription: state => state.subscriptions.pause_subscription,
            button_text: state => state.subscriptions.buttons
        })
    },
    methods: {
        ...mapActions({
            get_organization: 'getOrganization'
        }),
        on_close() {
            this.$emit('onSubscriptionCancelClose', true)
        },
        message() {
            this.$router.push({path: `/messages/${this.user._id}/new`})
        },
        on_pause() {
            this.change_view('pause');
            this.modal_title = `See you soon ${this.user.first_name}!`;
        },
        on_pause_return() {
            this.$emit('closeModal', true)
        },
        on_pause_confirm() {

            this.$emit('onSubscriptionPause', true);
        },
        on_survey_cancel() {
            this.selected_survey = null;
            this.change_view('option');
        },
        on_cancel_option() {
            this.change_view('survey')
        },
        change_view(view) {
            this.modal_title = `We'll miss you ${this.user.first_name}!`;
            this.view = view;
            this.$forceUpdate();
        },
        on_survey_item(item) {
            this.other_selected = false;
            this.other_description = null;
            this.selected_survey = item;

            if (item.id === 'other') {
               this.other_selected = true;
            }
        },
        on_subscription_cancel() {
            this.$emit('onSubscriptionCancel', {
                selected_survey: this.selected_survey,
                other_description: this.other_description
            });
        }
    }
}
</script>
<style lang="scss">

@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/patient-portal/shared.scss";

.vm--modal {
    border-radius: 12px;
}



.subscription-modal {
    &__body {
        padding: 2rem;
    }

    &__form {
        margin-top: 2.5rem;

        .ppe-action-card__container {
            margin-bottom: 1rem;
        }
    }

    &__survey {
        .modal-footer__container {
            margin-top: 4rem;
            padding: 0;
        }
    }

    &__survey-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 0.25rem 0.75rem;
            border: 1px solid $border-light;
            border-radius: 6px;
            margin-bottom: 0.5rem;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover, &.active {
                border: 1px solid $border-default;
            }

            .hs-radio {
                margin-bottom: 0;
            }
        }
    }


}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
