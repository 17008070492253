<template>
<div class="subscription-item__container" v-if="subscription">
    <div class="subscription-item__wrapper">
        <ul class="subscription-item__showcase" v-if="subscription.product_plans.length">
            <li class="subscription-item__showcase-item"  :style="{'background-image': `url(${subscription.product_plans[0].product.images[0]})`}"></li>
        </ul>
        <div class="subscription-item__details">
            <div class="subscription-item__status-tabs">
                <span class="subscription-item__status" :class="{'active': subscription.status === 'active'}">{{subscription.status | readable}}</span>
                <span class="subscription-item__status pending" v-if="subscription.status === 'awaiting_physician_review'">Pending</span>
                    <span v-if="get_schedule_item(vendor) && subscription.status === 'active'" class="subscription-item__status pending">Ships on {{ get_schedule_item(vendor) | unix_date('MMM Do') }}</span>
            </div>
            <div class="subscription-item__title">
                <span class="subscription-item__plan-name">{{subscription.plan_name}}</span>

            </div>

            <div class="subscription-item__renewal">
                <ul>
                    <li>Billing every {{ subscription.duration }} month{{ subscription.duration > 1 ? 's' : ''}}</li>
                    <li v-if="subscription.status === 'active' && subscription.subscription_id">Renews on {{ (vendor.current_period_end || get_schedule_item(this.vendor)) | unix_date("MMM Do") }}</li>
                </ul>
                <a class="subscription-item__invoice" v-if="latest_invoice" :href="latest_invoice.invoice_pdf"><ion-icon name="arrow-down-sharp"></ion-icon> Invoice PDF</a>
            </div>
        </div>
    </div>

    <div class="subscription-item__actions">
        <button class="pill-button black-btn" @click="on_contact">Message Us</button>
        <button class="pill-button" v-if="vendor && vendor.id && !vendor.pause_collection && subscription.status === 'active'" @click="on_snooze_subscription()">Reschedule</button>
        <button class="pill-button" v-if="(vendor && vendor.pause_collection) && subscription.status === 'paused'" @click="on_resume_subscription()">Resume</button>
            <button v-if="subscription.status === 'active' || subscription.status === 'paused'" class="pill-button link" @click="on_cancel_subscription()">
                Pause or Cancel
            </button>
    </div>
</div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {required, minLength, between, email} from 'vuelidate/lib/validators';
import moment from 'moment';
import {getShipmentDate} from "@/helpers/subscription";

export default {
    name: 'subscription-item',
    props: {
        id: {
            type: String,
            required: true,
        },
        subscriptionItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            modify_subscription_modal: false,
            cancel_subscription_button: 'Cancel Subscription',
            subscription_selected: null,
            subscription: null,
            vendor: null,
            latest_invoice: null
        }
    },
    watch: {
        subscriptionItem: {
            handler() {
                this.fetchSubscriptionData();
            },
            immediate: true,
            deep: true,
        },
    },
    async created() {
        await this.fetchSubscriptionData();
    },
    methods: {
        ...mapActions({
            cancelSubscription: 'subscriptions/cancelSubscription',
            getSubscriptions: 'subscriptions/getSubscriptions',
            getProductTemplate: 'products/getProductTemplate',
            getSubscription: 'subscriptions/getSubscription',
            getInvoice: 'subscriptions/getInvoice',
        }),
        async fetchSubscriptionData() {
            let subscriptionPayload = {
                patient: this.user._id,
                subscription_id: this.id,
            };

            if (this.subscriptionItem.status !== 'awaiting_physician_review') {
                subscriptionPayload['type'] = 'stripe';
            }

            try {
                await this.getSubscription(subscriptionPayload);
                this.subscription = this.data.subscription;
            } catch (error) {
                console.error('Error fetching subscription:', error);
                
                return;
            }

            if ('details' in this.data) {
                this.vendor = this.data.details;
                this.latest_invoice = await this.getInvoice({
                    invoice: this.vendor.latest_invoice,
                    type: 'stripe',
                });
            }

            this.configure_plans();
        },
        on_contact() {
            this.$router.push({path: `/messages/${this.user._id}/new`})
        },
        configure_plans() {
            let product_plans = this.subscription.product_plans;
            if (product_plans.length) {
                this.subscription['plan_name'] = product_plans.length > 1 ?
                    `${product_plans[0].product_template.product_name} and ${product_plans.length - 1} more...` :  product_plans[0].product_template.product_name;
            } else {
                this.subscription['plan_name'] = this.subscription.name;
            }

            this.$forceUpdate();
        },
        on_cancel_subscription() {
            this.$emit('onSubscriptionCancelRequest', {
                vendor: this.vendor,
                subscription: this.subscription
            });
        },
        on_resume_subscription() {
            this.$emit('onSubscriptionResumeRequest', {
                vendor: this.vendor,
                subscription: this.subscription
            })
        },
        on_snooze_subscription() {
            this.$emit('onSubscriptionSnoozeRequest', {
                vendor: this.vendor,
                subscription: this.subscription
            })
        },
        get_schedule_item(vendor) {
            return getShipmentDate(vendor);
        }
    },
    filters: {
        readable: (string) => {
            if (string) {
                let words = string.split('_');
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1); // capitalize the first letter of each word
                }
                return words.join(' ');
            } else {
                return string;
            }

        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            data: state => state.subscriptions.subscription.data,
            invoice: state => state.subscriptions.invoice.data
        }),

    }
}

</script>


<style lang="scss">

.subscription-item {
    &__container {
        display: flex;
        align-items: center;
        padding: 2rem 2rem;
        background-color: #FFFFFF;
        border: 1px solid #f1f1f1;
        border-radius: 12px;
        transition: all 0.3s ease;
        justify-content: space-between;

        @media screen and (max-width: 962px){
            flex-direction: column;
        }

        &:hover {
            box-shadow: rgb(0 0 0 / 8%) 0 0.25rem 0.75rem
        }


    }

    &__wrapper {
        display: flex;

        @media screen and (max-width: 962px){
            width: 100%;
            padding-bottom: 1rem;
        }

        @media screen and (max-width: 390px){
            flex-direction: column;
        }
    }


    &__showcase {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
    }

    &__showcase-item {
        width: 150px;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 12px;
        border: 1px solid #f1f1f1;
        background-color: #f9f9f9;
    }

    &__details {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 390px){
            margin-left: 0;
            margin-top: 1rem;
        }
    }

    &__title {
        font-size: 1rem;
        font-weight: 500;
        font-family: 'ZP-UI', sans-serif;
    }

    &__actions {
        display: flex;
        flex-direction: column;

        button {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: 962px){
            width: 100%;
            margin-top: 1rem;
        }
    }

    &__sub-actions {
        font-family: 'ZP-UI', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-size: 0.875rem;
        font-weight: 500;
        padding-top: 0.5rem
    }

    &__status-tabs {
        margin-bottom: 0.5rem;
    }

    &__status {
        font-size: 0.75rem;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        background-color: $cool100;
        border-radius: 5px;
        font-weight: 500;

        &.pending {
            background-color: #f1f1f1;
        }

        &.active {
            background-color: $nature100;
            color: $nature600;
        }
    }

    &__renewal {
        font-family: 'ZP-UI', sans-serif;
        font-size: 0.875rem;
        color: $neutral600;

        ul {
            list-style: none;
            padding: 0;
            margin: 0.5rem 0;
            display: flex;
            flex-direction: column;

            li {
                padding: 0.25rem 0;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    &__invoice {
        padding: 0.25rem 0.5rem;
        background-color: #f1f1f1;
        border-radius: 5px;
        font-size: 0.75rem;
        display: inline-block;
        font-weight: 500;
        transition: all 0.3s ease;
        text-decoration: none;
        color: $neutral600;

        &:hover {
            background-color: darken(#f1f1f1, 8%);
            text-decoration: none;
            color: $neutral600;
        }

        ion-icon {
            vertical-align: middle;
        }
    }
}

.subscription {
    &__product-item-list {
        list-style: none;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
    }

    &__product-item {
        display: flex;
        margin-bottom: 1.5rem;
    }

    &__product-name {
        margin-left: 1rem;
        font-family: "Sofia Semi Bold", sans-serif;
    }

    &__product-image {
        width: 50px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.cancel-subscription {
    &__container {
        width: 100%;
    }

    &__modal-container {
        padding: 2rem;
    }

}


</style>

