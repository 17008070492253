<template>
    <transition name="footer-nav" mode="out-in">
        <div v-if="scrollUp" class="footernav">
            <div class="footernav-container">
                <router-link :to="'/dashboard/' + user._id + '/labs'" class="footernav-item">
                    <labs-icon :color="page === 'labs' || page === 'lab-analysis' ? '#FF4C41' : '#b1b1b1'" />
                    <div class="footernav__label" :class="{'footernav__label-active': page === 'labs'}">
                        Labs
                    </div>
                </router-link>
                <router-link :to="'/dashboard/' + user._id + '/orders'" class="footernav-item">
                    <OrdersIcon :color="page === 'orders' ? '#FF4C41' : '#b1b1b1'" />
                    <div class="footernav__label" :class="{'footernav__label-active': page === 'orders'}">
                        Orders
                    </div>
                </router-link>
                <router-link :to="'/dashboard/' + user._id + '/subscriptions'" class="footernav-item">
                    <subscriptions-icon :color="page === 'subscriptions' ? '#FF4C41' : '#b1b1b1'" />
                    <div class="footernav__label" :class="{'footernav__label-active': page === 'subscriptions'}">
                        Subscriptions
                    </div>
                </router-link>
                <router-link to="/shop" class="footernav-item">
                    <shop-icon :color="page === 'shop' ? '#FF4C41' : '#b1b1b1'" />
                    <div class="footernav__label" :class="{'footernav__label-active': page === 'shop'}">
                        Shop
                    </div>
                </router-link>
                <router-link :to="'/messages/' + user._id + '/new'" class="footernav-item">
                    <messages-icon :color="page === 'messages' ? '#FF4C41' : '#b1b1b1'" />
                    <div class="footernav__label" :class="{'footernav__label-active': page === 'messages'}">
                        Messages
                    </div>
                    <span v-if="display_unread_notification" class="footernav-item__alert" />
                </router-link>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import LabsIcon from '@/assets/icons/nav/mobile/Labs.vue';
import MessagesIcon from '@/assets/icons/nav/mobile/Messages.vue';
import OrdersIcon from '@/assets/icons/nav/mobile/Orders.vue';
import ShopIcon from '@/assets/icons/nav/mobile/Shop.vue';
import SubscriptionsIcon from '@/assets/icons/nav/mobile/Subscriptions.vue';

export default {
    props: {
        scrollUp: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        LabsIcon,
        OrdersIcon,
        ShopIcon,
        SubscriptionsIcon,
        MessagesIcon,
    },
    methods: {
        ...mapActions({
            logout: 'authenticate/logout', 
        }),
    },
	computed: {
		...mapState({
			user: (state) => state.user.user,
			conversation_details: (state) => state.conversations.conversation_details,
		}),
		page() {
			return this.$route.params.page;
		},
		display_unread_notification() {
			return this.conversation_details && !this.conversation_details.patient_read;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.footernav {
	position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 68px;
	border-top: 1px solid #F2F2F2;
	background-color: #fff;
	padding: 0 1rem;
	z-index: $z-footer;
    transition: transform 0.2s ease-in-out;

	&__label {
		@extend %caption-sm;
		font-size: 0.6875rem;;
		font-weight: 500;
		color: $gray-600;
	}

	&__label-active {
		color: $health-orange;
	}

	@media (min-width: 1024px) {
		display: none;
	}
}

.footernav-container {
	display: flex;
	height: 100%;
	justify-content: center;
}

.footernav-item {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 0.125rem;
	hyphens: auto ;
	width: 19.8%;
	text-align: center;
	text-decoration: none;
	padding-top: 0.35rem;
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;

	&:first-child {
		width: 17.5%;
	}

	&:nth-child(3) {
		width: 23%;
	}

	&:hover {
		text-decoration: none;
	}

	&__alert {
		position: absolute;
		top: 0.5rem;
		right: 1rem;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 0.25rem;
		background-color: $health-orange;
	}
}

.footer-nav-enter,
.footer-nav-leave-to {
  transform: translateY(100%);
}

.footer-nav-enter-active,
.footer-nav-leave-active {
  transition: .3s ease-out;
}

</style>
