<template>
    <div class="treatment__dose-selection">
        <label class="checkout-input-label">Dosage Strength</label>
        <div class="text-input-container ">
            <div class="checkout-dropdown-container">
                <svg aria-hidden="true" focusable="false"
                     role="presentation" style="display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 4; overflow: visible;" viewBox="0 0 32 32"
                     xmlns="http://www.w3.org/2000/svg">
                    <g fill="none">
                        <path
                            d="m28 12-11.2928932 11.2928932c-.3905243.3905243-1.0236893.3905243-1.4142136 0l-11.2928932-11.2928932"></path>
                    </g>
                </svg>
            </div>
            <select id="dosage-strenth" v-model="dosage_strength" class="textfield-checkout"
                    name="dosage-strength" @change="on_strength_selected(dosage_strength)">
                <option v-for="(value, index) in dosage_strengths"
                        :selected="index === 0"
                        :value="value">
                    {{ value.strength }}
                </option>
            </select>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
    name: 'TreatmentDoseSelection',
    components: {},
    props: ['dosage_strengths', 'default'],
    data() {
        return {
            dosage_strength: null
        }
    },
    created() {
        this.dosage_strength = this.default;
    },
    computed: {
        ...mapState({})
    },
    methods: {
        ...mapActions({}),
        on_strength_selected(dosage_strength) {
            this.$emit('onChange', dosage_strength);
        }

    }
}
</script>
<style lang="scss"></style>
