export function loadGoogleMaps(apiKey, callback) {
    const existingScript = document.getElementById('google-maps-script');

    if (existingScript) {
        existingScript.remove();
    }

    const script = document.createElement('script');

    script.id = 'google-maps-script';
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${apiKey}&callback=initMapCallback`;
    document.head.appendChild(script);

    window.initMapCallback = () => {
        callback();

        // clean up, but make sure it works when the script is loaded multiple times
        delete window.initMapCallback;
    };
}

export function initMap(autocompleteInput, callback, data) {
    const input = autocompleteInput.$refs.input_element;

    const options = {
        componentRestrictions: {
            country: ['us'],
        },
        types: ['address'],
        fields: ['address_components'],
    };

    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(input, options);

    const mapping = {
        street_number: 'street_number',
        route: 'street_address',
        locality: 'city',
        administrative_area_level_1: 'state',
        postal_code: 'zip_code',
        country: 'country',
    };

    const handlePlaceSelection = () => {
        const place = autocomplete.getPlace();
        const newData = { ...data };
        let streetNumber = '';
        let streetName = '';

        place.address_components.forEach((component) =>
            component.types.forEach((type) => {
                if (type in mapping) {
                    if (type === 'street_number') {
                        streetNumber = component.long_name;
                    } else if (type === 'route') {
                        streetName = component.short_name;
                    } else if (type === 'country') {
                        newData.shipping_info.country = component.long_name;
                    } else {
                        newData.shipping_info[mapping[type]] = component.short_name;
                    }
                }
            }),
        );

        newData.shipping_info.street_address = streetNumber
            ? streetNumber + ' ' + streetName
            : streetName;

        Object.assign(data, newData);
        callback();
    };

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(autocomplete, 'place_changed', handlePlaceSelection);
    autocompleteInput.handlePlaceSelection = handlePlaceSelection;
}
