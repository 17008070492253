<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM10.8243 5.17574L10.757 5.11764C10.5482 4.96272 10.2616 4.96081 10.0509 5.1119L9.97574 5.17574L8 7.1512L6.02426 5.17574L5.95697 5.11764C5.74818 4.96272 5.46161 4.96081 5.2509 5.1119L5.17574 5.17574L5.11764 5.24303C4.96272 5.45182 4.96081 5.73839 5.1119 5.9491L5.17574 6.02426L7.1512 8L5.17574 9.97574L5.11764 10.043C4.96272 10.2518 4.96081 10.5384 5.1119 10.7491L5.17574 10.8243L5.24303 10.8824C5.45182 11.0373 5.73839 11.0392 5.9491 10.8881L6.02426 10.8243L8 8.8488L9.97574 10.8243L10.043 10.8824C10.2518 11.0373 10.5384 11.0392 10.7491 10.8881L10.8243 10.8243L10.8824 10.757C11.0373 10.5482 11.0392 10.2616 10.8881 10.0509L10.8243 9.97574L8.8488 8L10.8243 6.02426L10.8824 5.95697C11.0373 5.74818 11.0392 5.46161 10.8881 5.2509L10.8243 5.17574L10.757 5.11764L10.8243 5.17574Z"
            :fill="fill"
        />
    </svg>
</template>

<script>
export default {
    name: 'Shape',
    props: {
        fill: {
            type: String,
            default: '#ffffff',
        },
    },
};
</script>
