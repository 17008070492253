import router from '@/router.js'
import axios from 'axios'
import { analytics, analyticsActions } from '../../helpers/analytics';
import * as Sentry from "@sentry/vue";


const baseUrl = process.env.VUE_APP_BASE_END;

const vm = this;


export const authenticate = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        token: localStorage.getItem('hs_token') || '',
        user: {},
        duplicate: {
            duplicate_value: false,
            error_message: ''
        },
        error: {
            fourHundred: false
        },
        bad_credentials: false,
        server_down: false,
    },
    getters: {
        //check if user has a valid token
        isLoggedIn: (state, getters) => !!state.token && getters.jwtNotExpired,
        //token serialized
        jwt: state => state.token,
        //token -> object
        jwtData: (state, getters) => {
            try {
                return getters.jwt ? JSON.parse(atob(getters.jwt.split('.')[1])) : null;
            } catch (error) {
                return false;
            }
        },
        //check if the token is expired
        jwtNotExpired: (state, getters) => (getters.jwtData.exp > (Date.now() / 1000)),
    },
    mutations: {
        logout(state) {
            state.user = '';
            state.token = '';
        },
        initializeUserStore(state) {
            if (localStorage.getItem('hs_token')) {
                state.token = localStorage.getItem('hs_token');
            }
        },
        auth_error(state) {
            state.duplicate.duplicate_value = true;
            state.duplicate.error_message = 'An account with this email already exists.';
        },
        devError(state) {
            state.error.fourHundred = true;
        },
        setUser(state, userObject) {
            //save user to local storage
            localStorage.setItem('hs_user', JSON.stringify(userObject.user));
            //update state.user
            state.user = userObject.user;
            //update state.token --> router.js checks for store.getters['authenticate/isLoggedIn'
            //we need to update the state.token in order to get past a protected route
            state.token = userObject.token;
        },
        serverDown(state) {
            state.bad_credentials = false;
            state.server_down = true;
        },
        //this only accepts the user object without the populated questionnaire and resets local storage
        updateUser(state, user_object) {
            //make copy of the current user state
            //set the user propoerty of the user state to the returned user object
            let existing_user = state.user;

            if (existing_user !== '') {
                existing_user.user = user_object;
                state.user = existing_user;
                localStorage.setItem('hs_user', JSON.stringify(existing_user));
            } else {
                state.user = user_object;
                localStorage.setItem('hs_user', JSON.stringify(user_object));
            }
        },
        badCredentials(state) {
            state.server_down = false;
            state.bad_credentials = true;
            localStorage.removeItem('hs_token');
        },
        resetBadCredentials(state) {
            state.bad_credentials = false;
        },
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('hs_token', token);
            //set the http headers to the token
            axios.defaults.headers.common['Authorization'] = token;
        }
    },
    actions: {
        loginUser({commit, rootState}, auth_object) {
            localStorage.removeItem('hs_token');
            //reset any of the error messages --> if an error message set to false
            commit('resetBadCredentials');
            //call the login end point with credential payload
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/login/' + rootState.orgId.data,
                    data: auth_object,
                    method: 'POST'
                }).then(response => {
                    const user_object = response.data;
                    commit('updateUser', user_object);
                    const token = response.data.token;
                    commit('setToken', token);
                    rootState.user.user = user_object;
                    resolve(response.data);
                }).catch(err => {
                    console.log(err);
                    localStorage.removeItem('hs_token')
                    //network error
                    if (err.status === 401) {
                        commit('badCredentials');
                    }
                    if (err.status === 500) {
                        commit('serverDown');
                    }
                    reject(err);
                })
            })

        },
        requestNewPassword({commit, rootState}, email) {

            let payload = {
                email_address: email
            }

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/reset/' + rootState.orgId.data + '/password-reset-request',
                    data: payload,
                    method: 'POST'
                }).then(response => {
                    //resolve the promise response
                    resolve(response)
                }).catch(err => {
                    //reject the err response
                    reject(err);
                })
            })
        },
        submitNewPassword({commit, rootState}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + payload.organization + '/' + payload.user_id + '/reset-password-token',
                    data: payload,
                    method: 'PUT'
                }).then(response => {
                    //resolve the promise
                    resolve(response);
                }).catch(err => {
                    //reject the promise err
                    reject(err);
                })
            })
        },
        async createUser({ commit, rootState }, user) {
            try {
                const response = await axios.post(`${baseUrl}/v2/users`, {
                    ...user,
                    organization: rootState.orgId.data,
                });
                const token = response.data.token;

                localStorage.setItem('hs_token', token);
                axios.defaults.headers.common['Authorization'] = token;
                commit('setUser', { user: response.data });

                return response;
            } catch (e) {
                localStorage.removeItem('hs_token');
                if (e.status === 404 || e.status === 500) { commit('devError'); }
                if (e.status === 400) { commit('auth_error'); }
                throw e;
            }
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout');
                localStorage.removeItem('hs_token');
                localStorage.removeItem('hs_user');
                delete axios.defaults.headers.common['Authorization'];
                resolve();
            });
        },
    },
};
