import Vue from 'vue';
import Router from 'vue-router';

import ProductInstance from '@/components/Shop/ProductInstance';
import SignupWrapper from '@/views/SignupWrapper';
import UploadIdentification from '@/views/UploadIdentification';

import ScheduleOnboarding from './components/Dashboard/ScheduleOnboarding/Components/ScheduleOnboarding.vue';
import store from './store';
import AppointmentCallback from './views/AppointmentCallback';
import Authentication from './views/Auth.vue';
import ConfirmationView from './views/ConfirmationView';
import DashboardView from './views/DashboardView.vue';
import IntakeWrapper from './views/IntakeWrapper.vue';
import ResetRequest from './views/ResetRequest.vue';
import SetPasswordReset from './views/SetPasswordReset.vue';
import Shop from './views/Shop.vue';

Vue.use(Router);

const router = new Router({
    scrollBehavior (to, from, savedPosition) {
        // savedPosition is only available for popstate navigations.
        if (savedPosition) return savedPosition;

        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        if (to.params.savePosition) return {};

        // scroll to anchor by returning the selector
        if (to.hash) {
            // specify offset of the element
            // if (to.hash === '#anchor2') {
            //   position.offset = { y: 100 }
            // }
            return { selector: to.hash };
        }

        // scroll to top by default
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            redirect: '/get-started',
        },
        {
            path: '/login',
            name: 'login',
            component: Authentication,
        },
        {
            path: '/product/:product_id',
            name: 'product-landing',
            redirect: '/product/login/:product_id',
        },
        {
            path: '/product/:type/:product_id',
            name: 'product-landing',
            component: Authentication,
        },
        {
            path: '/request-password-reset',
            name: 'request-password-reset',
            component: ResetRequest,
        },
        {
            path: '/request-password-reset/:orgId/:userId/:resetToken',
            name: 'set-password-reset',
            component: SetPasswordReset,
        },
        {
            path: '/get-started',
            name: 'signup-no-query',
            component: Authentication,
        },
        {
            path: '/get-started/:intakeTemplateId',
            name: 'signup-query',
            component: Authentication,
        },
        {
            path: '/signup/:section',
            name: 'signup-wrapper',
            component: SignupWrapper,
        },
        {
            path: '/signup/:section/:productTemplateId',
            name: 'signup-wrapper',
            component: SignupWrapper,
        },
        {
            path: '/shop',
            name: 'shop',
            component: Shop,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:userID/:intakeId/question/:questionId',
            name: 'intake-wrapper',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section(protocol-selection)/:intakeId',
            name: 'protocol-selection',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId',
            name: 'treatment-selection',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId/m/:membershipId',
            name: 'membership-signup',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId',
            name: 'treatment-checkout',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId/l/:labProductId',
            name: 'lab-checkout',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId/p/:productTemplateId/:product/t/:photoType',
            name: 'identification-step',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId/t/:photoType',
            name: 'identification-step-alt',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId/p/:productTemplateId/:product',
            name: 'intake-default',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/consultation/:section/:intakeId',
            name: 'intake-default-alt',
            component: IntakeWrapper,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/dashboard/:userId/:page',
            name: 'dashboard',
            component: DashboardView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/dashboard/:userId/:page/:id',
            name: 'analysis',
            component: DashboardView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/dashboard/:userId/:page/intake/:intakeId/:section',
            name: 'photoreupload',
            component: DashboardView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/messages/:userId',
            redirect: '/dashboard/:userId/messages',
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/messages/:userId/:conversationId',
            redirect: '/dashboard/:userId/messages',
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/start/:intakeTemplateId/p/:productTemplateId',
            name: 'start-product-instance',
            component: ProductInstance,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/verification/:userId/:steps',
            name: 'verification',
            component: UploadIdentification,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/lab-instructions/:labId?',
            name: 'lab-instructions',
            component: ConfirmationView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/appointment/cb',
            name: 'appointment-cb',
            component: AppointmentCallback,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/notifications',
            name: 'notifications',
            component: DashboardView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/schedule-onboarding',
            name: 'scheduleOnboarding',
            component: ScheduleOnboarding,
            meta: {
                requiresAuth: true,
            },
        },
    ],
});

router.beforeResolve(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        // if there is no token or the token is expired or invalid, go to login
        if (!store.getters['authenticate/isLoggedIn']) {
            next('login');
        }

        // if no orgId we should fetch it since it's being used everywhere including /patient request
        if (!store.state.orgId.data) {
            await store.dispatch('initializeOrganizationId', window.location.host);
        }

        if (!store.state.user?.user?._id) {
            try {
                if (to.params['userId']) {
                    await store.dispatch('user/retrieveUser', { user: to.params['userId'] });
                } else next('login');
            } catch (error) {
                next('login');
            }
        }

        next();
    } else {
        next();
    }
});

export default router;
