<template>
<div class="appointment__items">
    <div class="appointment__header-group">
        <div class="appointment__header">
            <h4 class="headline-3">Appointments</h4>
            <p class="appointment__desc">View your appointments below.</p>
        </div>
        <div v-if="allow_booking" class="appointment__action">
            <button class="zp-btn" @click="bookAppointment()">Book Appointment</button>
        </div>
    </div>
    <div class="appointment__items-group">
        <appointment-item v-for="appointment in appointments.docs" :appointment="appointment"></appointment-item>
    </div>
</div>
</template>
<script>
import AppointmentItem from "@/components/Dashboard/Appointments/AppointmentItem";

export default {
    name: 'AppointmentItems',
    props: ['appointments'],
    components: {
        AppointmentItem: AppointmentItem
    },
    data() {
        return {
            allow_booking: false
        }
    },
    created() {},
    computed: {},
    methods: {
        bookAppointment() {
            this.$emit('onBookAppointment', true);
        }
    }
}
</script>
<style lang="scss">
.appointment {
    &__items-group {
        margin-top: 3rem;
    }
}
</style>
