<template>
    <div ref="elementRef" class="biomarker">
        <div class="biomarker-header">
            <div class="biomarker-header__top">
                <div class="biomarker-header__name" @click="toggleComponent">
                    <span>{{ item.name }}</span>
                    <ChevronRt />
                </div>
                <div class="biomarker-header__unit text-dim-80">
                    {{ item.unit }}
                </div>
            </div>
            <div v-if="myRange.type !== 'no_range'" class="biomarker-header__right">
                <h-badge :label="myRange.name" :color="badgeColor" small dark />
            </div>
        </div>
        <div class="biomarker-body">
            <lab-bar-chart :ranges="item.ranges" :my-value="formattedValue" :my-range="myRange.type" />
        </div>
        <div class="biomarker-footer" @click="toggleComponent">
            <div>Our Assessment</div>
        </div>
        <h-modal :open="showChildComponent" :on-close="toggleComponent">
            <div class="biomarker-details">
                <div class="biomarker-details__header">
                    <div class="biomarker-details__header--top">
                        <h3>{{ item.name }}</h3>
                        <p class="biomarker-details__header--mb text-dim-60">
                            {{ item.unit }}
                        </p>
                        <h-badge
                            v-if="myRange.type !== 'no_range'"
                            :label="myRange.name"
                            :color="badgeColor"
                            small
                            dark
                        />
                    </div>
                    <p class="biomarker-details__header--dt text-dim-60">
                        {{ item.unit }}
                    </p>
                </div>
                <div class="biomarker-details__chart">
                    <lab-bar-chart :ranges="item.ranges" :my-value="formattedValue" :my-range="myRange.type" />
                </div>
                <div v-if="myRange.type !== 'no_range'" class="biomarker-legend biomarker-legend--mobile" :class="expandClass" @click="toggleLegend('legend')">
                    <div class="biomarker-legend__label">
                        <span>Legend</span>
                        <span class="biomarker-legend__icon">
                            <ChevronUp v-if="show_legend" fill="#599BEA" />
                        </span>
                    </div>
                    <div class="biomarker-legend--hidden" :class="{'biomarker-legend--show': show_legend}">
                        <div v-if="show_legend">
                            <div v-for="range in item.ranges" :key="range.type" class="biomarker-legend__item">
                                <span class="biomarker-legend__color" :class="range.type" />
                                <span class="h-caption text-dim-60">{{ getMyRangeName(range.type) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="myRange.type !== 'no_range'" class="biomarker-legend biomarker-legend--desktop">
                    <div v-for="range in item.ranges" :key="range.type" class="biomarker-legend__item">
                        <span class="biomarker-legend__color" :class="range.type" />
                        <span class="h-caption">{{ getMyRangeName(range.type) }}</span>
                    </div>
                </div>
                <div v-if="item.legend.suggestion">
                    <span class="medium">Our Suggestion: </span>{{ item.legend.suggestion }}
                </div>
                <div v-if="item.legend.description">
                    <span class="medium">About this biomarker: </span>{{ item.legend.description }}
                </div>
            </div>
        </h-modal>
    </div>
</template>

<script>
import ChevronRt from '@/assets/icons/ChevronRt.vue';
import ChevronUp from '@/assets/icons/ChevronUp.vue';
import HBadge from '@/components/Shared/HBadge.vue';
import HModal from '@/components/Shared/HModal.vue';
import { badgeColorMapping, rangeNameMapping } from '@/helpers/labs';

import LabBarChart from './LabBarChart.vue';

export default {
    name: 'LabBiomarker',
    components: {
        LabBarChart,
        HModal,
        HBadge,
        ChevronRt,
        ChevronUp,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			show_legend: false,
			open_modal: false,
			showChildComponent: false,
		};
    },
	computed: {
		myRange() {
			const external = this.item.ranges.length === 1 && this.item.ranges[0].type === 'normal';
			for (const range of this.item.ranges) {
				if (external && (range.min === undefined || range.min === null) && (range.max === undefined || range.max === null)) {
					return {
						type: 'no_range',
						name: this.getMyRangeName('normal')
					}
				} else if (this.item.value >= range.min && this.item.value <= range.max) {
					return {
						type: range.type,
						name: this.getMyRangeName(range.type)
					}
				} else if ((range.min == null || range.min <= this.item.value) && this.item.value <= range.max) {
					return {
						type: range.type,
						name: this.getMyRangeName(range.type)
					}
				} else if ((!range.max || range.max === null) && range.max !== 0 && this.item.value >= range.min) {
					return {
						type: range.type,
						name: this.getMyRangeName(range.type)
					}
				} else if (this.item.value < 0) {
					if (range.min === 0) {
						return {
							type: range.type,
							name: this.getMyRangeName(range.type)
						}
					}
				}
			}
		},
		formattedValue() {
			const value = parseFloat(this.item.value.toFixed(2));
			return value < 0 ? 0 : value;
        },
		expandClass() {
			if (this.show_legend) {
                const name = `biomarker-legend--expand${this.item.ranges.length}`;
				return name;
			}
			return '';
		},
        badgeColor() {
			return badgeColorMapping[this.myRange.type] || '';
        },
    },
    methods: {
        toggleComponent() {
            this.showChildComponent = !this.showChildComponent;
            this.show_legend = false;
        },
        getMyRangeName(type) {
            return rangeNameMapping[type] || '';
        },
        toggleLegend(path) {
            if (path === 'modal') {
                this.show_legend = false;
            } else {
                this.show_legend = !this.show_legend;
            }
        },
    },
};
</script>
