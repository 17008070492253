<template>
	<button
		:type="type && type"
		:disabled="disabled || loading"
		:class="{
			wide: wide,
			'full-width': full_width,
			large: large,
			primary: variant === 'primary',
			secondary: variant === 'secondary',
			tertiary: variant === 'tertiary',
			'secondary-cta': variant === 'secondary-cta',
			// @deprecated, use one of the above variants and cta
			ctaVariant: variant === 'cta',
			cta: !!cta,
			loading: loading,
			'loading loading__primary': loading && (variant === 'primary' || variant === 'cta'),
			disabled: disabled,
            small: small,
		}"
	    @click="handleClick ? handleClick() : null"
	>
		<span class="label">{{ label }}</span>
	</button>
</template>

<script>
export default {
	name: 'HButton',
	props: {
		type: {
			type: String,
		},
		label: {
			type: String,
			required: true,
		},
		handleClick: {
			type: Function,
			required: false,
		},
		variant: {
			type: String,
			default: 'primary',
		},
		cta: {
			type: Boolean,
		},
		wide: {
			type: Boolean,
		},
		full_width: {
			type: Boolean,
		},
		large: {
			type: Boolean,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
		},
        small: {
            type: Boolean,
        },
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

button {
	position: relative;
	display: block;
	white-space: nowrap;
	min-height: 40px;
	border: 1px solid transparent;
	border-radius: 20px;
	outline: none !important;
	background: none;
	color: $black-85;
	font-family: 'ZP-UI', sans-serif;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	text-align: center;
	padding: 4px 16px;
	margin: 0;
	transition-property: all !important;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;
	cursor: pointer;
}

.wide {
	min-width: 140px;
}

.full-width {
	width: 100%;
}

.large {
	font-size: 1rem;
	padding: 1rem 1rem;
	border-radius: 35px;
}
.primary {
	background: $charcoal;
	color: $white;
	font-weight: 500;

	&:hover {
		background-color: $charcoal-hover;
	}
}

.secondary {
	background: $white-100;
	border: 1px solid $border-default;

	&:hover {
		border-color: $black-100;
		background-color: $white-100;
		color: $black-100;
	}
}

.secondary-cta {
	background: $white-100;
	border: 2px solid $charcoal;

	&:hover {
		border-color: $charcoal;
		background-color: $charcoal;
		color: $white-100;
	}
}

.tertiary {
	border: none;

	&:hover {
		text-decoration: underline;
	}
}

.ctaVariant {
	font-family: "Helvetica Now Display", serif;
    font-size: 0.875rem;
    letter-spacing: 0.2rem;
    font-weight: 800;
    text-transform: uppercase;
	background: $charcoal;
	color: $white-100;

	&:hover {
		background-color: $charcoal-hover;
		color: $white-100;
	}
}

.cta {
	font-family: 'Helvetica Now Micro', serif;
    font-size: 0.875rem;
    letter-spacing: 0.075rem;
    font-weight: 700;
    text-transform: uppercase;
}

.small {
	font-size: 0.75rem;
}

.active {
	border-width: 2px !important;
	border-color: $black-85 !important;
	background: $gray-75 !important;
}

.loading {
	.label {
		visibility: hidden;
	}

	&:after {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 1px solid transparent;
		border-top-color: $black-85;
		border-radius: 50%;
		margin: auto;
		animation: button-loading-spinner 1s ease infinite;
	}

	&__primary {
		&:after {
			border: 2px solid transparent;
			border-top-color: $white-100;
		}
	}

	@keyframes button-loading-spinner {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

.disabled {
	background: $disabled;
	color: $gray-700;
	cursor: not-allowed;

	&:hover {
		background: $disabled
	}
}
</style>
