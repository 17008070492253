<template>
    <div class="subscription-modal__container">
        <modal-header-icon icon="cube" :title="'Welcome Back'" @onClose="on_close"></modal-header-icon>
        <div class="subscription-modal__body">
            <div class="subscription-modal__view" v-if="view === 'options'">
                <p class="ppe-small-title">What would you prefer to do?</p>
                <div class="subscription-modal__form">
                    <action-card
                        v-if="subscription.type === 'medication'"
                        title="Resume and ship now"
                        description="Resume your treatment and ship your medication"
                        :has_icon="true"
                        icon="arrow-forward-outline"
                        @onAction="option_selected('resume_now')"
                    ></action-card>
                    <action-card
                        v-if="subscription.type === 'medication'"
                        :title="resume_renewal"
                        description="Resume your treatment and ship your medication on your next renewal"
                        :has_icon="true"
                        icon="arrow-forward-outline"
                        @onAction="option_selected('resume_renewal')"
                    ></action-card>
                </div>
            </div>
            <div class="subscription-modal__view" v-if="view === 'resume_renewal'">
                <modal-confirm
                    title="Are you sure you want to resume your subscription?"
                    :confirm_button_text="button_text.resume"
                    :is_submitting="update_subscription.loading"
                    cancel_button_text="Cancel"
                    @onCancel="on_close"
                    @onConfirm="on_confirm('resume_renewal')">
                </modal-confirm>
            </div>
            <div class="subscription-modal__view" v-if="view === 'resume_now'">
                <modal-confirm
                    title="Are you sure you want to resume your subscription and ship now?"
                    :confirm_button_text="button_text.resume_now"
                    :is_submitting="update_subscription.loading"
                    cancel_button_text="Cancel"
                    @onCancel="on_close"
                    @onConfirm="on_confirm('resume_now')">
                </modal-confirm>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import ModalHeaderIcon from "@/components/Dashboard/Modals/Components/ModalHeaderIcon.vue";
import ActionCard from "@/components/Shared/ActionCard.vue";
import ModalConfirm from "@/components/Dashboard/Modals/Components/ModalConfirm.vue";

export default {
    name: 'SubscriptionResumeModal',
    components: {
        ModalHeaderIcon: ModalHeaderIcon,
        ActionCard: ActionCard,
        ModalConfirm: ModalConfirm
    },
    props: ['subscription', 'vendor'],
    data() {
        return {
            resume_renewal: null,
            view: 'options'
        }
    },
    created() {
        this.resume_renewal = `Resume and ship on ${this.$options.filters.unix_date(this.vendor.current_period_end, 'MMM Do') }`
    },
    computed: {
        ...mapState({
            button_text: state => state.subscriptions.buttons,
            update_subscription: state => state.subscriptions.update_subscription
        })
    },
    methods: {
        ...mapActions({}),
        on_close() {
            this.$emit('onModalClose', false);
        },
        on_confirm(view) {
            this.$emit('onResumeSubscriptionSubmit', view);
        },
        option_selected(view) {
            this.view = view;
            this.$forceUpdate();
        }
    }
}
</script>
<style lang="scss">
.subscription-resume {
    &__container {

    }
}
</style>
