import { analytics, analyticsActions } from './analytics';

export async function startVisitMethod(intakeTemplateId, product = null) {
    window.localStorage.removeItem('zp_product_template');

    const hasIntakeInstance = this.incompleteIntakes?.data?.length && this.incompleteIntakes.data.find((visit) => visit.template._id === intakeTemplateId);

    analytics.track(analyticsActions.start_intake_visit_click, {
        source: 'shop',
        product_name: product ? product.product_name : null,
        product_id: product ? product._id : null,
        intakeTemplateId,
        hasIntakeInstance: !!hasIntakeInstance,
    });

    if (hasIntakeInstance) {
        this.existingIntakeVisit(this.user._id, hasIntakeInstance.intake._id);
    } else {
        const intakeTemplate = await this.getIntakeTemplate({ templateId: intakeTemplateId });
        
        await this.newIntakeInstance(intakeTemplate);
    }
}
