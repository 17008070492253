<template>
    <div class="cardbase">
        <slot name="header" />
        <div class="cardbase-main">
            <div class="cardbase-main__image">
                <img :src="imgSrc" alt="">
            </div>
            <div v-if="textContent">
                <p v-if="title" class="cardbase-main__title">
                    {{ title }}
                </p>
                <p v-if="subtitle" class="cardbase-main__sub">
                    {{ subtitle }}
                </p>
                <div v-if="description" class="cardbase-main__description">
                    {{ descriptionText }}
                </div>
            </div>
        </div>
        <slot name="footer" />
    </div>
</template>

<script>
import {truncateText} from '@/helpers/formatting.js';

export default {
    name: 'HCardBase',
    props: {
        imgSrc: {
            type: String,
            required: true,
        },
        textContent: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        descriptionLength: {
            type: Number,
            default: 182,
            }
        },
    computed: {
        descriptionText() {
            return truncateText(this.description, this.descriptionLength);
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.cardbase {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-default;
    border-radius: 0;
    padding: 1.5rem;
    @extend %helveticaNowText;

    @media (min-width: 480px) {
        border-radius: 1.5rem;
    }

    @media (min-width: 1280px) {
        max-width: 380px;
    }

    @media (min-width: 1440px) {
        max-width: 416px;
    }
}

.cardbase-main {
    padding: 0.75rem 0;
    p {
        margin: 0;
    }

    &__image {
        display: flex;
        justify-content: center;
        margin-bottom: 0.75rem;

        img {
            width: auto;
            height: 223px;
            object-fit: cover;
        }
    }

    &__title {
        font-weight: 700;
        @extend %helveticaNowText;
    }

    &__sub {
        font-weight: 700;
        color: $neutral-400;
        padding-top: 0.35rem;
    }

    &__description {
        @extend %body;
        border-top: 1px solid $charcoal;
        padding-top: 0.75rem;
        margin-top: 0.75rem;
    }

    @media (min-width: 680px) {
        img {
            width: auto;
            height: 368px;
        }
    }

    @media (min-width: 1024px) {
        img {
            width: 368px;
            height: 368px;
        }
    }
}
</style>
