<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7 14V9C7 7.34315 8.34315 6 10 6H14C15.6569 6 17 7.34315 17 9V14" stroke="#242E49" stroke-width="2" />
        <path d="M7 14V15C7 16.6569 5.65685 18 4 18H3" stroke="#242E49" stroke-width="2" />
        <path d="M17 14V15C17 16.6569 18.3431 18 20 18H21" stroke="#242E49" stroke-width="2" />
        <path d="M4 18H20" stroke="#242E49" stroke-width="2" />
        <path d="M12 6V3" stroke="#242E49" stroke-width="2" />
        <path d="M10 18V19.5C10 20.3284 10.6716 21 11.5 21H12.5C13.3284 21 14 20.3284 14 19.5V18" stroke="#242E49" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'NotificationIcon',
};
</script>

<style lang="scss" scoped>

</style>
