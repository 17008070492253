<template>
    <svg
        fill="none"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.707 4.293a1 1 0 0 1 0 1.414L9.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0Z"
            :fill="fill"
        />
    </svg>
</template>

<script>
export default {
    name: 'ChevronLeft',
    props: {
        fill: {
            type: String,
            default: '#242E49',
        },
    },
};
</script>
