<template>
    <div>
        <h2 class="protocol-header">
            Medication Type
        </h2>
        <div class="treatment-selection__caption protocol-caption n-400">
            Select which medication type best suits your needs.
        </div>
        <div class="treatment-selection">
            <div
                class="treatment-selection__option"
                :class="{'active': activeTemplateId === GENERIC_RAPAMYCIN_TEMPLATE_ID}"
                @click="handleSelectIntakeTemplate(GENERIC_RAPAMYCIN_TEMPLATE_ID)"
            >
                <div class="treatment-selection__option-header" :class="{'active': activeTemplateId === GENERIC_RAPAMYCIN_TEMPLATE_ID}">
                    <p class="protocol-body480 strong">
                        Generic Rapamycin
                    </p>
                </div>
                <div
                    class="protocol-caption"
                    :class="{'active': activeTemplateId === GENERIC_RAPAMYCIN_TEMPLATE_ID}"
                >
                    Generic rapamycin is an FDA-approved medication with consistent bioavailability and absorption, produced under strict standards to ensure predictable effects.
                </div>
                <div class="treatment-selection__badge--mobile">
                    <h-badge
                        label="Recommended"
                        color="#55D291"
                        dark
                        small
                        no_border
                    />
                </div>
                <div class="treatment-selection__badge--desktop">
                    <h-badge
                        label="Recommended"
                        color="#55D291"
                        dark
                        small
                        no_border
                    />
                </div>
            </div>
            <div
                class="treatment-selection__option"
                :class="{'active': activeTemplateId === COMPOUNDED_RAPAMYCIN_TEMPLATE_ID}"
                @click="handleSelectIntakeTemplate(COMPOUNDED_RAPAMYCIN_TEMPLATE_ID)"
            >
                <div class="treatment-selection__option-header" :class="{'active': activeTemplateId === COMPOUNDED_RAPAMYCIN_TEMPLATE_ID}">
                    <p class="protocol-body480 strong">
                        Compounded Enteric Coated Rapamycin
                    </p>
                </div>
                <div
                    class="protocol-caption"
                    :class="{'active': activeTemplateId === COMPOUNDED_RAPAMYCIN_TEMPLATE_ID}"
                >
                    Compounded rapamycin may be an option for those seeking an alternative to generic versions. However, due to its minimal overall absorption compared to generic rapamycin and lower clinical efficacy, higher doses are typically required to achieve similar effects.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HBadge from '@/components/Shared/HBadge.vue';

export default {
    name: 'TreatmentCompoundedRapamycin',
    components: {
        HBadge,
    },
    props: ['onSelectIntakeTemplate', 'activeTemplateId'],
    data() {
        return {
            GENERIC_RAPAMYCIN_TEMPLATE_ID: process.env.VUE_APP_GENERIC_RAPAMYCIN_TEMPLATE_ID,
            COMPOUNDED_RAPAMYCIN_TEMPLATE_ID: process.env.VUE_APP_COMPOUNDED_RAPAMYCIN_TEMPLATE_ID,
        };
    },
    computed: {},
    methods: {
        handleSelectIntakeTemplate(intakeId) {
            this.$emit('onSelectIntakeTemplate', intakeId);
        },
    },
};
</script>
<style lang="scss">
    @import '@/assets/scss/_type.scss';
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/utility.scss';

    .treatment-selection {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin: 0;
        padding: 0;
        margin-top: 26px;
        margin-bottom: 24px;

        &__option {
            display: flex;
            flex-direction: column;
            align-items: start;
            list-style: none;
            position: relative;
            border: 1px solid $border-default;
            border-radius: 0.75rem;
            background-color: $white-100;
            color: $charcoal;
            padding: 1.5rem 1rem;
            transition: all 0.3s ease;
            cursor: pointer;

            &.active {
                color: $white-100;
                background-color: $health_orange;
                border: 1px solid $health_orange;
            }
        }

        &__input {
            display: flex;
            align-items: center;

            input {
                position: absolute;
                opacity: 0;
            }
        }

        &__option-header {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            width: 100%;
            border-bottom: 1px solid $border-default;
            padding-bottom: 1.25rem;
            margin-bottom: 1.25rem;

            &.active {
                border-bottom: 1px solid rgba($urgent-red-100, 0.3);
            }
        }

        &__badge {
            &--mobile {
                position: absolute;
                top: 1.875rem;
                right: 1.875rem;
            }

            &--desktop {
                display: none;
            }
        }

        &__caption {
            margin: 12px 0;
        }

        @media (min-width: 480px) {
            &__badge {
                &--mobile {
                display: none;
                }

                &--desktop {
                    display: block;
                    position: absolute;
                    top: 1.875rem;
                    right: 1.875rem;
                }
            }
        }

        @media (min-width: 1024px) {
            &__option {
                padding: 2rem;
            }
        }
    }
</style>
