<template>
    <div class="co-item">
        <div class="co-item-details">
            <div class="co-item-details__image">
                <img v-if="imageSrc" :src="imageSrc" :alt="product?.name">
            </div>
            <div class="co-item-details__details">
                <div class="co-item-details__descriptions">
                    <div class="co-item-details__name">
                        <template v-if="!isLoading && product?.product_name">
                            {{ product?.product_name }}
                        </template>
                        <skeleton-base v-else width="100px" height="20px" />
                    </div>
                    <div>
                        <template v-if="!isLoading && formattedTotal">
                            {{ formattedTotal }}
                        </template>
                        <skeleton-base v-else width="40px" height="14px" />
                    </div>
                    <div v-if="!isLoading && price?.savings > 0 && formattedSavings" class="co-item-details__savings">
                        {{ formattedSavings }} Savings
                    </div>
                </div>
                <div v-if="recurringDuration" class="co-item-details__renew">
                    <img src="@/assets/icons/restart.svg" alt="recurring-icon">
                    <span>Renews every {{ recurringDuration }} {{ recurringDuration > 1 ? 'months' : 'month' }}</span>
                </div>
            </div>
        </div>
        <div v-if="product?.product_features?.length" class="co-item-more" @click="toggleMore">
            <div class="co-item-more__header">
                <div>More Information</div>
                <div class="co-item-more__chevron" :class="{active: isMoreExpanded}">
                    <ion-icon name="chevron-down-outline" />
                </div>
            </div>
            <transition name="toggleExpand">
                <div v-show="isMoreExpanded" class="co-item-more__content">
                    <ul>
                        <li v-for="feature in product.product_features" :key="feature.title">
                            <p class="strong">
                                {{ feature.title }}
                            </p>
                            <p>
                                {{ feature.description }}
                            </p>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import SkeletonBase from '@/components/Shared/Skeletons/Base/SkeletonBase.vue';

export default {
    name: 'SummaryItem',
    components: {
        SkeletonBase,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        recurringDuration: {
            type: Number,
            default: 0,
        },
        price: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isLoading: true,
            isMoreExpanded: false,
        };
    },
    computed: {
        imageSrc() {
            return this.product?.product_images?.[0] || null;
        },
        formattedTotal() {
            return `$${this.price?.total.toFixed(2)}`;
        },
        formattedSavings() {
            return `$${this.price?.savings.toFixed(2)}`;
        },
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },
    methods: {
        toggleMore() {
            this.isMoreExpanded = !this.isMoreExpanded;
        },
    },
};
</script>
