<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="none"
    >
        <path :fill="fill" d="M5.4 6.6a.6.6 0 0 1 1.2 0v1.8a.6.6 0 0 1-1.2 0V6.6ZM6 3.3a.9.9 0 1 0 0 1.8.9.9 0 0 0 0-1.8ZM0 6a6 6 0 1 1 12 0A6 6 0 0 1 0 6Zm6-4.8a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6Z" />
    </svg>
</template>

<script>
export default {
    name: 'ChevronBackIcon',
    props: {
        fill: {
            type: String,
            default: '#212931',
        },
    },
};
</script>
