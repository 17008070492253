<template>
    <div class="item__container">
        <div class="details">
            <div class="image">
                <img v-if="img_url" :src="img_url">
                <ion-icon v-else name="flask-outline" />
            </div>
            <div class="descriptions">
                <slot name="descriptions" />
            </div>
        </div>
        <slot name="status" />
        <div class="actions">
            <h-button
                v-if="primary_button"
                :label="primary_button.label"
                :wide="true"
                variant="primary"
                :loading="primary.loading"
                :handle-click="(variant, name) => handleClick(primary_button, 'primary') "
            />
            <h-button
                v-if="secondary_button"
                :label="secondary_button.label"
                :wide="true"
                variant="secondary"
                :handle-click="(variant, name) => handleClick(secondary_button, 'secondary')"
            />
            <h-button
                v-if="tertiary_button"
                :label="tertiary_button.label"
                :wide="true"
                variant="tertiary"
                :handle-click="(variant, name) => handleClick(tertiary_button, 'tertiary')"
            />
        </div>
    </div>
</template>

<script>
import HButton from './HButton.vue';

export default {
	name: 'HListItem',
	components: {
		HButton,
	},
    data() {
        return {
            primary: {
                loading: false,
            },
			secondary: {
				loading: false,
			},
			tertiary: {
				loading: false,
			},
        }
    },
	props: {
		id: {
			type: String,
		},
		img_url: {
			type: String,
		},
		primary_button: {
			type: [Object, Boolean],
			default: () => {},
		},
		secondary_button: {
			type: Object,
			default: () => {},
		},
		tertiary_button: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		async handleClick(variant, name) {

            this[name].loading = true;
            try {
                let response = await variant.method();

                this.$emit(`${name}Success`, {
                    variant: variant,
                    name: name,
                    response: response
                });
                this[name].loading = false;
            } catch (e) {
                this.$emit(`${name}Error`, {
                    variant: variant,
                    name: name,
                    error: e
                });
                this[name].loading = false;
            }


            this.$forceUpdate()
        }
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.item {
	&__container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		background-color: $white-100;
		border: 1px solid $border-light;
		border-radius: 12px;
		padding: 1.5rem;
		transition: all 0.3s ease;

		&:hover {
			box-shadow: rgb(0 0 0 / 8%) 0 0.25rem 0.75rem;
		}

		@media (min-width: 1024px) {
			flex-direction: row;
			gap: 4rem;
			padding: 2rem;
		}
	}
}

.details {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	@media (min-width: 640px) {
		flex-direction: row;
		margin-bottom: 1rem;
	}
}

.image {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	margin: 0 auto;

	ion-icon {
		font-size: 2rem;
		--ionicon-stroke-width: 1.25rem;
		color: #6a6a6a;
	}

	img {
		width: 150px;
		height: 150px;
		object-fit: cover;
		border: 1px solid $border-light;
		border-radius: 12px;
	}

	@media (min-width: 760px) {
		margin: 0;
	}
}

.descriptions {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__title {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 0.875rem;

		p {
			@extend %body;
			font-weight: 700;
			margin: 0;
		}
	}

	&__caption {
		@extend %caption;
		font-size: 0.75rem;
		margin-bottom: 1.35rem;
	}

	@media (min-width: 1024px) {
		&__title {
			margin-bottom: 0;
		}

		&__caption {
			order: 3;
			margin-bottom: 0;
		}
	}
}

.status {
	width: 100%;

	@media (min-width: 1024px) {
		display: none;
	}
}

.message {
	@extend %caption;
	border-radius: 0.75rem;
	background-color: rgba($powder-blue-100, .3);
	padding: 1rem;
	margin-top: 1rem;
}

.actions {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 1rem;

	button {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media (min-width: 1024px) {
		width: auto;
		margin: 0;
	}
}

.mobile {
	@media (min-width: 1024px) {
		display: none;
	}
}

.desktop {
	display: none;

	@media (min-width: 1024px) {
		display: block;
	}
}
</style>
