<template>
    <div class="confirm-container">
        <confirmation-nav />
        <hero-component :page-type="pageType" />
        <div class="confirm-main">
            <schedule :page-type="pageType" />
            <preparation />
            <visit :page-type="pageType" />
            <results />
            <HButton full-width @click="handleReturn">
                Return to labs
            </HButton>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import HButton from '@/components/Shared/HButtonV2';

import HeroComponent from './SubComponents/Hero.vue';
import ConfirmationNav from './SubComponents/Nav.vue';
import Preparation from './SubComponents/Preparation.vue';
import Results from './SubComponents/Results/Results.vue';
import Schedule from './SubComponents/Schedule/Schedule.vue';
import Visit from './SubComponents/Visit.vue';

export default {
    name: 'LabSuccess',
    components: {
        ConfirmationNav,
        HeroComponent,
        Preparation,
        Results,
        Visit,
        Schedule,
        HButton,
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        pageType() {
            return this.$route.params.labId ? 'success' : 'instructions';
        },
    },
    methods: {
        handleReturn() {
            this.$router.push({ path: `/dashboard/${this.user._id}/labs` });
        },
    },
};
</script>
