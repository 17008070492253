<template>
    <div>
        <OrganizationProducts></OrganizationProducts>
    </div>
</template>

<script>

import OrganizationProducts from '../components/Shop/SubComponents/OrganizationProducts';

export default {
    components: {
        OrganizationProducts,
    },
};
</script>
