<template>
    <div class="h-progress__base">
        <div class="h-progress__overlay" :style="{right: 100 - currentProgress + '%'}"></div>
    </div>
</template>

<script>
    export default {
        name: 'HProgressBar',
        props: {
            current_step: {
                type: Number,
                required: true,
            },
            total_steps: {
                type: Number,
                required: true,
            },
        },
        computed: {
            currentProgress() {
                return (this.current_step / this.total_steps) * 100;
            },
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.h-progress {
    &__base {
        position: relative;
        height: 3px;
        width: 100%;
        background-color: $gray-200;
        border-radius: 5px;
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 5px;
        background-color: $charcoal;
        transition: right 0.3s ease-in-out;
    }
}
</style>