<template>
	<div>
        <div class="zp-block-youtube-container">
            <youtube :video-id="block.youtube_id" player-width="100%" player-height="auto"></youtube>
        </div>
    </div>
</template>
<script>
export default {
	name: 'YoutubeBlock',
    props: ['block'],
	components: {},
	data() {
		return {

		}
	},
	created() {
	},
	computed: {},
	methods: {}
}
</script>
<style lang="scss">

</style>
