<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="5"
            y="4"
            width="14.6667"
            height="16.5"
            rx="2"
            stroke="#212931"
            stroke-width="1.5"
        />
        <path d="M8.33325 15.8667H13.6666" stroke="#212931" stroke-width="1.5" />
        <path d="M8.33325 9.19995H16.3333" stroke="#212931" stroke-width="1.5" />
        <path d="M8.33325 12.5333H16.3333" stroke="#212931" stroke-width="1.5" />
    </svg>
</template>

<script>
export default {
    name: 'OrderIcon',
};
</script>
