<template>
    <div class="authinfo">
        <div class="authinfo__header">
            <div v-if="organization_settings.data && organization_settings.data.logo" class="authinfo__logo">
                <span class="organization__logo" :style="{ 'background-image': 'url(' + organization_settings.data.logo + ')' }"></span>
            </div>
            <div v-if="organization_settings.data && !organization_settings.data.logo" class="">
                <span>{{ organization_settings.data.name }}</span>
            </div>
            <div v-if="type === 'signup' && product_name" class="authinfo__badge">
                <h-badge :label="product_name" :color="brand_color()" />
            </div>
            <div>
                <h2>{{ getTitle() }}</h2>
                <p>{{ getDescription() }}</p>
            </div>
        </div>
        <div><slot></slot></div>
        <div v-if="type !== 'reset' && type !== 'new_password' && benefits">
            <benefits :features="organization_settings.data.signup.features" />
        </div>
    </div>
</template>

<script>
import Benefits from '@/components/Auth/Benefits';
import HBadge from '@/components/Shared/HBadge.vue';

export default {
    name: 'AuthInfo',
    components: {
        HBadge,
        Benefits,
    },
    props: {
        type: {
            type: String,
        },
        organization_settings: {
            type: Object,
        },
        product_name: {
            type: String,
        },
    },
    computed: {
        benefits() {
            return this.organization_settings.data && this.organization_settings.data.signup && this.organization_settings.data.signup.features;
        },
    },
    methods: {
        brand_color() {
            return this.organization_settings.data && this.organization_settings.data.brand ? this.organization_settings.data.brand.primary_color : '#93ba79';
        },
        getTitle() {
            if (this.type === 'reset' || this.type === 'new_password') return 'Reset your password';

            if (this.type === 'signup') {
                return this.organization_settings.data && this.organization_settings.data.signup && this.organization_settings.data.signup.title || 'Get started with your health optimization visit';
            } else {
                return 'Welcome Back';
            } 
        },
        getDescription() {
            if (this.type === 'reset') return 'Enter the email address and password associated with your account';
            if (this.type === 'new_password') return 'Enter your new password';

            if (this.type === 'signup') {
                return this.organization_settings.data && this.organization_settings.data.signup && this.organization_settings.data.signup.description || 'This is an opportunity for you to tell your doctor about your health, medical history, and lifestyle. Your doctor will use this information to evaluate your overall health and, if appropriate, prescribe medication to optimize your health.';
            } else {
                return 'Let’s get you logged in';
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';

.authinfo {
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex: 1.25;
    overflow-y: scroll;
    padding: 1rem;

    &__header {
        max-width: 500px;
        text-align: center;
        padding: 2.625rem 0 1.875rem;
        margin: 0 auto;

        h2 {
            @extend %headingSm;
            margin-bottom: 1.25rem;
        }

        p {
            @extend %bodySm;
            margin: 0;
        }
    }

    &__logo {
        max-width: 500px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    &__badge {
        display: flex;
        justify-content: center;
        padding: 1.375rem 0 1.875rem;
    }

    @media (min-width: 1280px) {
        flex: 1;
        padding: 2rem 10rem 1.5rem;

        &__logo {
            justify-content: flex-start;
        }

        &__badge {
            justify-content: flex-start;
            border-bottom: none;
            padding: 2.25rem 0 2.125rem;
        }

        &__header {
            width: 500px;
            text-align: left;
            padding: 0 0 1rem;

            p {
                font-size: 1.125rem;
                line-height: 1.5rem;
                letter-spacing: -0.0225rem;
            }
        }
    }
}
</style>
