const Pusher = require("pusher-js/with-encryption");

let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY,
    {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        authEndpoint: process.env.VUE_APP_BASE_END + '/pusher/auth'
    }
);

export const pusherChannels = {
    namespaced: true,
    state: {
        pusher: pusher,
        /* {
            channelName: {
                channelName: String of payload channel,
                channel: Channel Object from Pusher,
                events: {
                    eventName: [array of functions handlers]
                }
            }
        } */
        channels: {}
    },
    mutations: {
        addChannel(state, newChannel) {
            state.channels[newChannel.channelName] = newChannel;
        },
        addEventHandler(state, payload) {
            state.channels[payload.channelName].events[payload.eventName] = payload.handlers;
        },
        removeChannel(state, channel) {
            delete state.channels[channel];
        }

    },
    actions: {
        // subscribe to a channel
        async subscribeToChannel({rootState, state, commit}, payload) {
            let channel = state.pusher.subscribe(payload.channelName);
            commit('addChannel', {
                channelName: payload.channelName,
                channel: channel,
                events: {}
            })
            return Promise.resolve();
        },
        // unsubscribe from channel
        async unsubscribeFromChannel({state, commit}, payload) {
            state.pusher.unsubscribe(payload.channelName);
            commit('removeChannel', {
                channelName: payload.channelName
            });
            return Promise.resolve();
        },
        /*
         {
            channelName - the channels to bind to
            eventName - the event to bind to,
            handlers - the handlers to add to this event
         }
         */
        async bindHandlerToEvent({state, commit}, payload) {
            commit('addEventHandler', payload);
            console.log(state.channels[payload.channelName]);
            state.channels[payload.channelName].channel.bind(payload.eventName, payload.handler);
            return Promise.resolve();
        }


    }
}