<template>
    <h-modal
        dark
        :open="isOpen"
        :on-close="onClose"
        remove-header
        full-height
        remove-padding
        name="welcome-video-modal"
    >
        <div class="welcome-video-modal__container">
            <youtube :video-id="'UYbEAK8Uc4A'" player-width="100%" player-height="500px" class="welcome-video-modal__video"></youtube>
        </div>
    </h-modal>
</template>

<script>
import HModal from '@/components/Shared/HModal.vue';

export default {
    name: 'WelcomeVideoModal',
    components: {
        HModal,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.welcome-video-modal {
    @media (min-width: 1280px) {
        bottom: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70vw;
        max-height: 70vh;
        border-radius: 1rem;
    }

    @media screen and (max-width: 600px) {
        &__container {
            position: fixed;
            background-image: none;
            max-height: 100vh;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100% !important;
            border-radius: 0 !important;
            padding: 0;
            color: $charcoal;
        }
    }

    &__video {
        display: flex;
    }
}
</style>
