<template>
    <div class="shop-skeleton">
        <skeleton-base height="84px" color="#F9F9F9" radius="0" />
        <div class="shop-hero">
            <div class="hero-noshop shop-skeleton-hero">
                <skeleton-base color="#F9F9F9" />
            </div>
        </div>
        <div class="shop-main">
            <div class="shop-filter shop-skeleton-filter">
                <div class="shop-skeleton-filter__mobile">
                    <skeleton-base width="80px" height="40px" color="#ebebeb" radius="1.5rem" />
                </div>
                <div class="shop-filter__section">
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="180px" height="14px" color="#ebebeb" />
                    </div>
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="100px" height="14px" color="#ebebeb" />
                    </div>
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="140px" height="14px" color="#ebebeb" />
                    </div>
                </div>
                <div class="shop-filter__section">
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="180px" height="14px" color="#ebebeb" />
                    </div>
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="100px" height="14px" color="#ebebeb" />
                    </div>
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="140px" height="14px" color="#ebebeb" />
                    </div>
                </div>
                <div class="shop-filter__section">
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="180px" height="14px" color="#ebebeb" />
                    </div>
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="100px" height="14px" color="#ebebeb" />
                    </div>
                    <div class="filter-skeleton">
                        <skeleton-base width="20px" height="20px" color="#ebebeb" />
                        <skeleton-base width="140px" height="14px" color="#ebebeb" />
                    </div>
                </div>
            </div>
            <div class="shop-results">
                <product-grid-skeleton />
            </div>
        </div>
    </div>   
</template>

<script>
import SkeletonBase from '../Base/SkeletonBase.vue';
import ProductGridSkeleton from './ProductGridSkeleton.vue';

export default {
    name: 'ShopSkeleton',
    components: {
        SkeletonBase,
        ProductGridSkeleton,
    },
};
</script>

<style lang="scss">

.shop-skeleton-hero {
    .skeleton-base {
        border-radius: 0 !important;

        @media (min-width: 1024px) {
            border-radius: 1.5rem !important;
        }
    }
}

.shop-skeleton-filter {

    &__mobile {
        display: flex;
        justify-content: center;

        @media (min-width: 1024px) {
            display: none;
        }
    }

    .shop-filter__section {
        display: none;

        @media (min-width: 1024px) {
            display: block;
        }
    }
}

.filter-skeleton {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 0.75rem 0;
}
</style>
