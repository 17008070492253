<template>
    <div>
        <div class="appointment__main-container">
            <div v-if="appointment_view === 'SelectPhysician'" class="appointment__select-physician">
                <select-physicians
                    v-if="!physicians.loading && physicians.data.length > 1"
                    :physicians="physicians"
                    @onBookItem="onBookItemSelected"
                ></select-physicians>
            </div>
            <div v-if="appointment_view === 'SelectedPhysician'" class="appointment__physician-selected">
                <appointment-selected-physician
                    v-if="selected_physician"
                    :physician="selected_physician"
                    @onCalendlyEvent="onCalendlyEvent"
                    @onCloseSelectedPhysician="onSelectedPhysicianClose"
                ></appointment-selected-physician>
            </div>
            <div v-if="appointment_view === 'Appointments'" class="appointment__patient-appointments">
                <appointment-items
                    v-if="!appointments.loading"
                    :appointments="appointments.data"
                    @onBookAppointment="onBookAppointment"
                ></appointment-items>
            </div>
            <div v-if="appointment_view === 'Loading'" class="appointment__patient-loading">
                <Loading text="Loading Appointments"></Loading>
            </div>
            <div v-if="appointment_view === 'EmptyState'" class="appointment__empty">
                <appointment-empty-state @onBookAppointment="onBookAppointment"></appointment-empty-state>
            </div>
        </div>
    </div>
</template>

<script>
import Pusher from 'pusher-js/with-encryption';
import { mapActions, mapMutations, mapState } from 'vuex';

import AppointmentEmpty from '@/components/Dashboard/Appointments/AppointmentEmpty';
import AppointmentItems from '@/components/Dashboard/Appointments/AppointmentItems';
import AppointmentSelectedPhysician from '@/components/Dashboard/Appointments/AppointmentSelectedPhysician';
import AppointmentPhysicians from '@/components/Dashboard/Appointments/AppointmentSelectPhysicians';
import LoadingContainer from '@/components/LoadingContainer';

new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authEndpoint: process.env.VUE_APP_BASE_END + '/pusher/auth',
});

export default {
    components: {
        AppointmentSelectedPhysician,
        selectPhysicians: AppointmentPhysicians,
        AppointmentItems,
        AppointmentEmptyState: AppointmentEmpty,
        Loading: LoadingContainer,
    },
    data() {
        return {
            section: 'upcoming',
            copy_button_text: 'Copy',
            select_calendly: false,
            show_calendar: false,
            appointment_view: 'Appointments',
            calendly_time_selected: false,

        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            appointments: state => state.appointments.appointments,
            patient_state: state => state.user.patient_state,
            physicians: state => state.physician.state_physicians,
            selected_physician: state => state.physician.selected_physician,
        }),
    },
    methods: {
        ...mapActions({
            retrieveAppointments: 'appointments/retrieveAppointments',
            getPhysicians: 'physician/getPhysicians',
            subscribeToChannel: 'pusherChannels/subscribeToChannel',
            bindHandler: 'pusherChannels/bindHandlerToEvent',
        }),
        ...mapMutations({
            setPhysician: 'physician/setPhysician',
        }),

        onBookItemSelected(event) {
            this.setPhysician(event);
            this.appointment_view = 'SelectedPhysician';
        },

        onSelectedPhysicianClose() {
            this.setPhysician(null);
            this.appointment_view = 'SelectPhysician';
        },

        async onBookAppointment() {
            await this.getPhysicians({
                state_id: this.patient_state.data._id,
                is_provider: false,
            });

            if (this.physicians && this.physicians.data.length === 1) {
                this.setPhysician(this.physicians.data[0]);
                this.appointment_view = 'SelectedPhysician';
            }
        },
    },

    async created() {

        try {
            await this.retrieveAppointments({
                patient: this.user._id,
            });

            if (this.appointments.data && !this.appointments.data.docs.length) {
                this.appointment_view = 'EmptyState';
            }
        } catch (error) {
            console.error(error);
        }
    },
};

</script>

<style lang="scss">

.appointment {
    &__main-container {
        @media (min-width: 1440px) {
            width: 100%;
            max-width: 1440px;
            margin: 0 auto;
        }
    }

    &__calendly {
        height: 100vh;
    }

    &__book-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid $neutral300;
        padding: 2rem 1rem;
        position: relative;
        font-family: 'Sofia Pro', sans-serif;
    }

    &__book-list {
        margin-top: 2rem;
        list-style: none;
        padding: 0;
        font-family: 'Sofia Pro', sans-serif;

        li {
            display: flex;
            align-items: center;
            cursor: pointer;
            border-bottom: 1px solid $neutral300;
            padding: 2rem 1rem;
            position: relative;

            &:hover {
                background-color: $neutral200;
            }
        }
    }

    &__book-avatar {
        width: 65px;
        height: 65px;
        border-radius: 100%;
        background-size: cover;
        margin-right: 1rem;
        display: inline-block;
    }

    &__physician--info {
        display: flex;
        flex-direction: row;
    }

    &__book-bio {
        display: flex;
        flex-direction: column;
    }

    &__book-title {
        font-family: 'Sofia Semi Bold', sans-serif;
        font-size: 1.5rem;
    }

    &__book-desc {
        color: $neutral500;
    }

    &__book-icon {
        position: absolute;
        right: 22px;
        font-size: 1.5rem;
        top: 55px;
    }

    &__sub-text {
        color: $neutral500;
        font-size: 1rem;
        font-family: 'Sofia Pro', sans-serif;

        span {
            display: inline-block;
            padding: 0.5rem;
            background-color: $neutral200;
            border-radius: 4px;
            color: $stoneBlack;
        }
    }

    &__desc {
        font-family: 'Sofia Pro', sans-serif;
    }

    &__header-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

#calendly {
    height: 100vh;
}

</style>
