<template> 
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2929 11.5354L7.41421 14.4141L6 12.9998L8.87868 10.1212C9.65973 9.34012 9.65973 8.07379 8.87868 7.29274L6 4.41406L7.41421 2.99985L10.2929 5.87853C11.855 7.44062 11.855 9.97328 10.2929 11.5354Z" :fill="fill" />
    </svg>
</template>

<script>
export default {
    name: 'ChevronRt',
    props: {
        fill: {
            type: String,
            default: '#242E49',
        },
    },
};
</script>
