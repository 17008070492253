<template>
    <div class="hbadge" data-test="badge">
        <div class="hbadge__dot">
            <img src="@/assets/icons/checkmark-circle-charcoal.svg" alt="">
        </div>
        <p class="hbadge__label">
            HSA | FSA Accepted
        </p>
    </div>
</template>

<script>
export default {
    name: 'HFSABadge',
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.hbadge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 3rem;
    background-color: $powder-blue-100;
    color: $charcoal;
    padding: 0.5rem 1rem;

    p {
        margin: 0;
    }

    &__label {
        font-family: 'Helvetica Now Micro', sans-serif;
        font-size: 0.625rem;
        line-height: 1.25rem;
        letter-spacing: 0.0625rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}

</style>
