export const truncateText = (text, count) => {
    if (text.length > count) {
        let truncatedText = text.substring(0, count);
        const lastSpaceIndex = truncatedText.lastIndexOf(' ');

        if (lastSpaceIndex !== -1) {
            truncatedText = truncatedText.substring(0, lastSpaceIndex);
        }

        return truncatedText + '...';
    }

    return text;
};
