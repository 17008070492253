<template>
<div :class="is_current ? 'progress-line-container-5' : 'progress-line-container-4'">
    <div class="progress-step-container">
        <StepBubble :is_active="true" :is_step="false"/>
        <div class="progress-inner-step-container">
            <p class="progress-step-label cereal-category-label">Medical Review</p>
            <p class="progress-step-status-label cereal-text">
                <span>Completed</span>
            </p>
        </div>
    </div>

    <div class="progress-step-container" v-if="order.prescriptions.length">
        <StepBubble :is_active="order.status.physician_reviewed"
                    :is_step="(!order.status.physician_reviewed && !order.status.erx_submitted)"/>

        <div class="progress-inner-step-container">
            <p class="progress-step-label cereal-category-label">Doctor Review</p>
            <p class="progress-step-status-label cereal-text">
                    <span v-if="order.status.physician_reviewed">Completed
                        <span v-if="order.prescriptions">
                            <span>: {{ order.prescriptions[0].created_at | date }}</span>
                        </span>
                    </span>
                <span v-else>In Progress</span>
                <span v-if="order.prescriptions.length > 0" style="display:block;">
                        <span
                            v-if="order.prescriptions[0].prescriber_name">Doctor: {{ order.prescriptions[0].prescriber_name }}</span>
                    </span>
            </p>
        </div>
    </div>

    <div class="progress-step-container">

        <StepBubble :is_active="order.status.fulfilled"
                    :is_step="(order.status.erx_submitted || order.products.length) && !order.status.fulfilled"/>
        <div class="progress-inner-step-container"
             v-bind:class="{ 'muted-g' : !order.status.fulfilled && !order.status.erx_submitted }">
            <p v-if="!contains_labs" class="progress-step-label cereal-category-label">Pharmacy Processing</p>
            <p v-if="contains_labs" class="progress-step-label cereal-category-label">Lab Processing </p>
            <p class="progress-step-status-label cereal-text">
                <span v-if="order.status.erx_submitted || (order.products.length && order.status.fulfilled)">
                    <span v-if="!order.status.fulfilled">
                        Order Processing
                    </span>
                    <span v-else>
                        Processed
                    </span>
                </span>
                <span v-else>Awaiting Fulfillment</span>
            </p>
        </div>
    </div>

    <div class="progress-step-container">
        <StepBubble :is_active="order.status.fulfilled && (contains_labs || (order.tracking_url && !contains_labs))"
                    :is_step="order.status.fulfilled && !order.delivery_date"/>
        <div class="progress-inner-step-container" v-bind:class="{ 'muted-g' : !order.status.fulfilled }">
            <p v-if="!contains_labs" class="progress-step-label cereal-category-label">Treatment Shipped</p>
            <p v-if="contains_labs" class="progress-step-label cereal-category-label">Lab Requisition </p>
            <p class="progress-step-status-label cereal-text">
                <span v-if="order.status.fulfilled && order.tracking_url && !contains_labs">Shipped from Pharmacy
                    <span v-if="order.delivery_date">
                        {{ order.delivery_date | date }}
                    </span>

                </span>
                <span v-else-if="order.status.fulfilled && contains_labs">Lab Ready
                        <span v-if="order.delivery_date">
                            {{ order.delivery_date | date }}
                        </span>

                    </span>
                <span v-else>Awaiting</span>
                <span v-if="order.tracking_url">
                        <a :href="order.tracking_url" class="progress-step-label-emp cereal-category-label"
                           target="_blank">Tracking URL</a>
                    </span>
            </p>
        </div>
    </div>
    <div v-if="is_current" class="progress-step-container">
        <div class="progress-step-bubble-box">
            <div class="highlighted-step-bubble">
                <div class="inner"></div>
            </div>
        </div>
        <div class="progress-inner-step-container">
            <p class="progress-step-label cereal-category-label">Next Refill</p>
            <p class="progress-step-status-label cereal-text">
                <span>{{ subscription.current_period_end | date_unix }}</span>
            </p>
        </div>
    </div>
</div>

</template>
<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {required, minLength, between, email} from 'vuelidate/lib/validators';
import moment from 'moment';
import StepBubble from '@/components/Components/StepBubble.vue'


export default {
    name: 'orders_subcomponent',
    components: {
        StepBubble: StepBubble
    },
    props: ['order', 'subscription', 'is_current'],
    data() {
        return {}
    },
    created() {
        return
    },
    computed: {
        contains_labs() {
            if (this.order.products) {
                for (let {product, product_template} of this.order.products) {
                    if (product.type === 'lab') {
                        return true;
                    }

                }
            }
            return false;

        }
    },
    filters: {
        date: function (date) {
            return moment(date).format('MMMM Do, YYYY');
        },
        date_unix: (date) => {
            return moment.unix(date).format('MMMM Do, YYYY');
        }
    },
}

</script>
