<template>
    <div class="h-input">
        <label v-if="label" :for="id">{{ label }}</label>
        <div class="h-input__input-container">
            <input
                :id="id"
                ref="input_element"
                v-mask="mask"
                :type="inputType"
                :value="value"
                :name="name"
                :disabled="disabled"
                :inputmode="inputmode"
                class="h-input__input"
                :class="{ 'h-input__error-border': hasErrors, 'h-input__disabled': disabled }"
                @input="onInput($event)"
            >
            <div v-if="type === 'password'" class="h-input__password" @click="togglePassword">
                <ion-icon :name="showPassword ? 'eye-off-outline' : 'eye-outline'" />
            </div>
        </div>
        <div v-if="hasErrors">
            <span v-for="(error, index) in errors" :key="index" class="h-input__error">
                {{ error || null }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HTextInputSecondary',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: [String, Number],
        },
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        vuelidate: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        mask: {
            type: String,
            default: '',
        },
        inputmode: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            inputType: this.type,
            showPassword: false,
        };
    },
    computed: {
        hasErrors() {
            return this.errors.some(error => error);
        },
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
            this.inputType = this.showPassword ? 'text' : 'password';
        },
        onInput(event) {
            this.$emit('input', event.target.value);
            this.vuelidate.$touch();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.h-input {
    label {
        @extend %caption;
        font-weight: 700;
        margin-bottom: 0.65rem;
    }

    &__input-container {
        position: relative;
    }

    &__input {
        @extend %caption;
        width: 100%;
        border: 1px solid $border-medium;
        border-radius: 0.25rem;
        background-color: $white-100;
        padding: 0.625rem 1rem;

        &:focus {
            outline: none;
            border: 1px solid $black-85;
        }
    }

    &__password {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-flex;
        cursor: pointer;
        user-select: none;
    }

    &__error {
        display: inline-block;
        color: $errors;
        font-size: 0.75rem;
        padding-top: 0.125rem;
    }

    &__error-border {
        border-color: $errors;

        &:focus {
            outline: none;
            border: 1px solid $errors;
        }
    }

    &__disabled {
        background-color: $gray-50;
        border-color: $border-medium;
    }

}
</style>
