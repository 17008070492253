<template>
    <div class="auth">
        <auth-info type="new_password" :organization_settings="organizationSettings">
            <form class="login-form" @submit.prevent="checkForm()">
                <div class="login-form__input">
                    <Input
                        id="new_password"
                        v-model="password.new_password"
                        name="new_password"
                        type="password"
                        label="New Password"
                        :vuelidate="$v.password.new_password"
                        :errors="[
                            !$v.password.new_password.required && $v.password.new_password.$dirty && 'Please enter your password.',
                            !$v.password.new_password.minlength && $v.password.new_password.$dirty && 'Password must be at least 6 characters long.',
                        ]"
                    />
                    <Input
                        id="confirm_password"
                        v-model="password.confirm_password"
                        name="confirm_password"
                        type="password"
                        label="Confirm Password"
                        :vuelidate="$v.password.confirm_password"
                        :errors="[
                            !$v.password.confirm_password.required && $v.password.confirm_password.$dirty && 'Please enter your password.',
                            !$v.password.confirm_password.minlength && $v.password.confirm_password.$dirty && 'Password must be at least 6 characters long.',
                            !$v.password.confirm_password.sameAsPassword && $v.password.confirm_password.$dirty && 'Please make sure your passwords match.',
                        ]"
                    />
                </div>
                <span v-if="error.is_error && submitted" class="reset-success-msg">
                    {{ error.err_msg }}. 
                    <a class="login-link" href="/#/request-password-reset">Resubmit your password reset request.</a>
                </span>
                <h-button
                    label="Continue"
                    variant="primary"
                    full_width
                    large
                    :handle-click="checkForm"
                    :loading="submitted"
                    :disabled="$v.$invalid"
                />
            </form>
        </auth-info>
        <div class="auth__hero">
            <div class="auth__image auth__hero__backdrop auth__hero__right" :style="{'background-image': `url(${get_image()})`}"></div>
        </div>
    </div>
</template>

<script>

import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapState } from 'vuex';

import AuthInfo from '@/components/Auth/AuthInfo.vue';
import Input from '@/components/Auth/Input.vue';
import HButton from '@/components/Shared/HButton';

export default {
    components: {
        AuthInfo,
        Input,
        HButton,
    },
    data() {
        return {
            submitted: false,
            button_text: 'Change Password',
            authentication: {
                email: '',
                success: false,
            },
            password: {
                new_password: null,
                confirm_password: null,
            },
            error: {
                is_error: false,
                err_msg: null,
            },
        };
    },
    validations: {
        password: {
            new_password: { required, minlength: minLength(6) },
            confirm_password: { required, minlength: minLength(6), sameAsPassword: sameAs('new_password') },
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (!this.organizationSettings.data) {
                this.getOrganizationSettings();
            }
        });
    },
    methods: {
        get_image() {
            return this.organizationSettings.data.login.image;
        },
        checkForm() {

            //the submitted flag will trigger validation messages
            this.submitted = true;

            //es6 reference to this for arrow functions
            const vm = this;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            //payload will include password.new_password and password.confirm_password
            const payload = this.password;

            payload['reset_token'] = this.$route.params.resetToken;
            payload['user_id'] = this.$route.params.userId;
            payload['organization'] = this.$route.params.orgId;

            //submit the payload to the backend
            this.submitNewPassword(payload).then(() => {

                //change the button text to refelect a successful upload
                vm.button_text = 'Success';

                //make sure the validation flag
                vm.submitted = false;

                //re-route the
                vm.$router.push({ path: '/login' }).catch(() => {});

            }).catch(err => {
                console.error(err.response);
                vm.error.is_error = true;
                vm.error.err_msg = err.response.data.message;
                vm.button_text = 'Invalid Attempt. Try again.';
            });
        },
        ...mapMutations({
            initialize: 'questionnaire/initializeQuestionnaireStore',
        }),
        ...mapActions({
            resetPassword: 'authenticate/requestNewPassword',
            submitNewPassword: 'authenticate/submitNewPassword',
            getOrganizationSettings: 'getOrganizationSettings',
        }),
    },

    computed: {

        ...mapState({
            bad_credentials: state => state.authenticate.bad_credentials,
            organizationSettings: state => state.organizationSettings,
        }),

        ...mapGetters({
            isLoggedIn: 'authenticate/isLoggedIn',
            jwt: 'authenticate/jwt',
            jwtData: 'authenticate/jwtData',
        }),
    },
};
</script>

<style lang="scss">

</style>
