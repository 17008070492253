<template>
<div class="new-consultation-wrapper">
    <!--include nav-->
    <Nav stage="0"/>
    <Loading v-if="loading"/>
    <!--main wrapper-->
    <transition name="fadein">
        <div>
        <div v-if="!loading && !(selectedIntakeTemplate.data && selectedIntakeTemplate.data.visit_intro_template) && scheduleStatus === 'none'" appear class="consultation-wrapper">
            <div class="consultation-container new-consultation-container">
                <h2 class="new-consultation-header">Let’s get started on your visit {{ name }}</h2>
                <h4 class="new-consultation-subheader">A bit about our process and what you'll get through Healthspan:</h4>
                <InfoCard
                    :type="visit_text.assessment.type"
                    :title="visit_text.assessment.title"
                    :badge="{ label: 'now', color: '#55D291' }"
                    :isActive="true"
                    :disabled="true"
                    :small="true"
                >
                <div>
                    <div>
                        {{visit_text.assessment.description}}
                    </div>
                    <div class="consultation-time">{{visit_text.assessment.estimate === 'intake_estimate' ? `About ${this.totalTime} mins` : visit_text.assessment.estimate}}</div>
                </div>
                </InfoCard>
                <InfoCard
                    :type="visit_text.provider.type"
                    :title="visit_text.provider.title"
                    :disabled="true"
                    :small="true"
                >
                    <div>
                        {{visit_text.provider.description}}
                    </div>
                    <div class="consultation-time">{{ visit_text.provider.estimate }}
                        <div class="popover-trigger" v-if="visit_text.provider.tooltip">
                            <img src="@/assets/icons/attention.svg"/>
                            <div class="popover-content">{{visit_text.provider.tooltip}}</div>
                        </div>
                        <span class="annotation-sign" v-if="visit_text.provider.tooltip">*</span>
                    </div>
                </InfoCard>
                <InfoCard
                    :type="visit_text.personalized.type"
                    :title="visit_text.personalized.title"
                    :disabled="true"
                    :small="true"
                >
                    <div>
                        {{visit_text.personalized.description}}
                    </div>
                    <div class="consultation-time">{{visit_text.personalized.estimate}}</div>
                </InfoCard>
                <div class="button-wrapper">
                    <HButton
                        :handleClick="startQuestionnaire"
				    	label="CONTINUE"
				    	variant="cta"
				    	full_width
				    	large
				    />
                </div>
                <div v-if="isHealthspan" @click="() => setScheduleStatus('scheduling')" class="customer-care">Not ready yet? Speak to our care team</div>
                <div class="annotation" v-if="visit_text.provider.tooltip">
                    {{ visit_text.provider.tooltip }}
                </div>
                <div class="certification">
                    <HCertification />
                </div>
            </div>
        </div>
        <div v-if="scheduleStatus === 'scheduling'" class="consultation-schedule-container">
            <Schedule :calcomConfig="{ 'zp-type': 'general', 'zp-intake-id': this.$route.params.intakeId }" :calcomLink="link" :handleReturn="() => setScheduleStatus('none')" :handleContinue="scheduleSuccess"/>
        </div>
    </div>
    </transition>
</div>
</template>

<script>
import Nav from '@/components/Consultation/Nav.vue'
import Loading from '@/components/Loading.vue'
import {mapState, mapActions} from 'vuex'
import { tags } from '@/constants/mailchimpTags';
import HButton from '@/components/Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';
import { analytics, analyticsActions } from '@/helpers/analytics'
import InfoCard from '@/components/Shared/InfoCard';
import Schedule from '@/components/Identification/Schedule.vue';

const consultationCalcomLinks = {
    'dev01': 'team/healthspan/dev01-identity-verification',
    'dev02': 'team/healthspan/dev02-identity-verification',
    'staging': 'team/healthspan/staging-identity-verification',
    'production': 'team/healthspan/talk-to-our-team',
}

export default {
    name: 'ConsultationHiw',
    components: {
        Nav: Nav,
        Loading: Loading,
        InfoCard,
        HButton,
        HCertification,
        Schedule
    },
    data() {
        return {
            loading: true,
            loading1: false,
            scheduleStatus: 'none',
            link: consultationCalcomLinks[process.env.NODE_ENV] || consultationCalcomLinks.production,
            hiw_content: {
                intake: {
                    assessment: {
                        type: 'assessment',
                        title: 'Online Assessment',
                        description: 'Answer questions about how you\'ve been feeling lately.',
                        estimate: 'intake_estimate'
                    },
                    provider: {
                        type: 'provider',
                        title: 'Provider diagnosis',
                        description: "A licensed provider will assess your responses and prescribe treatment only if it's right for you.",
                        estimate: 'Typically 12-48 hrs',
                        tooltip: 'Processing time for physician reviews may be extended on account of weekends or holidays.'
                    },
                    personalized: {
                        type: 'rapamycin',
                        title: 'Personalized treatment',
                        description: `If prescribed, ${ this.isHealthspan ? 'Healthspan' : 'We' } will ship your medication directly to you for free.`,
                        estimate: 'ASAP'
                    }

                },
                labs: {
                    assessment: {
                        type: 'assessment',
                        title: 'Quick Booking',
                        description: 'Secure your testing appointment swiftly through our online booking system, accessing over 2000+ partner labs nationwide.',
                        estimate: 'About 4 mins'
                    },
                    provider: {
                        type: 'provider',
                        title: 'Actionable Results',
                        description: "A licensed provider will review your lab results and provide recommendations to ensure you stay on track for optimal healthspan.",
                        estimate: 'Typically 12-48 hrs',
                        tooltip: 'Estimation may vary depending on partnered lab, holidays and or weekends'
                    },
                    personalized: {
                        type: 'labs',
                        title: 'Continuous Health Tracking',
                        description: `Receive detailed results that translate complex data into actionable health insights, reviewed by top medical professionals.`,
                        estimate: 'ASAP'
                    }
                }
            },
            visit_text: {}
        }
    },
    async mounted() {
        this.$nextTick(async () => {
            const productTemplate = JSON.parse(window.localStorage.getItem('zp_product_template'));

            if (productTemplate?.type === 'lab') {
                this.visit_text = this.hiw_content['labs'];
            } else {
                await this.getIntakeData();
                this.visit_text = this.hiw_content['intake'];
            }
        })
    },
    created() {
        this.add_mailchimp_subscriber();
        setTimeout(() => {
            this.loading = false;
            setTimeout(() => {
                this.loading1 = true;

            }, 750)
        }, 1500);
    },


    methods: {
        setScheduleStatus(status) {
            if (status === 'scheduling') {
                analytics.track(analyticsActions.open_schedule_view, {
                    source: 'Start Your Visit',
                });
            } else {
                analytics.track(analyticsActions.close_schedule_view, {
                    source: 'Start Your Visit',
                });
            }

            this.scheduleStatus = status;
        },
        scheduleSuccess() {
            analytics.track(analyticsActions.appointment_scheduled, {
                intake_id: this.intake_id,
                source: 'Start Your Visit',
            });
            this.$router.push({ path: `/dashboard/${this.user._id}/appointments` });
        },
        add_mailchimp_subscriber() {
            if (this.mailchimp_exists) {
                if (!this.user.mailchimp_id) {
                    this.add_subscriber({
                        patient: this.user._id
                    }).then(subscriber => {
                        this.add_subscriber_tags({
                            tag: {
                                name: tags.status.intake.incomplete,
                                status: 'active'
                            },
                            patient: this.user._id
                        });
                        window.localStorage.setItem('hs_user', JSON.stringify(subscriber));
                    });
                }
            }
        },
        checkProfileDataExists() {
            return this.user && (this.user.sex && this.user.birthday && this.user.state && this.user.state !== "");
        },
        checkWeightHeightExists() {
            return this.user && (this.user.weight && this.user.height);
        },
        async getIntakeData() {
            let retrieveIntake;
            const intakeId = this.$route.params.intakeId;

            if (!intakeId) return;

            try {
                const payload = {user: this.user._id, _id: intakeId};

                retrieveIntake = await this.retrieveIntake(payload);
            } catch (err) {

            }

            if (!this.selectedIntakeTemplate.data) {
                try {
                    const payload = { templateId: retrieveIntake.intake_template._id };

                    await this.getIntakeTemplate(payload);
                } catch (err) {

                }
            }

            if (this.selectedIntakeTemplate.data?.visit_intro_template) {
                await this.getVisitIntroTemplate({ visit_intro_id: this.selectedIntakeTemplate.data.visit_intro_template });
            }
        },
        async startQuestionnaire() {
            let analytics_payload = {
                sex: this.user.sex,
                profile_data_exists: !!this.checkProfileDataExists(),
                weight_height_exists: !!this.checkWeightHeightExists()
            }
            if (this.intake_id) {
                analytics_payload['intake_id'] = this.intake_id;
            }

            analytics.track(analyticsActions.questionnaire_start, analytics_payload);

            const productTemplate = JSON.parse(window.localStorage.getItem('zp_product_template'));

            if (productTemplate?.type === 'lab') {
                await this.checkoutProfileOption();
            } else {
                await this.intakeProfileOption();
            }
        },

        async checkoutProfileOption() {
            await this.$router.push('/signup/profile');
        },

        async intakeProfileOption() {
            if (!this.checkProfileDataExists() || !this.checkWeightHeightExists()) {
                await this.$router.push({ path: '/consultation/profile/' + this.user._id + '/' + this.currentIntakeInstance.data._id + '/question/none' });
                return;
            }

            this.$emit('onContinue');
        },

        ...mapActions({
            retrieveIntake: 'intake/retrieveIntake',
            getIntakeTemplate: 'intake/getIntakeTemplate',
            getVisitIntroTemplate: 'visits/getVisitIntroTemplate',
            updateIntakeResponse: 'intake/updateIntakeResponse',
            add_subscriber: 'user/addSubscriber',
            add_subscriber_tags: 'user/addSubscriberTags',
            check_integration: 'integrations/checkIntegrationAvailable'
        })
    },

    computed: {
        ...mapState({
            name: state => state.user.user.first_name,
            user: state => state.user.user,
            intake_id: state => state.intake.intake_id,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            visitIntroTemplate: state => state.visits.visitIntroTemplate,
            mailchimp_exists: state => state.integrations.mailchimp_exists
        }),

        totalTime() {
            if (this.currentIntakeInstance.data) {
                return this.currentIntakeInstance.data.total_steps
                    ? Math.ceil(this.currentIntakeInstance.data.total_steps * 1/3) // 20 sec (1/3 of a minute) per question
                    : 5;
            }

            return 10;
        },

        isHealthspan() {
            return this.user && this.user.organization === process.env.VUE_APP_ORG_ID;
        }
    },
}

</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';

.consultation-time {
    color: #FF5850;
    font-weight: 700;
    margin-top: 10px;
}

.consultation-schedule-container {
    padding-top: 138px;
    padding-bottom: 60px;
}

.new-consultation-wrapper,
.consultation-wrapper {
    background-color: #FFF;
    @extend %helveticaNowDisplay;
}

.new-consultation-container {
    width: 100%;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    .button-wrapper {
        margin-top: 14px;

        @media screen and (max-width: 768px) {
            margin-top: 100px;
        }
    }

    .popover-trigger {
        display: inline-block;
        top: -1px;
        padding: 0 0.3rem;
        position: relative;

        @media screen and (max-width: 480px){
            display: none;
        }
    }

    .popover-content {
        animation: fadeOut 0.3s forwards;
        text-align: left;
        position: absolute;
        border-radius: 0.75rem;
        top: calc(-100% - 0.375rem);
        left: 30px;
        width: 250px;
        padding: 0.75rem 0.95rem;
        background-color: #212931;
        color: #FFF;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
        font-weight: normal;
        font-size: 14px;

        @media screen and (max-width: 760px){
            top: 35px;
            left: -100px;
        }
    }

    .popover-content::before {
        content: "";
        position: absolute;
        bottom: calc(50% - 0.3rem);
        left: -9px;
        width: 0;
        height: 0;
        border-right: 10px solid #212931;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;

        @media screen and (max-width: 760px){
            left: 42%;
            top: -14px;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-bottom: 10px solid #212931;
        }
    }

    .popover-trigger:hover .popover-content {
        animation: fadeIn 0.3s forwards;
    }

    .customer-care {
        color: #599BEA;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        letter-spacing: 0.04rem;
        cursor: pointer;
        margin-top: 10px;

        @media screen and (max-width: 480px){
            font-size: 14px;
        }
    }

    .certification {
        margin-top: 25px;
    }

    .annotation-sign {
        display: none;
        @media screen and (max-width: 480px){
            display: inline;
        }
    }

    .annotation {
        display: none;
        font-family: inherit;
        font-size: 14px;
        color: #928D8A;
        margin-top: 98px;
        padding: 0 10px;
        text-align: center;

        @media screen and (max-width: 480px){
            display: block;
        }
    }

}

@keyframes fadeIn {
    0% {
        display: block;
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    99% {
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        display: none;
        opacity: 0;
        transform: translateX(10px);
    }
}

.hiw-container {
    width: 100%;
}

.consult-icon {
    width: 65px;
    height: 65px;
}

.consult-hiw-text-container {
    width: 100%;
}

.consult-hiw-label {
    font-size: 18px;
    margin-bottom: 5px;
}

.button-wrapper {
    margin-top: 25px;
    width: 100%;
}

.hiw-bullet-number {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 500;
}


</style>

