import axios from 'axios';
import Vue from "vue";


const baseUrl = process.env.VUE_APP_BASE_END;
export const orders = {

    namespaced: true,
    state: {
        orders: {},
        orders_error: {
            is_error: false,
            error_msg: null
        },
        postOrder: {
            loading: null,
            data: null
        },
        intake_order: {},
        updated_order: {},
        order: {},
        patient_order: {},
        invoice_order: {
            loading: false,
            data: null,
            error: null
        }
    },
    mutations: {
        setPatientOrders(state, payload) {
            Vue.set(state.orders, payload.id, payload.value);
        },
        setPatientOrder(state, payload) {
            Vue.set(state.patient_order, payload.id, payload.value);
        },
        setInvoiceOrder(state, payload) {
            Vue.set(state.invoice_order, payload.id, payload.value);
        }
    },
    actions: {

        retrievePatientOrders({state, getters, commit, context, rootState}, payload) {
            let patient_id = payload.user_id;
            let orgId = rootState.orgId.data;
            let {query} = payload;

            commit('setPatientOrders', {id: 'loading', value: true});
            commit('setPatientOrders', {id: 'error', value: false});
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/orders/' + orgId + '/patient/' + patient_id,
                    method: 'GET',
                    params: query
                }).then(response => {
                    commit('setPatientOrders', {id: 'loading', value: false});
                    let sorted = response.data.sort((a, b) => {
                        if (a.created_at > b.created_at) {
                            return -1;
                        } else if (a.created_at < b.created_at) {
                            return 1;
                        }
                        return 0;
                    });
                    commit('setPatientOrders', {id: 'data', value: sorted});
                    resolve(response.data);
                }).catch(err => {
                    commit('setPatientOrders', {id: 'loading', value: false});
                    commit('setPatientOrders', {id: 'data', value: null});
                    commit('setPatientOrders', {id: 'error', value: true});
                    reject(err);
                })

            })
        },
        async getOrderV2({state, commit}, payload) {
            try {
                const {organization, order_id} = payload;
                commit('setInvoiceOrder', {id: 'loading', value: true});
                commit('setInvoiceOrder', {id: 'error', value: false});
                const response = await axios.get(`${baseUrl}/v2/orders/${order_id}/invoice`, {
                    headers: {
                        'zenpatient-organization': organization,
                    }
                });
                if ('data' in response) {
                    commit('setInvoiceOrder', {id: 'loading', value: false});
                    commit('setInvoiceOrder', {id: 'error', value: false});
                    commit('setInvoiceOrder', { id: 'data', value: response.data });
                    return response.data;
                }
            } catch (error) {
                commit('setInvoiceOrder', {id: 'loading', value: true});
                commit('setInvoiceOrder', {id: 'error', value: false});
                commit('setInvoiceOrder', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setInvoiceOrder', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }

        },

        async createPatientOrder({commit, rootState}, payload) {
            const { organization, data } = payload;
            commit('setPatientOrder', { id: 'error', value: false });
            commit('setPatientOrder', { id: 'loading', value: true });

            try {
                const response = await axios.post(`${baseUrl}/v2/orders/patient`, data, {
                    headers: {
                        'zenpatient-organization': organization,
                    }
                });
                if ('data' in response) {
                    commit('setPatientOrder', { id: 'loading', value: false });
                    commit('setPatientOrder', { id: 'data', value: response.data });
                    return response.data;
                }
            } catch (error) {
                commit('setPatientOrder', { id: 'error', value: true });
                commit('setPatientOrder', { id: 'loading', value: false });
                commit('setPatientOrder', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setPatientOrder', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },

        async submitOrder({state, commit}, payload) {
            try {
                const {organization, order_id} = payload;
                const response = await axios.post(`${baseUrl}/v2/orders/${order_id}/submit`,null, {
                    headers: {
                        'zenpatient-organization': organization,
                    }
                });
                if ('data' in response) {
                    return response.data;
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    commit('setInvoiceOrder', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }
                throw error;
            }
        },
    }
}
