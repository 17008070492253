<template>
    <div class="modal-confirm__container">
        <p class="ppe-small-title">{{title || 'Are you sure?'}}</p>
        <div class="modal-confirm__actions">
            <button :disabled="is_submitting" type="button" class="pill-button black-btn mr-3" @click="on_confirm()">{{confirm_button_text || 'Confirm'}}</button>
            <button type="button" class="pill-button" @click="on_cancel()">{{cancel_button_text || 'Cancel'}}</button>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
    name: 'ModalConfirm',
    components: {},
    props: ['title', 'confirm_button_text', 'cancel_button_text', 'is_submitting'],
    data() {
        return {}
    },
    created() {

    },
    computed: {
        ...mapState({})
    },
    methods: {
        ...mapActions({}),
        on_confirm() {
            this.$emit('onConfirm', true);
        },
        on_cancel() {
            this.$emit('onCancel', true);
        }
    }
}
</script>
<style lang="scss">

.modal-confirm {

    &__container {
        .ppe-small-title {
            padding-bottom: 3rem;
        }
    }

    &__actions {
        display: flex;
    }
}
</style>
