import axios from "axios";
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const integrations = {
    namespaced: true,
    state: {
        integrationSelected: {
            loading: false,
            data: null
        },
        merchantSelected: {
            loading: false,
            data: null,
            error: false
        },
        integration_exists: {},
        mailchimp_exists: {}
    },
    mutations: {
        setIntegrationSelectedLoading(state, loading) {
            state.integrationSelected.loading = loading;
        },
        setIntegrationSelectedData(state, data) {
            state.integrationSelected.data = data;
        },
        setMerchantSelectedLoading(state, loading) {
            state.merchantSelected.loading = loading;
        },
        setMerchantSelectedError(state, error) {
            state.merchantSelected.error = error;
        },
        setMerchantSelectedData(state, data) {
            state.merchantSelected.data = data;
        },
        setIntegrationCheck(state, payload) {
            Vue.set(state.integration_exists, payload.id, payload.value);
        },
        setMailchimpCheck(state, payload) {
            Vue.set(state.mailchimp_exists, payload.id, payload.value);
        }
    },
    getters: {},
    actions: {
        getIntegration({commit, getters, state, rootState}, payload) {
            commit('setIntegrationSelectedLoading', true);
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/organization/' + rootState.orgId.data + '/settings/integration',
                    method: 'GET',
                    params: payload
                }).then(response => {
                    resolve(response.data);
                    commit('setIntegrationSelectedData', response.data);
                    commit('setIntegrationSelectedLoading', false);
                }).catch(err => {
                    reject(err);
                    commit('setIntegrationSelectedLoading', false);
                })
            })
        },
        getMerchant({commit, getters, state, rootState}) {
            commit('setMerchantSelectedError', false);
            commit('setMerchantSelectedLoading', false);
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/organization/' + rootState.orgId.data + '/merchant',
                    method: 'GET'
                }).then(response => {
                    resolve(response.data);
                    commit('setMerchantSelectedData', response.data);
                    commit('setMerchantSelectedLoading', false);
                    commit('setMerchantSelectedError', false);
                }).catch(err => {
                    reject(err);
                    commit('setMerchantSelectedLoading', false);
                    commit('setMerchantSelectedError', true);
                })
            })
        },

        async checkIntegrationAvailable({commit, rootState}, payload) {
            commit('setIntegrationCheck', { id: 'error', value: false });
            commit('setIntegrationCheck', { id: 'loading', value: true });

            const { type } = payload;

            try {
                let response = await axios.get(`${baseUrl}/integrations/${rootState.orgId.data}/check`, {
                    params: {
                        type: type
                    }
                });
                if ('data' in response) {
                    commit('setIntegrationCheck', { id: 'data', value: response.data });
                    commit('setIntegrationCheck', { id: 'loading', value: false });
                    return response.data;
                }
            } catch (error) {
                commit('setIntegrationCheck', { id: 'error', value: true });
                commit('setIntegrationCheck', { id: 'loading', value: false });
                commit('setIntegrationCheck', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setIntegrationCheck', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },

        async checkMailchimpIntegration({commit, rootState}) {
            try {
                let response = await axios.get(`${baseUrl}/integrations/${rootState.orgId.data}/check`, {
                    params: {
                        type: 'MAILCHIMP'
                    }
                });
                if ('data' in response) {
                    commit('setMailchimpCheck', { id: 'data', value: response.data.integration_exists });
                    commit('setMailchimpCheck', { id: 'loading', value: false });
                    return response.data;
                }
            } catch (error) {
                commit('setMailchimpCheck', { id: 'error', value: true });
                commit('setMailchimpCheck', { id: 'loading', value: false });
                commit('setMailchimpCheck', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setMailchimpCheck', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        }
    }
}
