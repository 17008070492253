<template>
    <div class="intake">
        <div class="intake__content" data-test="incomplete-panel-card">
            <div class="intake__image">
                <img v-if="intakeInfo.image" :src="intakeInfo.image" :alt="intakeInfo.title">
                <SkeletonBase v-else width="100%" height="100%" />
            </div>
            <div class="intake__info">
                <div v-if="intakeInfo.title" class="intake__title">
                    {{ intakeInfo.title }}
                </div>
                <div v-if="intakeInfo.description" class="intake__description">
                    {{ intakeInfo.description }}
                </div>
            </div>
        </div>
        <div class="intake__list">
            <div class="intake__status">
                <div class="intake__status-name">
                    Questions
                </div>
                <div class="intake__status-value">
                    <div class="intake__progress">
                        <div v-if="questionsCount" class="intake__progress-count">
                            {{ questionsCount }}
                        </div>
                        <div v-else class="intake__checkmark">
                            <span class="intake__checkmark-icon" />
                        </div>
                        <div class="intake__progress-bar">
                            <div class="intake__progress-bar--completed" :style="questionsProgressStyle" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="intake__status">
                <div class="intake__status-name">
                    Checkout
                </div>
                <div class="intake__status-value">
                    Pending
                </div>
            </div>
        </div>
        <div class="intake__actions">
            <HButton
                variant="secondary"
                :loading="isLoading"
                :disabled="isLoading"
                full-width
                data-test="incomplete-panel-continue-button"
                @click="handleClickAction('continue')"
            >
                Complete Visit
            </HButton>
            <HButton
                variant="tertiary"
                :loading="isLoading"
                :disabled="isLoading"
                full-width
                data-test="incomplete-panel-cancel-button"
                @click="handleClickAction('cancel')"
            >
                Cancel
            </HButton>
        </div>
    </div>
</template>

<script>
import { generateProgress } from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/utils/generateProgress';
import { getIncompleteIntakeInfo } from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/utils/getIncompleteIntakeInfo';
import HButton from '@/components/Shared/HButtonV2';
import SkeletonBase from '@/components/Shared/Skeletons/Base/SkeletonBase';

export default {
    name: 'PanelCardContent',
    components: {
        SkeletonBase,
        HButton,
    },
    props: {
        incompleteIntake: {
            type: Object,
            required: true,
        },
        productTemplatesMap: {
            type: Map,
            required: true,
        },
        onContinue: {
            type: Function,
            required: true,
        },
        onCancel: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        intakeInfo() {
            return getIncompleteIntakeInfo(this.productTemplatesMap, this.incompleteIntake);
        },
        questionsCount() {
            const { status } = this.incompleteIntake;

            if (status?.complete?.total === status?.questions?.total) {
                return null;
            }

            return `${status?.complete?.total || 0}/${status?.questions?.total || 0}`;
        },
        questionsProgressStyle() {
            return {
                width: `${generateProgress(this.incompleteIntake.status?.complete?.total, this.incompleteIntake.status?.questions?.total)}%`,
            };
        },
    },
    methods: {
        async handleClickAction(action) {
            try {
                this.isLoading = true;

                if (action === 'continue') {
                    await this.onContinue(this.incompleteIntake);
                } else {
                    await this.onCancel(this.incompleteIntake);
                }
            } catch (error) {
                console.error(`Error while click action ${action}`, error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.intake {
    width: 100%;
    display: flex;
    min-width: 0;
    padding: 1.25rem 0;
    gap: 1.5rem;
    flex-direction: column;

    &__content {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        min-width: 36px;
        height: 36px;
        border-radius: 0.5rem;
        background-color: $gray-75;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }

    &__title {
        @include caption;
        font-weight: 700;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__description {
        @include caption-sm;
        color: $neutral-400;
    }

    &__list {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__status {
        display: flex;
        width: 100%;
        min-width: 0;
        gap: 0.5rem;
        align-items: center;
        justify-content: space-between;

        &-name {
            @include caption-sm;
            flex: 1 1 auto;
            font-weight: 700;
        }

        &-value {
            @include caption-sm;
            flex: 1 1 auto;
            font-weight: 700;
            text-align: right;
            color: $neutral-400;
        }
    }

    &__progress {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;

        &-count {
            @include caption-sm;
            color: $black-100;
            font-weight: 700;
        }

        &-bar {
            width: 100%;
            height: 6px;
            border-radius: 8px;
            background-color: $calm-green-100;
            overflow: hidden;
        }

        &-bar--completed {
            height: 100%;
            background-color: $calm-green-200;
        }
    }

    &__checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-color: $calm-green-200;
        border-radius: 100%;

        &-icon {
            position: relative;
            width: 8px;
            height: 4px;
            margin-top: -3px;
            border-left: 1.5px solid #fff;
            border-bottom: 1.5px solid #fff;
            transform: rotate(-50deg);
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }
}

@media ($min-810) {
    .intake {
        padding: 2rem 1.5rem;

        &__title {
            @include body;
            font-weight: 700;
        }

        &__description {
            @include caption;
        }

        &__image {
            width: 74px;
            min-width: 74px;
            height: 74px;
        }

        &__actions {
            flex-direction: row;
        }

        &__status {
            &-name {
                @include caption;
                font-weight: 700;
            }

            &-value {
                @include caption;
                font-weight: 700;
                color: $neutral-400;
            }
        }

        &__progress {
            &-count {
                @include caption;
                color: $black-100;
                font-weight: 700;
            }

            &-bar {
                height: 8px;
            }
        }
    }
}
</style>
