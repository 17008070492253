<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11 6L11 12C11 13.1046 11.8954 14 13 14L18 14" :stroke="color" stroke-width="2" />
        <path d="M5 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V4C2 2.34315 3.34315 1 5 1Z" :stroke="color" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'SubscriptionsIcon',
    props: {
        color: {
            type: String,
            default: '#b1b1b1',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
