<template>
    <div class="why-verify">
        <h2>Why do we need you to verify your account?</h2>
        <ol>
            <li><strong>Compliance with Regulations:</strong> {{organization_name}} may need to comply with healthcare regulations to ensure the security and privacy of health information.</li>
            <li><strong>Patient Safety:</strong> Verifying the identity of individuals is crucial for patient safety, ensuring that the right person is accessing {{organization_name}}’s services and health-related information.</li>
            <li><strong>Prescription Management:</strong> {{organization_name}}’s involvement in prescribing medications or providing health-related advice, identity verification helps confirm the patient's identity for accurate and safe health recommendations.</li>
            <li><strong>Fraud Prevention:</strong> Identity verification helps prevent fraud and abuse of {{organization_name}}’s services, reducing the risk of individuals using false information for unauthorized access or obtaining health-related services under false pretenses.</li>
            <li><strong>Legal and Ethical Considerations:</strong> Maintaining the legal and ethical relationship between healthcare providers and patients is important in {{organization_name}}’s services, and identity verification contributes to the integrity of this relationship.</li>
            <li><strong>Security of Health Information:</strong> Identity verification enhances the overall security of health information on {{organization_name}}, protecting against unauthorized access and potential breaches.</li>
        </ol>
        <p>In summary, the need for identity verification in {{organization_name}} platforms aligns with similar considerations as in telehealth, focusing on regulatory compliance, patient safety, fraud prevention, legal and ethical standards, security, and access regulation.</p>
    </div>
</template>

<script>
    export default {
        name: "WhyVerify",
        props: {
            organization_name: {
                type: String,
                required: true
            },
        }
    }
</script>

<style lang="scss">
@import "@/assets/scss/_type.scss";

.why-verify {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h2 {
        @extend %headingSm;
    }

    ol {
        @extend %body;

        li {
            padding: .5rem 0;
        }
    }

    p {
        @extend %body;
    }

    @media (min-width: 1280px) {
        h2 {
            font-size: 3rem;
            line-height: 3.5rem;
            font-weight: 300;
        }
    }
}

</style>
