<template>
	<nav class="h-pagination">
		<button :disabled="!hasPrevPage" @click="handlePrevNextPage('prev')"><ion-icon name="chevron-back-outline"></ion-icon></button>
		<ul>
			<li v-for="page in pagesRange" :class="{ active: page === currentPage }" @click="handlePageChange(page)">{{ page }}</li>
		</ul>
        <button :disabled="!hasNextPage" @click="handlePrevNextPage('next')">
            <ion-icon name="chevron-forward-outline" />
        </button>
	</nav>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'Pagination',
	props: {
		totalPages: {
			type: Number,
			required: true,
		},
		currentPage: {
			type: Number,
			required: true,
		},
		pageNumberLimit: {
			type: Number,
			required: true,
		},
		pagingCounter: {
			type: Number,
			required: true,
		},
		hasPrevPage: {
			type: Boolean,
			required: true,
		},
		hasNextPage: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			limit: 0,
			min: 0,
			max: 0,
		};
	},
	created() {
		this.limit = this.pageNumberLimit;
		this.min = this.pagingCounter;
		this.max = this.pageNumberLimit;
	},
	computed: {
		pagesRange() {
			if (this.totalPages < this.max) {
				return _.range(this.min, this.totalPages + 1);
			} else {
				return _.range(this.min, this.max + 1);
			}
		},
	},
	methods: {
		handlePageChange(page) {
			this.$emit('handlePageChange', page);
		},
		handlePrevNextPage(option) {
			if (option === 'prev' && (this.currentPage - 1) % this.limit === 0) {
				this.min = this.min - this.limit;
				this.max = this.max - this.limit;
			}
			if (option === 'next' && this.currentPage + 1 > this.max) {
				this.min = this.min + this.limit;
				this.max = this.max + this.limit;
			}
			this.$emit('handlePageChange', option === 'prev' ? this.currentPage - 1 : this.currentPage + 1);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.h-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: 100%;
	padding: 1rem 0;

	ul {
		display: flex;
		gap: 0.5rem;
		margin: 0;
		padding: 0;
	}

	li {
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.875rem;
		font-weight: 500;
		cursor: pointer;

		&:hover {
			background-color: $bg-medium;
		}
	}

	.active {
		color: $white-100;
		background-color: $charcoal;

		&:hover {
			background-color: $charcoal-hover;
		}
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		border: none;
	}
}
</style>
