<template>
    <div>
        <div class="testimonials-text h-body">
            {{ testimonial }}
        </div>
        <div class="testimonials-patient">
            <div class="testimonials-patient__info">
                <div class="testimonials-patient__info__avatar">
                    <img :src="profile_image" alt="" />
                </div>
                <div class="testimonials-patient__info__name h-body">
                    {{ full_name }}
                </div>
            </div>
            <div v-if="member_since" class="testimonials-patient__since h-caption">
                Member Since {{ formatMemberSince }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Testimonials',
    props: {
        full_name: {
            type: String,
            required: true,
        },
        profile_image: {
            type: String,
            required: true,
        },
        testimonial: {
            type: String,
            required: true,
        },
        member_since: {
            type: String,
        },
    },
    computed: {
        formatMemberSince() {
            return this.member_since ? moment(this.member_since).format('MMM YYYY') : '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

.testimonials-text {
    color: #fff;
    text-shadow: 0px 4px 4px rgba($black-100, 0.25);
    margin-bottom: 1.5rem;
}
.testimonials-patient {
    display: flex;
    justify-content: space-between;

    &__info {
        display: flex;
        gap: 1rem;

        &__avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__name {
            font-weight: 700;
            color: #fff;
        }
    }
    &__since {
        color: rgba($white-100, 0.5);
    }
}
</style>
