<template>
    <div class="intake-question">
        <div class="intake-question__header">
            <h2>{{ question.label }}</h2>
            <p class="intake-question__description strong text-dim-60">
                Select all that apply
            </p>
        </div>
        <div class="intake-question__options">
            <div
                v-for="option in question.options"
                :key="option.value"
                class="intake-question__option"
            >
                <input
                    :id="option.value"
                    v-model="selectedResponses"
                    type="checkbox"
                    :value="option.value"
                    class="hidden-radio"
                    @change="onOptionChange"
                />
                <label :for="option.value">
                    <div>{{ option.label }}</div>
                    <div v-if="selectedResponses.includes(option.value)">
                        <img src="@/assets/images/icons/checkmark.svg" alt="">
                    </div>
                </label>
            </div>
            <div class="intake-question__option">
                <input
                    id="none-of-above"
                    :checked="!selectedResponses.length"
                    type="checkbox"
                    class="hidden-radio"
                    @change="onNoneChange"
                />
                <label for="none-of-above">
                    <div>None of the above</div>
                    <div v-if="!selectedResponses.length">
                        <img src="@/assets/images/icons/checkmark.svg" alt="Selected">
                    </div>
                </label>
            </div>
        </div>
        <div class="pharmacy-radio-button">
            <h-button
                variant="primary"
                :loading="submitting"
                full_width
                data-test="continue-button"
                @click="handleContinue"
            >
                Continue
            </h-button>
        </div>
        <div class="intake-certification">
            <h-certification />
        </div>
    </div>
</template>

<script>

import HButton from '@/components/Shared/HButtonV2.vue';
import HCertification from '@/components/Shared/HCertification.vue';

export default {
    name: 'PharmacyCheckbox',
    components: {
        HButton,
        HCertification,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        medicalProfile: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            submitting: false,
            selectedResponses: [],
        };
    },
    created() {
        this.initializeCheckbox();
    },
    methods: {
        initializeCheckbox() {
            const pregnantOptions = ['pregnant', 'planning_pregnancy', 'breastfeeding'];

            this.selectedResponses = this.question.options.filter(option =>
                pregnantOptions.includes(option.value) && this.medicalProfile[option.value])
                .map(option => option.value);
        },
        onNoneChange(event) {
            if (event.target.checked) {
                this.selectedResponses = [];
                this.$nextTick(() => {
                    this.handleContinue();
                });
            }
        },
        handleContinue() {
            const data = {};

            this.question.options.forEach(option => {
                data[option.value] = this.selectedResponses.includes(option.value);
            });

            setTimeout(() => {
                this.submitting = true;
                this.$emit('continue', data);
            }, 300);
        },
    },
};
</script>

<style lang="scss" scoped>
.pharmacy-radio-button {
    width: 100%;
    max-width: 495px;
    margin: 2.5rem auto 0;

    button {
        width: 100%;
    }
}
</style>
