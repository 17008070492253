<template>
    <WidgetContainer class="widget__container">
        <div class="widget__content">
            <skeleton-base width="20%" height="32px" />
            <skeleton-base class="widget__header" width="80%" height="40px" />
            <skeleton-text-block class="widget__skeleton" color="#e7e7e7" />
        </div>
        <skeleton-base class="widget__skeleton" width="30%" height="32px" />
    </WidgetContainer>
</template>

<script>
import SkeletonBase from '@/components/Shared/Skeletons/Base/SkeletonBase.vue';
import SkeletonTextBlock from '@/components/Shared/Skeletons/Base/SkeletonTextBlock.vue';
import WidgetContainer from '@/components/Dashboard/Widgets/WidgetContainer';

export default {
    name: 'WidgetSkeleton',
    components: {
        WidgetContainer,
        SkeletonBase,
        SkeletonTextBlock,
    },
};
</script>

<style lang="scss" scoped>
.widget {
    &__container {
        justify-content: space-between;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__header {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }

    &__skeleton {
        margin-top: 1rem;
    }
}
</style>
