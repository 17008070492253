<template>
    <div id="app">
        <Loading v-if="orgIdLoading" color="#222" />
        <router-view v-if="orgId && !orgIdLoading" />
    </div>
</template>

<script>
import LogRocket from 'logrocket';
import VueMask from 'v-mask';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import VueYouTubeEmbed from 'vue-youtube-embed';
import { mapActions, mapState } from 'vuex';

import Loading from '@/components/Loading.vue';
import Favicon from '@/services/Favicon';

Vue.use(VueMask);
Vue.use(VueYouTubeEmbed);

if (process.env.NODE_ENV === 'production') {
    LogRocket.init('grs7qm/healthspan');
}

Vue.use(VModal);

export default {
    data() {
        return {
            favicons: null,
        };
    },

    async created() {
        //we grab the orgId and settings if they are not in localStorage or state
        try {
            if (!this.$store.state.orgId.data && !this.$store.state.orgId.promise) {
                this.$store.dispatch('initializeOrganizationId', window.location.host);
            }
        } catch (error) {
            // supress, handled in the promise
            console.error(error);
        }

        try {
            await this.$http.interceptors.response;
            const settings = await this.get_organization_settings();

            if (settings.favicons) {
                this.favicons = settings.favicons;
                this.favicons.forEach(f => {
                    Favicon.setFavicons(f);
                });
            }

            if (this.organizationSettings.name) {
                window.document.title = `${this.organizationSettings.name} | Patient Portal`;
            }

        } catch (error) {
            if (error?.status === 401 && error.config && !error.config.__isRetryRequest) {
                await this.$store.dispatch('logout');
            }
        }
    },
    components: {
        Loading,
    },
    computed: {
        ...mapState({
            orgId: state => state.orgId.data,
            orgIdLoading: state => state.orgId.loading,
            organizationSettings: state => state.organizationSettings.data,
            organization: state => state.organization.organization,
        }),
    },
    methods: {
        ...mapActions({
            get_organization_settings: 'getOrganizationSettings',
        }),
    },
};
</script>

<style lang="scss">

.footer {
    position: absolute;
    bottom: -163px;
    text-align: center;
    padding-bottom: 1rem;
    font-size: .875rem;
    font-weight: 500;
    color: $stone500;
    width: 100%;

    a {
        color: $stone500;

        &:hover {
            text-decoration: none;
        }
    }
}

@import "src/assets/scss/main.scss";

</style>
