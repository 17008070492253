<template>
    <WidgetContainer class="widget__container">
        <img :src="src" :alt="alt" class="widget__image" />
        <div class="widget__content">
            <HBadge
                v-if="label"
                :label="label"
                preset="blue"
                dark
                small
                class="widget__badge"
            />
            <div class="widget__header">
                {{ header }}
            </div>
            <div class="widget__description">
                <slot></slot>
            </div>
        </div>
        <h-button
            v-if="onClick"
            variant="tertiary-dark"
            class="widget__button"
            data-test="primary-widget-button"
            @click="onClick"
        >
            {{ buttonText }}
        </h-button>
    </WidgetContainer>
</template>

<script>
import HBadge from '@/components/Shared/HBadge';
import HButton from '@/components/Shared/HButtonV2';
import WidgetContainer from '@/components/Dashboard/Widgets/WidgetContainer';

export default {
    name: 'PrimaryWidget',
    components: {
        HBadge,
        HButton,
        WidgetContainer,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: null,
        },
        header: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        onClick: {
            type: Function,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.widget {
    &__badge {
        width: fit-content;
        z-index: 1;
        margin-bottom: 1.5rem;
    }

    &__container {
        border: 1px solid $gray-150;
        justify-content: space-between;
    }

    &__content {
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

    &__header {
        @include heading-sm;
        color: $white;
        z-index: 1;
        margin-bottom: 0.5rem;
    }

    &__description {
        @include body;
        color: $gray-400;
        z-index: 1;
    }

    &__button {
        z-index: 2;
        justify-self: end;
        margin-top: 1.5rem;
        max-width: fit-content;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }

    @media (max-width: 768px) {
        &__header {
            @include heading-sm;
        }
    }

    @media (max-width: 430px) {
        &__header {
            @include body;
            font-weight: 700;
        }

        &__description {
            @include caption
        }

        &__button {
            max-width: 100%;
            flex: 1;
        }
    }
}
</style>
