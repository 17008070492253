import AppointmentsIcon from '../Assets/AppointmentsIcon.vue';
import ChatIcon from '../Assets/ChatIcon.vue';
import LabIcon from '../Assets/LabIcon.vue';
import OrderIcon from '../Assets/OrderIcon.vue';
import VisitIcon from '../Assets/VisitIcon.vue';

export const NOTIFICATION_ICON_MAP = {
    message: ChatIcon,
    lab: LabIcon,
    appointments: AppointmentsIcon,
    visit: VisitIcon,
    order: OrderIcon,
};

export const NOTIFICATION_ROUTE_MAP = {
    visit: 'orders',
    lab: 'labs',
    message: 'messages',
    order: 'orders',
    welcome: 'dashboard',
};

export const NOTIFICATION_CATEGORY = {
    welcome: 'welcome',
};
