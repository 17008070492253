<template>
<div class="appointment__physician-list">
    <ul class="appointment__book-list providers__list">
        <li v-for="physician in physician_list">
            <div class="appointment__physician--info">
                <span v-if="physician.profile_img.url" :style="{'background-image': `url(${physician.profile_img.url})`}"
                      class="appointment__book-avatar"></span>
                <span class="appointment__book-bio">
					<span
                        class="appointment__book-title">Dr. {{ physician.first_name }} {{ physician.last_name }}</span>
					<span class="appointment__book-desc">NPI #{{ physician.npi_num }} </span>
				</span>
            </div>
            <div class="providers__bio" v-if="physician.bio && physician.bio.bio_description" >
                <p><strong>About</strong></p>
                <p>{{physician.bio.bio_description}}</p>
            </div>
            <div class="providers__states" v-if="!physician_states.loading">
                <p><strong>Available States</strong></p>
                <p class="providers__state-items">
                    {{physician.states}}
                </p>
            </div>

        </li>
    </ul>

</div>
</template>
<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'ProvidersList',
    components: {},
    props: ['physicians'],
    data() {
        return {
            physician_list: null
        }
    },
    async created() {
        this.physician_list = this.physicians.data;
        try {
            let physicians  = this.physicians.data.map(async physician => {
                try {
                    let states = await this.get_physician_states({
                        physician: physician._id
                    });
                    physician.states = states.map(s => s.abbreviation).join(' ')
                    return physician;
                } catch (e) {
                    throw e;
                }
            });
            this.physician_list = await Promise.all(physicians);
            this.$forceUpdate();
        } catch (e) {
            console.log('what is the error', e );
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            organization_states: state => state.organization.organization_states,
            physician_states: state => state.physician.physician_states
        })
    },
    methods: {
        ...mapActions({
            get_physician_states: "physician/getPhysicianStates",
        })
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/_colors.scss";

    .providers {
        &__list {
            li {
                display: flex;
                flex-direction: column !important;
                align-items: start;
            }
        }

        &__bio {
            padding-top: 2rem;
        }

        &__states {
            margin-top: 1rem;
        }

        &__state-items {
            font-size: 1rem;
            color: $neutral-400;
        }
    }
</style>
