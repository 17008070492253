<template>
    <div class="progress-step-bubble-box">
        <div :class="is_active ? 'progress-step-bubble' : 'highlighted-step-bubble' ">
            <div v-if="!is_active && is_step" class="inner"></div>
            <div v-if="is_active">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    style="display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 5.33333; overflow: visible;"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="m4 16.5 8 8 16-16" fill="none"></path>
                </svg>
            </div>
        </div>
        <div :class="is_active ? 'step-bubble-bridge-horizontal' : 'step-bubble-bridge-dotted-horizontal'">
        </div>
    </div>
</template>

<script>

export default {
    name: 'BubbleStep',
    props: ['is_active', 'is_step', 'is_current'],
};

</script>
