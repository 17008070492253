<template>
    <div class="protocol-frequency">
        <div class="protocol-frequency__dosage" :class="{'protocol-frequency__dosage-hide': custom_frequency}">
            <label class="protocol-body480 strong" >Frequency</label>
            <div class="protocol-frequency__cell" v-if="dosage_frequencies.length === 1">
                <p class="protocol-body480">{{ dosage_frequency.frequency.label }}</p>
                <p v-if="dosage_frequency && dosage_frequency.instructions"
                class="protocol-frequency__cell--ins protocol-body480">
                    {{ parseFrequencyInstructions(dosage_frequency) }}</p>
            </div>
            <div v-if="dosage_frequencies.length > 1"
                class="protocol-frequency__dropdown">
                    <select id="dosage-frequency" v-model="selected_frequency" class="protocol__select"
                            name="dosage-frequency"
                            @change="on_frequency(selected_frequency)">
                        <option v-for="(value, index) in dosage_frequencies"
                                :value="value">
                            {{ value.frequency.label }}
                        </option>
                    </select>

                <p v-if="dosage_frequency && dosage_frequency.instructions"
                class="protocol-caption">
                    {{ parseFrequencyInstructions(dosage_frequency) }}</p>
            </div>
        </div>
        <div v-if="!dosage_frequency.has_custom_quantity" class="protocol-frequency__custom" >
            <div @click="handleSelectCustom" class="protocol-frequency__option" :class="{'protocol-frequency__option-selected': custom_frequency}">
                <div class="protocol-frequency__option-top">
                    <div>
                        <img v-if="custom_frequency" src="@/assets/icons/checkmark-circle.svg" alt="" />
                        <div v-else class="protocol-frequency__option-select"></div>
                    </div>
                    <div class="protocol-frequency__option-label">
                        <p class="protocol-body strong">Need a Custom {{product}} Protocol?</p>
                        <p class="protocol-body">Work with our Clinical Team to find you the right protocol for {{product}}</p>
                    </div>
                </div>
                <div class="protocol-caption">Our clinical team can recommend a treatment protocol for you based on your biomarkers and health goals.</div>
            </div>
            <div v-if="custom_frequency">
                <p class="protocol-caption strong mb-75">Is there anything else you would like us to know?</p>
                <textarea placeholder="Type your message here" v-model="comment" @input="handleEmitComment"></textarea>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'TreatmentFrequency',
    components: {},
    props: [
        'dosage_frequency',
        'dosage_frequencies',
        'dose_quantity',
        'dosage_strength',
        'product_units',
        'product',
        'custom_comment'
    ],
    data() {
        return {
            selected_frequency: null,
            custom_frequency: false,
            comment: this.custum_comment
        }
    },
    created() {
        this.selected_frequency = this.dosage_frequency;
    },
    methods: {
        on_frequency(selected_frequency) {
            this.$emit('onFrequencyChange', selected_frequency);
            this.$forceUpdate();
        },
        parseFrequencyInstructions(frequency) {
            const instructions = frequency.instructions;
            if (instructions.includes('${dosageWeekly}')) {
                const doseQuantity = this.dose_quantity ? this.dose_quantity : 1;
                const calculateDose = (this.product_units * doseQuantity * this.dosage_strength.dose) / 4;
                return instructions.replace('${dosageWeekly}', calculateDose);
            } else if (instructions.includes('${compoundedDosage}')) {
                const doseQuantity = this.dose_quantity ? this.dose_quantity : 1;
                const calculateDose = (this.product_units * doseQuantity) / 4;

                return instructions.replace('${compoundedDosage}', calculateDose * this.dosage_strength.dose);
            } else {
                return instructions;
            }
        },
        handleSelectCustom() {
            this.custom_frequency = !this.custom_frequency;
            this.$emit('onCustomSelect', this.custom_frequency);
        },
        handleEmitComment() {
            this.$emit('input', this.comment);
        }
    }
}
</script>
<style lang="scss"></style>
