<template>
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M34 34L14 14M14 14V34M14 14H34"
            :stroke="stroke"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'ArrowUpLeftIcon',
    props: {
        stroke: {
            type: String,
            default: '#ffffff',
        },
    },
};
</script>
