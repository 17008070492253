<template>
    <div class="settings-section">
        <div class="settings-alert">
            <transition name="alert-fade" mode="out-in">
                <HAlert v-if="alert.status" :message="alert.message" :status="alert.status" @dismiss="closeAlert" />
            </transition>
        </div>
        <div class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div class="settings-section__header">
            <h2>Password & Security</h2>
            <p>Manage your security preferences</p>
        </div>
        <form class="settings-password" @submit.prevent="updatePassword">
            <h-text-input-secondary
                id="currentPassword"
                v-model="password.currentPassword"
                name="currentPassword"
                label="Current Password"
                type="password"
                :vuelidate="$v.password.currentPassword"
                :errors="[$v.password.currentPassword.$invalid && $v.password.currentPassword.$dirty && errorMessages.PASSWORD]"
            />
            <h-text-input-secondary
                id="newPassword"
                v-model="password.newPassword"
                name="newPassword"
                label="New Password"
                type="password"
                :vuelidate="$v.password.newPassword"
                :errors="[
                    !$v.password.newPassword.required && $v.password.newPassword.$dirty && errorMessages.NEW_PASSWORD,
                    !$v.password.newPassword.notSame && $v.password.newPassword.$dirty && errorMessages.OLD_PASSWORD,
                ]"
            />
            <div v-if="$v.password.newPassword.$dirty" class="settings-password__validation">
                <ul>
                    <li :class="{active: $v.password.newPassword.hasLength && $v.password.newPassword.$dirty}">
                        <ion-icon name="checkmark-circle" />
                        8 characters minimum
                    </li>
                    <li :class="{active: $v.password.newPassword.containsLowercase && $v.password.newPassword.$dirty}">
                        <ion-icon name="checkmark-circle" />
                        one lowercase character
                    </li>
                    <li :class="{active: $v.password.newPassword.containsUppercase && $v.password.newPassword.$dirty}">
                        <ion-icon name="checkmark-circle" />
                        one uppercase character
                    </li>
                    <li :class="{active: $v.password.newPassword.containsNumber && $v.password.newPassword.$dirty}">
                        <ion-icon name="checkmark-circle" />
                        one number
                    </li>
                    <li :class="{active: $v.password.newPassword.notSame && $v.password.newPassword.$dirty}">
                        <ion-icon name="checkmark-circle" />
                        not current password
                    </li>
                </ul>
            </div>
            <h-button 
                variant="secondary" 
                :loading="submitting" 
                :disabled="$v.$invalid" 
                class="settings-section__btn" 
            >
                Submit
            </h-button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HAlert from '@/components/Shared/HAlert.vue';
import HButton from '@/components/Shared/HButtonV2.vue';
import HTextInputSecondary from '@/components/Shared/HTextInputSecondary.vue';
import Alert from '@/helpers/alert';
import { errorMessages, newPasswordValidation, requiredValidation  } from '@/helpers/validations';

export default {
    name: 'PasswordSection',
    components: {
        HTextInputSecondary,
        HButton,
        HAlert,
    },
    data() {
        return {
            submitting: false,
            password: {
                currentPassword: null,
                newPassword: null,
            },
            errorMessages,
            alert: new Alert(),
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
    },
    validations: {
        password: {
            currentPassword: requiredValidation,
            newPassword: newPasswordValidation,
        }, 
    },
    methods: {
        ...mapActions({
            resetPassword: 'user/resetPassword',
        }),
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        closeAlert() {
            this.alert.dismiss();
        },
        async updatePassword() {
            const payload = {
                current_password: this.password.currentPassword,
                new_password: this.password.newPassword,
            };
            
            this.submitting = true;

            try {
                await this.resetPassword(payload);
                this.alert.showWithTimeout('Password updated successfully', 'success');
            } catch (error) {
                this.alert.showWithTimeout(error.message, 'critical');
            } finally {
                this.submitting = false;
                this.$nextTick(() => {
                    this.$v.$reset();
                });
                this.password = {
                    current_password: null,
                    new_password: null,
                };
            }
        },
    },
};
</script>
