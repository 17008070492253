<template>
    <div class="subscription-modal__container snooze-modal">
        <modal-header-icon icon="cube"
                           :title="'See you soon'"
                           @onClose="on_close">
        </modal-header-icon>
        <div class="subscription-modal__body">
            <div class="subscription-modal__view" v-if="view === 'datePicker'">
                <p class="ppe-small-title">Select the date you would like to reschedule your treatment to ship</p>
                <p class="ppe-description">Your treatment will ship and resume on the date selected.</p>
                <div class="subscription-modal__loading" v-if="active_prescriptions.loading">
                    <div class="ppe-loader ppe-loader__dual"></div>
                </div>
                <div class="subscription-modal__cal" v-if="!active_prescriptions.loading && active_prescriptions.data">
                    <date-picker
                        :inline="true"
                        :value="default_date"
                        @selected="on_date_selected"
                        calendar-class="ppe-calendar"
                        :disabled-dates="disabled_dates"
                    ></date-picker>
                </div>
                <modal-footer
                    :has_cancel="true"
                    @onCancel="on_close"
                    @onSubmit="on_submit">
                </modal-footer>
            </div>
            <div class="subscription-modal__view" v-if="view === 'confirm'">
                <modal-confirm :title="confirm_title"
                               :confirm_button_text="button_text.reschedule"
                               cancel_button_text="Cancel"
                               @onCancel="on_return"
                               @onConfirm="on_reschedule()"
                >
                </modal-confirm>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState, mapMutations} from "vuex";
import ModalHeaderIcon from "@/components/Dashboard/Modals/Components/ModalHeaderIcon.vue";
import Datepicker from "vuejs-datepicker";
import ModalFooter from "@/components/Dashboard/Modals/Components/ModalFooter.vue";
import ModalConfirm from "@/components/Dashboard/Modals/Components/ModalConfirm";
import moment from "moment";
import {getShipmentDate} from "@/helpers/subscription";

export default {
    name: 'SubscriptionSnoozeModal',
    components: {
        ModalHeaderIcon: ModalHeaderIcon,
        DatePicker: Datepicker,
        ModalFooter: ModalFooter,
        ModalConfirm: ModalConfirm
    },
    props: ['subscription', 'vendor'],
    data() {
        return {
            view: 'datePicker',
            disabled_dates: {
                to: new Date(),
                from: new Date(),
                dates: [],
            },
            default_date: null,
            confirm_title: null
        }
    },
    async created() {

        try {
            const payload = {
                patient: this.user._id,
                subscription_id: this.subscription._id,
                prescription_id: this.subscription.prescriptions[0]
            }
            await this.get_active_prescription(payload);
            this.disabled_dates.to = moment().endOf('day').toDate();
            this.disabled_dates.from = new Date(this.vendor.current_period_end * 1000);
            const currentShipmentDate = getShipmentDate(this.vendor)

            if (currentShipmentDate) {
                this.disabled_dates.dates = [new Date(currentShipmentDate * 1000)];
            }
            this.default_date = new Date(currentShipmentDate ? currentShipmentDate * 1000 : new Date());
            this.default_date = moment(this.default_date).add(1, 'day').toDate();
        } catch (e) {
              console.log('created() error', e);
        }

    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            active_prescriptions: state => state.prescriptions.active_prescription,
            button_text: state => state.subscriptions.buttons,
            update_subscription_billing_cycle: state => state.subscriptions.update_subscription_billing_cycle
        })
    },
    methods: {
        ...mapActions({
            get_active_prescription: 'prescriptions/getActivePrescription',
            update_billing_cycle: 'subscriptions/updateBillingCycle',
            get_subscriptions: 'subscriptions/getSubscriptions',
            update_subscription: 'subscriptions/updateSubscription',
        }),
        ...mapMutations({
            set_button: 'subscriptions/setButtonText'
        }),
        on_return() {
          this.view = 'datePicker';
        },
        on_close() {
            this.$emit('onModalClose', true);
        },
        on_date_selected(date) {
            this.default_date = date;
        },
        on_submit() {
            this.confirm_title = `Are you sure you want to reschedule your treatment to ship on ${moment(this.default_date).format('MM/DD/YYYY')}?`
            this.view = 'confirm';
            this.$forceUpdate();
        },
        async on_reschedule() {
            const date = new Date(this.default_date).getTime();
            try {
                this.set_button({id: 'reschedule', value: 'Submitting...'});

                await this.update_billing_cycle({
                    patient: this.user._id,
                    subscriptionId: this.subscription._id,
                    newTime: date
                });
                await this.get_subscriptions({
                    patient: this.user._id
                });
                this.set_button({id: 'reschedule', value: 'Reschedule'});
                this.on_close();
                this.$emit('onRescheduleSuccess');
            } catch (e) {
                console.log('Error on_reschedule', e);
            }
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/patient-portal/calendar.scss";


.subscription-modal {
    &__cal {
        margin-top: 2rem;
    }

    &__loading {
        height: 459px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.snooze-modal {


    .modal-footer__container {
        margin-top: 2rem;
        padding: 0;
    }
}
</style>
