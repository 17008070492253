<template>
    <div class="filter">
        <div 
            class="filter-item"
            :class="{ 'filter-active': localSelectedOption === defaultOption }"
            @click="selectOption(defaultOption)"
        >
            {{ defaultOption }}
        </div>
        <div 
            v-for="option in options" 
            :key="option" 
            class="filter-item"
            :class="{ 'filter-active': localSelectedOption === option }"
            @click="selectOption(option)"
        >
            {{ option }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationFilter',
    props: {
        defaultOption: {
            type: String,
            default: 'All',
        },
        options: {
            type: Array,
            required: true,
        },
        selectedOption: {
            type: String,
            default: 'All',
        },
    },
    data() {
        return {
            localSelectedOption: this.selectedOption,
        };
    },
    watch: {
        selectedOption: {
            immediate: true,
            handler(newVal) {
                this.localSelectedOption = newVal;
            },
        },
    },
    methods: {
        selectOption(option) {
            this.localSelectedOption = option;
            this.$emit('filter-selected', option);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';

.filter {
    width: 100%;
    display: flex;
    gap: 0.35rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.filter-item {
    @include caption;
    font-weight: 500;
    border: 1px solid $border-light;
    padding: 0.5rem 1.25rem;
    border-radius: 2rem;
    cursor: pointer;
}

.filter-active {
    background-color: $charcoal;
    color: $white;
}

</style>
