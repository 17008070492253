export const SHIPPING_FORM_NAMES_BY_KEY = {
    apt_suite: 'Apt, Suite',
    city: 'City',
    country: 'Country',
    first_name: 'First Name',
    last_name: 'Last Name',
    mobile_number: 'Mobile Number',
    phone_number: 'Phone Number',
    state: 'State',
    street_address: 'Street Address',
    zip_code: 'Zip Code',
};
