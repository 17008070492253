export const detectBrowser = () => {
    const userAgent = navigator.userAgent;

    switch (true) {
    case (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Edge') === -1):
        return 'chrome';
    case (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1):
        return 'safari';
    case userAgent.indexOf('Firefox') > -1:
        return 'firefox';
    case userAgent.indexOf('Edge') > -1:
        return 'edge';
    default:
        return 'unknown';        
    }
};
