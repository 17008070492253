<template>
    <div class="user-menu" :class="{'user-menu--open': open}">
        <ul>
            <li>
                <router-link :to="`/dashboard/${userId}/settings`" class="user-menu__item" data-test="settings-page">
                    <span>Settings</span>
                </router-link>
            </li>
            <li>
                <a to="/" class="user-menu__item" @click="handleLogout">
                    <span>Logout</span>
                    <power-icon />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import PowerIcon from '@/assets/icons/Power.vue';

export default {
    name: 'UserMenu',
    components: {
        PowerIcon,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userOpen: false,
        };
    },
    methods: {
        ...mapActions({
            logout: 'authenticate/logout',
        }),
        async handleLogout() {
            await this.logout().then(() => {
                this.$router.push({ path: '/login' });
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.user-menu {
    position: absolute;
    bottom: 16px;
    left: 65px;
    border-radius: 12px;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0.5rem;
    z-index: $z-tooltip;
    opacity: 0;
    transform: scale(0);
    transform-origin: 0 56px;
    transition: all 0.2s ease-out;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        @include caption;
        font-weight: 700;
        color: $charcoal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5.5rem;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: #f9f9f9;
            color: $charcoal;
            border-radius: 0.75rem;

            svg {
                path {
                    stroke: $charcoal;
                
                }
            }
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        bottom: 26px;
        left: -6.4px;
        background: #fff;
        border-radius: 4px;
        transform: rotate(45deg);
        z-index: -2;
    }

    &:before {
        content: "";
        width: 14px;
        height: 28px;
        position: absolute;
        background: #fff;
        left: 0;
        bottom: 20px;
        z-index: -1;
    }

    &--open {
        opacity: 1;
        transform: scale(1);
    }
}

</style>
