<template>
<div>
    <div class="appointment__empty-state">

        <div class="appointment__empty-container">
            <div class="appointment__empty-icon">
                <img src="@/assets/images/verification/schedule.svg"/>
            </div>
            <div class="appointment__empty-text">
                <h4 class="h4-account">No Upcoming Appointments</h4>
                <p>Appointments created in by your clinical team will shown here.</p>
<!--                Will be shown once we have appointment functionality in dashboard-->
                <button v-if="false" class="appointment__empty-button" @click="bookAppointment()">Book an Appointment</button>
            </div>

        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'AppointmentEmptyState',
    components: {},
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        async bookAppointment() {
            this.$emit('onBookAppointment', true);
        }
    }
}
</script>
<style lang="scss">

.appointment {
    &__empty-state {
        width: 100%;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
    }

    &__empty-container {
        padding-left: 24px !important;
        padding-right: 24px !important;
        margin: 0px !important;
        border: 1px solid rgb(228, 228, 228) !important;
        background-color: rgb(255, 255, 255) !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    &__empty-text {
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
        font-family: "Sofia Pro", sans-serif;

        p {
            font-family: "Sofia Pro", sans-serif;
            margin-top: 1rem;
        }
    }

    &__empty-button {
        padding: 16px 45px;
        background: $stoneBlack;
        border-radius: 3px;
        font-size: 14px;
        color: #fff !important;
        font-family: 'Sofia Semi Bold', sans-serif;
        letter-spacing: 1px;
        text-decoration: none !important;
        display: inline-block;
        border: none;
        margin-top: 1rem;
    }

    &__empty-icon {
        height: 150px;
        margin-top: 40px;
        text-align: center;

        img {
            height: 100%;
        }
    }

}


</style>
