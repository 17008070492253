<template>
    <a
        :href="href"
        :target="target"
        :data-variant="variant"
        :data-loading="loading"
    >
        <span class="label"><slot /></span>
    </a>
</template>

<script>
export default {
    name: 'HButtonLink',
    props: {
        label: {
            type: String,
            default: 'Continue',
        },
        href: {
            type: String,
            required: true,
        },
        target: {
            type: String,
            default: '_self',
        },
        variant: {
            type: String,
            default: 'primary',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

a {
    @extend %tag;
    text-align: center;
    position: relative;
    height: 56px;
    border-radius: 3.5rem;
    background-color: $charcoal;
    white-space: nowrap;
	padding: 1rem 1.5rem;
    transition: all 0.125s ease-in-out;

    .label {
        color: $white;
    }

	&:hover {
		background-color: $charcoal-hover;
        text-decoration: none;
		cursor: pointer;
	}

	&:active {
		background-color: $charcoal-active;
	}

    &:focus {
        outline: 0.25rem solid $gray-400;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }

    &[data-variant="secondary"] {
        @extend %caption;
        font-weight: 700;
        text-transform: none;
        height: 40px;
        padding: 0.65rem 1.5rem;
    }

    &[data-variant="tertiary"] {
        @extend %caption;
        font-weight: 700;
        text-transform: capitalize;
        height: 40px;
        background-color: $powder-blue-100;
        color: $charcoal;
        padding: 0.625rem 1.5rem;

        &:hover {
            background-color: rgba($powder-blue-100, 0.8);
        }

         &:active {
            background-color: $powder-blue-100;
        }

        .label {
            color: $charcoal;
        }
    }
}

a[data-loading="true"] {
	.label {
		visibility: hidden;
	}

	&:after {
		content: "";
        position: absolute;
        top: 0;
		left: 0;
		right: 0;
		bottom: 0;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 2px solid $gray-400;
        border-bottom-color: transparent;
        opacity: 1;
        animation: button-loading-spinner 1s ease infinite;
        margin: auto;
	}

	@keyframes button-loading-spinner {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

</style>
