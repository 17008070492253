<template>
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8.83333 14.1667H12.1667" :stroke="stroke" stroke-width="2" />
        <rect
            x="5.5"
            y="2.5"
            width="10"
            height="15"
            rx="3"
            :stroke="stroke"
            stroke-width="2"
        />
    </svg>
</template>

<script>
export default {
    name: 'MobilePhone',
    props: {
        stroke: {
            type: String,
            default: '#fff',
        },
    },
};
</script>
