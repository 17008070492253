import memoize from 'lodash/memoize';
import moment from 'moment';

export function downloadPdfToDevice(file, fileName) {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(file);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const badgeColorMapping = {
    'borderline-low': ' #FFA114',
    'borderline-high': ' #FFA114',
    'low': '#FF5850',
    'high': '#FF5850',
    'normal': '#0fc08d',
    'optimal': '#0fc08d',
};

export const rangeNameMapping = {
    'borderline-low': 'Needs Improvement',
    'borderline-high': 'Needs Improvement',
    'low': 'Low',
    'high': 'High',
    'normal': 'Normal',
    'optimal': 'Optimal',
};

export const createFormatDate = (format) => {
    return memoize((date) => moment(date).format(format));
};

export const errorMsgMapping = {
    'Phone number is not correct': 'Please enter a valid phone number.',
    'Invalid state for walk-in phlebotomy': 'Due to regulatory restrictions prohibiting the online prescribing of lab testing, we cannot provide lab testing in NY, NJ and RI.',
};
