<template>
    <HSidebar badge_label="Policy" @handleClose="handleClose">
        <div class="content">
            <h2>SMS Policy</h2>
            <p>
                By providing your phone number and opting into SMS communications, you consent to receive text
                messages related to your healthcare services, including appointment reminders, health-related
                updates, and other information as permitted under HIPAA.
            </p>
            <p>
                We are committed to protecting your privacy and maintaining the confidentiality of your personal
                health information. All SMS communications will comply with HIPAA regulations, and your information
                will not be shared with unauthorized parties.
            </p>
            <p>
                If you wish to opt-out of receiving SMS messages, you may do so at any time by logging into your
                patient portal or contacting us directly.
            </p>
        </div>
    </HSidebar>
</template>

<script>
import HSidebar from '@/components/Shared/HSidebar';

export default {
    name: 'SmsPolicySidePanel',
    props: {
        open: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    components: {
        HSidebar,
    },
    methods: {
        handleClose() {
            this.$emit('handleClose');
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > p {
    @include body;
    margin-bottom: 0;
  }
}
</style>
