export const getIncompleteIntakeInfo = (productTemplatesMap, intake) => {
    const productId = intake.template?.product_defaults?.[0];
    const bundleSettings = intake.template?.bundle_settings;
    const productTemplate = productTemplatesMap.get(productId);

    return {
        image: bundleSettings?.image || productTemplate?.product_images?.[0],
        title: bundleSettings?.title || productTemplate?.product_name,
        description: bundleSettings?.description || productTemplate?.description,
    };
};
