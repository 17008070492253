<template>
    <div>
        <div class="testimonials-text">
            {{ testimonial }}
        </div>
        <div class="testimonials-patient">
            <div class="testimonials-patient__info__avatar">
                <img :src="profile_image" alt="" />
            </div>
            <div class="testimonials-patient__name">{{ full_name }}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HTestimonials',
    props: {
        full_name: {
            type: String,
            required: true
        },
        profile_image: {
            type: String,
            required: true
        },
        testimonial: {
            type: String,
            required: true
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_type.scss";
@import "@/assets/scss/_colors.scss";

.testimonials-text {
    @extend %caption;
    color: $white-100;
    margin-bottom: 1rem;
}
.testimonials-patient {
    display: flex;
    align-items: center;
    gap: .5rem;

    &__name {
        @extend %caption;
        font-weight: 700;
        color: $white-100;
    }

    &__info {
        display: flex;
        gap: 1rem;

        &__avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }
    &__since {
        color: rgba($white-100, 0.5);
    }
}
</style>
