<template>
    <WidgetContainer v-if="alerts.length" class="widget__container">
        <div class="widget__content">
            <div class="widget__header">
                <HBadge
                    label="Needs attention"
                    dark
                    small
                    class="widget__badge"
                />
            </div>
            <div
                v-for="alert in alerts"
                :key="alert.type"
                class="widget__alert"
                role="button"
                tabIndex="0"
                @click="handleClickAlert(alert.type)"
            >
                <div class="widget__alert-content">
                    <div v-if="alert.title" class="widget__alert-title">
                        <MobilePhoneIcon v-if="alert.type === 'mobile-verification'" />
                        <CardIcon v-if="alert.type === 'id-verification'" />
                        <span>{{ alert.title }}</span>
                    </div>
                    <div v-if="alert.description" class="widget__alert-description">
                        {{ alert.description }}
                    </div>
                </div>
                <ArrowRightIcon class="widget__alert-arrow" fill="#fff" />
            </div>
        </div>
        <div v-if="hasMobileVerificationAlert">
            <transition name="sidebar" mode="out-in">
                <HSidebar
                    v-if="openedSidebar === 'mobile-verification'"
                    :open="openedSidebar === 'mobile-verification'"
                    badge_label="Mobile Verification"
                    @handleClose="handleCloseSidebar"
                >
                    <MobileVerification @submit-success="handleCloseSidebar" />
                </HSidebar>
            </transition>
        </div>
    </WidgetContainer>
</template>

<script>
import { mapState } from 'vuex';

import ArrowRightIcon from '@/assets/icons/ArrowRight';
import CardIcon from '@/assets/icons/Card';
import MobilePhoneIcon from '@/assets/icons/MobilePhone';
import MobileVerification from '@/components/Confirmation/LabSuccess/SubComponents/Results/components/MobileVerification';
import WidgetContainer from '@/components/Dashboard/Widgets/WidgetContainer';
import HBadge from '@/components/Shared/HBadge';
import HSidebar from '@/components/Shared/HSidebar';
import { getStateUser } from '@/domains/user/lib/getStateUser';

export default {
    name: 'AlertsWidget',
    components: {
        HBadge,
        WidgetContainer,
        ArrowRightIcon,
        MobilePhoneIcon,
        CardIcon,
        HSidebar,
        MobileVerification,
    },
    props: {
        alertTypes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            openedSidebar: null,
        };
    },
    computed: {
        ...mapState({
            user: getStateUser,
        }),
        hasMobileVerificationAlert() {
            return !this.user?.mobile_verified;
        },
        alerts() {
            return [
                {
                    type: 'mobile-verification',
                    title: 'Mobile Verification Required',
                    description: 'To provide assistance, our clinician team may need to communicate over text message with you regarding your treatment plan.',
                },
                {
                    type: 'id-verification',
                    title: 'Identity Verification',
                    description: 'Verify your identity to ensure secure, personalized care and seamless access to your treatment plan.',
                },
            ].filter(alert => this.alertTypes.includes(alert.type));
        },
    },
    methods: {
        handleClickAlert(alertType) {
            switch (alertType) {
            case 'mobile-verification':
                this.openedSidebar = alertType;
                break;
            case 'id-verification':
                this.$router.push({
                    name: 'verification',
                    params: { userId: this.user._id, steps: 'verify_account' },
                });
                break;
            default:
                break;
            }
        },
        handleCloseSidebar() {
            this.openedSidebar = null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.widget {
    &__container {
        background-color: $health-orange;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__badge {
        width: fit-content;
    }

    &__alert {
        width: 100%;
        display: flex;
        padding: 1rem;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        border-radius: 1rem;
        border: 1px solid #FE776E;
        color: $white-100;
        transition: background-color 0.3s;
        cursor: pointer;

        &:hover {
            background-color: #FE776E;
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &-title {
            @include caption;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: 2px;
            font-weight: 700;
        }

        &-description {
            @include caption-sm;
        }

        &-arrow {
            width: 24px;
            min-width: 24px;
            height: 24px;
        }
    }

    @media ($min-810) {
        &__content {
            gap: 1.5rem;
        }

        &__alert {
            padding: 2rem 1.5rem;

            &-title {
                @include body;
                font-weight: 700;
                gap: 6px;
            }

            &-description {
                @include caption;
            }
        }
    }
}
</style>
