<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M23 5C23 3.34315 21.6569 2 20 2H4C2.34315 2 1 3.34315 1 5V20.3787C1 21.715 2.61572 22.3843 3.56066 21.4393L5.62132 19.3787C6.18393 18.8161 6.94699 18.5 7.74264 18.5H20C21.6569 18.5 23 17.1569 23 15.5V5Z" :stroke="color" stroke-width="2" />
        <path d="M5 8L19 8" :stroke="color" stroke-width="2" />
        <path d="M5 13L12 13" :stroke="color" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'MessagesIcon',
    props: {
        color: {
            type: String,
            default: '#b1b1b1',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
