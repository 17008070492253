<template>
    <div class="protocol-select__container">
        <Nav stage="4" />
        <div>
            <h1 class="protocol-select__header">
                Select your protocol
            </h1>
            <div class="protocol-select__protocol">
                <img class="protocol-select__protocol-hero" src="@/assets/images/consultation/generic_rapa_select.png" width="100%" />
                <div class="protocol-select__protocol-content protocol-select__protocol--featured">
                    <div class="protocol-select__protocol-description">
                        <div>
                            <h3><strong>Generic Rapamycin</strong></h3>
                            <p class="protocol-select__protocol-body">
                                Generic rapamycin is suitable for most patients seeking its healthspan-promoting benefits. Clinical trials show that generic rapamycin has three times the absorption rate compared to compounded versions. Its protective coating ensures it passes through the intestinal tract without degradation.
                            </p>
                            <p class="protocol-select__protocol-price">
                                Starting at $99/mo
                            </p>
                        </div>
                        <div>
                            <ul class="protocol-select__protocol-benefits">
                                <li><strong>High Quality</strong></li>
                                <li><strong>3x Bioavailability</strong></li>
                                <li><strong>More Clinically Effective</strong></li>
                            </ul>
                        </div>
                    </div>
                    <h-button
                        label="Select"
                        variant="primary"
                        :loading="loading"
                        :handle-click="() => handleClick(GENERIC_RAPAMYCIN_TEMPLATE_ID)"
                        large
                        wide
                        cta
                    />
                </div>
            </div>
            <div class="protocol-select__protocol">
                <div class="protocol-select__protocol-content">
                    <div class="protocol-select__protocol-description">
                        <div>
                            <h3><strong>Compounded Rapamycin</strong></h3>
                            <p class="protocol-select__protocol-body">
                                Compounded rapamycin may be an option for those seeking an alternative to generic versions. However, due to its minimal overall absorption compared to generic rapamycin and lower clinical efficacy, higher doses are typically required to achieve similar effects.
                            </p>
                            <p class="protocol-select__protocol-price">
                                Starting at $64/mo
                            </p>
                        </div>
                        <div>
                            <ul class="protocol-select__protocol-benefits">
                                <li><strong>Enteric-coated capsules</strong></li>
                                <li><strong>Lower Bioavailability</strong></li>
                                <li><strong>Lower Clinical Efficacy</strong></li>
                            </ul>
                        </div>
                    </div>
                    <h-button
                        label="Select"
                        variant="primary"
                        :loading="loading"
                        :handle-click="() => handleClick(COMPOUNDED_RAPAMYCIN_TEMPLATE_ID)"
                        large
                        wide
                        cta
                    />
                </div>
            </div>
        </div>
        <div class="intake-certification protocol-select__footer">
            <h-certification />
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import Nav from '@/components/Consultation/Nav.vue';
import HButton from '@/components/Shared/HButton';
import HCertification from '@/components/Shared/HCertification.vue';
import { getStateUser } from '@/domains/user/lib/getStateUser';

export default {
    name: 'ProtocolSelection',
    components: {
        Nav,
        HButton,
        HCertification,
    },
    async created() {
        try {
            this.loadingPromise = Promise.all([
                this.get_intake({
                    _id: this.$route.params.intakeId,
                    user: this.user._id,
                }),
                this.get_intake_template({ templateId: process.env.VUE_APP_GENERIC_RAPAMYCIN_TEMPLATE_ID }),
                this.get_intake_template({ templateId: process.env.VUE_APP_COMPOUNDED_RAPAMYCIN_TEMPLATE_ID }),
            ]);

            const [intake, genericRapamycinTemplateId, compoundedRapamycinTemplateId] = await this.loadingPromise;

            this.intake = intake;
            this.templates = {
                [process.env.VUE_APP_GENERIC_RAPAMYCIN_TEMPLATE_ID]: genericRapamycinTemplateId,
                [process.env.VUE_APP_COMPOUNDED_RAPAMYCIN_TEMPLATE_ID]: compoundedRapamycinTemplateId,
            };
        } catch (error) {
            // TODO: UI Error
            console.error('Error preparing Protocol Selection', error);
            alert('Error preparing Protocol Selection');

            throw error;
        } finally {
            this.loading = false;
        }
    },
    data() {
        return {
            GENERIC_RAPAMYCIN_TEMPLATE_ID: process.env.VUE_APP_GENERIC_RAPAMYCIN_TEMPLATE_ID,
            COMPOUNDED_RAPAMYCIN_TEMPLATE_ID: process.env.VUE_APP_COMPOUNDED_RAPAMYCIN_TEMPLATE_ID,
            loading: false,
        };
    },
    methods: {
        ...mapActions({
            get_intake: 'intake/retrieveIntake',
            get_intake_template: 'intake/getIntakeTemplate',
        }),
        handleClick(intakeTemplateId) {
            if (!this.loading) {
                this.loading = true;

                this.loadingPromise.then(() => {
                    const template = this.templates[intakeTemplateId];

                    const params = {
                        intake_id: this.$route.params.intake_id,
                        section: 'product-configure',
                    };

                    const query = {
                        product: template.product_defaults[0],
                        intakeTemplateId,
                        initialIntakeTemplateId: intakeTemplateId,
                    };

                    this.$router.push({ name: 'treatment-selection', params, query }).catch(err => console.log(err));
                });
            }
        },
    },
    computed: {
        ...mapState({
            intake_template: state => state.intake.selectedIntakeTemplate,
            user: getStateUser,
        }),
    },
}
</script>
<style lang="scss">
</style>
