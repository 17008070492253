export function isMedicalProfileEmpty(medicalProfile) {
    const requiredFields = ['has_allergies', 'has_medical_conditions', 'has_current_medications'];

    return requiredFields.every(field => !(field in medicalProfile) || medicalProfile[field] === null);
}

export function isMedicalProfileIncomplete(medicalProfile) {
    const { has_allergies: hasAllergies, has_medical_conditions: hasMedicalConditions, has_current_medications: hasCurrentMedications, allergy_text: allergyText, medical_condition_text: medicalConditionText, current_medications: currentMedications } = medicalProfile;

    return (hasAllergies === null || hasMedicalConditions === null || hasCurrentMedications === null) ||
           (hasAllergies && !allergyText) || 
           (hasMedicalConditions && !medicalConditionText) || 
           (hasCurrentMedications && !currentMedications);
}
