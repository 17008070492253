import { render, staticRenderFns } from "./FilterCategory.vue?vue&type=template&id=27e89a73&scoped=true"
import script from "./FilterCategory.vue?vue&type=script&lang=js"
export * from "./FilterCategory.vue?vue&type=script&lang=js"
import style0 from "./FilterCategory.vue?vue&type=style&index=0&id=27e89a73&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e89a73",
  null
  
)

export default component.exports