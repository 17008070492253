<template>
    <div>
        <div class="nav-wrapper">
            <div class="nav-row-wrapper rel">
                <div class="nav__logo-container">
                    <div v-if="displayBack" class="nav__back" @click="backPage()"></div>
                    <div class="nav__logo" :style="{'background-image': `url(${organizationSettings.logo})`}"></div>
                </div>
                <p class="step-text vertical-align-step-text">
                    <span v-if="stage === 0">How it Works  </span>
                    <span v-if="stage === 1">Profile</span>
                    <span v-if="stage === 2">Medical History</span>
                    <span v-if="stage === 3">Medical Review</span>
                    <span v-if="stage === 4">Protocol Preference</span>
                    <span v-if="stage === 5">Identity Verification</span>
                    <span v-if="stage === 6">Checkout</span>
                </p>

                <div class="stage-indicator-wrapper">
                    <div v-if="stage < 4" class="row">
                        <div class="col-3">
                            <div class="step-indicator-active"></div>
                        </div>
                        <div class="col-3">
                            <div class="step-indicator-active" :class="{ 'step-indicator' : stage < 1 }"></div>
                        </div>
                        <div class="col-3">
                            <div class="step-indicator-active" :class="{ 'step-indicator' : stage < 2 }"></div>
                        </div>
                        <div class="col-3">
                            <div class="step-indicator-active" :class="{ 'step-indicator' : stage < 3 }"></div>
                        </div>
                        <div class="col-3">
                            <div class="step-indicator-active" :class="{ 'step-indicator' : stage < 4 }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'Consultnav',
    props: ['section', 'back_to_dashboard'],
    methods: {
        backPage() {
            if (this.back_to_dashboard) {
                this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
            } else if (this.$route.name === 'verification') {
                this.handleVerificationBack();
            } else {
                this.routerPushBack({ step: this.step, gender: this.user.sex });
            }
        },
        handleVerificationBack() {
            const params = this.$route.params;

            if (params.steps === 'verify_account') {
                this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
            } else if (params.steps === 'id_image' || params.steps === 'profile_image') {
                this.$router.push({ name: 'verification', params: { steps: 'existing_identity' } });
            } else {
                this.$router.push({ name: 'verification', params: { steps: 'verify_account' } });
            }
        },
        ...mapMutations({
            routerPushBack: 'intake/routerPushBack',
        }),
    },
    computed: {
        displayBack() {
            return !(this.$route.params.section === 'hiw');
        },
        step() {
            return Number(this.$route.query.step);
        },
        stage() {
            const section = this.$route.params.section;

            switch (section) {
            case 'hiw':
                return 0;
            case 'profile':
            case 'body':
                return 1;
            case 'pharmacy-overview':
            case 'pharmacy-questions':
                return 2;
            case 'intake':
                return 3;
            case 'treatment':
            case 'membership':
            case 'product-configure':
            case 'lab-checkout':
            case 'protocol-preference':
            case 'protocol-selection':
                return 4;
            case 'identification-step':
                return 5;
            case 'checkout':
                return 6;
            default:
                return 0;
            }
        },
        ...mapState({
            organizationSettings: state => state.organizationSettings.data,
            user: state => state.user.user,
        }),
    },
};

</script>

<style lang="scss">
@import '@/assets/scss/utility.scss';

.nav {
    &__logo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 70px;
        position: absolute;
        left: 15px;
        z-index: $z-nav;
    }

    &__back {
        background-image: url("../../assets/icons/arrow.svg");
        width: 25px;
        height: 25px;
        background-size: contain;
        transform: rotate(180deg);
        cursor: pointer;
    }

    &__logo {
        width: 150px;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @media(max-width: 590px){
            display: none;
        }
    }
}

.nav-wrapper {
    position: fixed;
    z-index: $z-nav;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05) !important;
}

.nav-row-wrapper {
    border-bottom: 0.0625rem solid rgb(224, 224, 224);
    height: 72px;
}

.top-row {
    @media(max-width: 480px) {
    }
}

.logo-text-wrapper {
    width: 100%;
    text-align: center;
}

.back-arrow-wrapper {
    height: 72px;
    position: absolute;
    left: 3rem;
    @media(max-width: 480px) {
        left: 1.5rem;
    }
}

.logo-text {
    font-family: 'Noe Bold', sans-serif;
    line-height: 72px;
    font-size: 28px;
    letter-spacing: 1.5px;
}

.hiw-logo-section {
    height: 72px !important;
}

.step-text {
    width: 100%;
    text-align: center;
    margin: auto;
    font-family: 'Sofia Semi Bold', sans-serif;
    font-size: 12px;
    position: absolute;

}

.vertical-align-step-text {
    line-height: 69px;
}

.stage-indicator-wrapper {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translate(-50%)
}

.step-indicator {
    background: rgb(224, 224, 224) !important;
}

.step-indicator-active {
    background: #9db790;
    max-width: 111px;
    margin: 0 auto;
    height: 2px;
    width: 100%;
}

</style>
