<template>
    <button
        class="h-icon-button"
        :data-size="size"
        @click="handleClick"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'HIconButton',
    props: {
        size: {
            type: String,
            default: 'md',
        },
    },
    methods: {
        handleClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.h-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 50%;
    background-color: $gray-200;
    padding: 0;
    transition: all 0.3s ease;

    svg {
        transition: all 0.3s ease;
    }
  
    &:hover {
        background-color: rgba($gray-200, 0.8);
        color: $white;

        svg {
            fill:  $white;
        }
    }

    &[data-size="sm"] {
        width: 30px;
        height: 30px;
    }
}
</style>
