<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1_8170)">
            <path d="M10.0001 5.83332V7.49999M12.5001 6.66635C13.5121 7.42652 14.1667 8.63681 14.1667 9.99999C14.1667 12.3012 12.3013 14.1667 10.0001 14.1667C7.6989 14.1667 5.83341 12.3012 5.83341 9.99999C5.83341 8.63681 6.48804 7.42652 7.50008 6.66635M18.3334 9.99999C18.3334 14.6024 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6024 1.66675 9.99999C1.66675 5.39762 5.39771 1.66666 10.0001 1.66666C14.6025 1.66666 18.3334 5.39762 18.3334 9.99999Z" stroke="#B1B1B1" stroke-width="1.5" stroke-linecap="round" />
        </g>
        <defs>
            <clipPath id="clip0_1_8170">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'PowerIcon',
};
</script>

<style lang="scss" scoped>

</style>
