<template>
    <div class="dashboard-widgets">
        <div class="dashboard-widgets__row">
            <VideoWidget
                class="dashboard-widgets__card--primary"
                :src="videos.welcomeVideo"
                :label="userName"
                header="Welcome to Healthspan"
                :poster="images.welcomePoster"
                :external-link="externalLink"
            >
                Healthspan is the first-ever digital medical clinic to help patients fight cellular senescence and regain control over the levers of aging that
                are at the foundation of most age-related chronic diseases.
            </VideoWidget>
        </div>
        <div class="dashboard-widgets__row" :class="isOddWidgetList && 'dashboard-widgets__row--odd'">
            <AlertsWidget v-if="visibleWidgets.alerts" :alert-types="alerts" data-test="dashboard-alerts-widget" class="dashboard-widgets__card--secondary" />
            <IncompleteIntakesWidget v-if="visibleWidgets.incompleteIntakes" data-test="dashboard-incomplete-widget" class="dashboard-widgets__card--secondary" />
            <WidgetSkeleton v-if="incompleteIntakes.loading" class="dashboard-widgets__card--skeleton" />
            <PrimaryWidget
                v-if="visibleWidgets.appointment"
                class="dashboard-widgets__card--secondary"
                :src="images.meetYourTeam"
                label="Your team"
                :on-click="goToAppointmentBooking"
                button-text="Book Your Appointment"
                header="Meet Your Clinical Team"
                data-test="dashboard-appointment-widget"
            >
                If you would like, one of our team members will help guide you through the onboarding process and address any unanswered questions you may have
                about the protocol.
            </PrimaryWidget>
            <PrimaryWidget
                v-if="visibleWidgets.labs"
                class="dashboard-widgets__card--secondary"
                :src="images.labsImage"
                label="Labs"
                :on-click="goToLabInstructions"
                button-text="Get started"
                header="Included Baseline Test"
                data-test="dashboard-labs-widget"
            >
                <div class="dashboard-widgets__widget-content">
                    <div>Healthspan offers lab testing to ensure that your protocol is optimized and personalized to meet your specific longevity needs.</div>
                    <div class="dashboard-widgets__widget-info">
                        In addition to your included lab work, Healthspan also offers a wide variety of lab tests available from the
                        <router-link to="/shop?type=lab" class="dashboard-widgets__widget-info__link">
                            shop
                        </router-link>
                    </div>
                </div>
            </PrimaryWidget>
            <PrimaryWidget
                v-if="visibleWidgets.labsShop"
                class="dashboard-widgets__card--secondary"
                :src="images.labsImage"
                label="Labs"
                :on-click="goToLabsShop"
                button-text="Shop"
                header="Get Started with Labs"
                data-test="dashboard-labs-shop-widget"
            >
                <div class="dashboard-widgets__widget-content">
                    Healthspan offers lab testing to ensure that your protocol is optimized and personalized to meet your specific longevity needs. You can
                    access your lab results at any time through the patient portal, where you can view your current bloodwork status and lab analysis.
                </div>
            </PrimaryWidget>
            <PrimaryWidget
                v-if="visibleWidgets.intake"
                class="dashboard-widgets__card--secondary"
                :src="images.rapamycinImage"
                :on-click="onClickIntakeWidget"
                label="Protocol"
                :header="incompleteIntake ? 'Continue with Rapamycin' : 'Get Started with Rapamycin'"
                :button-text="incompleteIntake ? 'Continue' : 'Get started'"
                :loading="intakeLoading"
                data-test="dashboard-intake-widget"
            >
                <div class="dashboard-widgets__widget-content">
                    Rapamycin is considered to be one of the most promising healthspan-promoting interventions for its role in slowing down the formation of
                    senescent cells through its inhibition of the mTOR pathway.
                </div>
            </PrimaryWidget>
            <WidgetSkeleton v-if="isLoadingProductWidgets" class="dashboard-widgets__card--skeleton" />
        </div>
        <div class="dashboard-widgets__row">
            <InfoWidget
                class="dashboard-widgets__card--secondary"
                :icon="images.people"
                :on-click="goToCommunity"
                button-text="Join the community"
                header="Healthspan community"
                data-test="dashboard-community-widget"
            >
                Our community is a great place to ask questions, learn from fellow members, and for you to give or receive guidance or knowledge that you may
                have.
            </InfoWidget>
            <InfoWidget
                class="dashboard-widgets__card--secondary"
                :icon="images.chatBubble"
                :on-click="goToMessages"
                button-text="Message us"
                header="Message Us"
                data-test="dashboard-message-widget"
            >
                Our team is always on hand via chat to assist with any inquiries you might have, whether they relate to orders, subscription management, or
                health-related questions. We are here to help. In case of a medical emergency, please seek professional assistance immediately.
            </InfoWidget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import chatBubble from '@/assets/icons/chat-bubble.svg';
import people from '@/assets/icons/people.svg';
import Play from '@/assets/icons/play.svg';
import labsImage from '@/assets/videos/dashboard/labs.png';
import meetYourTeam from '@/assets/videos/dashboard/meet-your-team.png';
import rapamycinImage from '@/assets/videos/dashboard/rapamycin.png';
import welcomeVideo from '@/assets/videos/dashboard/welcome-widget-background-video.webm';
import welcomePoster from '@/assets/videos/dashboard/welcome-widget-background-video.webp';
import AlertsWidget from '@/components/Dashboard/Widgets/AlertsWidget';
import IncompleteIntakesWidget from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/IncompleteIntakesWidget';
import InfoWidget from '@/components/Dashboard/Widgets/InfoWidget';
import PrimaryWidget from '@/components/Dashboard/Widgets/PrimaryWidget';
import VideoWidget from '@/components/Dashboard/Widgets/VideoWidget';
import WidgetSkeleton from '@/components/Dashboard/Widgets/WidgetSkeleton';
import { getStateUser } from '@/domains/user/lib/getStateUser';
import IntakeService from '@/services/IntakeService';
import { getIncompleteIntakes, getPatientIntakes } from '@/store/selectors/intake';
import { getLabResults } from '@/store/selectors/labResults';
import { getOrgId } from '@/store/selectors/organization';

import {
    getAlerts,
    getCreatedOrderId,
    getGreetingsText,
    getIncompleteIntake,
    getIsOddWidgetList,
    getShowAppointment,
    getShowIncompleteIntakes,
    hasApprovedIntake,
    hasOrderedLabs,
    hasPendingIntakes,
} from './DashboardWidgets.controller';

export default {
    name: 'DashboardWidgets',
    components: {
        IncompleteIntakesWidget,
        WidgetSkeleton,
        PrimaryWidget,
        VideoWidget,
        InfoWidget,
        AlertsWidget,
    },
    data() {
        return {
            rapamycinTemplateId: process.env.VUE_APP_GENERIC_RAPAMYCIN_TEMPLATE_ID,
            videos: {
                welcomeVideo,
            },
            images: {
                welcomePoster,
                meetYourTeam,
                labsImage,
                rapamycinImage,
                chatBubble,
                people,
            },
            externalLink: {
                link: 'https://www.youtube.com/watch?v=UYbEAK8Uc4A',
                description: 'Watch Our Introductory Video',
                icon: Play,
            },
            intakeLoading: false,
        };
    },
    computed: {
        ...mapState({
            orgId: getOrgId,
            user: getStateUser,
            notifications: (state) => state.alerts.notifications,
            labs: (state) => state.labs.notifications,
            unreadCount: (state) => state.alerts.unreadCount,
            subscriptions: (state) => state.subscriptions.subscriptions,
            pendingIntakes: getPatientIntakes,
            appointments: (state) => state.appointments.appointments,
            incompleteIntakes: getIncompleteIntakes,
            labsResults: getLabResults,
        }),
        ...mapGetters({
            isCompletedUserProfile: 'user/isCompletedUserProfile',
        }),
        isLoadingProductWidgets() {
            return this.labsResults.loading || this.pendingIntakes.loading || this.incompleteIntakes.loading || this.appointments.loading;
        },
        hasPendingIntakes() {
            return hasPendingIntakes(this.pendingIntakes.data, this.rapamycinTemplateId);
        },
        hasApprovedIntake() {
            return hasApprovedIntake(this.pendingIntakes.data, this.rapamycinTemplateId);
        },
        incompleteIntake() {
            return getIncompleteIntake(this.incompleteIntakes.data, this.rapamycinTemplateId);
        },
        userName() {
            return getGreetingsText(this.user.first_name);
        },
        hasActiveLabs() {
            return !!this.labsResults.data?.docs?.length;
        },
        hasOrderedLabs() {
            return hasOrderedLabs(this.labsResults.data?.docs);
        },
        createdOrderId() {
            return getCreatedOrderId(this.labsResults.data?.docs);
        },
        alerts() {
            return getAlerts(this.user?.mobile_verified, this.user.verificationStatus);
        },
        visibleWidgets() {
            return {
                alerts: !!this.alerts.length,
                incompleteIntakes: getShowIncompleteIntakes(this.incompleteIntakes),
                appointment: getShowAppointment(
                    this.appointments?.data?.docs?.length,
                    this.isLoadingProductWidgets,
                    this.hasActiveLabs,
                    this.hasPendingIntakes,
                ),
                labs: this.labsResults.isInit
                    && this.pendingIntakes.isInit
                    && !this.hasOrderedLabs
                    && this.hasApprovedIntake
                    && !this.isLoadingProductWidgets
                    && this.createdOrderId,
                labsShop: this.labsResults.isInit
                    && this.pendingIntakes.isInit
                    && !this.hasActiveLabs
                    && !this.hasPendingIntakes
                    && !this.hasApprovedIntake
                    && !this.isLoadingProductWidgets,
                intake: this.pendingIntakes.isInit && !this.hasPendingIntakes && !this.isLoadingProductWidgets,
            };
        },
        isOddWidgetList() {
            return getIsOddWidgetList(
                this.visibleWidgets,
                this.isLoadingProductWidgets,
            );
        },
    },
    async created() {
        try {
            await this.getLabsResults({ queries: { page: 1, status: 'created' } });
        } catch (err) {
            console.error('Could not upload lab results', err);
        }
    },
    methods: {
        ...mapActions({
            getLabsResults: 'labResults/getLabsResults',
            getIntakeTemplate: 'intake/getIntakeTemplate',
            createIntakeInstance: 'intake/createIntakeInstance',
            retrieveIntake: 'intake/retrieveIntake',
            saveSignupProductTemplateId: 'productTemplates/saveSignupProductTemplateId',
            submitOrder: 'orders/submitOrder',
        }),
        ...mapMutations({
            setIntakeId: 'intake/setIntakeId',
            initializeIntakeId: 'intake/initializeIntakeId',
        }),
        async goToLabInstructions() {
            await this.submitOrder({
                organization: this.orgId,
                order_id: this.createdOrderId,
            });

            this.$router.push(`/lab-instructions/${this.createdOrderId}`);
        },
        goToLabsShop() {
            this.$router.push({ path: '/shop', query: { type: 'lab' } });
        },
        goToAppointmentBooking() {
            this.$router.push('/schedule-onboarding');
        },
        goToCommunity() {
            window.open('https://community.gethealthspan.com', '_blank');
        },
        goToMessages() {
            this.$router.push(`/messages/${this.user._id}/new`);
        },
        async onGetStartedIntake() {
            this.intakeLoading = true;

            const template = await this.getIntakeTemplate({ templateId: this.rapamycinTemplateId });

            try {
                const intake = await this.createIntakeInstance({
                    templateId: template._id,
                    userId: this.user._id,
                    pharmacy_verification: true,
                });

                this.initializeIntakeId(intake);
                this.saveSignupProductTemplateId(template.product_defaults[0]);

                window.localStorage.setItem('intake_template', JSON.stringify(template));

                IntakeService.initSignupConfiguration(template);

                this.$router.push({
                    path: '/consultation/hiw/' + intake.user + '/' + intake._id + '/question/none',
                    query: { referrer: 'dashboard' },
                });
            } catch (error) {
                console.error('newIntakeInstance error', error);
            }

            this.intakeLoading = false;
        },
        async onContinueIntake() {
            if (!this.incompleteIntake) return;

            this.intakeLoading = true;

            await IntakeService.continueCompletingIntake(
                {
                    retrieveIntake: this.retrieveIntake,
                    setIntakeId: this.setIntakeId,
                    initializeIntakeId: this.initializeIntakeId,
                    routerPush: (props) => this.$router.push(props),
                },
                {
                    userId: this.user._id,
                    source: 'dashboard',
                    intakeId: this.incompleteIntake.intake._id,
                    intakeTemplate: this.incompleteIntake.template,
                    status: this.incompleteIntake.status,
                    productId: this.incompleteIntake.template?.product_defaults[0],
                    medicalProfile: this.user.medical_profile,
                    isCompletedUserProfile: this.isCompletedUserProfile,
                },
            );

            this.intakeLoading = false;
        },
        onClickIntakeWidget() {
            window.localStorage.removeItem('zp_product_template');

            if (this.incompleteIntake) this.onContinueIntake();
            else this.onGetStartedIntake();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_type.scss";
@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/utility.scss";

.dashboard-widgets {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media ($min-810) {
        gap: 2rem;
    }

    &__row {
        display: flex;
        height: fit-content;
        flex-wrap: wrap;
        gap: 1.5rem;

        @media ($min-810) {
            gap: 2rem;
        }

        &:empty {
            display: none;
        }
    }

    &__card {
        &--primary {
            flex: 1 1 100%;
        }

        &--skeleton,
        &--secondary {
            flex: 1 1 100%;

            @media ($min-810) {
                flex: 1 1 calc(50% - 1.5rem);
                max-width: 50%;
            }
        }
    }

    &__row--odd > &__card--secondary:first-child {
        flex: 1 1 100%;
        max-width: 100%;
    }

    &__widget-info {
        @include caption;

        border-radius: 0.75rem;
        padding: 1rem;
        color: $white;
        background-color: rgba(0, 0, 0, 0.3);

        &__link {
            color: $urgent-red-200;
        }
    }

    &__widget-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
</style>
