<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="14"
            y="17"
            width="4"
            height="4"
            rx="0.5"
            transform="rotate(90 14 17)"
            :stroke="color"
            stroke-width="2"
        />
        <rect
            x="14"
            y="10"
            width="4"
            height="4"
            rx="0.5"
            transform="rotate(90 14 10)"
            :stroke="color"
            stroke-width="2"
        />
        <rect
            x="14"
            y="3"
            width="4"
            height="4"
            rx="0.5"
            transform="rotate(90 14 3)"
            :stroke="color"
            stroke-width="2"
        />
    </svg>
</template>

<script>
export default {
    name: 'MoreIcon',
    props: {
        color: {
            type: String,
            default: '#212931',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
