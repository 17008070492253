<template>
<div class="zp-messenger-block-paragraph-container">
    {{ block_obj.text }}
</div>
</template>

<script>
export default {
    props: ['block_obj', 'index_num']
}
</script>

