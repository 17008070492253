<template>
    <div class="auth">
        <auth-info
            :type="type"
            :organization_settings="organizationSettings"
            :product_name="product_template && product_template.product_name"
        >
            <signup-form
                v-if="type === 'signup'"
                :product_template_id="product_template_id"
                :product_type="product_template && product_template.type"
                @changeType="updateType"
            />
            <login-form
                v-if="type === 'login'"
                :redirect_product_id="redirect_product"
                :redirect_path="redirect_path"
                :product_type="product_template && product_template.type"
                @changeType="updateType"
            />
        </auth-info>
        <div class="auth__hero">
            <div class="auth__hero auth__hero__backdrop" :class="product_template ? 'auth__hero__product' : 'auth__hero__generic'" :style="{'background-image': `url(${get_image(true)})`}"></div>
            <div v-if="product_template && product_template.testimonials.length !== 0" class="auth__hero__testimonials">
                <testimonials :full_name="testimonial.full_name" :profile_image="testimonial.profile_image" :testimonial="testimonial.testimonial" :member_since="testimonial.member_since" />
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';

import AuthInfo from '@/components/Auth/AuthInfo.vue';
import Testimonials from '@/components/Auth/Testimonials.vue';
import LoginForm from '@/components/Components/LoginForm';
import SignupForm from '@/components/Components/SignupForm';

export default {
    name: 'AuthContainer',
    components: {
        AuthInfo,
        SignupForm,
        LoginForm,
        Testimonials,
    },
    data() {
        return {
            path: null,
            type: null,
            product_template_id: null,
            product_template: null,
            testimonial: {
                full_name: '',
                profile_image: '',
                testimonial: '',
                member_since: '',
            },
        };
    },
    watch: {
        $route(to, from) {
            if (to.fullPath !== from.fullPath) {
                this.getPageType();
            }
        },
    },
    computed: {
        ...mapState({
            organizationSettings: state => state.organizationSettings,
            public_product: state => state.products.public_product,
            user: state => state.user.user,
        }),
        redirect_product() {
            return this.$route.params.product_id || false;
        },
        redirect_path() {
            return this.$route.query.redirectPath || false;
        },
    },
    created() {
        this.getPageType();
        this.product_template_id = this.$route.params.product_id ? this.$route.params.product_id.toString() : null;
    },
    async mounted() {
        await this.getOrganizationSettings();

        try {
            if (!this.product_template_id) return;
            await this.getPublicProduct({ product_template: this.product_template_id });
            this.product_template = this.public_product.data.product_template;
            this.testimonial = this.product_template.testimonials[Math.floor(Math.random() * this.product_template.testimonials.length)];
        } catch (error) {
            console.error('getPublicProduct error', error);
        }
    },
    methods: {
        getPageType() {
            this.path = this.$route.name;

            if (this.user?._id && this.isLoggedIn) {
                this.type = 'login';
            } else {
                // TODO: rework that
                this.type = (this.path === 'product-landing')
                    ? this.$route.params.type === 'signup' ? 'signup' : 'login'
                    : (this.path === 'signup-no-query' ? 'signup' : 'login');
            }
        },
        updateType(newType) {
            this.type = newType;
        },
        get_image(useAlternate) {
            return (this.path === 'product-landing')
                ? (useAlternate && this.product_template && this.product_template.settings && this.product_template.settings.alternate_image)
                    ? this.product_template.settings.alternate_image
                    : this.product_template && this.product_template.product_images[0]
                : this.organizationSettings.data && this.organizationSettings.data.login && this.organizationSettings.data.login.image;
        },
        ...mapActions({
            getOrganizationSettings: 'getOrganizationSettings',
            getPublicProduct: 'products/getPublicProduct',
        }),
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/_colors.scss";

.auth {
    display: flex;
    height: 100%;

    &__hero {
        display: none;
    }

    &__link {
        position: relative;
        display: inline-block;
        color: $charcoal;
        text-decoration: none;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            bottom: -0.125rem;
            left: 0;
            width: 100%;
            height: 1px;
            background: $charcoal;
        }

        &:hover {
            text-decoration: none;
            color: $charcoal;
        }
    }

    @media (min-width: 1280px) {
        &__hero {
            position: relative;
            display: block;
            flex: 1;
            background-color: $charcoal;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;

            &__generic {
                background-position: 80% 0;
            }

            &__product {
                background-position: right;
            }

            &__testimonials {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 2rem 3.125rem;
                z-index: 2;
            }

            &__backdrop {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    background: $charcoal;
                    background: linear-gradient(0deg, rgba($charcoal, 1) 0%, rgba($charcoal, 0) 100%);
                    z-index: 1;
                }
            }
        }
    }

    @media (min-width: 1600px) {
        &__hero {

            &__generic {
                background-position: 82% 0;
            }
        }
    }

    @media (min-width: 1920px) {
        &__hero {

            &__generic {
                background-position: 86% 0;
            }
        }
    }
}

.auth-alert {
    &__success {
        padding-top: 2rem;
    }

    &__error {
        padding: 13px 21px;
        background: rgba($urgent-red-100, 0.3);
        font-family: 'Sofia Regular', sans-serif;
        -webkit-font-smoothing: antialiased;

        span {
            color: $errors;
            font-weight: 700;
        }
    }
}

</style>
