export default {
    randomNumber() {
        return Math.floor((Math.random() * 10) + 1);
    },

    setFavicons(favicon) {
        const headTitle = document.querySelector('head');
        const setFavicon = document.createElement('link');

        setFavicon.setAttribute(favicon.type, favicon.name);
        setFavicon.setAttribute('href', `${favicon.path}?=${this.randomNumber()}`);
        headTitle.appendChild(setFavicon);
    },
};
