<template>
<div class="zp-messenger-block-action">
    <a :href="actualLink" :title="text" class="zp-btn zp-btn--primary" target="_blank">{{ text }}</a>
</div>
</template>
<script>
import {mapState} from 'vuex';

function createUrl(baseUrl, params) {
    var url = new URL(baseUrl);
    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            url.searchParams.set(key, params[key]);
        }
    }
    return url.href;
}

export default {
    name: 'ActionBlock',
    components: {},
    data() {
        return {}
    },
    props: ['text', 'linkUrl'],
    created() {
    },
    computed: {
        actualLink() {
            if (this.linkUrl.includes('cal.com')) {
                return createUrl(this.linkUrl, {
                    'zp-patient-id': this.user._id,
                    email: this.user.email,
                    name: `${this.user.first_name} ${this.user.last_name}`,
                })
            } else {
                return this.linkUrl
            }
        },
        ...mapState({
            user: state => state.user.user,
        })
    },
    methods: {}
}
</script>
<style lang="scss">
.zp-messenger-block-action {
    margin: 2rem 0;

    a {
        text-decoration: none;
        font-family: "Basel Grotesk", sans-serif;
    }

}
</style>
