<template>
    <svg
        width="6"
        height="12"
        viewBox="0 0 6 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M4.5858 8.82847L1.70712 11.7072L0.292908 10.2929L3.17159 7.41426C3.95264 6.63321 3.95264 5.36688 3.17159 4.58583L0.292907 1.70715L1.70712 0.29294L4.5858 3.17162C6.1479 4.73372 6.1479 7.26637 4.5858 8.82847Z" :fill="fill" />
    </svg>
</template>

<script>
export default {
    name: 'ChevronRight',
    props: {
        fill: {
            type: String,
            default: '#242E49',
        },
    },
};
</script>
