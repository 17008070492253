export const filterSelectedValues = (filters) => {
    return Object.keys(filters).filter((key) => filters[key] && key !== 'All');
};

export const updateFilterByQuery = (value) => {
    if (!value) return {};

    return Array.isArray(value)
        ? value.reduce((acc, item) => ({ ...acc, [item]: true }), {})
        : { [value]: true };
};
