<template>
    <div class="checkout-nav">
        <div class="checkout-nav__logo-container">
            <div v-if="back_button" class="checkout-nav__back-arrow" @click="handleBack()"></div>
            <div v-if="organizationSettings.logo" class="checkout-nav__logo" :style="{'background-image': `url(${organizationSettings.logo})`}"></div>
            <div v-if="!organizationSettings.logo" class="checkout-nav-logo__logo-text">
                <h3 class="subheadline-consultation">
                    {{ organizationSettings.name }}
                </h3>
            </div>
        </div>
        <div class="checkout-nav__section-title">
            {{ nav_title }}
        </div>
        <div v-if="nav_action" class="checkout-nav__button" @click="handleAction()">
            {{ nav_action }}
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'CheckoutNav',
    components: {},
    props: {
        back_button: {
            type: Boolean,
            required: false,
            default: false,
        },
        nav_action: {
            type: String,
        },
    },
    computed: {
        ...mapState({
            organizationSettings: state => state.organizationSettings.data,
            nav_title: state => state.navigation.title,
        }),
    },
    methods: {
        handleAction() {
            this.$emit('onAction', true);
        },
        handleBack() {
            this.$emit('onBack', true);
        },
    },
};
</script>
<style lang="scss">

@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/_type.scss";
@import "@/assets/scss/utility.scss";

.checkout-nav {
    background-color: #FFFFFF;
    border-bottom: 1px solid $border-light;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 86px;
    padding: 0 1.25rem 0 2rem;
    z-index: $z-nav;

    @media (min-width: 760px) {
        padding: 0 2rem;
    }

    &__logo-container {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-left: 1rem;
    }

    &__back-arrow {
        width: 22px;
        height: 22px;
        background-size: contain;
        background-image: url('../../assets/icons/arrow.svg');
        transform: rotate(180deg);
        cursor: pointer;
        margin-right: 0.5rem;
    }

    &__logo {
        width: 150px;
        height: 30px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media screen and (max-width: 480px) {
            width: 120px;
        }
    }

    &__section-title {
        width: 100%;
        text-align: center;
        @extend %body;
        @extend .strong;
        @media screen and (max-width: 480px) {
            display: none;
            text-align: left;
            padding-left: 1rem;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        border-radius: 1.5rem;
        background-color: transparent;
        color: #000;
        padding: .75rem;
        cursor: pointer;
        font-size: 0.875rem;
        white-space: nowrap;
        font-weight: 500;
        position: absolute;
        right: 0;
        margin-right: 1rem;

        @media (min-width: 760px) {
            padding: .75rem 1rem;
        }

        &:hover {
            background-color: #f7f7f7;
        }
    }
}

.checkout-nav-logo {
    display: flex;
    align-items: center;

    &__back-arrow {
        width: 25px;
        height: 25px;
        background-size: contain;
        transform: rotate(180deg);
        cursor: pointer;
    }

    &__logo-img {
        img {
            width: 150px;
            height: 100%;
        }
    }

    &__logo-text {
        h3 {
            font-family : 'ZP-UI', sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0;
        }
    }
}

.checkout-nav-link {
    display: flex;
    align-items: center;
    height: 100%;

    &__button {
        display: flex;
        align-items: center;
        border-radius: 1.5rem;
        background-color: transparent;
        color : #000;
        padding : .75rem;
        cursor: pointer;

        @media (min-width: 760px) {
            padding : .75rem 1rem;
        }

    &:hover{
        background-color: #f7f7f7;
    }

    span{
        font-family : 'ZP-UI', sans-serif;
        font-size: .875rem;
        font-weight: 500;
        height: 1.125rem;
    }
};

}
</style>
