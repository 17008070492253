import * as Sentry from '@sentry/vue';

export function initializeSentry(Vue, router) {
    Sentry.init({
        Vue,
        environment: process.env.NODE_ENV,
        dsn: 'https://1b3af7eb13b8ad197d9becbcd667ec7d@o4504968426029056.ingest.sentry.io/4506321792204800',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],

        tracesSampleRate: 0.1,
        tracePropagationTargets: [/^https:\/\/api(-[\w]+)?\.(development\.)?zenpatient\.(com|cloud)/],
        // replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    Vue.config.errorHandler = (error, vm, info) => {
        Sentry.withScope((scope) => {
            scope.setContext('Vue', {
                info,
                componentName:
                    vm.$options.name ||
                    vm.$options._componentTag ||
                    'anonymous-component',
            });

            Sentry.captureException(error);
        });

        console.error(error);
    };

    window.onunhandledrejection = function (event) {
        // Check if the rejection reason is an object and not an Error instance
        if (event.reason && typeof event.reason === 'object' && !(event.reason instanceof Error)) {
            const customError = new Error('Unhandled promise rejection with object');

            customError.data = event.reason;

            Sentry.withScope(scope => {
                scope.setExtra('rejectionReason', event.reason);
                Sentry.captureException(customError);
            });

            console.error('Handled non-Error promise rejection:', event.reason);
        } else {
            Sentry.captureException(event.reason ?? event);
            console.error('Unhandled promise rejection:', event.reason);
        }
    };
}
