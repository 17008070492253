<template>
    <div class="confirm-block confirm-schedule">
        <h2>1. Schedule your appointment</h2>
        <p>Secure your testing appointment swiftly through Labcorp booking system, accessing over 2000+ partner labs nationwide.</p>
        <div class="confirm-schedule__action">
            <HButtonLink v-if="pageType === 'success'" variant="secondary" href="https://www.labcorp.com/labs-and-appointments" target="_blank">
                Book Your Appointment
            </HButtonLink>
            <HButton variant="text" @click="handleSidebar">
                View Instructions
            </HButton>
        </div>
        <transition name="sidebar" mode="out-in">
            <h-sidebar v-if="open_sidebar" :open="open_sidebar" badge_label="Instructions" @handleClose="handleSidebar">
                <ScheduleInstructions />
            </h-sidebar>
        </transition>
    </div>
</template>

<script>
import HButtonLink from '@/components/Shared/HButtonLink.vue';
import HButton from '@/components/Shared/HButtonV2';
import HSidebar from '@/components/Shared/HSidebar';

import ScheduleInstructions from './components/ScheduleInstructions';

export default {
    name: 'LabSchedule',
    components: {
        HButton,
        HButtonLink,
        HSidebar,
        ScheduleInstructions,
    },
    props: {
        pageType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            open_sidebar: false,
        };
    },
    methods: {
        handleSidebar() {
            this.open_sidebar = !this.open_sidebar;
        },
    },
};
</script>
