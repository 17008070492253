<template>
    <div class="h-payment-container">
        <div v-if="has_card" class="h-payment-card">
            <div class="h-payment-card__patient">
                <div v-if="patientCard" class="h-payment-image" :class="patientCard.brand?.toLowerCase() || ''" />
                <div class="h-payment-card__details">
                    <div class="h-payment-card__brand-text">
                        <div class="h-payment-card__brand-hidden">
                            <div class="h-payment-card__hidden">
                                <span /><span /><span /><span />
                            </div>
                            <div class="h-payment-card__hidden">
                                <span /><span /><span /><span />
                            </div>
                            <div class="h-payment-card__hidden">
                                <span /><span /><span /><span />
                            </div>
                            <div class="h-payment-card__hidden">
                                {{ patientCard?.last4 || '****' }}
                            </div>
                        </div>
                    </div>
                    <div>Exp {{ patientCard?.exp_month || '**' }}/{{ patientCard?.exp_year || '****' }}</div>
                </div>
            </div>
            <div class="link-button" data-cypress="payment-edit" @click="$emit('edit-card')">
                Edit
            </div>
        </div>
        <div v-else class="h-payment-stripe">
            <div class="h-payment-stripe__header">
                <span>Credit or debit</span>
                <div class="h-payment-stripe__cards">
                    <div v-for="brand in cardTypes" :key="brand" class="h-payment-image" :class="brand.toLowerCase().replace(' ', '-')" />
                </div>
            </div>

            <div class="h-payment-stripe__body">
                <div v-if="isStripeElementReady" class="stripe-checkout-container">
                    <stripe-element-card
                        ref="elementRef"
                        :element-style="style" 
                        :pk="publishable_key"
                        @token="handleToken"
                        @element-change="handleChange"
                    />
                </div>
                <div v-if="patientCard" class="h-payment-stripe__action link-button" data-cypress="payment-cancel" @click="$emit('edit-card')">
                    Cancel
                </div>
            </div>
        </div>
        <div v-if="paymentError.error" class="h-payment-error">
            <span>{{ paymentError.error_msg }}</span>
        </div>
    </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
export default {
    name: 'PaymentOption',
    components: {
        StripeElementCard,
    },
    props: {
        has_card: {
            type: Boolean,
            required: true,
        },
        patientCard: {
            type: Object,
            default: () => {},
        },
        paymentError: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            publishable_key: process.env.VUE_APP_STRIPE_KEY,
            style: {
                base: {
                    color: '#000',
                    fontFamily: 'Helvetica Now Text, sans-serif',
                    fontSize: '16px',
                    backgroundColor: '#fff',
                },
            },
            cardTypes: ['Visa', 'MasterCard', 'Discover', 'American Express'],
            isStripeElementReady: false,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.isStripeElementReady = true;
        });
    },
    methods: {
        getStripeRef() {
            return this.$refs.elementRef;
        },
        handleToken(token) {
            this.$emit('token', token);
        },
        handleChange(event) {
            this.$emit('event', event);
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.h-payment-container {
	width: 100%;
}

.h-payment-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: 0.9375rem;

	.small {
		width: 28px;
		height: 19px;
		margin-right: 0.5rem;
	}

	&__details {
		@include caption;
		display: flex;
		gap: 0.75rem;
	}

	&__action {
		@include caption;
		font-weight: 700;
		color: $powder-blue-200
	}

	&__brand-text {
		display: flex;
		gap: 0.25rem;
	}

	&__brand-hidden {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	&__hidden {
		display: flex;
		align-items: center;
		gap: 0.125rem;

		span {
			display: inline-block;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: #000;
		}
	}

	&__brand-expires {
		font-size: 0.875rem;
		color: #6d6d6d;
	}

	&__card-info {
		margin-left: 0.5rem;
	}

	&__patient {
		display: flex;
		align-items: center;
	}

	@media ($min-430) {
		&__details {
			@include body;
		}
	}
}

.h-payment-stripe {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 1.125rem;

		span {
			@include caption;
			font-weight: 700;
		}
	}

	&__cards {
		display: flex;
	}

	&__body {
		position: relative;
	}

	&__action {
		display: inline-block;
		padding-top: 1rem;
	}

	@media ($min-430) {
		&__header {
			span {
				@include body;
			}
		}
	}
}

.h-payment-error {
	border-radius: 0.25rem;
	background: #ffe6e6;
	color: #b74646;
	font-family: 'ZP-UI', sans-serif;
	font-size: 0.875rem;
	font-weight: 500;
	padding: 0.875rem 1.25rem;
	margin: 0.75rem 0;
}

.stripe-checkout-container {
	width: 100%;
	height: 62px;
	border: 0.0625rem solid $border-default;
	-o-border-image: initial;
	border-image: initial;
	border-radius: 0.75rem !important;
	background-color: #fff;
	padding: 0.75rem 0.25rem;
	margin: 0.5rem 0;

	.StripeElement {
		box-shadow: none !important;
	}

	.StripeElement--invalid {
		border: none !important;
	}

	#stripe-element-errors {
		display: none;
	}
}

.h-payment-image {
	width: 24px;
	height: 16px;
	background-size: cover !important;
	background-position: center;
	margin-right: 0.5rem;
}

.visa {
		background-image: url('../../assets/images/credit-cards/consultation-payment-step-visa-card-image-2x.png');
	}

.mastercard {
	background-image: url('../../assets/images/credit-cards/consultation-payment-step-mastercard-image-2x.png');
}

.discover {
	background-image: url('../../assets/images/credit-cards/consultation-payment-step-discover-card-image-2x.png');
}

.american-express {
	background-image: url('../../assets/images/credit-cards/consultation-payment-step-amex-card-image-2x.png');
}

ion-icon {
	font-size: 1.5rem;
}
</style>
