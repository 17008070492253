<template>
    <div v-if="question" class="intake-question">
        <header class="intake-question__header">
            <h2>{{ question.label }}</h2>
            <p v-if="question.description_text" class="intake-question__description strong">
                {{ question.description_text }}
            </p>
        </header>

        <div class="intake-question__options">
            <div v-for="option in options" :key="option.value" class="intake-question__single-option">
                <input
                    :id="`${option.value}-option`"
                    v-model="selectedResponse"
                    :value="option.value"
                    class="hidden-radio"
                    type="radio"
                    @change="onOptionSelect(option)"
                >
                <label :for="`${option.value}-option`">
                    <div class="radio-text">{{ option.label }}</div>
                </label>
            </div>
        </div>

        <div v-if="showAdditionalInfo" class="intake-question__textarea">
            <label class="intake-question__small">{{ question.subLabel || 'Please provide additional details:' }}</label>
            <textarea v-model="additionalInfo"></textarea>
        </div>

        <div v-if="saved && !autoContinue" class="pharmacy-radio-button">
            <h-button
                variant="primary"
                :loading="submitting"
                :disabled="!isButtonEnabled"
                full_width
                data-test="continue-button"
                @click="handleContinue"
            >
                Continue
            </h-button>
        </div>

        <div class="intake-certification">
            <h-certification />
        </div>
    </div>
</template>

<script>
import HButton from '@/components/Shared/HButtonV2.vue';
import HCertification from '@/components/Shared/HCertification.vue';

export default {
    name: 'PharmacyRadio',
    components: {
        HButton,
        HCertification,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        medicalProfile: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            submitting: false,
            selectedResponse: null,
            saved: false,
            autoContinue: false,
            additionalInfo: '',
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
            ],
        };
    },
    computed: {
        showAdditionalInfo() {
            return this.selectedResponse === 'yes';
        },
        isButtonEnabled() {
            return this.selectedResponse && (!this.showAdditionalInfo || this.additionalInfo.trim().length > 0);
        },
    },
    created() {
        this.initializeResponse();
    },
    methods: {
        initializeResponse() {
            const { key, textKey } = this.question;

            if (this.medicalProfile[key] === true) {
                this.selectedResponse = 'yes';
                this.additionalInfo = this.medicalProfile[textKey] || '';
                this.saved = true;
            } else if (this.medicalProfile[key] === false) {
                this.selectedResponse = 'no';
                this.saved = true;
            } else {
                this.selectedResponse = null;
                this.saved = false;
            }
        },
        onOptionSelect(option) {
            this.saved = true;

            if (option.value === 'no') {
                this.autoContinue = true;
                this.handleContinue();
            } else {
                this.additionalInfo = '';
                this.autoContinue = false;
            }
        },
        handleContinue() {
            const data = {
                response: this.selectedResponse,
                additionalInfo: this.showAdditionalInfo ? this.additionalInfo : null,
            };

            setTimeout(() => {
                this.submitting = true;
                this.$emit('continue', data);
            }, 300);
        },
    },
};
</script>

<style lang="scss" scoped>
.pharmacy-radio-button {
    width: 100%;
    max-width: 495px;
    margin: 2.5rem auto 0;

    button {
        width: 100%;
    }
}
</style>
