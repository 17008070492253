exports.createNewConversationPayload = (user, message, socketId) => {
    return {
        patient: user._id,
        status: 'open',
        subject: message,
        originating_source: 'app',
        is_automated: false,
        bot_initiated: false,
        has_patient_participant: true,
        patient_sender: {
            first_name: user.first_name,
            last_name: user.last_name,
            display_name: user.first_name,
            type: 'patient',
            image_url: user.image.profile.profile_url,
        },
        patient_read: true,
        messages: [{
            blocks: [{
                type: 'paragraph',
                text: message,
            }],
            author: {
                is_admin: false,
                avatar: {
                    square_25: user.image.profile.profile_url,
                },
                first_name: user.first_name,
                last_name: user.last_name,
                display_name: user.first_name,
                patient_id: user._id,
            },
            is_reply: false,
            socket_id: socketId,
        }],
    };
};

exports.createMessagePayload = (user, message, conversationId, socketId) => {
    const sender = {};
    const payload = {};

    sender['first_name'] = user.first_name;
    sender['last_name'] = user.last_name;
    sender['display_name'] = user.first_name;
    sender['type'] = 'patient';

    const block = {
        type: 'paragraph',
        text: message,
    };

    payload['blocks'] = [block];
    payload['author'] = {
        is_admin: false,
        avatar: {
            square_25: user.image.profile.profile_url,
        },
        first_name: user.first_name,
        last_name: user.last_name,
        display_name: user.first_name,
        patient_id: user._id,
    };
    payload['sender'] = sender;
    payload['is_patient'] = true;
    payload['conversation_id'] = conversationId;
    payload['socket_id'] = socketId;
    payload['is_reply'] = true;

    return payload;

};
