<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M16.9282 15.2889C17.2507 14.5104 17.4166 13.676 17.4166 12.8333C17.4166 11.6401 17.0496 10.4757 16.3653 9.49808L13.4577 5.34434C12.2634 3.63819 9.73659 3.63819 8.54228 5.34434L5.63466 9.49809C4.95036 10.4757 4.58331 11.6401 4.58331 12.8333C4.58331 13.676 4.74928 14.5104 5.07175 15.2889C5.39422 16.0674 5.86687 16.7748 6.46271 17.3706C7.05855 17.9665 7.76592 18.4391 8.54443 18.7616C9.32293 19.084 10.1573 19.25 11 19.25C11.8426 19.25 12.677 19.084 13.4555 18.7616C14.234 18.4391 14.9414 17.9665 15.5372 17.3706C16.1331 16.7748 16.6057 16.0674 16.9282 15.2889Z" :stroke="color" stroke-width="2" />
        <path d="M12.9445 14.7779C12.5599 15.1625 12.0699 15.4244 11.5365 15.5305C11.0031 15.6366 10.4501 15.5822 9.94762 15.374C9.44512 15.1659 9.01563 14.8134 8.71346 14.3612C8.41128 13.9089 8.25 13.3772 8.25 12.8333" :stroke="color" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'LabsIcon',
    props: {
        color: {
            type: String,
            default: '#212931',
        },
        size: {
            type: Number,
            default: 22,
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
