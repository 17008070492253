<template>
    <div class="medical-questions-container">
        <div class="medical-questions">
            <div class="medical-questions-header">
                <h2 data-test="medical-history-title">
                    Review Medical History
                </h2>
                <p class="medical-questions-header__small text-dim-60">
                    Please update your medical history below if needed, or continue.
                </p>
            </div>
            <div v-for="question in filteredBySexQuestions" :key="question.id">
                <div v-if="question.type === 'checkbox'" class="medical-question" data-test="checkbox-question">
                    <div class="medical-question__label">
                        {{ question.label }}
                    </div>
                    <ul class="medical-question__options">
                        <li v-for="option in question.options" :key="option.id" class="medical-question__option">
                            <h-checkbox
                                orange
                                reverse
                                :checked="isOptionSelected(option.value)"
                                data-test="checkbox-option"
                                @input="handleOptionChange(option.value)"
                            >
                                {{ option.label }}
                            </h-checkbox>
                        </li>
                        <li>
                            <h-checkbox
                                orange
                                reverse
                                :checked="isOptionSelected('none')"
                                data-test="checkbox-none"
                                @input="handleOptionChange('none')"
                            >
                                None
                            </h-checkbox>
                        </li>
                    </ul>
                </div>
                <div v-if="question.type === 'radio'" class="medical-question" data-test="radio-question">
                    <div class="medical-question__container">
                        <div class="medical-question__label">
                            {{ question.label }}
                        </div>
                        <h-toggle 
                            v-model="medicalProfile[question.key]"
                            data-test="radio-toggle"
                            @change="toggleQuestion(question)"
                        />
                    </div>

                    <div v-if="medicalProfile[question.key] && originalText[question.textKey] && !isEditing[question.textKey]" class="medical-question__text">
                        <p>{{ originalText[question.textKey] }}</p>
                        <h-button variant="text" @click="editText(question.textKey)">
                            Edit
                        </h-button>
                    </div>
                    
                    <div v-if="medicalProfile[question.key] && isEditing[question.textKey]" class="medical-question__textarea">
                        <p class="medical-question__sublabel">
                            {{ question.subLabel }}
                        </p>
                        <textarea
                            v-model="medicalProfile[question.textKey]"
                            placeholder="Please provide more details"
                        ></textarea>
                        <div v-if="originalText[question.textKey]" class="medical-question__cancel">
                            <h-button variant="text" @click="cancelEdit(question.textKey)">
                                Cancel
                            </h-button>
                        </div>
                    </div>
                </div>
            </div>
            <h-button :disabled="isContinueDisabled" :loading="isSubmitting" data-test="continue-button" @click="onContinue">
                Continue
            </h-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HButton from '@/components/Shared/HButtonV2.vue';
import HCheckbox from '@/components/Shared/HCheckbox.vue';
import HToggle from '@/components/Shared/HToggle.vue';

import { MEDICAL_HISTORY_QUESTIONS } from './constants/pharmacyQuestions';
import { isMedicalProfileIncomplete } from './utils/medicalProfileUtils';

export default {
    name: 'PharmacyOverview',
    components: {
        HToggle,
        HButton,
        HCheckbox,
    },
    data() {
        return {
            medicalHistoryQuestions: MEDICAL_HISTORY_QUESTIONS,
            medicalProfile: {
                pregnant: false,
                planning_pregnancy: false,
                breastfeeding: false,
                has_allergies: null,
                allergy_text: '',
                has_medical_conditions: null,
                medical_condition_text: '',
                has_current_medications: null,
                current_medications: '',
            },
            isEditing: {
                allergy_text: false,
                medical_condition_text: false,
                current_medications: false,
            },
            originalText: {
                allergy_text: '',
                medical_condition_text: '',
                current_medications: '',
            },
            isSubmitting: false,
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        filteredBySexQuestions() {
            return this.user?.sex === 'male' ? this.medicalHistoryQuestions.slice(1) : this.medicalHistoryQuestions;
        },
        isContinueDisabled() {
            return isMedicalProfileIncomplete(this.medicalProfile);
        },
    },
    created() {
        if (this.user?.medical_profile) {
            this.medicalProfile = this.user.medical_profile;

            ['allergy_text', 'medical_condition_text', 'current_medications'].forEach((key) => {
                this.originalText[key] = this.medicalProfile[key] || '';
                this.isEditing[key] = false;
            });
        }
    },
    methods: {
        ...mapActions({
            updateUserInfo: 'user/updateUserInfo',
        }),
        isOptionSelected(optionValue) {
            if (optionValue === 'none') {
                return !this.medicalProfile.pregnant && !this.medicalProfile.planning_pregnancy && !this.medicalProfile.breastfeeding;
            }

            return Boolean(this.medicalProfile[optionValue]);
        },
        handleOptionChange(optionValue) {
            const pregnancyOptions = ['pregnant', 'planning_pregnancy', 'breastfeeding'];

            if (optionValue === 'none') {
                pregnancyOptions.forEach((key) => {
                    this.medicalProfile[key] = false;
                });
            } else {
                this.medicalProfile[optionValue] = !this.medicalProfile[optionValue];

                pregnancyOptions.forEach((key) => {
                    if (key !== optionValue) {
                        this.medicalProfile[key] = Boolean(this.medicalProfile[key]);
                    }
                });
            }
        },
        toggleQuestion({ textKey, key }) {
            if (!this.medicalProfile[key]) {
                this.isEditing[textKey] = false;
                this.medicalProfile[textKey] = '';
            } else {
                this.medicalProfile[textKey] = this.originalText[textKey] || '';
                this.isEditing[textKey] = !this.originalText[textKey];
            }
        },
        editText(textKey) {
            this.isEditing[textKey] = true;
        },
        cancelEdit(textKey) {
            this.medicalProfile[textKey] = this.originalText[textKey];

            this.isEditing[textKey] = false;
        },
        async onContinue() {
            this.isSubmitting = true;

            try {
                await this.updateUserInfo({ _id: this.user._id, medical_profile: this.medicalProfile });
                this.$emit('onContinue');
            } catch (error) {
                console.error(error);
            } finally {
                this.isSubmitting = false;
            }
        },                
    },
};
</script>
