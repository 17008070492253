<template>
    <div :class="['dashboard', { 'dashboard-overlfow': isOverflowHidden }]">
        <h-alert-bar
            v-if="shouldShowAlertBar"
            type="warning"
            :message="verification_alert_message"
            button-label="Verify Account"
            :handle-alert-click="handleAlertClick"
        />
        <header-nav />
        <div class="dashboard-main">
            <SideNav
                :user_id="user._id"
                :page="page"
                :display_unread_notification="display_unread_notification"
                :show_appointments="isHealthspan"
            />
            <div v-if="page !== 'messages'" class="dashboard-content">
                <NotificationHeader v-if="!isNotifications" />
                <Profile v-if="page === 'profile'" />
                <Labs v-if="page === 'labs'" />
                <LabAnalysis v-if="page === 'lab-analysis'" />
                <Orders v-if="page === 'orders'" />
                <Subscriptions v-if="page === 'subscriptions'" />
                <Appointments v-if="page === 'appointments'" />
                <ScheduleOnboarding v-if="page === 'schedule-onboarding'" />
                <Settings v-if="page === 'settings'" />
                <Notifications v-if="isNotifications" />
                <Providers v-if="page === 'providers'" />
            </div>
            <Conversation v-if="page === 'messages'" :alert="!user.verified && verification_alert_message && !appointments.loading" />
        </div>
        <footer-nav v-if="page !== 'messages'" :scroll-up="scrollUp" />
    </div>
</template>

<script>
import moment from 'moment';
import Pusher from 'pusher-js/with-encryption';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Conversation from '@/components/Dashboard/Conversation.vue';
import Providers from '@/components/Dashboard/Providers';
import scrollResizeMixin from '@/helpers/scrollResizeMixin';

import Appointments from '../components/Dashboard/Appointments.vue';
import FooterNav from '../components/Dashboard/FooterNav.vue';
import HeaderNav from '../components/Dashboard/HeaderNav.vue';
import LabAnalysis from '../components/Dashboard/Labs/LabAnalysis.vue';
import Labs from '../components/Dashboard/Labs/Labs.vue';
import Notifications from '../components/Dashboard/Notifications/Notifications.vue';
import NotificationHeader from '../components/Dashboard/Notifications/Subcomponents/NotificationHeader.vue';
import Orders from '../components/Dashboard/Orders.vue';
import Profile from '../components/Dashboard/Profile.vue';
import ScheduleOnboarding from '../components/Dashboard/ScheduleOnboarding/Components/ScheduleOnboarding.vue';
import Settings from '../components/Dashboard/Settings.vue';
import SideNav from '../components/Dashboard/SideNav.vue';
import Subscriptions from '../components/Dashboard/Subscriptions.vue';
import HAlertBar from '../components/Shared/HAlertBar.vue';

//declare an encrypted channel name
let encryptedChannelName = 'presence-channel-';

export default {
    name: 'Dashboardview',
    components: {
        HAlertBar,
        Profile,
        Orders,
        Subscriptions,
        FooterNav,
        Appointments,
        Providers,
        SideNav,
        Labs,
        LabAnalysis,
        HeaderNav,
        Settings,
        Conversation,
        Notifications,
        NotificationHeader,
        ScheduleOnboarding,
    },
    mixins: [scrollResizeMixin],
    data() {
        return {
            show_appointments: false,
            localVerificationStatus: null,
            isOverflowHidden: false,
        };
    },
    mounted() {
        this.updateOverflow();
    },
    methods: {
        ...mapMutations({
            setPresenceChannel: 'conversations/setPresenceChannel',
            forceOpenPhotoMismatch: 'alerts/forceOpenPhotoMismatch',
        }),
        ...mapActions({
            logout: 'authenticate/logout',
            retrieveUser: 'user/retrieveUser',
            getPatientState: 'user/getPatientState',
            getStatePhysicians: 'physician/getPhysicians',
            getOrganizationStates: 'organization/getOrganizationStates',
            getOrganization: 'getOrganization',
            retrieveAppointments: 'appointments/retrieveAppointments',
        }),
        show_verify_account() {
            return (
                this.localVerificationStatus === 'not_started'
                || this.localVerificationStatus === 'outdated'
                || this.localVerificationStatus === 'rejected'
            );
        },
        handleAlertClick() {
            const params = {
                userId: this.user._id,
                steps: 'verify_account',
            };

            this.$router.push({ name: 'verification', params });
        },
        updateOverflow() {
            if (this.page === 'messages') {
                this.isOverflowHidden = true;
            } else {
                this.isOverflowHidden = false;
            }
        },
    },
    computed: {
        shouldShowAlertBar() {
            return this.show_verify_account() && this.verification_alert_message && !this.appointments.loading;
        },
        hasImage() {
            return this.user && !!this.user && !!this.user.image && !!this.user.image.id.id_url && !!this.user.image.profile.profile_url;
        },
        page() {
            return this.$route.params.page;
        },
        isNotifications() {
            return this.$route.name === 'notifications';
        },
        ...mapState({
            unreadCount: state => state.alerts.unreadCount,
            user: state => state.user.user,
            conversation_details: state => state.conversations.conversation_details,
            closed: state => state.alerts.closed,
            orgId: state => state.orgId.data,
            force_open_photo_mismatch: state => state.alerts.force_open_photo_mismatch,
            incomplete_intakes: state => state.intake.incomplete_intakes,
            patient_state: state => state.user.patient_state,
            appointments: state => state.appointments.appointments,
            state_physicians: state => state.physician.state_physicians,
            verificationStatus: state => state.user.user.verificationStatus,
        }),
        ...mapGetters({
        }),
        isHealthspan() {
            return this.user && this.user.organization === process.env.VUE_APP_ORG_ID;
        },
        appointmentCounts() {
            if (this.user.appointment_counts) return this.user.appointment_counts;
            else return 0;
        },
        display_unread_notification() {
            return this.conversation_details && !this.conversation_details.patient_read;
        },
        verification_alert_message() {
            const userVerification = this.appointments.data && this.appointments.data.docs;
            const upcomingEvent = !!userVerification && userVerification.filter(doc => moment(doc.event.start_time).isAfter(moment()));

            if (upcomingEvent.length > 0) {
                return 'You have a call scheduled to verify your account. In the meantime, you have the option to proceed with identify verification.';
            } else if (upcomingEvent.length === 0) {
                return 'To ensure the prompt and secure delivery of your protocol, please verify your account.';
            }

            return '';
        },
        user_verification_status() {
            return this.user.verificationStatus;
        },
    },
    async created() {
        const userIdParam = this.$route.params['userId'] || this.user._id;

        try {
            await this.getOrganization();
            await this.retrieveUser({
                user: userIdParam,
            });
            await this.retrieveAppointments({
                patient: userIdParam,
            });

            const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                authEndpoint: process.env.VUE_APP_BASE_END + '/pusher/auth/presence/' + this.user._id + '/' + this.user.first_name + '/patient',
            });

            //add the encrypted channel name
            encryptedChannelName = encryptedChannelName + this.user.organization;

            //subscribe to presence channel
            const PresenceChannel = pusher.subscribe(encryptedChannelName);

            this.setPresenceChannel(PresenceChannel);

            PresenceChannel.bind('pusher:subscription_succeeded', () => {});

            this.localVerificationStatus = this.user.verificationStatus;
        } catch (error) {
            console.error('Created error: ', error);
        }

        try {
            await this.getOrganizationStates();

            if (this.user.state) {
                await this.getPatientState({ patient: this.user._id });
            }

            if (this.patient_state.data && this.patient_state.data.syncRequired) {
                await this.getStatePhysicians({
                    state_id: this.patient_state.data._id,
                });
                this.show_appointments = this.state_physicians.data.length;
            }
        } catch (error) {
            console.error('What is the error', error);
        }
    },
    watch: {
        user_verification_status(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.localVerificationStatus = newVal;
            }
        },
        page() {
            this.updateOverflow();
        },
    },
};
</script>
