<template>
    <h-card-base 
        :img-src="product.product_images[0]"
        :text-content="defaultFooter"
        :title="price"
        :subtitle="instructions"
        :description="product.description"
        >
        <template v-slot:header>
            <div class="addon-card-header">
                <h-checkbox :checked="checked" @input="handleCheckbox" orange>
                    <span>{{product.product_name}}</span>
                    <span v-if="dosage" class="n-400">{{ dosage }}</span>
                </h-checkbox>
            </div>
        </template>
        <template v-slot:footer>
            <div v-if="defaultFooter" class="addon-card-footer">
                <h-button v-if="changePlan" label="Change Plan" variant="secondary-cta" :handleClick="handleChangePlan" large wide cta/>
                <h-button label="Learn More" variant="tertiary" :handleClick="handleLearmMore" large wide cta />
            </div>
                <slot></slot>
        </template>
    </h-card-base>
</template>

<script>
import HCardBase from "../../Shared/HCardBase.vue";
import HCheckbox from "@/components/Shared/HCheckbox";
import HButton from "@/components/Shared/HButton";

    export default {
        name: 'AddonCard',
        components: {HCardBase, HCheckbox, HButton},
        props: {
            product: {
                type: Object,
                required: true
            },
            checked: {
                type: Boolean,
                default: false
            },
            dosage: {
                type: String,
            },
            price: {
                type: String,
            },
            instructions: {
                type: String,
            },
            defaultFooter: {
                type: Boolean,
                default: false
            },
            changePlan: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            handleCheckbox() {
                this.$emit('onCheckbox', true);
            },
            handleLearmMore() {
                this.$emit('onLearnMore');
            },
            handleChangePlan() {
                this.$emit('onChangePlan');
            },
        },
    }
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.addon-card-header {
    label {
        font-size: 1.125rem;
        font-weight: 700;

        span {
            span {
                &:nth-child(2) {
                    margin-left: 0.625rem;
                }
            }
        }
    }
}

.addon-card-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;

    
    @media (min-width: 480px) {
        flex-direction: row;
        gap: 0;
        
        button {
            flex: 1;
        }
    }
}


</style>