<template>
<div>
    <pending-visits></pending-visits>
    <div v-if="!loading" class="orders-container">
        <div class="mb1r w100">
            <h4 class="headline-2 mb2r">Your orders</h4>
        </div>

        <div class="orders-container-nav">

            <div class="orders-container-nav__order-option">
                <button :class="{'active-status' : order_tab === 'active' }" class="pill-button"
                        @click="retrieveActiveSubscriptionMedications()">
                    Active Order
                </button>
            </div>
            <div class="orders-container-nav__order-option">
                <button :class="{'active-status' : order_tab === 'completed' }" class="pill-button"
                        @click="retrieveOrders('completed')">
                    Completed Orders
                </button>
            </div>
        </div>
        <div
                v-if="!orders.loading && ((order_tab !== 'active' && (!orders?.data || !orders.data?.length)) || (order_tab === 'active' && !hasActiveSubs(subscriptions.data)))"
            class="no-plan-container">
            <div class="no-plan-inner-container">
                <h3 class="headline-3-slim">You don't have any orders
                    <span v-if="order_tab === 'completed'"> that are completed</span>
                    yet!</h3>
                <router-link class="store-link" :to="'/dashboard/' + userData._id + '/shop'">GO TO THE STORE</router-link>
            </div>
        </div>
        <div v-if="!subscriptions.loading && subscriptions.data && order_tab === 'active'" class="order-container">
            <div v-for="(subscription, index) in subscriptions.data" v-if="subscription.status === 'active' && subscription.subscription_id"
                 :key="subscription._id"
                 class="w100 mb2r flex flex-col">
                <OrderSubcomponent :is_current="true" :order="subscription.parent_product"
                                   :subscription="subscription"/>
            </div>
        </div>

            <div v-if="!orders.loading && orderData" class="order-container">
                <div v-for="(order) in orderData" :key="order._id" class="w100 mb2r flex flex-col">
                <OrderSubcomponent v-bind:key="order._id" :is_current="false" :order="order"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {required, minLength, between, email} from 'vuelidate/lib/validators';
import moment from 'moment';
import StepBubble from '@/components/Components/StepBubble.vue'
import OrderSubcomponent from '@/components/Dashboard/Subcomponents/OrderSubcomponent.vue';
import PendingVisits from "@/components/Visits/PendingVisits";

export default {
    name: 'orders',
    components: {
        StepBubble: StepBubble,
        OrderSubcomponent: OrderSubcomponent,
        PendingVisits: PendingVisits
    },
    data() {
        return {
            loading: false,
            no_results: {
                error: false,
                message: null
            },
            order_tab: null,
        }
    },
    methods: {
        ...mapActions({
            retrievePatientOrders: 'orders/retrievePatientOrders',
            getMedicationSubscriptions: 'subscriptions/getMedicationSubscriptions',
        }),
        ...mapMutations({
            setPatientOrders: 'orders/setPatientOrders',
            setSubscriptions: 'subscriptions/setSubscriptions'
        }),
        closeResults() {
            this.$modal.hide('results');
            this.selectedLab = null;
        },
        hasActiveSubs(data) {
            return data && data.filter(i => i.status === 'active' && i.subscription_id).length > 0
        },
        async retrieveActiveSubscriptionMedications() {
            this.resetData();
            this.order_tab = 'active';
            const subscription_payload = {organization: this.userData.organization, patient: this.userData._id};
            try {
                let subscriptions = await this.getMedicationSubscriptions(subscription_payload);
            } catch (e) {

            }
        },
        resetData() {
            this.setPatientOrders({id: 'data', value: null});
            this.setSubscriptions({id: 'data', value: null});
        },
        generateOrdersPayloadAwaiting() {
            const ten_days_ago = moment().subtract(10, 'd').format('YYYY-MM-DD');
            const payload = {
                user_id: this.userData._id,
                query: {
                    "status.physician_reviewed": false,
                    created_at: {$gte: ten_days_ago}
                }
            }
            return payload;
        },
        generateOrdersPayloadComplete() {
            let payload = {
                user_id: this.userData._id,
                query: {
                    "status.fulfilled": true,
                }
            }
            return payload;
        },
        async retrieveOrders(type) {
            let payload = (type === 'awaiting') ? this.generateOrdersPayloadAwaiting() : this.generateOrdersPayloadComplete();
            this.order_tab = type;
            this.resetData();
            try {
                let orders = await this.retrievePatientOrders(payload);
            } catch (e) {
                console.log('error : ', e);
            }
        },
    },
    computed: {
        ...mapState({
            userData: state => state.user.user,
            orders: state => state.orders.orders,
            subscriptions: state => state.subscriptions.subscriptions,
        }),
        orderData () {
            return this.orders?.data?.filter(order => {
                return !order.status.admin_cancelled && order.products.length && !order.deleted;
            });
        },
    },
    filters: {
        date: function (date) {
            return moment(date).format('MMMM Do, YYYY');
        }
    },
    async created() {
        this.loading = true;
        //grab subscription
        if (!this.hasActiveSubs(this.subscriptions.data)) {
            this.order_tab = 'completed';
            try {
                let awaiting_orders = await this.retrieveOrders(this.order_tab);
            } catch (e) {
                console.log('error retrieving orders: ', e);
            }

        }
        this.loading = false;
    }
}


</script>

<style lang="scss">

.link-action {
    font-weight: 500;
    color: $cool400;
    display: block;
    cursor: pointer;
}

.dotted-border {
    width: fit-content;
    text-transform: capitalize;
    line-height: 3.5rem !important;
    border-style: none none dotted;
    -o-border-image: initial;
    border-image: initial;
    border-bottom: 0.125rem dotted rgba(0, 0, 0, 0.44);
}

.dashed-border-w100 {
    width: 100%;
    text-transform: capitalize;
    line-height: 3.5rem !important;
    border-style: none none dotted;
    -o-border-image: initial;
    border-image: initial;
    border-bottom: 0.125rem dashed rgba(0, 0, 0, 0.44);
}


.order-details-grid {
    display: flex;
    flex-wrap: wrap;
    height: initial;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding-left: 0rem;
    padding-right: 0rem;
    width: 100%;

    @media(min-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.order-column-left {
    display: flex;
    min-height: initial;
    margin-left: 0px;
    width: 100%;
    @media(min-width: 768px) {
        margin-left: 0px;
        width: 50%;
    }
    @media(min-width: 1024px) {
        margin-left: 0px;
        width: 50%;
    }
}

.order-column {
    display: flex;
    min-height: initial;
    margin-left: 0px;
    width: 100%;
    font-family: 'Sofia Regular';
    @media(min-width: 768px) {
        margin-left: 0px;
        width: 50%;
    }
    @media(min-width: 1024px) {
        margin-left: 0px;
        width: 50%;
    }
}

.fd-column {
    flex-direction: column;
}

.product-name-row {
    position: relative;
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;


}

.product-name {
    font-size: 1rem;
    order: 1;
    font-family: 'Sofia Semi Bold', sans-serif;
    color: rgba(0, 0, 0, 0.88);
    -webkit-font-smoothing: antialiased;
    text-transform: capitalize;
}

.product-order-details {
    max-width: 320px;
}

.product-order-description {
    color: #777777;
}

.product-order-items {

}

.order-items-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.order-item-product {
    border-bottom: 1px solid $neutral300;
    margin-bottom: 1rem;

    &:last-child {
        border-bottom: none;
    }
}

.order-item-product-image {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.product-order-title {
    text-transform: capitalize;
    font-size: 1rem;
    font-family: 'Sofia Semi Bold', sans-serif;
}

.product-order-instructions {
    font-size: 1rem;
    color: $neutral500;
}

.product-order-cost {
    font-size: 1rem;
    font-weight: 500;
}

.product-order-image {
    width: 100%;
    height: 90px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    margin-bottom: 1.5rem;
}

.label-row {
    margin-bottom: 1rem;
}

.label-row label {
    color: rgba(0, 0, 0, 0.44);
    font-size: 0.875rem;
    margin-bottom: 0px;
}

.label-row p {
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
    padding-right: 10px;
}

.product-service-list {
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    color: #777777;

    li {
        margin-bottom: .5rem;
    }
}

</style>
