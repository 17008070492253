<template>
    <div class="settings-section">
        <div class="settings-alert">
            <transition name="alert-fade" mode="out-in">
                <HAlert v-if="alert.status" :message="alert.message" :status="alert.status" @dismiss="closeAlert" />
            </transition>
        </div>
        <div class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div class="settings-section__header">
            <h2>Home State</h2>
            <p>The state you currently reside in or on your drivers license or valid ID</p>
        </div>
        <form class="settings-state" @submit.prevent="updateState">
            <h-dropdown-secondary
                v-model="userState"
                label="Home State"
                :options="new_states"
                placeholder="Select State"
                :selected="userState"
                :vuelidate="$v.userState"
                :error="($v.userState.$invalid && $v.userState.$dirty) && errorMessages.STATE"
            />
            <h-button
                variant="secondary"
                :disabled="$v.$invalid"
                :loading="submitting"
                class="settings-section__btn"
            >
                Submit
            </h-button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HAlert from '@/components/Shared/HAlert.vue';
import HButton from '@/components/Shared/HButtonV2.vue';
import HDropdownSecondary from '@/components/Shared/HDropdownSecondary.vue';
import Alert from '@/helpers/alert';
import { errorMessages, requiredValidation  } from '@/helpers/validations';

export default {
    name: 'HomeStateSection',
    components: {
        HDropdownSecondary,
        HButton,
        HAlert,
    },
    data() {
        return {
            submitting: false,
            userState: null,
            new_states: [],
            errorMessages,
            alert: new Alert(),
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            states: state => state.states.states,
        }),
    },
    watch: {
        states: {
            async handler(states) {
                if (!this.userState && this.user.state) {
                    this.userState = states.find(state => state.abbreviation === this.user.state) || null;
                }
            },
            immediate: true,
        },
    },
    validations: {
        userState: requiredValidation,
    },
    created() {
        this.userState = this.user.state;
        this.populateStates();
    },
    methods: {
        ...mapActions({
            updateUser: 'user/updateUser',
            retrieveStates: 'states/retrieveStates',
        }),
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        closeAlert() {
            this.alert.dismiss();
        },
        async populateStates() {
            const states = await this.retrieveStates();
            
            this.new_states = states.map(item => ({
                ...item,
                value: item.abbreviation,
            }));
        },
        async updateState() {
            const payload = {
                _id: this.user._id,
                state: this.userState,
            };
            
            this.submitting = true;

            try {
                await this.updateUser(payload);
                this.alert.showWithTimeout('Home state updated successfully', 'success');
            } catch (error) {
                this.alert.showWithTimeout(error.message, 'critical');
            } finally {
                this.submitting = false;
            }
            
        },
    },
    
};
</script>
