<template>
    <div class="cbmg-message-container">
        <div
            class="message-wrapper"
            :class="{ 'message-container-position-left' : message.author.is_admin, 'message-container-position-right' : !message.author.is_admin }"
        >
            <AdminBubble
                v-if="message.author.is_admin"
                :conversation_details="conversation_details"
                :last_patient_sent="last_patient_sent"
                :message="message"
            />
            <PatientBubble
                v-else
                :conversation_details="conversation_details"
                :last_patient_sent="last_patient_sent"
                :message="message"
            />
        </div>
    </div>
</template>

<script>

import AdminBubble from '@/components/ConversationComponents/AdminBubble.vue';
import PatientBubble from '@/components/ConversationComponents/PatientBubble.vue';

export default {
    name: 'DefaultChatComponent',
    components: {
        AdminBubble,
        PatientBubble,
    },
    props: ['message', 'conversation_details', 'last_patient_sent'],
    data() {
        return {};
    },
    computed: {},
};

</script>
