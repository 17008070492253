<template>
    <div class="toggle-wrapper" :class="{ 'toggle-wrapper--disabled': disabled }">
        <label
            v-for="option in options"
            :key="option.value"
            class="toggle-button"
            :class="[`toggle-button--${option.label.toLowerCase()}`, { active: value === option.value, disabled }]"
            @click="!disabled && updateValue(option.value)"
        >
            {{ option.label }}
        </label>
        <div class="separator" :class="{ 'separator__active': value !== null}"></div>
    </div>
</template>

<script>
export default {
    name: 'HToggle',
    props: {
        value: {
            type: Boolean,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [
                { name: 'yes', label: 'Yes', value: true },
                { name: 'no', label: 'No', value: false },
            ],
        };
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
            this.$emit('change');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.toggle-wrapper {
    position: relative;
    display: flex;
    border-radius: $radius;
    min-width: 150px;
    height: 46px;
    overflow: hidden;

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    label {
        margin: 0;
    }
}

.separator {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: $border-medium;

    &__active {
        background-color: $health-orange;
    }
}

.toggle-button {
    @include body-sm;
    font-weight: 700;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;

    &:not(.active) {
        background-color: white;
        color: $charcoal;
    }

    &.active {
        background-color: $health-orange;
        color: $white-100;
        border-color: $health-orange;
    }

    &--yes {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
        border: 1px solid $border-medium;
        border-right: none;
    }

    &--no {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
        border: 1px solid $border-medium;
        border-left: none;
    }

    &.disabled {
        cursor: not-allowed;
    }
}
</style>
