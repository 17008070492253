<template>
    <div class="access">
        <div class="access-header typography-header">
            <h1>Unable to access webcam</h1>
            <div v-if="browser === 'safari'" class="access-body">
                <p>To grant access to your webcam.</p>
                <ol>
                    <li>Select Safari in your Mac Toolbar at the top.</li>
                    <li>Click Settings</li>
                    <li>Navigate to Websites, then Camera</li>
                    <li>You’ll see app.gethealthspan.com, and in the dropdown click Allow</li>
                </ol>
            </div>
            <div v-if="browser === 'firefox'" class="access-body">
                <p>To grant access to your webcam.</p>
                <ol>
                    <li>Click the camera icon in the address bar.</li>
                    <li>Click the "Blocked Temporarily" button</li>
                    <li>Reload the page</li>
                    <li>Then you will be prompted to allow access to your camera again.</li>
                </ol>
            </div>
            <div v-if="browser === 'chrome'" class="access-body">
                To grant access to your webcam, click the permission icon in your navigation bar and <span class="strong">toggle Allow</span>
            </div>
        </div>
        <img :src="imageSource" alt="camera">
        <div class="access-upload">
            <p>You may choose to upload a photo from your library by clicking Upload Image</p>
            <h-button @click="upload">
                Upload Image
            </h-button>
        </div>
    </div>
</template>

<script>
import HButton from '@/components/Shared/HButtonV2.vue';

import ChromeSettings from './assets/chrome-settings.webp';
import FirefoxSettings from './assets/firefox-settings.webp';
import SafariSettings from './assets/safari-settings.webp';

export default {
    name: 'CameraAccess',
    components: {
        HButton,
    },
    props: {
        browser: {
            type: String,
            default: 'chrome',
        },
    },
    computed: {
        imageSource() {
            switch (this.browser.toLowerCase()) {
            case 'safari':
                return SafariSettings;
            case 'firefox':
                return FirefoxSettings;
            default:
                return ChromeSettings;
            }
        },
    },
    methods: {
        upload() {
            document.body.style.overflow = 'unset';
            this.$emit('upload');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';

.access {
    img {
        width: 60%;
        height: auto;
    }
}

.access-header {
    margin-bottom: 2rem;

    h1 {
        text-transform: none;
    }
}

.access-body {
    @extend %body;

    ol {
        margin-top: 0.5rem;
    }

    li {
        padding: 0.125rem 0;
    }
}

.access-upload {
    border-top: 1px solid #E0E0E0;
    padding-top: 2rem;
    margin-top: 2rem;
}
</style>
