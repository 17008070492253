<template>
    <div v-click-outside="closeDropdown" class="select">
        <button @click="handleClick">
            <div class="select-btn__label">
                <span v-if="option_selected && showIndicator" class="select-btn__dot" :class="dot_color" />
                <span>{{ btn_label }}</span>
            </div>
            <ion-icon name="chevron-down-outline" />
        </button>
        <div class="select__mobile">
            <HBottomSheet :open="buttonClicked" @close="handleClick">
                <ul class="options">
                    <li @click="handleOptionSelect(defaultOption)">
                        <span v-if="showIndicator" class="select-btn__dot" />
                        <span>{{ defaultOption.name }}</span>
                    </li>
                    <li v-for="option in options" :key="option.value" @click="handleOptionSelect(option)">
                        <span v-if="showIndicator" class="select-btn__dot" :class="option.value" />
                        <span>{{ option.name }}</span>
                    </li>
                </ul>
            </HBottomSheet>
        </div>
        <div v-if="buttonClicked" class="select__desktop">
            <div class="select-dropdown-container">
                <ul class="options select-dropdown">
                    <li @click="handleOptionSelect(defaultOption)">
                        <span v-if="showIndicator" class="select-btn__dot" />
                        <span>{{ defaultOption.name }}</span>
                    </li>
                    <li v-for="option in options" :key="option.value" @click="handleOptionSelect(option)">
                        <span v-if="showIndicator" class="select-btn__dot" :class="option.value" />
                        <span>{{ option.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import HBottomSheet from '../../../Shared/HBottomSheet.vue';

export default {
    name: 'FilterOptions',
    components: {
        HBottomSheet,
    },
    directives: { ClickOutside },
    props: {
        type: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        defaultLabel: {
            type: String,
            required: true,
        },
        showIndicator: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            buttonClicked: false,
            option_selected: false,
            dot_color: '',
            btn_label: '',
            defaultOption: {
                name: this.defaultLabel,
                value: 'all',
            },
        };
    },
    watch: {
        defaultLabel(newLabel) {
            this.btn_label = newLabel;
        },
    },
    created() {
        this.btn_label = this.defaultOption.name;
        this.option_selected = true;
    },
    methods: {
        handleClick() {
            this.buttonClicked = !this.buttonClicked;
        },
        closeDropdown() {
            this.buttonClicked = false;
        },
        handleOptionSelect(option) {
            this.btn_label = option.name;
            this.dot_color = option.value;
            this.option_selected = true;
            this.buttonClicked = false;
            this.$emit('option-selected', { type: this.type, value: option.value });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.select {
    position: relative;
    white-space: nowrap;
    transition: all 0.3s ease;
    
    button {
        @include caption;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border: 1px solid $border-light;
        border-radius: $radius-xl;
        background-color: #fff;
        padding: 0.45rem 1rem;

        ion-icon {
            font-size: 1rem;
        }
    }

    &__options {
        transition: all 0.3s ease;
    }

    &__desktop {
        display: none;
    }

    @media (min-width: 1024px) {
        &__mobile {
            display: none;
        }

        &__desktop {
            display: block;
        }
    }
}

.select-btn {
    &__label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__dot {
        display: inline-flex;
        width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #212931;
    }
}

.optimal {
    background-color: #0fc08d;
}

.borderline {
	background-color: #ffc046;
}

.high-low {
	background-color: #ff7f71;
}

.options {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        @include caption;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.75rem 1rem;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: $gray-75;
        }
    }
}

.select-dropdown-container {
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 0.25rem;
}

.select-dropdown {
    border: 0.0625rem solid rgb(235, 235, 235);
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1rem 0;
    z-index: $z-dropdown;

    li {
        width: 100%;
        padding: 0.5rem 1.5rem;
    }
}

</style>
