<template> 
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5356 7.7071L14.4143 10.5858L13.0001 12L10.1214 9.12132C9.34036 8.34027 8.07404 8.34027 7.29299 9.12132L4.41431 12L3.00009 10.5858L5.87877 7.70711C7.44087 6.14501 9.97353 6.14501 11.5356 7.7071Z" :fill="fill" />
    </svg>
</template>

<script>
export default {
    name: 'ChevronUp',
    props: {
        fill: {
            type: String,
            default: '#242E49',
        },
    },
};
</script>
