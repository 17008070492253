<template>
    <div class="shop-filter__mobile">
        <div class="shop-filter__mobile-header">
            <div class="shop-filter__mobile-actions">
                <div class="shop-filter__mobile-actions--close" @click="$emit('close')">
                    Submit
                </div>
                <div class="shop-filter__mobile-actions--reset" @click="resetCheckedItems">
                    Reset
                </div>
                <div class="shop-filter__mobile-actions--close" @click="$emit('close')">
                    Close
                </div>
            </div>
            <h3>Filters</h3>
        </div>

        <div v-for="option in options" :key="option.type" class="shop-filter__section">
            <div class="shop-filter__header">
                <div>{{ option.type }}</div>
                <div class="shop-filter__collapse" :class="{'open': checkCollapse(option.type) }" @click="handleCollapse(option.type)" />
            </div>
            <transition name="collapse" mode="out-in">
                <div v-if="!checkCollapse(option.type)">
                    <h-checkbox
                        :checked="checkedItems[option.type].all"
                        orange
                        @input="handleChecked('All', option.type)"
                    >
                        All
                    </h-checkbox>
                    <h-checkbox
                        v-for="item in option.valid_values"
                        :key="item"
                        :checked="checkedItems[option.type].checks[item]"
                        orange
                        @input="handleChecked(item, option.type)"
                    >
                        {{ item }}
                    </h-checkbox>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import HCheckbox from '../../Shared/HCheckbox.vue';
import { getUpdatedQuery, makeDefaultCheckedItems, resetCheckedItems } from './ShopFilter.controller';

export default {
    name: 'ShopFilter',
    components: {
        HCheckbox,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            checkedType: [],
            checkedProtocols: [],
            checkedBenefits: [],
            collapseType: false,
            collapseProtocols: false,
            collapseBenefits: false,
            checkedItems: makeDefaultCheckedItems(this.$route.query, this.options),
        };
    },
    methods: {
        handleChecked(item, type) {
            if (item === 'All') {
                this.checkedItems[type].all = !this.checkedItems[type].all;
                this.checkedItems[type].keys.forEach(key => {
                    this.checkedItems[type].checks[key] = this.checkedItems[type].all;
                });
            } else {
                this.checkedItems[type].checks[item] = !this.checkedItems[type].checks[item];
                this.checkedItems[type].all = this.checkedItems[type].keys.every(key => this.checkedItems[type].checks[key]);
            }

            this.changeQuery(item, type);
            this.$emit(type.toLowerCase(), Object.assign({}, this.checkedItems[type].checks, { all: this.checkedItems[type].all }));
        },
        changeQuery(item, type) {
            this.$router
                .push({ query: getUpdatedQuery(this.$route.query, item, type, this.checkedItems) })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.error(error);
                    }
                });
        },
        checkCollapse(type) {
            return this[`collapse${type}`];
        },
        handleCollapse(type) {
            this[`collapse${type}`] = !this[`collapse${type}`];
        },
        resetCheckedItems() {
            this.checkedItems = resetCheckedItems(this.checkedItems);
            this.$router.push({ query: {} });
            this.$emit('reset');
        },
    },
};
</script>

<style lang="scss">
.collapse-enter, .collapse-leave-to {
    opacity: 0;
    height: 0;
}

.collapse-enter-active, .collapse-leave-active {
    transition: opacity 0.1s, height 0.4s ease-out;
}

</style>
