<template>
    <div class="message-patient-container message-avatar-container__patient">
        <div class="message-avatar-container">
            <div v-if="message.author.avatar.square_25">
                <div
                    :style="{ backgroundImage : 'url(' + message.author.avatar.square_25 + ')'}"
                    class="conversation-profile-circle bg-img-cover"
                >
                </div>
            </div>
            <div v-else class="conversation-profile-circle-patient-container">
                <img src="@/assets/icons/profile.svg" />
            </div>
        </div>
        <div class="message-bubble">
            <div class="message-bubble__message-info">
                <span class="message-bubble__message-info">

                    <span class="message-bubble__message-timestamp">{{ created_at }}</span>
                </span>
            </div>
            <div class="message-bubble__container">
                <div v-for="(block, index) in message.blocks" :key="index">
                    <TextBlockComment
                        v-if="block.type === 'paragraph'"
                        :block="block"
                        :block_obj="block"
                        :index_num="index"
                    />
                </div>
            </div>
            <div v-if="last_patient_sent === message._id" class="patient-read-status">
                <span v-if="conversation_details && conversation_details.medical_team_read">Seen</span>
                <span v-if="conversation_details && !conversation_details.medical_team_read">Delivered</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import TextBlockComment from '@/components/ConversationComponents/BlockComponents/TextBlockComment.vue';

export default {
    name: 'DefaultChatComponent',
    components: {
        TextBlockComment,
    },
    props: ['message', 'conversation_details', 'last_patient_sent'],
    data() {
        return {};
    },
    computed: {
        admin_name() {
            return 'You';
        },
        created_at() {
            return moment(this.message.created_at).format('MMM Do, h:mm A');
        },
    },
};

</script>
