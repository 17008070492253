<template>
<div v-linkified class="zp-messenger-block-paragraph-comment-container" v-html="block_obj.text"></div>
</template>

<script>
export default {
    props: ['block_obj', 'index_num'],
    data() {
        return {}
    }
}
</script>

