<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3333 2.83528L13.7158 2.45475C14.7721 1.40396 16.4847 1.40396 17.5411 2.45475C18.5974 3.50555 18.5974 5.20922 17.5411 6.26001L14.0983 9.68475C13.6758 10.1051 12.9908 10.1051 12.5682 9.68475L9.12553 6.26002C8.06921 5.20922 8.06921 3.50555 9.12553 2.45475C10.1818 1.40396 11.8945 1.40396 12.9508 2.45475L13.3333 2.83528ZM1.66663 8.33332H3.33329L8.55183 10.6164C9.35336 10.9671 9.72194 11.8987 9.37731 12.7028C9.325 12.8249 9.25925 12.9371 9.18252 13.0383L11.4442 14.4721C12.1385 14.8193 12.9041 15 13.6803 15H15C15.9204 15 16.6666 15.7462 16.6666 16.6667C16.6666 17.5871 15.9204 18.3333 15 18.3333H8.24041C7.20545 18.3333 6.18469 18.0924 5.25899 17.6295L1.66663 15.8333V8.33332Z" fill="#28303F" />
    </svg>
</template>

<script>
export default {
    name: 'VisitIcon',
};
</script>

<style lang="scss" scoped>

</style>
