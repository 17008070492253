<template>
    <div class="schedule-onboarding__main">
        <ShopNav />
        <div class="schedule-onboarding">
            <div v-if="!scheduled" class="schedule-onboarding__container" :class="{'schedule-call__hidden': scheduled}">
                <div class="schedule-onboarding__header">
                    <h1>Schedule Your Onboarding</h1>
                </div>
                <div class="schedule-onboarding__calcom">
                    <Calcom
                        :id="user._id"
                        :calcom-config="{ 'zp-type': 'general' }"
                        :calcom-link="link"
                        :email="user.email"
                        :name="fullName"
                        :intake_id="intakeId"
                        @booking-successful="handleBookingSuccessful"
                    />
                </div>
                <div v-if="!scheduled">
                    <div class="identity__link" @click="handleReturn">
                        Return
                    </div>
                </div>
            </div>
            <div v-if="scheduled" class="schedule-onboarding__scheduled">
                <div class="schedule-onboarding__scheduled__img__container">
                    <img src="@/assets/images/verification/scheduled.svg" />
                </div>
                <div class="schedule-onboarding__scheduled__header">
                    <h1>Successfully Scheduled!</h1>
                    <p class="identity__body strong">
                        Your appointment was successfully rescheduled!
                    </p>
                </div>
                <div><h-badge label="Appointment Details" color="#55D291" dark small /></div>
                <div class="schedule-onboarding__scheduled__details">
                    <div v-if="scheduled?.date" class="schedule-onboarding__scheduled__details__item">
                        <div>
                            <img src="@/assets/images/verification/calendar.svg" />
                        </div>
                        <div>{{ localDate }}</div>
                    </div>
                    <div class="schedule-onboarding__scheduled__details__item">
                        <div>
                            <img src="@/assets/images/verification/clock.svg" />
                        </div>
                        <div>{{ localTime }}</div>
                    </div>
                </div>
                <div class="schedule-onboarding__scheduled__button-container">
                    <h-button
                        label="Return to appointments"
                        variant="cta"
                        full_width
                        large
                        :handle-click="handleContinue"
                    />
                </div>
            </div>
            <div class="schedule-onboarding__footer">
                <HCertification />
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import Calcom from '@/components/Identification/Calcom.vue';
import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';

import ShopNav from '../../../Shop/SubComponents/ShopNav.vue';

const consultationCalcomLinks = {
    development: 'team/healthspan/dev01-identity-verification',
    dev01: 'team/healthspan/dev01-identity-verification',
    dev02: 'team/healthspan/dev02-identity-verification',
    staging: 'team/healthspan/staging-identity-verification',
    production: 'team/healthspan/talk-to-our-team',
};

export default {
    name: 'ScheduleOnboarding',
    data() {
        return {
            link: consultationCalcomLinks[process.env.NODE_ENV] || consultationCalcomLinks.production,
            scheduled: null,
        };
    },
    computed: {
        ...mapGetters({
            fullName: 'user/getFullName',
        }),
        ...mapState({
            user: (state) => state.user.user,
        }),
        intakeId() {
            return this.$route.params.intakeId || '';
        },
        localDate() {
            const utcMoment = moment.utc(this.scheduled?.date);

            return utcMoment.local().format('MMMM Do YYYY');
        },
        localTime() {
            const utcMoment = moment.utc(this.scheduled?.date);

            return utcMoment.local().format('LT z');
        },
    },
    components: {
        Calcom,
        HCertification,
        HBadge,
        HButton,
        ShopNav,
    },
    methods: {
        handleContinue() {
            this.$router.push({ path: `/dashboard/${this.user._id}/appointments` });
        },
        handleReturn() {
            this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
        },
        handleBookingSuccessful(data) {
            if (data && data.confirmed) {
                this.scheduled = data;
            }
        },
    },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/_type.scss';

.schedule-onboarding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex: 1;

    &__main {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__calcom {
        flex: 1;
        margin-bottom: 1.5rem;
    }

    &__header {
        @extend .typography-header;
        text-align: center;
        display: flex;
        align-self: center;
        font-size: 62px;
        text-transform: none;
        margin-bottom: 80px;
        margin-top: 1.5rem;
    }

    &__footer {
        display: flex;
        align-self: center;
        justify-self: end;
    }

    &__scheduled {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        padding: 0 1.5rem;

        &__img {
            &__container {
                position: relative;
                width: 100%;
                max-width: 249px;
                height: 253px;
                padding: 0 1rem;
                margin: 0 auto;

                img {
                    position: absolute;
                    top: 50%;
                    bottom: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &__header {
            text-align: center;

            h1 {
                @extend %helveticaNowDisplayImportant;
                font-size: 1.75rem !important;
                line-height: 2.5rem !important;
                font-weight: 400 !important;
                text-transform: capitalize;
            }
        }

        &__details {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: .75rem;
            background-color: #F9F9F9;
            width: 100%;
            max-width: 490px;
            padding: 1rem 3rem;

            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .5rem;
                width: 100%;
                padding: 1rem 0;

                &:first-child {
                    border-bottom: 1px solid #E5E5E5;
                }
            }
        }

        &__button-container {
            width: 100%;
            max-width: 490px;

            @extend %helveticaNowDisplayImportant;
            font-size: 0.875rem;
            letter-spacing: 0.2rem;
            font-weight: 800;
            text-transform: uppercase;
        }

        @media (min-width: 1280px) {
            &__header {
                @include helveticaNowDisplayImportantMixin();

                h1 {
                    @include helveticaNowDisplayImportantMixin();
                    font-size: 3rem !important;
                    line-height: 3.5rem !important;
                    font-weight: 300 !important;
                }
            }
        }
    }
}

</style>
