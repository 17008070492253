<template>
    <div class="learn-more">
        <div class="learn-more__header">
            <div class="learn-more__title">{{product.product_name}}</div>
            <div>{{product.description}}</div>
        </div>
        <div class="learn-more__product-image" v-if="product.settings && product.settings.alternate_image"
             :style="{'background-image': `url(${product.settings.alternate_image})`}"></div>
        <div class="learn-more__product-image" v-if="product.settings && product.settings.details_image"
             :style="{'background-image': `url(${product.settings.details_image})`}"></div>
        <div class="learn-more__membership" v-if="membership">
            <div class="learn-more__item">
                <div class="learn-more__item-title">Protocol</div>
                <div class="learn-more__item-desc">{{membership.description}}</div>
            </div>
            <ul class="learn-more__list">
                <li class="learn-more__list-item" v-for="feature in membership.features"><ion-icon name="checkmark"></ion-icon> {{feature.description}}</li>
            </ul>
        </div>
        <div v-if="product.shop && product.shop.benefits && product.shop.benefits.length" class="learn-more__product-feature">
            <h-badge label="Benefits" color="#55D291" dark small/>
            <ul class="learn-more__list">
                <li v-for="benefit in product.shop.benefits" :key="benefit.title" class="learn-more__feature">
                    <span class="learn-more__feature--title">{{benefit.title}}</span>
                    <span>{{benefit.description}}</span>
                </li>
            </ul>
        </div>
        <div class="learn-more__item">
            <div class="learn-more__item-title">Strengths</div>
            <div class="learn-more__item-desc" v-for="item in product.dosage_strengths">{{item.strength}}</div>
        </div>
        <div class="learn-more__item">
            <div class="learn-more__item-title">Frequencies</div>
            <div class="learn-more__item-desc" v-for="frequency in product.dosage_frequencies">{{frequency.frequency.label}}</div>
        </div>
        <div class="learn-more__item" v-if="product.settings && product.settings.product_type">
            <div class="learn-more__item-title">Type</div>
            <div class="learn-more__item-desc">{{product.settings.product_type}}</div>
        </div>

    </div>
</template>
<script>
import HBadge from './HBadge.vue';

export default {
	name: 'ProductInfoSidePanel',
	components: {HBadge},
    props: ['product', 'membership'],
}
</script>
<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.learn-more {
    @include caption;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__title {
        @include heading-sm;
    }

    &__product-image {
        padding: 1rem;
        border-radius: 12px;
        display: block;
        height: 342px;
        background-color: $bg-image;
        overflow: hidden;
        border: 1px solid $border-light;
        background-position: center;
        background-size: cover;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__item-title {
        @include body;
        font-weight: 700;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0;
        margin: 1.5rem 0;
    }

    &__list-item {
        list-style: none;
        margin-bottom: 1rem;
        font-size: 1.125rem;
    }

    &__feature {
        display: flex;
        flex-direction: column;
        list-style: none;

        &--title {
            font-weight: 700;
            margin-bottom: 0.25rem;
        }
    }

    @media (min-width: 480px) {
        @include body;
        gap: 2.125rem;

        &__title {
            @include heading-lg;
            margin-bottom: 1rem;
        }

        &__list {
            gap: 2rem;
        }

        &__item-title {
            @include heading-sm;
        }
    }
}
</style>
