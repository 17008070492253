<template>
<div class="appointment__physician-list">
    <div class="appointment__header">
        <h4 class="headline-3">Book Physician</h4>
        <p>Select a physician you would like to book an appointment with.</p>
    </div>
    <ul class="appointment__book-list">
        <li v-for="physician in physicians.data" @click="onSelectBookItem(physician)">
            <span :style="{'background-image': `url(${physician.profile_img.url})`}"
                  class="appointment__book-avatar"></span>
            <span class="appointment__book-bio">
					<span
                        class="appointment__book-title">Dr. {{ physician.first_name }} {{ physician.last_name }}</span>
					<span class="appointment__book-desc">NPI #{{ physician.npi_num }} </span>
				</span>
            <span class="appointment__book-icon">
					<ion-icon name="chevron-forward-outline"></ion-icon>
				</span>
        </li>
    </ul>

</div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'AppointmentSelectPhysicians',
    components: {},
    props: ['physicians'],
    data() {
        return {}
    },
    async created() {

    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        })
    },
    methods: {
        onSelectBookItem(physician) {
            this.$emit('onBookItem', physician);
        }
    }
}
</script>
<style lang="scss">

</style>
