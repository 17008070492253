<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="2"
            y="1"
            width="20"
            height="22"
            rx="3"
            :stroke="color"
            stroke-width="2"
        />
        <path d="M6 17H14" :stroke="color" stroke-width="2" />
        <path d="M6 7H18" :stroke="color" stroke-width="2" />
        <path d="M6 12H18" :stroke="color" stroke-width="2" />
    </svg>
</template>

<script>
export default {
    name: 'OrdersIcon',
    props: {
        color: {
            type: String,
            default: '#b1b1b1',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
