export const analyticsActions = {
    // identification
    identification_profile_back_photo: 'Identification Profile Back Photo Clicked',
    identification_profile_photo_selected: 'Identification Profile Photo Selected',
    identification_profile_photo_taken: 'Identification Profile Photo Taken',
    identification_profile_retake_photo: 'Identification Profile Retake Photo Clicked',
    identification_profile_select_photo: 'Identification Profile Select Photo Clicked',
    identification_profile_snap_photo: 'Identification Profile Snap Photo Clicked',
    identification_profile_submitted: 'Identification Profile Submitted',
    identification_profile_take_photo: 'Identification Profile Take Photo Clicked',
    identification_id_back_photo: 'Identification ID Back Photo Clicked',
    identification_id_photo_selected: 'Identification ID Photo Selected',
    identification_id_photo_taken: 'Identification ID Photo Taken',
    identification_id_retake_photo: 'Identification ID Retake Photo Clicked',
    identification_id_select_photo: 'Identification ID Select Photo Clicked',
    identification_id_snap_photo: 'Identification ID Snap Photo Clicked',
    identification_id_submitted: 'Identification ID Submitted',
    identification_id_take_photo: 'Identification ID Take Photo Clicked',

    // intake
    intake_step_opened: 'Intake Step Opened',
    intake_step_changed: 'Intake Step Changed',

    // payment
    payment_opened: 'Payment Opened',
    payment_submitted: 'Payment Submitted',

    // questionnaire
    questionnaire_body_opened: 'Questionnaire Body Opened',
    questionnaire_body_submitted: 'Questionnaire Body Submitted',
    questionnaire_body_success: 'Questionnaire Body Saved',
    questionnaire_profile_opened: 'Questionnaire Profile Opened',
    questionnaire_profile_submitted: 'Questionnaire Profile Submitted',
    questionnaire_profile_success: 'Questionnaire Profile Saved',
    questionnaire_start: 'Questionnaire Start My Visit Clicked',

    // shipping
    shipping_opened: 'Shipping Information Opened',
    shipping_submitted: 'Shipping Information Submitted',

    // signup
    signup: 'User Signup',

    // treatment preferences
    treatment_preferences_opened: 'Treatment Preference Opened',
    treatment_preferences_addon_change: 'Treatment Preference Addon Plan Changed',
    treatment_preferences_addon_change_plan: 'Treatment Preference Addon Change Plan Clicked',
    treatment_preferences_addon_product: 'Treatment Preference Addon Product Clicked',
    treatment_preferences_addon_skip: 'Treatment Preference Addon Skipped',
    treatment_preferences_addon_continue: 'Treatment Preference Addon Continue',
    treatment_preferences_continue: 'Treatment Preference Continue Clicked',
    treatment_preferences_duration: 'Treatment Preference Duration Selected',
    treatment_preferences_frequency: 'Treatment Preference Frequency Selected',
    treatment_preferences_item: 'Treatment Preference Item Selected',
    treatment_preferences_quantity: 'Treatment Preference Quantity Selected',

    // user
    user_updated: 'User Updated',
    session_started: 'Session Started',

    // other
    welcome_message_seen: 'Welcome Message Seen',

    // actions
    complete_visit_click: 'Complete Visit (Intake) Clicked',
    start_intake_visit_click: 'Start Visit (Intake) Clicked',

    // appointments
    appointment_scheduled: 'Appointment Scheduled',

    // start your visit
    open_schedule_view: 'Open Schedule View',
    close_schedule_view: 'Close Schedule View',
};

class Analytics {
    constructor() {
        // Removing the segment.io .track from window so all calls will be executed through this class for ease of tracking and updates.
        this.analytics = {
            ...window.analytics,
        };
        window.analytics.track = undefined;
    }

    track = (eventName, payload) => {
        if (process.env.NODE_ENV === 'production') {
            this.analytics.track(eventName, { ...payload, NODE_ENV: process.env.NODE_ENV });
        }

        if (process.env.NODE_ENV !== 'production' || localStorage.getItem('analytics_preview')) {
            // eslint-disable-next-line
            console.log('Track:', eventName, payload)
        }
    };

    identify = (userId, payload) => {
        if (process.env.NODE_ENV === 'production') {
            this.analytics.identify(userId, { ...payload, NODE_ENV: process.env.NODE_ENV });
        }

        if (process.env.NODE_ENV !== 'production' || localStorage.getItem('analytics_preview')) {
            // eslint-disable-next-line
            console.log('Identify:', userId, payload);
        }
    };
}

export const analytics = new Analytics();
