<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8 5L8 2" :stroke="color" stroke-width="2" />
        <path d="M16 5L16 2" :stroke="color" stroke-width="2" />
        <path d="M21 9L3 9" :stroke="color" stroke-width="2" />
        <rect
            x="3"
            y="5"
            width="18"
            height="14"
            rx="3"
            :stroke="color"
            stroke-width="2"
        />
        <rect
            x="6"
            y="12"
            width="2"
            height="2"
            :fill="color"
        />
        <rect
            x="11"
            y="12"
            width="2"
            height="2"
            :fill="color"
        />
        <rect
            x="16"
            y="12"
            width="2"
            height="2"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'AppointmentsIcon',
    props: {
        color: {
            type: String,
            default: '#212931',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
