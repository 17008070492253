<template>
    <div class="summary-item">
        <div class="">
            <div class="summary-item__container">
                <div class="summary-item__image">
                    <img :src="item.product_images[0]" alt="">
                </div>
                <div class="summary-item__content">
                    <div class="summary-item__header">
                        <p class="summary-item__title strong">
                            {{ item.product_name }}
                        </p>
                        <ion-icon v-if="addon" name="close-outline" class="summary-item__close" @click="removeProduct(item)" />
                    </div>
      
                    <p class="summary-item__instructions n-400">
                        {{ parseFrequencyInstructions(item.selected_configuration.frequency, item) }}
                    </p>
                    <p class="summary-item__price strong">
                        <span v-if="request">Starting at </span>${{ item.selected_configuration.pricing.total }}
                    </p>
                    <div v-if="request" class="summary-item__badge">
                        <h-badge :label="request === 'assistance' ? 'Clinical Assistance' : 'Custom Protocol Request'" :color="request === 'assistance' ? '#55D291' : '#FF4C41'" dark tiny />
                    </div>
                    <p class="summary-item__renewal strong">
                        <img src="@/assets/icons/restart.svg" alt="">
                        Renews every {{ item.selected_configuration.shipping.months }} month<span
                            v-if="item.selected_configuration.shipping.months > 1"
                        >s</span>
                    </p>
                </div>
            </div>
            <div v-if="item.membership" class="summary-item__included" :class="{active: expand}">
                <div class="summary-item__included-header" @click="toggleMembership()">
                    <p>Included with your protocol</p>
                    <div class="treatment-summary__expand">
                        <div class="chevron" :class="{active: expand}">
                            <ion-icon name="chevron-down-outline" />
                        </div>
                    </div>
                </div>
                <ul v-if="item.membership.features && item.membership.features.length">
                    <li v-for="feature in item.membership.features" :key="feature.title">
                        <p class="strong">
                            {{ feature.title }}
                        </p>
                        <p>{{ feature.description }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import HBadge from '@/components/Shared/HBadge.vue';

import { parseFrequencyInstructions } from './SummaryItem.service.js';

export default {
    name: 'SummaryItem',
    components: { HBadge },
    props: {
        item: {
            type: Object,
            required: true,
        },
        expandMembership: {
            type: Boolean,
            default: false,
        },
        addon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expand: false,
        };
    },
    computed: {
        request() {
            const {
                recommendation_requested: recomendationRequested,
                has_custom_quantity: hasCustomQuantity,
            } = this.item.selected_configuration.treatment_properties;
            
            if (recomendationRequested) {
                return hasCustomQuantity ? 'assistance' : 'custom';
            }

            return null;
        },
    },
    created() {
        this.expand = this.expandMembership;
    },
    methods: {
        removeProduct(item) {
            this.$emit('onRemoveItem', item);
        },
        toggleMembership() {
            this.expand = !this.expand;
        },
        parseFrequencyInstructions,
    },
};
</script>
<style lang="scss">
    .treatment-summary {
        &__title {
  
           font-weight: 700;
        }

        &__description {

            font-size: 0.875rem;
            line-height: 1.5;
        }

        &__renewal {

            font-size: 0.875rem;
            line-height: 1.5;
            font-weight: 500;
            margin-bottom: 0;
        }

        &__membership {
            border-top: 1px solid #ebebeb;
            padding: 1.5rem 1rem 0 1rem;
            margin-top: 1.5rem;
            overflow: hidden;
            max-height: 40px;
            transition: 0.3s ease max-height;

            &.active {
                max-height: 600px;
            }

            @media (max-width: 760px) {
                padding: 1.5rem 0 0 0;
            }
        }

        &__membership-header {
            display: flex;
            justify-content: space-between;
        }

        &__membership-features {
            list-style: none;
            padding: 0;
            margin: 0;

        }

        &__membership-feature {
            display: flex;
            margin-bottom: 0.5rem;
        }

        &__membership-description {
            display: flex;
            flex-direction: column;
            font-size: 0.875rem;
        }

        &__membership-feature-title {
            font-weight: 500;
        }

        &__footer-text {

            font-size: 0.875rem;
            line-height: 1.5;
            padding-top: 0.5rem;
        }
    }


    .chevron {
        position: relative;
        width: 15px;
        height: 15px;
        transition: 0.3s ease transform;

        &.active{
            cursor: pointer;
            transform:rotate(180deg);
        }

        ion-icon {
            font-size: 16px;
            cursor: pointer;
        }
    }

</style>
