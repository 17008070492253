<template>
    <div class="treatment-description__container">
        <div class="treatment-description__bundle" v-if="intake.bundle_support">
            <div class="treatment-description__image" :style="{'background-image': `url(${product.product_images[0]})`}"></div>
            <div class="treatment-description__information">
                <h3 class="cereal-product-showcase">
                    {{product.product_name}}
                    <span class="treatment-description__bundle-item">{{this.get_product_index(product._id).current}} of {{this.get_product_index(product._id).total}}</span>
                </h3>
                <p class="treatment-description__product-desc">
                    {{ product.description }}
                </p>
            </div>
        </div>


    </div>

</template>
<script>
export default {
	name: 'TreatmentTitle',
	components: {},
    props: ['intake', 'product'],
	data() {
		return {

		}
	},
	created() {
	},
	computed: {},
	methods: {
        get_product_index(product_id) {
            return {
                current: (this.intake.product_defaults.indexOf(product_id) + 1),
                total: this.intake.product_defaults.length
            };
        }
    }
}
</script>
<style lang="scss">
    .treatment-description {

        &__title {
            font-family: 'ZP-UI', sans-serif;
            font-weight: bold;
            font-size: 38px;
            margin-bottom: .5rem;
            color: #000;
            vertical-align: top;
            display: flex;
            align-items: center;
        }

        &__bundle-item {
            font-size: 0.875rem;
            font-family: 'ZP-UI', sans-serif;
            font-weight: 500;
            color: $stoneBlack;
            background-color: $stone200;
            padding: 0 1rem;
            border-radius: 40px;
            line-height: 34px;
            margin-left: 0.5rem;
            display: inline-block;
            vertical-align: bottom;
        }

        &__bundle {
            display: flex;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            padding: 2rem 1rem;
            border: 1px solid #E6E1DC;
            margin-bottom: 1.5rem;
        }

        &__information {
            width: 80%;
        }

        &__product-desc {
            color: rgba(0,0,0,.54);
            font-size: 1rem;
            line-height: 1.5;
            font-family: 'ZP-UI', sans-serif;
        }

        &__image {
            width: 30%;
            height: 130px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &__features {
            margin-top: 1.5rem !important;
        }


    }
</style>
