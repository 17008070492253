import axios from 'axios';
import uniqBy from 'lodash/uniqBy';

import { cdnFile } from '../../helpers/cdn';

const baseUrl = process.env.VUE_APP_BASE_END;

import Pusher from 'pusher-js/with-encryption';

import messagePayloadService from '../../services/messagePayloadService';

//pusher instance
const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY,
    {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        authEndpoint: process.env.VUE_APP_BASE_END + '/pusher/auth',
    },
);

export const conversations = {
    namespaced: true,
    state: {
        conversation: null,
        messages: [],
        conversation_id: null,
        conversation_details: null,
        new_conversation: false,
        loading: false,
        read_attempting: false,
        error_retrieving_conversations: false,
        error_retrieving_messages: false,
        error_posting_message: false,
        pagination_skip: 0,
        admin_is_typing: false,
        presence_channel: null,
        socket_id: null,
        pusher_channel: null,
        encrypted_channel_name: null,
    },
    getters: {
        first_message_id: (state) => {
            //if an empty array on the conversation return false
            if (state.messages.length === 0) {
                return false;
            }
            //if not an empty array return the first object id of the first message in the array
            else {
                return state.messages._id;
            }
        },
        last_patient_sent: (state) => {
            const messages = state.messages;

            const uniqueArray = uniqBy(messages, function (e) {
                return e._id;
            });

            const lastId = uniqueArray.slice().reverse().find(message => !message.author.is_admin);

            if (lastId) {
                return lastId._id;
            } else {
                return '';
            }
        },
    },
    mutations: {
        setPresenceChannel(state, payload) {
            state.presence_channel = payload;
        },
        showTyping(state) {
            //if the flag is set that admin_is_typing then break out
            //we don't want to reset the timeout function
            if (state.admin_is_typing) {
                return;
            }

            //set the admin is typing to true for ux chat bubble
            state.admin_is_typing = true;

            //after 10 seconds set admin_is_typing to false
            setTimeout(() => {
                state.admin_is_typing = false;
            }, 8000);
        },
        closeTyping(state) {
            state.admin_is_typing = false;
        },
        newConversation(state) {
            state.messages = [];
            state.conversation_details = {};
            state.new_conversation = true;
        },
        updateConversationId(state, conversationId) {
            state.conversation_id = conversationId;
        },
        resetConversation(state) {
            state.messages = [];
            state.pagination_skip = 0;
        },
        appendMessage(state, message) {
            state.messages.push(message);
            //remove duplicates
            state.messages = [...new Set(state.messages)];
        },
        prependMessages(state, messages) {
            //concatenate newly returned array of messages to the existing conversation messages
            state.messages = [...messages, ...state.messages];
        },
        setMessages(state, messageArray) {
            state.messages = messageArray;
        },
        setConversationDetail(state, conversation) {
            state.conversation_details = conversation;
        },
        playOperatorAudio() {
            const audio = new Audio(cdnFile('sounds/common/operator.mp3'));

            audio.play();
        },
        incrementPagination(state) {
            state.pagination_skip = state.pagination_skip + 1;
        },
        resetPagination(state) {
            state.pagination_skip = 0;
        },
        //patient fired a new message, set the medical team read state to false
        resetAdminUnread(state) {
            state.conversation_details.medical_team_read = false;
        },
        //the doctor has read the message, and pusher has updated the patient client message interface
        resetAdminRead(state) {
            state.conversation_details.medical_team_read = true;
        },
        resetPatientUnread(state) {
            state.conversation_details.patient_read = false;
        },
    },
    actions: {
        startNewConversation({ commit }) {
            commit('newConversation', true);
        },
        async submitMessage({ state, dispatch }, payload) {
            if (!state.conversation) {
                //determine if it is a new conversation
                const messagePayload = messagePayloadService.createNewConversationPayload(payload.user, payload.message, state.socket_id);
                const response = await dispatch('createConversation', messagePayload);

                await dispatch('change_conversation', response.conversation_id);
            } else {
                const messagePayload = messagePayloadService.createMessagePayload(payload.user, payload.message, state.conversation_id, state.socket_id);

                await dispatch('postMessage', messagePayload);
            }

        },
        //push to the server when a patient is typing
        isTypingPatient({ rootState }, payload) {

            //create a promise
            return new Promise((resolve, reject) => {
                //post that to the conversation endpoint /typing
                axios({
                    url: baseUrl + '/conversations/' + rootState.orgId.data + '/patient/' + payload.patient_id + '/conversation/' + payload.conversation_id + '/typing',
                    data: payload,
                    method: 'POST',
                }).then(response => {
                    //resolve the promise
                    resolve(response);
                }).catch(err => {
                    //reject the err
                    reject(err);
                });
            });
        },
        //set the patient read status to true. the patient has read the last message
        updatePatientReadStatus({ state, rootState }, payload) {

            //set the read attempting flag to true
            state.read_attempting = true;

            //create a promise token to call the update the conversation read status endpoint
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/conversations/' + rootState.orgId.data + '/patient/' + payload.patient_id + '/conversation/' + payload.conversation_id + '/patient/read',
                    data: payload,
                    method: 'PUT',
                }).then(response => {

                    //set the read attempting state to false
                    state.read_attempting = false;

                    //mark the conversation as read
                    state.conversation_details.patient_read = true;

                    //resolve the promise
                    resolve(response.data);

                }).catch(err => {

                    //set the read attempting state to false
                    state.read_attempting = false;

                    //mark the conversation as read to avoid making an additional call to avoid  blocking other calls in the call stack
                    state.conversation_details.patient_read = true;

                    //reject the err
                    reject(err);
                });
            });
        },

        async getConversations({ commit, state, dispatch }) {
            try {
                const { data } = await axios({ url: baseUrl + '/v2/conversations/patient' });

                state.conversation = data;

                if (data) {
                    state.new_conversation = true;
                    await dispatch('change_conversation', data._id);

                    commit('setMessages', data.messages);
                    commit('setConversationDetail', data);
                }

                return data;
            } catch (e) {
                state.error_retrieving_conversations = true;
                throw e;
            }
        },

        async change_conversation({ state, commit }, id) {

            //unsubscribe from any existing conversations

            commit('updateConversationId', id);
            commit('resetConversation');
            pusher.unsubscribe(state.encrypted_channel_name);

            //create a channel string
            state.encrypted_channel_name = 'private-encrypted-' + id;

            //subscribe to channel
            state.pusher_channel = pusher.subscribe(state.encrypted_channel_name);

            //set the socket id, in order to exclude it from the event propagation
            state.socket_id = pusher.connection.socket_id || null;

            //event name
            const eventName = 'chat-event' + id;

            //bind to channel
            state.pusher_channel.bind(eventName, (data) => {

                //create a variable for the response
                const pusherResponse = data;

                //if a message is sent back to the channel
                if (pusherResponse.type === 'message') {
                    commit('appendMessage', pusherResponse.message);
                    commit('resetPatientUnread');
                    commit('closeTyping');
                }

                //if the patient has read the previous message from the medical_team
                if (pusherResponse.type === 'read') {
                    //only mark as read if the user is not h
                    if (pusherResponse.sender_id !== this.user._id) {
                        //flag that the admin has read the message
                        commit('resetAdminRead');
                    }
                }

                //if the patient is typing
                if (pusherResponse.type === 'typing') {
                    //only call showTyping if it is not the current patient who is typing
                    //only show if the recipient of the message (the admin) is typing
                    if (pusherResponse.sender_id !== this.user._id) {
                        //flag that the support agent/admin is typing
                        commit('showTyping');
                    }
                }
            });
        },
        //create a conversation
        createConversation({ commit, rootState }, payload) {
            const patientId = rootState.user.user._id;
            const orgId = rootState.orgId.data;

            payload['patient_id'] = patientId;

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/conversations/' + orgId + '/patient/' + patientId,
                    data: payload,
                    method: 'POST',
                }).then(response => {
                    const message = response.data.messages[0];
                    const conversation = response.data.conversation_object || {};

                    commit('updateConversationId', conversation._id);
                    commit('appendMessage', message);
                    commit('playOperatorAudio');
                    resolve(message);
                }).catch(err => {
                    reject(err);
                });
            });
        },
        postMessage({ commit, state, rootState }, payload) {

            //get patient id from the user.js store module
            const patientId = rootState.user.user._id;

            //attach the patient_id to the payload
            payload['patient_id'] = patientId;

            //get conversationId from the payload
            const conversationId = payload.conversation_id;

            const orgId = rootState.orgId.data;

            //create a promise token
            return new Promise((resolve, reject) => {

                axios({
                    url: baseUrl + '/conversations/' + orgId + '/patient/' + patientId + '/conversation/' + conversationId,
                    data: payload,
                    method: 'PUT',
                }).then(response => {
                    const message = response.data;

                    commit('appendMessage', message);
                    commit('playOperatorAudio');
                    commit('resetAdminUnread');
                    resolve(response.data);
                }).catch(err => {
                    state.error_posting_message = true;
                    reject(err);
                });
            });
        },
        gatherMessages({ commit, state, rootState }, payload) {
            const params = {};
            const patientId = rootState.user.user._id;

            payload['patient_id'] = patientId;
            const conversationId = payload.conversation_id;
            const orgId = rootState.orgId.data;
            const firstMessageId = payload.lastId;

            //set the params lastId
            params['lastId'] = firstMessageId;
            params['internal_message'] = false;

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/conversations/' + orgId + '/patient/' + patientId + '/conversation/' + conversationId,
                    params,
                    method: 'GET',
                }).then(response => {
                    const conversation = response.data;
                    const { messages } = conversation;

                    if (firstMessageId === false) {
                        commit('setMessages', messages);
                    } else {
                        commit('prependMessages', messages);
                    }
                    commit('setConversationDetail', conversation);
                    resolve(messages);
                }).catch(err => {
                    state.error_retrieving_messages = true;
                    reject(err);
                });
            });
        },
    },
};
