export function getShipmentDate(vendor) {
    if (vendor && vendor.schedule_details) {
        const phases = vendor.schedule_details.phases;
        const latestPhase = phases[phases.length - 1];
        const start = latestPhase.start_date;

        if (start <= vendor.current_period_start) {
            return null;
        }

        return start;
    } else {
        return null;
    }
}
