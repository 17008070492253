<template>
    <div class="htabs">
        <ul>
            <li
                v-for="item in menu"
                :key="item.name"
                class="htabs__item"
                :data-test="`${item.section}-tab`"
                :class="{'htabs__active-item': active === item.section}"
                @click="$emit('click', item.section)"
            >
                {{ item.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'HTabs',
    props: {
        menu: {
            type: Array,
            required: true,
        },
        active: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.htabs {
    position: relative;
    border-bottom: 1px solid $gray-50;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        li {
            padding: 10px 0;
            margin-right: 1rem;
            cursor: pointer;
        }
    }

    &__item {
        @extend %body;
        text-decoration: none; 
        position: relative; 

        &:hover {
            &:after {
                position: absolute;
                content: '';
                height: 1px;
                bottom: -1px; 
                margin: 0 auto;
                left: 0;
                right: 0;
                width: 100%;
                background: $health-orange;
            }
        }
    }

    &__active-item {
        font-weight: 700;
        
        &:after {
            position: absolute;
            content: '';
            height: 1px;
            bottom: -1px; 
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 100%;
            background: $health-orange;
        }
    }
}
</style>
