<template>
    <h-card-base :title="product.product_name" :description="product.description" :description-length="126" :img-src="product.product_images[0]">
        <template #header>
            <div>
                <h-badge v-if="badge" :label="badge.label" :color="badgeColor" small />
                <h-badge v-if="product.type === 'lab'" label="Labs" color="#599bea" small />
                <h-badge v-if="product.type === 'supplement'" label="Supplements" color="#599bea" small />
            </div>
        </template>
        <template #footer>
            <div class="shop-card-footer">
                <h-button
                    v-if="product.type === 'lab' || product.type === 'supplement'"
                    label="Purchase"
                    variant="primary"
                    :handle-click="handleOneTimeCheckout"
                    full_width
                    cta
                    small
                />
                <h-button
                    v-else-if="!subscribed"
                    label="Start Visit"
                    variant="primary"
                    :handle-click="() => startVisit()"
                    :loading="visitLoading"
                    full_width
                    cta
                    small
                />
                <h-button
                    v-else-if="subscribed"
                    label="Manage"
                    variant="primary"
                    :handle-click="manageSubscription"
                    full_width
                    cta
                    small
                />
                <h-text-link label="Learn More" :link="product.product_url" cta blank />
            </div>
        </template>
    </h-card-base>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButton.vue';
import HCardBase from '@/components/Shared/HCardBase.vue';
import HTextLink from '@/components/Shared/HTextLink.vue';
import { startVisitMethod } from '@/helpers/visit.js';
import IntakeService from '@/services/IntakeService';

export default {

    name: 'ShopCard',
    components: {
        HCardBase,
        HBadge,
        HButton,
        HTextLink,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visitLoading: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            subscriptions: (state) => state.subscriptions.subscriptions,
            incompleteIntakes: state => state.intake.incomplete_intakes,
        }),
        ...mapGetters({
            isCompletedUserProfile: 'user/isCompletedUserProfile',
        }),
        badge() {
            return this.product?.shop?.tags?.find((tag) => tag.main);
        },
        badgeColor() {
            if (this.badge.label === 'Energy') {
                return '#F5C64F';
            } else if (this.badge.label === 'Metabolic') {
                return '#55D291';
            } else {
                return '#FF4C41';
            }
        },
        subscribed() {
            try {
                const result = this.subscriptions.data.find((sub) => sub.product_plans.some((item) => item.product_template === this.product._id));

                return result && result.status === 'active';
            } catch (error) {
                return false;
            }
        },
    },
    methods: {
        ...mapActions({
            getIntakeTemplate: 'intake/getIntakeTemplate',
            create_intake_instance: 'intake/createIntakeInstance',
            save_product_template_id: 'productTemplates/saveSignupProductTemplateId',
        }),
        ...mapMutations({
            setSelectedProduct: 'productTemplates/setSelectedProduct',
        }),
        async newIntakeInstance(intakeTemplate) {
            window.localStorage.removeItem('intake_template');

            try {
                let intake = await this.create_intake_instance({
                    templateId: intakeTemplate._id,
                    userId: this.user._id,
                    pharmacy_verification: true,
                });

                this.save_product_template_id(intakeTemplate.product_defaults[0]);
                window.localStorage.setItem('intake_template', JSON.stringify(intakeTemplate));
                IntakeService.initSignupConfiguration(intakeTemplate);
                this.visitLoading = false;
                this.$router.push({
                    path: '/consultation/hiw/' + intake.user + '/' + intake._id + '/question/none',
                    query: { referrer: 'shop' },
                });
            } catch (error) {
                this.visitLoading = false;
                throw error;
            }
        },
        existingIntakeVisit(user, intakeId) {
            this.visitLoading = false;
            this.$router.push({
                path: `/consultation/hiw/${user}/${intakeId}/question/none`,
                query: { referrer: 'shop' },
            });
        },
        async startVisit() {
            this.visitLoading = true;
            this.setSelectedProduct({ product: this.product, referrer: 'shop' });

            await startVisitMethod.call(this, this.product.intake_template, this.product );
        },
        manageSubscription() {
            this.$router.push({ path: `/dashboard/${this.user._id}/subscriptions` });
        },
        handleOneTimeCheckout() {
            if (this.isCompletedUserProfile) {
                this.$emit('openCheckoutPanel', this.product._id);
            } else {
                this.setSelectedProduct({ product: this.product, referrer: 'shop' });
                this.$router.push({ path: '/signup/profile', query: { productTemplateId: this.product._id } });
            }
        },
    },
};
</script>

<style lang="scss">
.cardbase {
    max-width: 296px;
    border-radius: 1.5rem;
    padding: 1.5rem;
}

.cardbase-main {
    margin: 0 auto;

    &__image {
        img {
            width: 237px;
            height: 237px;
        }
    }
}

.shop-card-footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.5rem;
    margin-top: auto;
}
</style>
