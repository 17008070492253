export const PRODUCT_TYPES = {
    medication: 'medication',
    supplement: 'supplement',
    lab: 'lab',
};

export const PRODUCT_PRICING_TYPES = {
    oneTime: 'one_time',
    recurring: 'recurring',
};
