<template>
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.25 8.75V14.25C2.25 15.3546 3.14543 16.25 4.25 16.25H16.75C17.8546 16.25 18.75 15.3546 18.75 14.25V8.75M2.25 8.75V5.75C2.25 4.64543 3.14543 3.75 4.25 3.75H16.75C17.8546 3.75 18.75 4.64543 18.75 5.75V8.75M2.25 8.75H18.75M5.25 12.75H8.75"
            :stroke="stroke"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Card',
    props: {
        stroke: {
            type: String,
            default: '#fff',
        },
    },
};
</script>
