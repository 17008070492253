<template>
	<div class="analysis-optimization">
		<div class="analysis-optimization__graph">
			<svg width="160px" height="160px" viewBox="0 0 40 40">
				<circle class="donut-hole" cx="20" cy="20" r="16" fill="transparent"></circle>
				<circle class="donut-ring" cx="20" cy="20" r="16" fill="transparent" stroke-width="2"></circle>
				<circle
					class="donut-segment"
					cx="20"
					cy="20"
					r="16"
					fill="transparent"
					stroke-width="2"
					stroke-linecap="round"
					:stroke-dasharray="`${summary} ${100 - summary}`"
					stroke-dashoffset="25"
				></circle>
				<g class="donut-text">
					<text y="50%">
						<tspan x="50%" text-anchor="middle" class="donut-main-text">{{ summary }}%</tspan>
					</text>
					<text y="63%">
						<tspan x="50%" text-anchor="middle" class="donut-sub-text">optimized</tspan>
					</text>
				</g>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LabOptimization',
	props: {
		markers: {
			type: Array,
			default: () => [],
		},
		summary: {
			type: Number,
		},
	},
};
</script>

<style lang="scss" scoped>
.analysis-optimization {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding: 1.5rem 1.25rem;

	&__graph {
		animation: donutfade 1s;

		@keyframes donutfade {
			0% {
				opacity: 0.2;
			}
			100% {
				opacity: 1;
			}
		}

		.donut-ring {
			stroke: rgba(15, 192, 142, 0.2);
		}

		.donut-segment {
			transform-origin: center;
			stroke: rgba(15, 192, 142, 1);
			animation: donut1 3s;
		}

		.donut-text {
			font-family: 'ZP-UI', sans-serif;
			font-size: 16px;
			fill: rgba(15, 192, 142, 1);
		}

		.donut-main-text {
			font-size: 0.4rem;
			line-height: 1;
			transform: translateY(0.5rem);
			font-weight: bold;
		}

		.donut-sub-text {
			font-size: 0.215rem;
			line-height: 1;
			text-align: center;
			text-anchor: middle;
			color: #666;
			fill: #666;
		}

		@keyframes donut1 {
			0% {
				stroke-dasharray: 0, 100;
			}
		}
	}
}
</style>
