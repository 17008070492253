import { isMedicalProfileEmpty } from '@/components/intake/utils/medicalProfileUtils';
import { analytics, analyticsActions } from '@/helpers/analytics';

export default {
    initSignupConfiguration(intakeTemplate, params) {
        let config = {};

        if (!intakeTemplate) {
            const productType = params.productType;
            const productId = params.productId;

            if (productType && productType === 'lab') {
                config = {
                    intake: {
                        hasIntake: false,
                    },
                    membership: {
                        hasMembership: false,
                    },
                    product: {
                        noProducts: true,
                    },
                    lab: {
                        product: productId,
                    },
                };
            }

            if (productType && productType === 'medication') {
                config = {
                    intake: {
                        hasIntake: false,
                    },
                    membership: {
                        hasMembership: false,
                    },
                    product: {
                        firstProductId: productId,
                    },
                };
            }

            if (productType && productType === 'membership') {
                config = {
                    intake: {
                        hasIntake: false,
                    },
                    membership: {
                        hasMembership: true,
                    },
                    product: {
                        noProducts: true,
                    },
                };
            }
        } else {
            config = {
                intake: {
                    hasIntake: true,
                    id: intakeTemplate._id,
                },
                membership: {
                    hasMembership: intakeTemplate.plan_checkout_options.membership_choice_step,
                },
                product: {
                    main_products: intakeTemplate.product_defaults,
                    addon_products: intakeTemplate.product_addons,
                    bundle_support: intakeTemplate.bundle_support,
                },
                lab: {
                    product: params ? params.labProductId : null,
                },
            };

            if (params && 'productTemplateId' in params) {
                if (params.productTemplateId) {
                    config.product['default'] = params.productTemplateId;
                }
            } else {
                config.product['default'] = intakeTemplate.product_templates[0];
            }
        }

        const converted = JSON.stringify(config);

        window.localStorage.setItem('signup-config', converted);
        window.localStorage.setItem('intake_template', JSON.stringify(intakeTemplate));

        return config;
    },

    clearSignupStorage() {
        window.localStorage.removeItem('signup-config');
        window.localStorage.removeItem('intake_template');
        window.localStorage.removeItem('selected_products');
        window.localStorage.removeItem('default_item');
    },

    /*
    * type TMethods: {
    *     retrieveIntake: (params: {_id: string, user: string}) => Promise<Object>;
    *     setIntakeId: (intakeId: string) => void;
    *     initializeIntakeId: (intake) => void;
    *     routerPush: (options: object) => void;
    * }
    *
    * type TOptions: {
    *     userId: string;
    *     intakeId: string;
    *     intakeTemplate: object;
    *     isCompletedUserProfile: boolean;
    *     source: string;
    *     status: {
    *        incomplete?: {
    *            total?: number;
    *            verification?: object[];
    *            intake_responses?: object[];
    *        },
    *        complete?: { total?: number };
    *        questions?: { total?: number };
    *     };
    *     productId?: string;
    *     medicalProfile?: object;
    * }
    *  */
    async continueCompletingIntake(methods, { userId, intakeId, status, intakeTemplate, source, ...options }) {
        try {
            analytics.track(analyticsActions.complete_visit_click, {
                source,
                intakeId,
                templateId: intakeTemplate._id,
                templateName: intakeTemplate.name,
            });

            const intake = await methods.retrieveIntake({ _id: intakeId, user: userId });

            await methods.initializeIntakeId(intake);

            methods.setIntakeId(intakeId);

            this.initSignupConfiguration(intakeTemplate);

            if (!options.isCompletedUserProfile) {
                return methods.routerPush({ path: `/consultation/profile/${userId}/${intake._id}/question/none` });
            }

            if (!options.medicalProfile || isMedicalProfileEmpty(options.medicalProfile)) {
                return methods.routerPush({ path: `/consultation/pharmacy-overview/${userId}/${intake._id}/question/none` });
            }

            if (status.complete?.total < status.questions?.total) {
                const responses = status.incomplete?.verification?.length
                    ? status.incomplete?.verification
                    : status.incomplete?.intake_responses;

                return methods.routerPush({
                    path: `/consultation/intake/${userId}/${intakeId}/question/${responses[0]?._id}`,
                    query: { step: status.complete.total },
                    replace: true,
                });
            }

            if (intake.intake_template._id === process.env.VUE_APP_COMPOUNDED_RAPAMYCIN_TEMPLATE_ID) {
                return methods.routerPush({
                    name: 'protocol-selection',
                    params: {
                        section: 'protocol-selection',
                        intakeId: intake._id,
                    },
                }).catch(err => console.error(err));
            }

            return methods.routerPush({
                name: 'treatment-checkout',
                params: {
                    intakeId,
                    section: 'product-configure',
                },
                query: {
                    product: options.productId,
                },
            });
        } catch (error) {
            console.error('Continue intake error', error);
        }
    },
};
