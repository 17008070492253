<template>
    <div class="auth">
        <auth-info 
            type="reset"
            :organization_settings="organizationSettings"
        >
            <form class="login-form" @submit.prevent="authentication.success ? onLogin() : onResetSubmit()">
                <Input
                    v-if="!authentication.success"
                    id="email"
                    v-model="authentication.email"
                    name="email"
                    label="Email"
                    :vuelidate="$v.authentication.email"
                    :errors="[!$v.authentication.email.email && $v.authentication.email.$dirty && 'Please enter a valid email address']"
                />
                <div v-if="error" class="auth-alert__error">
                    <span class="h-caption">{{ error }}</span>
                </div>
                <div v-if="authentication.success" class="auth-alert__success">
                    <p class="h-caption">
                        Please check your email for instructions on how to reset your password.
                    </p>
                </div>
                <h-button
                    v-if="!authentication.success"
                    :label="button_text"
                    variant="primary"
                    full_width
                    large
                    :handle-click="onResetSubmit"
                    :loading="submitted && !error"
                    :disabled="$v.$invalid"
                />
                <h-button
                    v-if="authentication.success"
                    label="Login"
                    variant="primary"
                    full_width
                    large
                    :handle-click="onLogin"
                />      
            </form>  
        </auth-info>
        <div class="auth__hero">
            <div class="auth__hero auth__hero__backdrop auth__hero__generic" :style="{'background-image': `url(${get_image()})`}"></div>
        </div>
    </div>
</template>

<script>

import { email, minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapState } from 'vuex';

import AuthInfo from '@/components/Auth/AuthInfo.vue';
import Input from '@/components/Auth/Input.vue';
import HButton from '@/components/Shared/HButton';

export default {
    components: {
        AuthInfo,
        Input,
        HButton,
    },
    data() {
        return {
            submitted: false,
            button_text: 'Submit',
            authentication: {
                email: '',
                success: false,
            },
            error: null,
        };
    },
    validations: {
        authentication: {
            email: { required, minlength: minLength(4), email },

        },
    },
    mounted() {
        this.$nextTick(() => {
            if (!this.organizationSettings.data) {
                this.getOrganizationSettings();
            }
        });
    },
    methods: {
        get_image() {
            return this.organizationSettings.data.login.image;
        },
        onResetSubmit() {

            //the submitted flag will trigger validation messages
            this.submitted = true;

            //es6 reference to this for arrow functions
            var vm = this;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.resetPassword(this.authentication.email).then(() => {

                //trigger a second success message
                vm.authentication.success = true;

                //reset the email
                vm.authentication.email = null;
                this.error = null;
                vm.submitted = false;

            }).catch(() => {
                this.error = 'Invalid Email. Try Again.';
                vm.submitted = false;
            });

        },
        onLogin() {
            this.$router.push('/login');
        },
        ...mapMutations({
            initialize: 'questionnaire/initializeQuestionnaireStore',
        }),
        ...mapActions({
            resetPassword: 'authenticate/requestNewPassword',
            getOrganizationSettings: 'getOrganizationSettings',
        }),
    },

    computed: {

        ...mapState({
            bad_credentials: state => state.authenticate.bad_credentials,
            organizationSettings: state => state.organizationSettings,
        }),
        ...mapGetters({
            isLoggedIn: 'authenticate/isLoggedIn',
            jwt: 'authenticate/jwt',
            jwtData: 'authenticate/jwtData',
        }),
    },
};
</script>

<style lang="scss">

</style>
