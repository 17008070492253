<template>
    <div class="shop-nav">
        <div class="shop-nav-main">
            <div class="shop-nav-main__left">
                <div class="shop-nav-main__back" @click="returnToPage">
                    <ChevronBackIcon />
                </div>
                <div
                    v-if="organizationSettings?.data?.logo"
                    :style="{'background-image': 'url(' + organizationSettings.data.logo +')'}"
                    class="shop-nav-main__logo"
                />
            </div>
            <div class="shop-nav-main__right" @click="returnToDashboard()">
                Return to Dashboard
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import ChevronBackIcon from '@/assets/icons/ChevronBackIcon.vue';

export default {
    name: 'ConfirmationNav',
    components: {
        ChevronBackIcon,
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            organizationSettings: state => state.organizationSettings,
        }),
    },
    methods: {
        returnToDashboard() {
            this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
        },
        returnToPage() {
            this.$router.push({ path: `/dashboard/${this.user._id}/labs` });
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.shop-nav {
    display: flex;
    align-items: center;
    height: 84px;
    border-bottom: 1px solid $gray-50;
}

.shop-nav-main {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding: 0 2rem;

    &__left {
        display: none;
    }

    &__back {
        cursor: pointer;
    }

    &__right {
        display: flex;
        align-items: center;
        @extend %bodySm;
        font-weight: 700;
        cursor: pointer;
    }

    @media ($min-640) {
        flex-direction: row;
        justify-content: space-between;

        &__left {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        &__logo {
            width: 150px;
            height: 65px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right center;
        }
    }

    @media ($min-1440) {
        max-width: 1440px;
        margin: 0 auto;
    }
}
</style>
