import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_END;

export const visits = {
    namespaced: true,
    state: {
        visitIntroTemplate: {
            data: null,
            loading: false,
            error: false
        }
    },
    getters: {},
    mutations: {
        setVisitIntroTemplateData(state, data) {
            state.visitIntroTemplate.data = data;
        },
        setVisitIntroTemplateError(state, error) {
            state.visitIntroTemplate.error = error;
        },
        setVisitIntroTemplateLoading(state, loading) {
            state.visitIntroTemplate.loading = loading;
        }
    },
    actions: {
        getVisitIntroTemplate({commit, getters, state, rootState}, payload) {
            commit('setVisitIntroTemplateLoading', true);
            commit('setVisitIntroTemplateError', false);
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/visits/' + rootState.orgId.data + '/template/visit-intro/' + payload.visit_intro_id,
                    method: 'GET'
                }).then(response => {
                    commit('setVisitIntroTemplateData', response.data);
                    commit('setVisitIntroTemplateLoading', false);
                    resolve(response.data);
                }).catch(err => {
                    commit('setVisitIntroTemplateError', true);
                    commit('setVisitIntroTemplateLoading', false);
                    reject(err.response.data);
                });
            })
        }
    }
}
