<template>
    <div class="shop-products">
        <div class="shop-grid">
            <skeleton-base width="287px" height="500px" color="#ebebeb" radius="1.5rem" />
        </div>
        <div class="shop-grid">
            <skeleton-base width="287px" height="500px" color="#ebebeb" radius="1.5rem" />
        </div>
        <div class="shop-grid">
            <skeleton-base width="287px" height="500px" color="#ebebeb" radius="1.5rem" />
        </div>
    </div>   
</template>

<script>
import SkeletonBase from '../Base/SkeletonBase.vue';

export default {
    name: 'ProductGridSkeleton',
    components: {
        SkeletonBase,
    },
};
</script>
