<template>
<div>
    <ul v-if="durations && durations.length > 1" class="protocol-duration" >
        <li v-for="shipping in durations"
            :key="shipping._id"
            class="protocol-duration__option"
            :class="{'protocol-duration__option--active': shipping.active}"
            @click="onPlanSelect(shipping)"
        >
            <div v-if="shipping.default" class="protocol-duration__option-ribbon--mobile">
                Best Value
            </div>
            <div v-if="shipping.default" class="protocol-duration__option-ribbon--desktop">
                <span>Best Value</span>
            </div>
            <div class="protocol-body480 strong">
                <div class="flex gap-25">
                    <span>{{ shipping.months }} Month </span><span class="protocol-duration__desktop">Plan</span>
                </div>
            </div>
            <div class="divider w-15"  :class="{'divider--active': shipping.active}"></div>
            <div v-if="shipping.prices && shipping.prices.total_discount !== shipping.prices.total" class="protocol-caption480 strong">
                <span v-if="!loading_treatment">Save ${{ (shipping.prices.total - shipping.prices.total_discount) }}</span>
                <div v-if="loading_treatment" class="protocol-checkout__spinner" :class="{'protocol-checkout__spinner--active': shipping.active }">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </div>
            <div v-if="shipping.prices && shipping.prices.total_discount === shipping.prices.total" class="protocol-caption480 strong">
                Pay Full Price
            </div>
        </li>
    </ul>
</div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import product_service from '@/services/Product';

export default {
    name: 'TreatmentPlanDurations',
    props: ['products', 'intake'],
    components: {},
    data() {
        return {
            prices: null,
            product_items: null,
            product_templates: null,
            durations: null,
            active_products: [],
            active_membership_prices: [],
            shipping_products: null,
            shipping_memberships: null,
            active_template: false,
            loading_treatment: false
        }
    },
    watch: {
      '$route.query.product': {
          async handler(product) {
              if (product) {
                  setTimeout(() => {
                      this.get_durations(this.$route.query.product);
                      this.set_default_duration();
                      this.$forceUpdate();
                      this.$nextTick();
                  }, 500)

                }
          },
          immediate: true,
          deep: true
      },
        'treatment_durations': {
            async handler(duration) {
                if (duration) {
                    this.loading_treatment = true;
                    setTimeout(() => {
                        this.get_durations(this.$route.query.product);
                        this.set_default_duration();
                        this.$forceUpdate();
                        this.$nextTick();
                        this.loading_treatment = false;
                    }, 1500);


                }
            },
            immediate: true,
            deep: true
        }
    },
    async created() {
        try {
            this.get_durations();
            this.$forceUpdate();
            await this.$nextTick();
        } catch (e) {
            console.log('created() TreatmentPlanDuration error', e);
        }

    },
    computed: {
        ...mapState({
            treatment_products: state => state.treatment.treatment_products,
            treatment_preferences: state => state.treatment.treatment_preferences,
            treatment_durations: state => state.treatment.treatment_durations,
            active_duration: state => state.treatment.active_treatment_duration
        })
    },
    methods: {
        ...mapActions({
            get_products: 'products/getProducts',
            get_membership_prices: 'memberships/getMembershipPrices'
        }),
        ...mapMutations({
           set_active_duration: 'treatment/setActiveDuration'
        }),
        onPlanSelect(shipping) {
            const shippingFreq = this.durations;
            const selected = shippingFreq.indexOf(shipping);
            this.durations.forEach((item, index) => {
                item.active = selected === index
            })
            this.$forceUpdate();
            this.$emit('onPlanSelected', shipping);
        },
        get_durations(product) {
            if (!this.intake.bundle_support) {
                this.durations = this.treatment_durations[product]

            } else {
                let duration_price_map = {}
                let all_durations = Object.entries(this.treatment_durations).flatMap(([key, value], index) => {
                    return value;
                });
                for (let duration of all_durations) {
                    let months = duration.months
                    if (months in duration_price_map) {
                        duration_price_map[months].push(duration);
                    } else {
                        duration_price_map[months] = [duration]
                    }
                }
                if (this.treatment_products && this.treatment_products.length) {
                    const new_durations = this.treatment_products[0].shipping_frequencies;
                    new_durations.map(duration => {
                        duration.prices = product_service.generateTotalPrice(duration_price_map[duration.months]);
                        return duration;
                    });
                    this.durations = new_durations;
                }
                this.set_default_duration();
            }
        },
        set_default_duration() {
            if (this.durations) {
                let index = this.durations.indexOf(this.durations.find(duration => duration.default));
                if (index === -1) {
                    index = 0;
                }
                if ('months' in this.active_duration) {
                   index = this.durations.indexOf(this.durations.find(duration => this.active_duration.months === duration.months));
                }

                this.durations.forEach(f => {
                    f.active = false;
                })
                this.durations[index].active = true;
                this.set_active_duration(this.durations[index]);
            }

        }
    }
}
</script>
<style lang="scss">

</style>
