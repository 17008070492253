import axios from "axios";
import Vue from "vue";
import { buttonText } from '@/constants/buttonText';

const baseUrl = process.env.VUE_APP_BASE_END;

export const subscriptions = {
    namespaced: true,
    state: {
        subscriptions: {},
        cancel_subscription: {},
        update_subscription: {},
        pause_subscription: {},
        update_subscription_billing_cycle: {},
        subscription: {},
        invoice: {},
        buttons: {
            pause: buttonText.subscription.pause.confirm_button_text,
            resume_now: buttonText.subscription.resume_now.confirm_button_text,
            resume: buttonText.subscription.resume.confirm_button_text,
            cancel: buttonText.subscription.cancel.submit_button_text,
            reschedule: buttonText.subscription.reschedule.confirm_button_text,
        },
    },
    mutations: {
        setSubscriptions(state, payload) {
            Vue.set(state.subscriptions, payload.id, payload.value);
        },
        setCancelSubscription(state, payload) {
            Vue.set(state.cancel_subscription, payload.id, payload.value);
        },
        setPauseSubscription(state, payload) {
            Vue.set(state.cancel_subscription, payload.id, payload.value);
        },
        setSubscription(state, payload) {
            Vue.set(state.subscription, payload.id, payload.value);
        },
        setInvoice(state, payload) {
            Vue.set(state.invoice, payload.id, payload.value);
        },
        setUpdateSubscription(state, payload) {
            Vue.set(state.update_subscription, payload.id, payload.value);
        },
        setBillingCycle(state, payload) {
            Vue.set(
                state.update_subscription_billing_cycle,
                payload.id,
                payload.value,
            );
        },
        setButtonText(state, payload) {
            Vue.set(state.buttons, payload.id, payload.value);
        },
    },
    actions: {
        async createNewSubscription(
            { commit },
            payload,
        ) {
            const { organization, patient, product_id: productId, data } = payload;

            commit('setSubscriptions', { id: 'loading', value: true });
            commit('setSubscriptions', { id: 'error', value: false });

            try {
                const newSubscriptions = await axios.post(
                    `${baseUrl}/users/${organization}/patient/${patient}/product/${productId}/subscription/create`,
                    data,
                );

                if (newSubscriptions && newSubscriptions.data) {
                    commit('setSubscriptions', { id: 'loading', value: false });
                    commit('setSubscriptions', {
                        id: 'data',
                        value: newSubscriptions.data,
                    });

                    return newSubscriptions.data;
                }
            } catch (error) {
                commit('setSubscriptions', { id: 'loading', value: false });
                commit('setSubscriptions', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setSubscriptions', { id: 'error', value: error.response.data });
                    throw new Error(error);
                } else {
                    commit('setSubscriptions', { id: 'error', value: true });
                    throw new Error(error);
                }
            }
        },
        async getSubscriptions({ rootState, commit }, payload) {
            const organization = rootState.orgId.data;
            const { patient } = payload;

            commit('setSubscriptions', { id: 'loading', value: true });
            commit('setSubscriptions', { id: 'error', value: false });

            try {
                const subscriptions = await axios.get(`${baseUrl}/users/${organization}/user/${patient}/subscriptions`);

                if ('data' in subscriptions) {
                    commit('setSubscriptions', { id: 'loading', value: false });
                    commit('setSubscriptions', { id: 'data', value: subscriptions.data });

                    return subscriptions.data;
                }
            } catch (error) {
                commit('setSubscriptions', { id: 'loading', value: false });
                commit('setSubscriptions', { id: 'data', value: null });
                commit('setSubscriptions', { id: 'error', value: true });

                if (axios.isAxiosError(error)) {
                    commit('setSubscriptions', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async updateSubscription({ rootState, commit }, payload) {
            const organization = rootState.orgId.data;
            const { patient, subscription_id: subscriptionId, data } = payload;

            commit('setUpdateSubscription', { id: 'error', value: false });
            commit('setUpdateSubscription', { id: 'loading', value: true });

            try {
                const response = await axios.put(
                    `${baseUrl}/subscriptions/${organization}/patient/${patient}/subscription/${subscriptionId}/update`,
                    data,
                );

                if ('data' in response) {
                    commit('setUpdateSubscription', { id: 'data', value: response.data });
                    commit('setUpdateSubscription', { id: 'loading', value: false });

                    return response.data;
                }
            } catch (error) {
                commit('setUpdateSubscription', { id: 'error', value: true });
                commit('setUpdateSubscription', { id: 'loading', value: false });
                commit('setUpdateSubscription', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setUpdateSubscription', {
                        id: 'error',
                        value: error.response.data,
                    });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async getMedicationSubscriptions({ commit }, payload) {
            const { organization, patient } = payload;

            commit('setSubscriptions', { id: 'loading', value: true });
            commit('setSubscriptions', { id: 'error', value: false });

            try {
                const medicationSubscriptions = await axios.get(
                    `${baseUrl}/users/${organization}/user/${patient}/subscriptions/medications`,
                );

                if ('data' in medicationSubscriptions) {
                    commit('setSubscriptions', { id: 'loading', value: false });
                    commit('setSubscriptions', {
                        id: 'data',
                        value: medicationSubscriptions.data,
                    });

                    return medicationSubscriptions.data;
                }
            } catch (error) {
                commit('setSubscriptions', { id: 'loading', value: false });
                commit('setSubscriptions', { id: 'data', value: null });
                commit('setSubscriptions', { id: 'error', value: true });

                if (axios.isAxiosError(error)) {
                    commit('setSubscriptions', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async getSubscription({ rootState, commit }, payload) {
            const { patient, subscription_id: subscriptionId, type } = payload;
            const organization = rootState.orgId.data;

            commit('setSubscription', { id: 'loading', value: true });
            commit('setSubscription', { id: 'error', value: false });
            commit('setSubscription', { id: 'data', value: null });

            try {
                const subscription = await axios.get(
                    `${baseUrl}/users/${organization}/user/${patient}/subscription/${subscriptionId}`,
                    {
                        params: {
                            type,
                        },
                    },
                );

                if ('data' in subscription) {
                    commit('setSubscription', { id: 'loading', value: false });
                    commit('setSubscription', { id: 'data', value: subscription.data });

                    return subscription.data;
                }
            } catch (error) {
                commit('setSubscription', { id: 'loading', value: false });
                commit('setSubscription', { id: 'data', value: null });
                commit('setSubscription', { id: 'error', value: true });

                if (axios.isAxiosError(error)) {
                    commit('setSubscription', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async pauseSubscription({ rootState, commit }, payload) {
            const organization = rootState.orgId.data;
            const { subscriptionId, data } = payload;

            commit('setPauseSubscription', { id: 'loading', value: true });

            try {
                const pauseResponse = await axios.post(
                    `${baseUrl}/v2/subscriptions/${subscriptionId}/pause`,
                    data,
                    {
                        headers: {
                            'zenpatient-organization': organization,
                        },
                    },
                );

                return pauseResponse.data;
            } finally {
                commit('setPauseSubscription', { id: 'loading', value: false });
            }
        },
        async cancelSubscription({ rootState, commit }, payload) {
            const organization = rootState.orgId.data;
            const { subscriptionId, data } = payload;

            commit('setCancelSubscription', { id: 'loading', value: true });
            commit('setCancelSubscription', { id: 'error', value: false });

            try {
                const cancelSubscription = await axios.post(
                    `${baseUrl}/v2/subscriptions/${subscriptionId}/cancel`,
                    data,
                    {
                        headers: {
                            'zenpatient-organization': organization,
                        },
                    },
                );

                if ('data' in cancelSubscription) {
                    commit('setCancelSubscription', { id: 'loading', value: false });
                    commit('setCancelSubscription', {
                        id: 'data',
                        value: cancelSubscription.data,
                    });

                    return cancelSubscription.data;
                }
            } catch (error) {
                commit('setCancelSubscription', { id: 'loading', value: false });
                commit('setCancelSubscription', { id: 'data', value: null });
                commit('setCancelSubscription', { id: 'error', value: true });

                if (axios.isAxiosError(error)) {
                    commit('setCancelSubscription', {
                        id: 'error',
                        value: error.response.data,
                    });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async getInvoice({ commit, rootState }, payload) {
            const organization = rootState.orgId.data;
            const { invoice, type } = payload;

            commit('setInvoice', { id: 'error', value: false });
            commit('setInvoice', { id: 'loading', value: true });

            try {
                const response = await axios.get(
                    `${baseUrl}/subscriptions/${organization}/invoice`,
                    {
                        params: {
                            type,
                            invoice,
                        },
                    },
                );

                if ('data' in response) {
                    commit('setInvoice', { id: 'loading', value: false });
                    commit('setInvoice', { id: 'data', value: response.data });

                    return response.data;
                }
            } catch (error) {
                commit('setInvoice', { id: 'loading', value: false });
                commit('setInvoice', { id: 'data', value: null });
                commit('setInvoice', { id: 'error', value: true });

                if (axios.isAxiosError(error)) {
                    commit('setInvoice', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async updateBillingCycle({ rootState, commit }, payload) {
            const organization = rootState.orgId.data;
            const { subscriptionId, newTime } = payload;

            commit('setBillingCycle', { id: 'error', value: false });
            commit('setBillingCycle', { id: 'loading', value: true });

            try {
                const response = axios.post(
                    `${baseUrl}/v2/subscriptions/${subscriptionId}/reschedule`,
                    {
                        new_time: Math.floor(newTime / 1000),
                    },
                    {
                        headers: {
                            'zenpatient-organization': organization,
                        },
                    },
                );

                if ('data' in response) {
                    commit('setBillingCycle', { id: 'data', value: response.data });
                    commit('setBillingCycle', { id: 'loading', value: false });

                    return response.data;
                }
            } catch (error) {
                commit('setBillingCycle', { id: 'loading', value: false });
                commit('setBillingCycle', { id: 'error', value: true });
                commit('setBillingCycle', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setBillingCycle', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },

        async createSubscriptionByProduct({ commit, state }, payload) {
            const { organization, productId } = payload;

            commit('setSubscriptions', { id: 'loading', value: true });
            commit('setSubscriptions', { id: 'error', value: false });

            try {
                const response = await axios.post(
                    `${baseUrl}/v2/subscriptions/product/${productId}`,
                    { quantity: 1 },
                    {
                        headers: { 'zenpatient-organization': organization },
                    },
                );

                commit('setSubscriptions', { id: 'loading', value: false });
                commit('setSubscriptions', {
                    id: 'data',
                    value: [...state.subscriptions.data ?? [], response.data],
                });

                return response.data;
            } catch (error) {
                commit('setSubscriptions', { id: 'loading', value: false });
                commit('setSubscriptions', { id: 'error', value: axios.isAxiosError(error) ? error.response.data : true });

                throw axios.isAxiosError(error) ? error.response.data : error;
            }
        },
    },
};
