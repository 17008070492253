<template>
    <button class="h-icon-button" @click="handleClick">
        <span><slot /></span>
        <span> {{ label }}</span>
    </button>
</template>

<script>
export default {
    name: 'HIconButton',
    props: {
        label: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
;
.h-icon-button {
    background-color: transparent;
    border: 2px solid $charcoal;
    border-radius: 56px;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;

    span {
        font-family: 'Helvetica Now Micro', serif;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.2px;
    }

    svg {
        transition: all 0.3s ease;
    }

    &:hover {
        background-color: $charcoal;
        color: $white;

        svg {
            fill:  $white;
        }
    }
}
</style>
