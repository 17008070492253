import axios from 'axios'
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const alerts = {
    namespaced: true,
    state: {
        closed: false,
        alerts: [],
        current_alert: {},
        create_alert: {},
        notifications: {
            docs: [],
        },
        notificationsError: null,
        unreadCount: 0,
    },
    mutations: {
        setAlerts(state, alerts) {
            state.alerts = alerts;
        },
        setAlert(state, alert) {
            state.current_alert = alert;
        },
        setCreateAlert(state, payload) {
            Vue.set(state.create_alert, payload.id, payload.value);
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
            state.unreadCount = notifications.docs.filter(notification => !notification.read).length;
        },
        setNotificationsError(state, error) {
            state.notificationsError = error;
        },
        markAllAsRead(state) {
            if (state.notifications.docs) {
                state.notifications.docs.forEach(notification => {
                    notification.read = true;
                });
            }
            state.unreadCount = 0;
        },
        updateUnreadCount(state) {
            if (state.notifications?.docs) {
                state.unreadCount = state.notifications.docs.filter(notification => !notification.read).length;
            } else {
                state.unreadCount = 0;
            }
        },
    },

    actions: {
        async createAlert({rootState, commit}, payload) {
            const organization = rootState.orgId.data;
            const { alert_id, data } = payload;
            commit('setCreateAlert', { id: 'error', value: false });
            commit('setCreateAlert', { id: 'loading', value: true });

            try {
                let response = await axios.post(`${baseUrl}/alerts/${organization}/create/${alert_id}`, data);
                if ('data' in response) {
                    commit('setCreateAlert', { id: 'loading', value: false });
                    commit('setCreateAlert', { id: 'data', value: response.data });
                    return response.data;
                }
            } catch (error) {
                commit('setCreateAlert', { id: 'loading', value: false });
                commit('setCreateAlert', { id: 'error', value: true });
                commit('setCreateAlert', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setCreateAlert', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }

        },
        async getNotifications({ commit }) {
            try {
                const response = await axios.get(`${baseUrl}/v2/notifications`);

                commit('setNotifications', response.data);

                return response.data;
            } catch (error) {
                commit(
                    'setNotificationsError',
                    axios.isAxiosError(error) ? error.response.data : error,
                );
            }
        },
        async markAllNotificationsAsRead({ commit }) {
            try {
                const response = await axios.post(`${baseUrl}/v2/notifications/read-all`);

                commit('markAllAsRead');

                if (response.data?.docs) {
                    commit ('setNotifications', response.data);
                }
            } catch (error) {
                console.error('Error marking all notifications as read', error);
            }
        },
        async markNotificationAsRead({ commit, state }, notificationId) {
            try {
                await axios.post(`${baseUrl}/v2/notifications/${notificationId}/read`);

                if (state.notifications.docs) {
                    const updatedDocs = state.notifications.docs.map(notification =>
                        notification._id === notificationId
                            ? { ...notification, read: true }
                            : notification,
                    );

                    commit('setNotifications', { ...state.notifications, docs: updatedDocs });
                }
            } catch (error) {
                console.error('Error marking notification as read', error);
            }
        },
    }
}
