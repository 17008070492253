exports.removeItem = (array, value) => {
    // eslint-disable-next-line no-console
    console.log(array, value);

    return array.filter(function (ele) {
        return ele != value;
    });
};

exports.childResponseMapper = (selectedResponseConditions) => {
    // eslint-disable-next-line no-console
    console.log(selectedResponseConditions);

    if (selectedResponseConditions) {
        const childResponses = selectedResponseConditions.map((responseCondition) => {
            // eslint-disable-next-line no-console
            console.log(responseCondition);

            return {
                is_free_format: true,
                free_format_response: responseCondition.free_format_response,
                question: responseCondition.question._id,
            };
        });

        // eslint-disable-next-line no-console
        console.log('child responses: ', childResponses);

        return childResponses;
    } else {
        return [];
    }
};
