<template>
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="#212931"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.5 11C11.1569 11 12.5 9.65685 12.5 8C12.5 6.34315 11.1569 5 9.5 5C8.10212 5 6.92755 5.95608 6.59451 7.25H3.5C3.08579 7.25 2.75 7.58579 2.75 8C2.75 8.41421 3.08579 8.75 3.5 8.75H6.59451C6.92755 10.0439 8.10212 11 9.5 11ZM3.5 15.25C3.08579 15.25 2.75 15.5858 2.75 16C2.75 16.4142 3.08579 16.75 3.5 16.75H10.5C10.9142 16.75 11.25 16.4142 11.25 16C11.25 15.5858 10.9142 15.25 10.5 15.25H3.5ZM15.5 19C16.8979 19 18.0725 18.0439 18.4055 16.75H21.5C21.9142 16.75 22.25 16.4142 22.25 16C22.25 15.5858 21.9142 15.25 21.5 15.25H18.4055C18.0725 13.9561 16.8979 13 15.5 13C13.8431 13 12.5 14.3431 12.5 16C12.5 17.6569 13.8431 19 15.5 19ZM14.5 7.25C14.0858 7.25 13.75 7.58579 13.75 8C13.75 8.41421 14.0858 8.75 14.5 8.75H21.5C21.9142 8.75 22.25 8.41421 22.25 8C22.25 7.58579 21.9142 7.25 21.5 7.25H14.5Z" />
    </svg>
</template>

<script>
export default {
    name: 'FilterIcon',
};
</script>
