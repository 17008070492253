<template>
    <div class="htooltip">
        <div class="htooltip__content">{{text}}</div>
    </div>
</template>

<script>
    export default {
        name: "HTooltip",
        props: {
            text: {
                type: String,
                required: true
            }
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/utility.scss";

.htooltip {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: .5rem;
    font-family: 'Helvetica Now Text', serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: -0.015rem;
    background-color: $charcoal;
    color: #fff;
    z-index: $z-tooltip;
    padding: 1rem .875rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: 4px solid;
        border-color: #000 #0000 #0000 #0000;
    }

    &__content {
        width: 250px;
    }
}
</style>