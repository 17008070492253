<template>
	<div class="modal-header-icon__container">
        <div class="modal-header-icon__icon">
            <ion-icon :name="icon"></ion-icon>
        </div>
        <div class="modal-header-icon__title">{{title}}</div>
        <div class="modal-header-icon__close" @click="on_close()">
            <ion-icon name="close"></ion-icon>
        </div>
    </div>
</template>
<script>
export default {
	name: 'ModalHeaderIcon',
	components: {},
    props: ['icon', 'title'],
	data() {
		return {

		}
	},
	created() {
	},
	computed: {},
	methods: {
        on_close() {
            this.$emit('onClose', true);
        }
    }
}
</script>
<style lang="scss">
    .modal-header-icon {
        &__container {
            display: flex;
            padding: 2rem;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
        }

        &__icon {
            font-size: 2rem;
            margin-right: 1rem;
            height: 34px;
            width: 34px;
        }

        &__title {
            font-size: 1.5rem;
            font-family: 'ZP-UI', sans-serif;
            font-weight: 600;
        }

        &__close {
            font-size: 1.5rem;
            position: absolute;
            right: 2rem;
            cursor: pointer;
        }
    }
</style>
