import { PRODUCT_PRICING_TYPES } from '../../../constants/product';

export const getSelectedPlanPricing = (planOptions, selectedPlanId) => {
    const selectedPlan = planOptions.find(plan => plan.id === selectedPlanId) || planOptions[0];

    if (selectedPlan) {
        const { price, savings = 0 } = selectedPlan;

        return {
            subTotal: price + savings,
            savings,
            total: price,
            duration: selectedPlan?.type === PRODUCT_PRICING_TYPES.recurring
                ? parseInt(selectedPlan.duration)
                : 0,
        };
    }

    return {
        subTotal: 0,
        savings: 0,
        total: 0,
        duration: 0,
    };
};
