<template>
	<vue-markdown class="h-markdown">{{ content }}</vue-markdown>
</template>

<script>
import VueMarkdown from 'vue-markdown/src/VueMarkdown';
export default {
	name: 'Markdown',
	components: {
		VueMarkdown,
	},
	props: {
		content: {
			type: String,
		},
	},
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.h-markdown {
	margin-bottom: 0.5rem;

    h1 {
		font-family: 'ZP-UI', sans-serif;
		font-size: 1.125rem;
		font-weight: 600;
        text-transform: capitalize;
		color: $gray-900;
		padding: 1.25rem 0;
		margin: 0;
	}

	h2 {
		font-family: 'ZP-UI', sans-serif;
		font-size: 1.125rem;
		line-height: 1.5rem;
		font-weight: 600;
		color: $gray-900;
		padding: 1.5rem 0 1.25rem;
		margin: 0;
	}

	h3 {
		font-family: 'ZP-UI', sans-serif;
		font-size: 0.9325rem;
		text-transform: uppercase;
		font-weight: 500;
		color: $gray-900;
		padding: 1.25rem 0 0.5rem;
		margin: 0;
	}

	h6 {
		font-family: 'ZP-UI', sans-serif;
		font-size: 0.9325rem;
		font-weight: 500;
		color: $gray-900;
		padding: 1.25rem 0 0;
		margin: 0;
	}

	ul {
		margin: 0;
		padding-left: 1.25rem;
	}

	li {
		font-size: 1rem;
		line-height: 1.65rem;
		padding-bottom: 0.5rem;

		&:last-child {
			padding: 0;
		}
	}

	p {
        font-family: 'ZP-UI', sans-serif;
		font-size: 1rem;
		line-height: 1.65rem;
		margin-bottom: 0;
	}

    pre code {
        display: block;
        font-family: 'ZP-UI', sans-serif;
        font-size: 0.9325rem;
		line-height: 1.65rem;
        word-wrap: break-word;
        white-space: pre-wrap; 
    }

    strong {
        font-weight: 500;
    }
}

</style>
