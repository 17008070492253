<template>
    <div>
        <h-modal v-if="showWebcamPrompt" :top-position="userBrowser === 'safari' ? '80%' : '50%'" dark>
            <div class="identity-permission">
                <ArrowUpLeftIcon v-if="userBrowser === 'chrome'" />
                <ArrowUpIcon v-if="userBrowser === 'firefox'" />
                Please allow access to your camera.
            </div>
        </h-modal>
        <transition name="sidebar" mode="out-in">
            <h-sidebar
                v-if="showWebcamInstructions"
                :open="showWebcamInstructions"
                badge_label="Allow Camera"
                @handleClose="closeSidebar"
            >
                <CameraAccess :browser="userBrowser" @upload="triggerFileInput" />
            </h-sidebar>
        </transition>
    </div>
</template>
  
<script>
import ArrowUpIcon from '@/assets/icons/arrowUp.vue';
import ArrowUpLeftIcon from '@/assets/icons/arrowUpLeft.vue';
import HModal from '@/components/Shared/HModalV2.vue';
import HSidebar from '@/components/Shared/HSidebar.vue';
import { detectBrowser } from '@/helpers/browser';

import CameraAccess from './CameraAccess.vue';
  
export default {
    name: 'CameraPermissions',
    components: {
        HSidebar,
        CameraAccess,
        HModal,
        ArrowUpIcon,
        ArrowUpLeftIcon,
    },
    data() {
        return {
            showWebcamPrompt: false,
            showWebcamInstructions: false,
            userBrowser: detectBrowser(),
        };
    },
    methods: {
        triggerFileInput() {
            this.$emit('trigger-file-input');
        },
        closeSidebar() {
            this.showWebcamInstructions = false;
        },
        checkPermissions() {
            navigator.permissions.query({ name: 'camera' })
                .then((permissionStatus) => {
                    if (permissionStatus.state === 'granted') {
                        this.$emit('start-camera');
                    } else if (permissionStatus.state === 'denied') {
                        this.showWebcamInstructions = true;
                    } else {
                        this.checkCameraAccess();
                    }
                })
                .catch(() => {
                    this.checkCameraAccess();
                }); 
        },
        checkCameraAccess() {
            this.showWebcamPrompt = true;

            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    stream.getTracks().forEach((track) => track.stop());
                    this.mediaStream = stream;
                    this.$emit('start-camera', stream);
                })
                .catch(() => {
                    this.showWebcamPrompt = false;
                    this.showWebcamInstructions = true;
                });
        },
    },
};
</script>
