<template>
    <div v-if="plan" class="protocol-summary">
        <div class="protocol-summary__container">
            <div class="protocol-summary__checkout">
                <div class="protocol-summary__checkout-top">
                    <div>
                        <p class="protocol-summary__title">
                            Total
                        </p>
                        <p class="protocol-summary__price">
                            <span v-if="plan.total" class="protocol-summary__price--main">${{ plan.totalDiscount.toFixed(2) }}</span>
                            <span v-if="pricePerMonth" class="protocol-summary__price--sub"> ${{ pricePerMonth }} per month </span>
                        </p>
                    </div>
                    <div class="protocol-summary__btn">
                        <h-button
                            label="Continue"
                            variant="primary"
                            :loading="submitting"
                            :handle-click="onContinueSelected"
                            large
                            wide
                            full_width
                            cta
                        />
                    </div>
                </div>
                <div class="protocol-summary__checkout-bottom">
                    <p class="protocol-summary__price">
                        <span v-if="plan.total && plan.total !== plan.totalDiscount" class="protocol-summary__body strike">
                            ${{ plan.total.toFixed(2) }}
                        </span>
                        <span v-if="plan.savings && plan.savings > 0" class="protocol-summary__price--savings h-orange strong">
                            ${{ plan.savings.toFixed(2) }} Savings
                        </span>
                    </p>
                </div>
            </div>

            <div v-if="plan.duration && plan.type === productPricingTypes.recurring" class="protocol-summary__duration protocol-summary__body">
                Your order will be billed and
                <strong>shipped every {{ monthsString(plan.duration) }}.</strong>
                Modify or cancel your order at any time.
            </div>
            <div v-if="plan.type === productPricingTypes.oneTime" class="protocol-summary__duration protocol-summary__body">
                Your order will be for a one time purchase and <strong>shipped for free</strong>.
            </div>
        </div>
    </div>
</template>
<script>

import HButton from '@/components/Shared/HButton';
import { PRODUCT_PRICING_TYPES } from '@/constants/product';

export default {
    name: 'ProductTotal',
    components: { HButton },
    props: ['submitting', 'product', 'selectedPlanId', 'planOptions'],
    data() {
        return {
            productPricingTypes: PRODUCT_PRICING_TYPES,
        };
    },
    computed: {
        plan() {
            return this.planOptions.find((plan) => plan.id === this.selectedPlanId);
        },
        pricePerMonth() {
            if (!this.plan || this.plan.type === PRODUCT_PRICING_TYPES.oneTime) return null;

            return (this.plan.totalDiscount / this.plan.duration).toFixed(2);
        },
    },
    methods: {
        onContinueSelected() {
            this.$emit('onContinue', true);
        },
        monthsString(months) {
            return `${months} month${months > 1 ? 's' : ''}`;
        },
    },
};
</script>
