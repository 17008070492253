<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8 8H16" :stroke="color" stroke-width="2" />
        <path d="M8 12H16" :stroke="color" stroke-width="2" />
        <path d="M8 16H12" :stroke="color" stroke-width="2" />
        <rect
            x="5"
            y="3"
            width="14"
            height="18"
            rx="3"
            :stroke="color"
            stroke-width="2"
        />
    </svg>
</template>

<script>
export default {
    name: 'OrdersIcon',
    props: {
        color: {
            type: String,
            default: '#212931',
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
