<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18.4672 16.1788C18.8189 15.3295 19 14.4193 19 13.5C19 12.1982 18.5996 10.928 17.8531 9.86153L14.4577 5.01099C13.2634 3.30484 10.7366 3.30485 9.5423 5.011L6.14692 9.86154C5.40041 10.928 5 12.1982 5 13.5C5 14.4193 5.18106 15.3295 5.53284 16.1788C5.88463 17.0281 6.40024 17.7997 7.05025 18.4497C7.70026 19.0998 8.47194 19.6154 9.32122 19.9672C10.1705 20.3189 11.0807 20.5 12 20.5C12.9193 20.5 13.8295 20.3189 14.6788 19.9672C15.5281 19.6154 16.2997 19.0998 16.9497 18.4497C17.5998 17.7997 18.1154 17.0281 18.4672 16.1788Z" stroke="#242E49" stroke-width="1.5" />
        <path d="M14.1213 15.6213C13.7018 16.0409 13.1672 16.3266 12.5853 16.4424C12.0033 16.5581 11.4001 16.4987 10.8519 16.2716C10.3038 16.0446 9.83524 15.6601 9.50559 15.1667C9.17595 14.6734 9 14.0933 9 13.5" stroke="#242E49" stroke-width="1.5" />
    </svg>
</template>

<script>
export default {
    name: 'LabIcon',
};
</script>

<style lang="scss" scoped>

</style>
