<template>
    <div>
        <transition name="drawer">
            <div v-if="open" class="drawer">
                <div class="drawer-header">
                    <button @click="handleClose">
                        <img src="@/assets/icons/close-icon.svg" alt="close-icon" width="20px" height="20px">
                    </button>
                </div>
                <div>
                    <slot />
                </div>
            </div>
        </transition>
        <transition name="backdrop">
            <div v-if="open" class="backdrop" @click="handleClose" />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'HBottomSheet',
    props: {
        open: {
            type: Boolean,
        },
    },
    watch: {
        open(newVal) {
            if (newVal) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
    },
    beforeDestroy() {
        document.body.style.overflow = '';
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.drawer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 80vh;
    background-color: $white-100;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding: 0 1.5rem 2.5rem;
    z-index: $z-drawer;
    overflow-y: scroll;
}

.drawer-header {
    position: sticky;
    top: 0;  
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: $white-100;
    padding: 1.25rem 0;
    z-index: $z-drawer;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: transparent;
        border: none;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover {
            background-color: $bg-medium;
        }
    }

    ion-icon {
        font-size: 3rem;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($black-100, 0.3);
    transition: all 0.3s ease;
    z-index: $z-overlay;
}

.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.2s ease;
}

.backdrop-enter,
.backdrop-leave-to {
    opacity: 0;
}

.drawer-enter-active,
.drawer-leave-active {
  transition: transform 0.3s ease;
}

.drawer-enter,
.drawer-leave-to {
  transform: translateY(100%);
}
</style>
