<template>
    <a :href="link" :target="blank ? '_blank' : ''" class="htextlink" :class="{'cta': cta}">{{ label }}</a>
</template>

<script>
export default {
    name: 'HTextLink',
    props: {
        label: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        cta: {
            type: Boolean,
            default: false,
        },
        blank: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.htextlink {
    color: $charcoal;
    cursor: pointer;

    &:hover {
		text-decoration: underline;
	}
}

.cta {
    display: block;
    font-family: 'Helvetica Now Micro', serif;
    font-size: 0.75rem;
    letter-spacing: 0.075rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: .75rem 0;
}

</style>
