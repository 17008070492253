<template>
    <div>
        <div class="messenger-announcement-container">
            <div class="zp-authored-container flex flex-col">
                <div class="zp-authored-header flex flex-row">
                    <div class="flex flex-col">
                        <div class="zp-avatar-container">
                            <div class="zp-avatar-small">
                                <span v-if="!hasProfileImg" class="zp-avatar s_m"></span>
                                <span
                                    v-else
                                    class="zp-avatar s_m bg-img-cover"
                                    :style="{ backgroundImage: 'url(' + message.author.avatar.square_25 + ')' }"
                                >

                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-1">
                        <div class="zp-author-summary">
                            <span class="zp-author-summary-name">{{ message.author.display_name }}</span>
                            <span class="zp-author-summary-from-clause"> from Healthspan</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="zp-post-body">
                    <div v-for="(block, index) in message.blocks" :key="index" class="component-container">
                        <YoutubeBlock v-if="block.type === 'youtube'" :block="block" :index_num="index"></YoutubeBlock>
                        <ImageBlock v-if="block.type === 'image'" :block="block" :block_obj="block" :index_num="index" />
                        <TextBlock v-if="block.type === 'paragraph'" :block="block" :block_obj="block" :index_num="index" />
                        <ListBlock v-if="block.type === 'list'" :block="block" :block_obj="block" :index_num="index" />
                        <ActionBlock
                            v-if="block.type === 'action'"
                            :link-url="block.linkUrl"
                            :text="block.text"
                        ></ActionBlock>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActionBlock from '@/components/ConversationComponents/BlockComponents/ActionBlock';
import ImageBlock from '@/components/ConversationComponents/BlockComponents/ImageBlock.vue';
import TextBlock from '@/components/ConversationComponents/BlockComponents/TextBlock.vue';
import YoutubeBlock from '@/components/ConversationComponents/BlockComponents/YoutubeBlock';

import ListBlock from './BlockComponents/ListBlock.vue';

export default {
    name: 'AnnouncementComponent',
    components: {
        YoutubeBlock,
        ImageBlock,
        TextBlock,
        ListBlock,
        ActionBlock,
    },
    props: ['message'],
    data() {
        return {};
    },
    computed: {
        hasProfileImg() {
            if (this.message.author.avatar.square_25 === null) {
                return false;
            } else {
                return true;
            }
        },
    },
};

</script>

<style lang="scss">

.messenger-announcement-container {
    width: 60%;
    max-width: 415px;
    border: 1px solid #eee;
    border-radius: 5px;
    @media(max-width: 760px) {
        width: 100%;
        min-width: 270px;
    }
}

</style>
