<template>
    <div class="halert" :class="status">
        <div class="message">
            <img v-if="status=== 'success'" src="@/assets/icons/alert-success.svg" alt="">
            <img v-if="status=== 'warning'" src="@/assets/icons/alert-warning.svg" alt="">
            <img v-if="status=== 'critical'" src="@/assets/icons/alert-critical.svg" alt="">
            <img v-if="status=== 'info'" src="@/assets/icons/alert-info.svg" alt="">
            <p>{{ message }}</p>
        </div>
        <button v-if="withDismiss" class="halert-close" @click="dismissAlert">
            <img src="@/assets/icons/dismiss.svg" alt="">
        </button>
    </div>
</template>

<script>
export default {
    name: 'HAlert',
    props: {
        message: {
            type: String,
            default: '',
        },
        status: {
            type: String,
            default: 'info',
        },
        withDismiss: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        dismissAlert() {
            this.$emit('dismiss');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.halert {
    display: flex;
    justify-content: space-between;
    border-radius: 0.75rem;
    background-color: $gray-50;
    padding: 1.125rem;
}

.success {
    background-color: rgba($success-100, 0.3);
}

.warning {
    background-color: $warning-100;
}

.critical {
    background-color: rgba($critical-200, 0.3);

    p {
        color: $critical-300;
    }
}

.message {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;

    p {
        @extend %caption;
        margin: 0;
    }
}

.halert-close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &:hover {
        background-color: rgba($white, 0.3);
    }

    img {
        width: 12px;
        height: 12px;
    }
}
</style>
