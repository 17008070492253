<template>
    <div class="info-card" :class="{'info-card--active': isActive, 'info-card--disabled': disabled, 'info-card--small': small}">
        <div v-if="badge" class="info-card__badge"><h-badge :label="badge.label" :color="badge.color" dark small /></div>
        <div class="info-card__image" :class="type">
            <img v-if="user_img" :src="user_img" alt="" />
        </div>
        <div class="info-card__container" :class="{'info-card__container--small': small}">
            <div class="info-card__container--content">
                <h2>{{ title }}</h2>
                <p v-if="description" class="identity__body text-dim-80">{{ description }}</p>
                <slot></slot>
            </div>
            <div v-if="withAction" class="info-card__container--action">
                <img v-if="user_img" src="@/assets/images/verification/check.svg" alt="" />
                <ion-icon v-else name="chevron-forward-outline"></ion-icon>
            </div>
        </div>
        <div v-if="button_label" class="info-card__button">
            <h-button :label="button_label" variant="primary" :handleClick="handleClick" full_width />
        </div>
    </div>
</template>

<script>
import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButton.vue';

export default {
    name: 'InfoCard',
    components: {
        HBadge,
        HButton,
    },
    props: {
        small: {
            type: Boolean,
        },
        isActive: {
            type: Boolean,
        },
        withAction: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        type: {
            type: String,
        },
        user_img: {
            type: String,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
        },
        badge: {
            type: Object,
        },
        button_label: {
            type: String,
        },
    },
    methods: {
        handleClick() {
            this.$emit('handleButtonClick');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $border-default;
    border-radius: 0.75rem;
    padding: 2.75rem 1.125rem;
    cursor: default;

    h2 {
        @extend %body;
        font-weight: 700;
    }

    p {
        padding: 1.25rem 0 0;
    }

    ul {
        width: 100%;
        list-style: none;
        padding: 0;
    }

    li {
        width: 100%;
        padding: 0.75rem 0;
        border-bottom: 1px solid $border-default;
    }

    &__image {
        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 0.75rem;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5rem;
        flex: 2;
        width: 100%;

        &--content {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: $charcoal;
        }

        &--action {
            display: none;
        }
    }

    &__button {
        width: 100%;
    }

    &--active,
    &:not(&--disabled):hover {
        border: 1px solid $gray-900;
    }
    &--active:not(&--disabled):hover {
        cursor: pointer;
    }

    &--small {
        padding: 1.5rem;

        @media (min-width: 680px) {
            padding: 1.25rem 1rem;
        }

        @media (max-width: 680px) {
            .info-card__badge {
                align-self: flex-end;
            }

            .info-card__container--content {
                text-align: left;
            }
        }
    }

    @media (min-width: 680px) {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        gap: 3rem;
        padding: 1.5rem 2.25rem;

        &__badge {
            position: absolute;
            top: 1em;
            right: 1rem;
        }

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 1.5rem;
            flex: 2;
            padding: 1.75rem 0;

            &--content {
                display: flex;
                flex-direction: column;
                align-items: start;
                text-align: start;
            }

            &--action {
                display: block;
                width: auto;
            }

            &--small {
                padding: 1.25rem 0;
            }
        }

        &__button {
            display: none;
        }

        ion-icon {
            font-size: 30px;
        }
    }
}

.assessment {
    background-image: url('../../assets/images/start-visit/assessment.png');
    background-size: cover;
    width: 90px;
    height: 57px;
    margin-bottom: 8px;
    margin-top: -34px;
    align-self: flex-start;

    @media (min-width: 680px) {
        align-self: auto;
        width: 167px;
        height: 116px;
        margin: 0;
    }
}

.provider {
    background-image: url('../../assets/images/start-visit/provider.png');
    background-size: cover;
    width: 108px;
    height: 58px;
    margin-bottom: 8px;
    align-self: flex-start;

    @media (min-width: 680px) {
        align-self: auto;
        width: 167px;
        height: 102px;
        margin: 0;
    }
}

.rapamycin {
    background-image: url('../../assets/images/start-visit/rapamycin.png');
    background-size: cover;
    width: 83px;
    height: 72px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-self: flex-start;

    @media (min-width: 680px) {
        align-self: auto;
        width: 167px;
        height: 114px;
        margin: 0;
    }
}

.labs {
    background-image: url('../../assets/images/start-visit/labs.png');
    width: 83px;
    height: 72px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-self: flex-start;

    @media (min-width: 680px) {
        align-self: auto;
        width: 167px;
        height: 114px;
        margin: 0;
    }
}
</style>
