<template>
    <ul class="features">
        <li v-for="feature in features">
            <div class="features__icon">
                <img src="@/assets/icons/checkmark-verified.svg" alt="">
            </div>
            <div class="protocol-body810">{{ feature.description }}</div>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'ProtocolFeatures',
    props: {
        features: {
            type: Array,
            default: () => []}
    },
};
</script>

<style lang="scss" scoped>
.features {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    list-style: none;
    padding: 2rem 0;
    margin: 0 auto;

    li {
        display: flex;
        align-items: start;
        gap: .75rem;
    }
}
</style>
