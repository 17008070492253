import { deprecatedProducts } from '@/constants/deprecatedProducts';

export default {
    DEFAULT_PREFERENCE_SELECTION: 'starter',
    selectProduct(products, filter) {
        return products.find(product => {
            return product.pricing.recurring.interval_count === filter.duration && product.plan_properties.dosage_frequency.label === filter.frequency.frequency.label;
        });
    },
    selectProductByFrequency(products, filter) {
        return products.find(product => {
            return product.plan_properties.dosage_frequency.label === filter.frequency.frequency.label;
        });
    },

    hasReview(treatmentSettings) {
        const selectedConfigurations = Object.values(treatmentSettings);

        return !!selectedConfigurations.find(s => s.recommendation_requested);
    },

    generatePrice(product, frequency, membershipPrice, units) {
        const productId = product.pricing.product ? product.pricing.product : product.stripe_product_id;

        const deprecatedProduct = deprecatedProducts.find(p => productId === p);
        let price = product.pricing.unit_amount / 100;

        let monthlyUnits = units;

        if (!units) {
            monthlyUnits = frequency?.has_custom_quantity ? frequency.custom_quantity.default : product.plan_properties.monthly_units;
        }

        if (deprecatedProduct) {
            price = price * monthlyUnits;
        }

        const productPricing = {
            product: frequency?.has_custom_quantity ? (monthlyUnits * price) : price,
            product_savings: product.pricing.discount ? product.pricing.discount / 100 : 0,
            membership: membershipPrice ? membershipPrice.unit_amount / 100 : 0,
            membership_savings: membershipPrice && membershipPrice.discount ? membershipPrice.discount / 100 : 0,
        };

        productPricing['total'] = productPricing.product + productPricing.product_savings + productPricing.membership + productPricing.membership_savings;
        productPricing['total_discount'] = (productPricing.total) - (productPricing.product_savings + productPricing.membership_savings);

        return productPricing;
    },

    generateTotalPrice(durations) {
        const pricing = {
            total: 0,
            total_discount: 0,
            product_savings: 0,
            membership_savings: 0,
            total_savings: 0,
            product: 0,
            membership: 0,
        };

        for (let i = 0; i < durations.length; i++) {
            if (durations[0].prices) {
                pricing.months = durations[i].months;
                pricing.total += durations[i].prices.total;
                pricing.total_discount += durations[i].prices.total_discount;
                pricing.product_savings += durations[i].prices.product_savings;
                pricing.membership_savings += durations[i].prices.membership_savings;
                pricing.product += durations[i].prices.product;
                pricing.membership += durations[i].prices.membership;
            }
        }

        return pricing;
    },
};
