<template>
    <div class="settings-section">
        <div class="settings-alert">
            <transition name="alert-fade" mode="out-in">
                <HAlert v-if="alert.status" :message="alert.message" :status="alert.status" @dismiss="closeAlert" />
            </transition>
        </div>
        <div class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div class="settings-section__header">
            <h2>Personal Information</h2>
            <p>Update your information</p>
        </div>
        <form class="settings-info" @submit.prevent="updateProfileInfo">
            <h-text-input-secondary
                id="firstName"
                v-model="userData.first_name"
                name="firstName"
                label="First Name"
                :vuelidate="$v.userData.first_name"
                :errors="[
                    !$v.userData.first_name.required && $v.userData.first_name.$dirty && errorMessages.FIRST_NAME_EMPTY,
                    !$v.userData.first_name.minLength && $v.userData.first_name.$dirty && errorMessages.FIRST_NAME_SHORT,
                ]"
            />
            <h-text-input-secondary
                id="lastName"
                v-model="userData.last_name"
                name="lastName"
                label="Last Name"
                :vuelidate="$v.userData.last_name"
                :errors="[
                    !$v.userData.last_name.required && $v.userData.last_name.$dirty && errorMessages.LAST_NAME_EMPTY,
                    !$v.userData.last_name.minLength && $v.userData.last_name.$dirty && errorMessages.LAST_NAME_SHORT,
                ]"
            />
            <h-dropdown-secondary
                v-model="userData.sex"
                label="Gender"
                :options="gender"
                placeholder="Select State"
                :selected="userData.sex"
                :vuelidate="$v.userData.sex"
                :error="($v.userData.sex.$invalid && $v.userData.sex.$dirty) && errorMessages.SEX"
            />
            <h-text-input-secondary
                id="birthday"
                v-model="userData.birthday"
                name="birthday"
                label="Birthday"
                mask="##-##-####"
                :vuelidate="$v.userData.birthday"
                :errors="[
                    !$v.userData.birthday.required && $v.userData.birthday.$dirty && errorMessages.BIRTHDAY_EMPTY,
                    $v.userData.birthday.$model?.length >= 10 && !$v.userData.birthday.isValidAge && !$v.userData.birthday.validateDate && $v.userData.birthday.$dirty && errorMessages.BIRTHDAY_DATE,
                    $v.userData.birthday.$model?.length >= 10 && !$v.userData.birthday.isValidAge && $v.userData.birthday.validateDate && $v.userData.birthday.$dirty && errorMessages.BIRTHDAY_AGE,
                ]"
            />
            <div class="height">
                <p class="settings-info__label">
                    Height
                </p>
                <div class="settings-info__flex">
                    <h-dropdown-secondary 
                        v-model="userData.height_feet" 
                        :selected="userData.height_feet"
                        :options="heightFeetOptions" 
                        :vuelidate="$v.userData.height_feet" 
                        suffix="ft" 
                        :error="($v.userData.height_feet.$invalid && $v.userData.height_feet.$dirty) && errorMessages.HEIGHT"
                    />
                    <h-dropdown-secondary 
                        v-model="userData.height_inches" 
                        :selected="userData.height_inches"
                        :options="heightInchesOptions" 
                        :vuelidate="$v.userData.height_inches" 
                        suffix="in" 
                        :error="($v.userData.height_inches.$invalid && $v.userData.height_inches.$dirty) && errorMessages.HEIGHT"
                    />
                </div>
            </div>
            <h-text-input-secondary
                id="weight"
                v-model="userData.weight"
                name="weight"
                label="Weight (Lbs)"
                :vuelidate="$v.userData.weight"
                :errors="[$v.userData.weight.$invalid && $v.userData.weight.$dirty && errorMessages.WEIGHT]"
            />
            <h-button
                variant="secondary"
                :loading="submitting"
                :disabled="$v.$invalid"
                class="settings-section__btn"
            >
                Submit
            </h-button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HAlert from '@/components/Shared/HAlert.vue';
import HDropdownSecondary from '@/components/Shared/HDropdownSecondary.vue';
import HTextInputSecondary from '@/components/Shared/HTextInputSecondary.vue';
import { dismissAlert, showAlertWithTimeout } from '@/helpers/alert';
import { convertToInches,  getHeight } from '@/helpers/conversions';
import { birthdayValidation, errorMessages, heightFeetValidation, heightInchesValidation, nameValidation, requiredValidation, weightValidation } from '@/helpers/validations';

import HButton from '../../Shared/HButtonV2.vue';

export default {
    name: 'PersonalInfoSection',
    components: {
        HAlert,
        HTextInputSecondary,
        HDropdownSecondary,
        HButton,
    },
    data() {
        return {
            submitting: false,
            userData: {
                first_name: '',
                last_name: '',
                sex: null,
                birthday: '',
                height_feet: null,
                height_inches: null,
                weight: null,
            },
            errorMessages,
            alert: {
                message: '',
                status: '',
            },
            gender: [
                {
                    name: 'Male',
                    value: 'male',
                },
                {
                    name: 'Female',
                    value: 'female',
                },
            ],
            heightFeetOptions: Array.from({ length: 10 }, (_, i) => ({ name: (i + 1).toString(), value: (i + 1).toString() })),
            heightInchesOptions: Array.from({ length: 12 }, (_, i) => ({ name: i.toString(), value: i.toString() })),
        };
    },
    validations: {
        userData: {
            first_name: nameValidation,
            last_name: nameValidation,
            sex: requiredValidation,
            birthday: birthdayValidation,
            weight: weightValidation,
            height_feet: heightFeetValidation,
            height_inches: heightInchesValidation,
        },
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
    },
    created() {
        const height = this.user.height ? getHeight(this.user.height) : { in: null, ft: null };
        
        this.userData = {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            sex: this.user.sex,
            birthday: this.user.birthday,
            weight: this.user.weight,
            height_inches: height.in,
            height_feet: height.ft,
        };
    },
    methods: {
        ...mapActions({
            updateUser: 'user/updateUser',
        }),
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        closeAlert() {
            dismissAlert(this);
        },
        async updateProfileInfo() {
            this.submitting = true;

            if (this.$v.$invalid) {
                this.submitting = false;
         
                return;
            }

            let inches = convertToInches(this.userData.height_feet, this.userData.height_inches);

            const payload = {
                _id: this.user._id,
                first_name: this.userData.first_name,
                last_name: this.userData.last_name,
                sex: this.userData.sex,
                birthday: this.userData.birthday,
                height: inches,
                weight: parseInt(this.userData.weight),
            };

            try {
                await this.updateUser(payload);
                showAlertWithTimeout(this, 'Personal information updated successfully', 'success');
            } catch (error) {
                showAlertWithTimeout(this, error.message, 'critical');
            } finally {
                this.submitting = false;
            }
        },
    },
};
</script>
