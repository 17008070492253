<template>
    <div class="loading-wrapper">
        <div class="loaders-container">
            <div class="container">
                <div :style="circleStyle" class="circle"></div>
            </div>
        </div>
        <span v-if="text" class="loading-text">{{ text }}</span>
    </div>
</template>

<script>

export default {
    name: 'LoadingContainer',
    props: ['color', 'text'],
    computed: {
        circleStyle() {
            return {
                borderTopColor: this.color ? this.color : '#93ba79',
            };
        },
    },
};

</script>

<style lang="scss">
.loading-wrapper {
    width: 90%;
    height: 20px;
    position: absolute;
}

.loaders-container {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-size: 0;

    .container {
        position: relative;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 30px;
        width: 214px;
        height: 140px;
    }
}

.loading-text {
    display: block;
    width: 100%;
    text-align: center;
    font-family: 'ZP-UI', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    padding-top: 3rem;
}

.circle {
    box-sizing: border-box;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 10px solid rgba(185, 185, 185, 0.2);
    border-top-color: #93ba79;
    animation-name: spin;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
