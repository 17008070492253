import axios from 'axios'
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const organization = {
    namespaced: true,
    state: {
        organization_states: {}
    },
    getters: {},
    mutations: {
        setOrganizationStates(state, payload) {
            Vue.set(state.organization_states, payload.id, payload.value);
        }
    },
    actions: {
        async getOrganizationStates({commit, rootState}) {
            const organization = rootState.orgId.data;
            commit('setOrganizationStates', {id: 'loading', value: true});
            commit('setOrganizationStates', {id: 'error', value: false});

            try {
                let response = await axios.get(`${baseUrl}/organization/${organization}/states`);
                if ('data' in response) {
                    commit('setOrganizationStates', {id: 'loading', value: false});
                    commit('setOrganizationStates', {id: 'data', value: response.data});
                    return response.data;
                }
            } catch (error) {
                commit('setOrganizationStates', {id: 'loading', value: false});
                commit('setOrganizationStates', {id: 'error', value: true});
                commit('setOrganizationStates', {id: 'data', value: null});

                if (axios.isAxiosError(error)) {
                    commit('setOrganizationStates', { id: 'error', value: error.response.data });
                    throw error.response.data;
                } else {
                    throw error;
                }
            }

        }
    }
}