<template>
    <div
        class="intake"
        role="button"
        tabIndex="0"
        data-test="incomplete-intake-card-btn"
        @click="onContinue(incompleteIntake)"
    >
        <div class="intake__content">
            <div
                class="intake__cancel"
                role="button"
                tabIndex="0"
                @click="handleCancel"
            >
                <ShapeIcon fill="#212121" />
            </div>
            <div class="intake__image">
                <img :src="intakeInfo.image" :alt="intakeInfo.title">
            </div>
            <div class="intake__info">
                <div class="intake__progress">
                    <div class="intake__progress-bar intake__progress-bar--completed" />
                    <div class="intake__progress-bar" :class="!incompleteIntake.status.incomplete.total && 'intake__progress-bar--completed'" />
                    <div class="intake__progress-bar" />
                </div>
                <div class="intake__title">
                    {{ intakeInfo.title }}
                </div>
            </div>
        </div>
        <ArrowRightIcon class="intake__arrow" fill="#212121" />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import ArrowRightIcon from '@/assets/icons/ArrowRight';
import ShapeIcon from '@/assets/icons/Shape';
import { getIncompleteIntakeInfo } from '@/components/Dashboard/Widgets/IncompleteIntakesWidget/utils/getIncompleteIntakeInfo';
import { getProductTemplatesMap } from '@/store/selectors/productTemplates';

export default {
    name: 'CardContent',
    components: {
        ArrowRightIcon,
        ShapeIcon,
    },
    props: {
        incompleteIntake: {
            type: Object,
            required: true,
        },
        onContinue: {
            type: Function,
            required: true,
        },
        onCancel: {
            type: Function,
            required: true,
        },
    },
    computed: {
        ...mapState({
            productTemplatesMap: getProductTemplatesMap,
        }),
        intakeInfo() {
            return getIncompleteIntakeInfo(this.productTemplatesMap, this.incompleteIntake);
        },
    },
    methods: {
        handleCancel(event) {
            event.stopPropagation();
            this.onCancel(this.incompleteIntake);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/utility.scss';

.intake {
    width: 100%;
    display: flex;
    padding: 1rem;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-radius: 1rem;
    color: $powder-blue-400;
    background-color: $white-100;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
        background-color: $gray-50;
    }

    &__content {
        display: flex;
        width: 100%;
        min-width: 0;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 1rem;
    }

    &__info {
        display: flex;
        width: 100%;
        min-width: 0;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__title {
        @include caption;
        font-weight: 700;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        min-width: 36px;
        height: 36px;
        border-radius: 0.5rem;
        background-color: $gray-75;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__cancel {
        display: flex;
        position: relative;
        min-width: 16px;
        width: 16px;
        height: 16px;
        align-items: center;
        justify-content: center;

        &::before {
            content: '';
            z-index: 0;
            position: absolute;
            width: calc(100% + 16px);
            height: calc(100% + 16px);
            border-radius: 50%;
        }

        &:hover::before {
            background-color: $powder-blue-100;
            opacity: 0.1;
        }
    }

    &__arrow {
        width: 24px;
        min-width: 24px;
        height: 24px;
    }

    &__progress {
        display: grid;
        width: 100%;
        max-width: 208px;
        height: 6px;
        grid-template-columns: repeat(3, 1fr);
        gap: 2px;

        &-bar {
            border-radius: 4px;
            height: 100%;
            background-color: $calm-green-100;
        }

        &-bar--completed {
            background-color: $calm-green-200;
        }
    }
}

@media ($min-810) {
    .intake {
        padding: 2rem 1.5rem;

        &__title {
            @include body;
            font-weight: 700;
        }

        &__image {
            width: 74px;
            min-width: 74px;
            height: 74px;
        }
    }
}
</style>
