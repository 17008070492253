import { NOTIFICATION_ROUTE_MAP } from './NotificationMap';

export function navigateToNotificationLink(notification, userId, router) {
    const basePath = `/dashboard/${userId}`;

    let categoryPath;

    if (notification.message === 'Lab Results are now ready') {
        categoryPath = `lab-analysis/${notification.data.reportId}`;
    } else {
        categoryPath = NOTIFICATION_ROUTE_MAP[notification.category];
    }

    router.push({ path: `${basePath}/${categoryPath}` });
}
