<template>
    <div class="h-certify">
        <img src="@/assets/images/verification/legitscript.svg" alt="" />
        <img src="@/assets/images/verification/hippa.svg" alt="" />
        <img src="@/assets/images/verification/surescripts.svg" alt="" />
    </div>
</template>

<script>
export default {
    name: 'HCertification',
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
.h-certify {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $border-light;
    padding: 2rem 0;
}
</style>
