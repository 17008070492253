<template>
    <div class="h-input-v2">
        <input
            :id="id"
            v-mask="mask"
            :type="type"
            :value="value"
            :name="name"
            class="h-input-v2__input"
            :class="{ 'h-input-v2__error-border': errors && errors.some((value) => !!value) }"
            @input="$emit('input', $event.target.value) && vuelidate.$touch()"
        />
        <label class="h-input-v2__label" :class="{ 'h-input-v2__has-value': value }" :for="id">{{ label }}</label>
        <!-- <span class="h-input-v2__error" v-if="errors && errors.length" v-for="error in errors">{{ error ? error : null }}</span> -->
        <span v-if="hasErrors">
            <span v-for="(error, index) in errors" :key="index" class="h-input-v2__error">
                <span v-if="error">{{ error }}</span>
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'Input',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: String,
        },
        id: {
            type: String,
        },
        name: {
            type: String,
        },
        label: {
            type: String,
            required: true,
        },
        vuelidate: {
            type: Object,
            required: true,
        },
        mask: {
            type: String,
        },
        errors: {
            type: Array,
        },
        error_border: {
            Boolean,
        },
    },
    computed: {
        hasErrors() {
            return this.errors && this.errors.some((value) => !!value);
        },
    },
};
</script>

<style lang="scss" scoped>
.h-input-v2 {
    position: relative;
    font-family: 'Helvetica Now Text', serif;

    input {
        &:focus {
            outline: none;
            border: 1px solid #1a1513;
        }
    }

    &__input {
        width: 100%;
        height: 60px;
        border: 1px solid #d8d8d8;
        border-radius: 0.5rem;
        font-size: 1.125rem;
        background-color: #fff;
        padding: 0.75rem 1rem 0;
    }

    &__label {
        position: absolute;
        left: 1rem;
        top: 1.25rem;
        color: #c0c0c0;
        font-size: 0.875rem;
        transform-origin: 0% 0%;
        transition: all 200ms cubic-bezier(0.33, 0, 0, 1) 0s;
        cursor: text;
        pointer-events: none;
    }

    &__has-value {
        top: 0.5rem !important;
        left: 1rem !important;
        color: #757575;
        transform: scale(0.75) !important;
    }

    &__error {
        display: inline-block;
        color: #ff4c41;
        font-size: 0.75rem;
        padding-top: 0.125rem;
    }

    &__error-border {
        border-color: #ff4c41;
    }

    &__input:focus + &__label {
        top: 0.5rem;
        left: 1rem;
        color: #757575;
        transform: scale(0.75) !important;
    }
}
</style>
